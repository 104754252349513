import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { MatTableDataSource } from '@angular/material/table';
import { UnitHelpers } from '../../../units/unit-helpers';
import { IUnitSystemDto, UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ScreenService } from '../../../../shared/services';
import { Subscription } from 'rxjs';
import { IColNameWithUnit, IParsedFileResult } from '../../../../+store/import-data/model/parsed-result';
import { GridResizeService } from '../../../../shared/services/grid-resize.service';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { GridConfig } from '../../../../shared/components/grid/grid-config';
import { selectCurrentUnitSystem } from '../../../../+store/units/units.selectors';
import { Store } from '@ngrx/store';
import { DELTA_TIME_COL_NAME } from '../../../../+store/import-data/model/col-config';
import { IIndexedDataType } from '@dunefront/common/dto/common-dto.interfaces';
import { IGridColumnConfig } from '../../../../shared/components/grid/grid.interfaces';

interface IColumnData {
  [index: number]: { key: string | undefined; unitSystem?: UnitSystem; unit?: number };
}

const selectionCell = {
  columnName: 'Include row',
  originalUnit: undefined,
  originalColumnName: 'Include row',
};

@Component({
  selector: 'app-paste-data-grid',
  templateUrl: './paste-data-grid.component.html',
  styleUrls: ['./paste-data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasteDataGridComponent<T extends IIndexedDataType> implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(CdkVirtualScrollViewport)
  public virtualScroll: CdkVirtualScrollViewport | undefined;

  @Input()
  public gridConfig!: GridConfig<T>;

  private _parsedResult: IParsedFileResult | undefined;
  public columnsWithUnits: IColNameWithUnit[] = [];
  public colNames: string[] = [];
  public colUnits: string[] = [];
  public origColNames: string[] = [];
  public origColUnits: string[] = [];
  public pastedRowsExpanded = true;

  @Input()
  public set parsedResult(parsedResult: IParsedFileResult | undefined) {
    this._parsedResult = parsedResult;
    this.columnsWithUnits = [selectionCell, ...(this._parsedResult?.columnsWithUnits ?? [])];
    const parsedColumn = this.columnsWithUnits.filter((colWithUnit) => colWithUnit.columnName !== DELTA_TIME_COL_NAME);
    const parsedColumnNames = parsedColumn.map((col) => col.columnName);
    const parsedColumnUnits = parsedColumn.map((col) => col.originalUnit ?? '');
    if (this.colNames.toString() !== parsedColumnNames.toString() || this.colUnits.toString() !== parsedColumnUnits.toString()) {
      this.colNames = parsedColumnNames;
      this.colUnits = parsedColumnUnits;
      this.origColNames = parsedColumnNames.map((col) => 'orig-name-' + col);
      this.origColUnits = parsedColumnNames.map((col) => 'orig-unit-' + col);
      this.mapParsedColumnsToConfigColumns();
    }
  }

  public get parsedResult(): IParsedFileResult | undefined {
    return this._parsedResult;
  }

  public height = 0;
  public dataSource: MatTableDataSource<string[]> | null = null;
  public headerDataSource: MatTableDataSource<string[]> | null = null;
  private subscription = new Subscription();

  public configColumnItems: ISelectItem<string>[] = [];
  public unitSymbolsItems: { [key: string]: ISelectItem<UnitSystem>[] | null } = {};
  public currentUnitSystem?: IUnitSystemDto;

  @Output()
  public columnSelectionsChanged = new EventEmitter<IColumnData>();
  public columnSelections: IColumnData = {};

  @Output()
  public rowsToIncludeChanged = new EventEmitter<{ [index: number]: boolean }>();
  public rowsToInclude: { [index: number]: boolean } = {};

  constructor(
    private resizeService: ScreenService,
    private cdRef: ChangeDetectorRef,
    private el: ElementRef,
    private store: Store,
    gridResizeService: GridResizeService,
  ) {
    this.subscription.add(
      this.store.select(selectCurrentUnitSystem).subscribe((currentUnitSystem) => (this.currentUnitSystem = currentUnitSystem)),
    );
    this.subscription.add(this.resizeService.screenResized$.subscribe((size) => this.resize()));
    this.subscription.add(gridResizeService.getResizeSubject().subscribe(() => this.resize()));
  }

  public ngAfterViewInit(): void {
    this.resize();
  }

  public columnTypeSelectError(index: number): string {
    const key = this.columnSelections[index]?.key;
    if (key == null) {
      return 'Column type is required';
    } else if (key !== 'ignore' && Object.values(this.columnSelections).filter((columnSelection) => columnSelection.key === key).length > 1) {
      return 'Column can be selected only one time';
    }
    return '';
  }

  public columnUnitSelectError(index: number): string {
    const unit = this.columnSelections[index]?.unit;
    if (unit == null) {
      return 'Unit is required';
    }
    return '';
  }

  public updateTypeSelection(value: string | undefined, columnIndex: number): void {
    const unitSystem = value != undefined ? this.gridConfig.columns.find((column) => column.colId === value)?.unitSystem : undefined;
    this.columnSelections[columnIndex] = {
      key: value,
      unitSystem,
    };
    this.columnSelectionsChanged.emit(this.columnSelections);
  }

  public updateUnitSelection(value: UnitSystem, columnIndex: number): void {
    this.columnSelections[columnIndex].unit = value;
    this.columnSelectionsChanged.emit(this.columnSelections);
  }

  public async updateRowsToIgnore(value: boolean, rowIndex: number): Promise<void> {
    this.rowsToInclude[rowIndex] = value;
    this.rowsToIncludeChanged.emit(this.rowsToInclude);
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.parsedResult != null && this.parsedResult != null) {
      this.dataSource = new TableVirtualScrollDataSource(this.parsedResult.data);
      const origColNames = this.columnsWithUnits.map((col) => col.originalColumnName);
      const origColUnits = this.columnsWithUnits.map((col) => col.originalUnit ?? '-');
      this.headerDataSource = new TableVirtualScrollDataSource([origColNames, origColUnits]);
      this.rowsToInclude = {};
      this.dataSource?.data.forEach((row, index) => (this.rowsToInclude[index] = true));
      this.rowsToIncludeChanged.emit(this.rowsToInclude);
    }

    if (changes.gridConfig != null) {
      this.configColumnItems = [
        {
          text: 'Ignore item',
          value: 'ignore',
        },
      ];
      changes.gridConfig.currentValue.columns
        .filter(
          (column: IGridColumnConfig<T>) => column.colId != null && column.colId.toString().trim() != '' && !column.disabled && column.visible,
        )
        .forEach((column: IGridColumnConfig<T>) => {
          const columnId = column.colId as string;
          const columnHeaderText = column.headerText ?? (column.colId as string);

          this.configColumnItems.push({
            text: columnHeaderText,
            value: columnId,
          });

          if (this.unitSymbolsItems[columnId] == null) {
            this.unitSymbolsItems[columnId] =
              column.unitSystem == null || column.unitSystem == UnitSystem.None ? [] : UnitHelpers.getUnitSymbolSelectItems(column.unitSystem);
          }
        });

      this.mapParsedColumnsToConfigColumns();
    }
  }

  private mapParsedColumnsToConfigColumns(): void {
    if (this.columnsWithUnits != null && this.gridConfig != null) {
      this.columnsWithUnits.forEach((column, index) => {
        // columnsWithUnits has additional column on a first position to select included rows
        if (column.columnName === 'Include row') {
          return;
        }
        const uiColIndex = index - 1;
        const matchedConfigColumnItem = this.gridConfig.columns
          .filter(
            (column: IGridColumnConfig<T>) => column.colId != null && column.colId.toString().trim() != '' && !column.disabled && column.visible,
          )
          .find((item) => item.matchingStrings?.find((matchingString) => column.columnName.toLowerCase().includes(matchingString)));

        const matchedColId = matchedConfigColumnItem ? (matchedConfigColumnItem.colId as string) : undefined;

        this.updateTypeSelection(matchedColId, uiColIndex);

        if (matchedConfigColumnItem != null && matchedColId != null) {
          const matchedUnitSymbol = this.unitSymbolsItems[matchedColId]?.find((item) => item.text === column.originalUnit);
          if (matchedUnitSymbol != null) {
            this.updateUnitSelection(matchedUnitSymbol.value, uiColIndex);
          }
        }
      });
    }
  }

  public getColumnSelectionKeyByIndex(index: number): string | undefined {
    return this.columnSelections[index]?.key;
  }

  public getUnitSymbolItemsByIndex(index: number): ISelectItem<UnitSystem>[] {
    const columnSelectionKey = this.getColumnSelectionKeyByIndex(index);
    if (columnSelectionKey) {
      return this.unitSymbolsItems[columnSelectionKey] ?? [];
    }
    return [];
  }

  public isHeaderOrigNamesRowVisible(): boolean {
    return this.columnsWithUnits.some((col, index) => index > 1 && col.originalColumnName != null && col.originalColumnName != '');
  }

  public isHeaderOrigUnitsRowVisible(): boolean {
    return this.columnsWithUnits.some((col, index) => index > 1 && col.originalUnit != null && col.originalUnit != '');
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private resize(): void {
    setTimeout(() => {
      if (this.el != null) {
        this.height = this.el.nativeElement.clientHeight;
        this.cdRef.markForCheck();
        return;
      }
      this.resize();
    }, 10);
  }
}
