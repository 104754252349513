<div class="home-sub-header" [class.electron]="isElectron">

  <ng-container *ngIf="repository">
  <div class="subhead-bar" [ngSwitch]="repository" *ngIf="!isRecentProjects && !isSettings">
    <span *ngSwitchCase="'personal'">Personal Projects</span>
    <span *ngSwitchCase="'shared'">Shared Projects</span>
    <span *ngSwitchCase="'demo'">Demo Projects</span>
    <div *ngSwitchCase="'trash'" class="trash-subheader">
      <span>Trash</span>
      <div class="small-button-panel">
        <app-button
          textIcon="icon-bin-trash-remove-cross"
          text="Empty trash"
          [isUiLockable]="false"
          (buttonClick)="onEmptyTrash()"
        ></app-button>
      </div>
    </div>
    <span *ngSwitchCase="'backup'">Backup</span>
  </div>

  <div class="subhead-bar" *ngIf="isRecentProjects">
    <span>Recently viewed</span>
  </div>
    <div class="subhead-bar" *ngIf="isSettings">
      <span>Settings</span>
    </div>
  </ng-container>

  <div class="filter-bar-container">
    <app-file-filter></app-file-filter>
  </div>


  <div *ngIf="appSpecificPlusContextMenu">
    <div class="add-button" data-cy="home-new-project" (click)="cm.show($event); $event.stopPropagation()">
      <span>Add new</span>
      <span class="icon-bottom-chevron"> </span>
    </div>
    <p-contextMenu #cm [model]="appSpecificPlusContextMenu" appendTo="body" ></p-contextMenu>
  </div>
</div>