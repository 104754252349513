<div (click)="onHelpChange('completion', 'schematics')">
  <div class="schematic-grid-container" *ngIf="data$ | async as data" #schematic>
    <div class="schematic-data-container">
      <app-schematic-data title="Workstring and Treating Lines" [data]="data.workstringAndTreatingLines"></app-schematic-data>
      <app-schematic-data
        *ngIf="data.toolPosition !== ToolPosition.Reverse"
        title="Outer Annulus Below Top of Screens"
        [data]="data.outerAnnulusBelowTopOfScreens"
      ></app-schematic-data>
      <app-schematic-data
        *ngIf="data.toolPosition !== ToolPosition.Reverse"
        title="Inner Annulus Below Top of Screens"
        [data]="data.innerAnnulusBelowTopOfScreens"
      ></app-schematic-data>
    </div>
    <div class="schema-container">
      <app-page-panel [header]="'Schematic'" [allowFullScreen]="false">
        <img [src]="data.imageRef" alt="schema" (load)="onImageLoaded()" />
      </app-page-panel>
    </div>
    <div class="schematic-data-container">
      <app-schematic-data title="Casing Annulus and Return Lines" [data]="data.casingAnnulusAndReturnLines"></app-schematic-data>
      <app-schematic-data
        *ngIf="data.toolPosition !== ToolPosition.Reverse"
        title="Blank Annulus"
        [data]="data.blankOuterAnnulus"
      ></app-schematic-data>
      <app-schematic-data *ngIf="data.toolPosition !== ToolPosition.Reverse" title="Washpipe" [data]="data.washpipe"></app-schematic-data>
    </div>
  </div>
</div>
