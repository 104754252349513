import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { homePageRoutes } from '@dunefront/client-common/pages/home/home-page.routes';
import { testPageRoutes } from '@dunefront/client-common/pages/simulate-evaluate-page/test-page/test-page.routes';
import { HomeComponent } from '@dunefront/client-common/pages/home/home.component';
import { calculationsPageRoutes } from '@dunefront/client-common/pages/calculations-page/calculations-page.routes';
import { simulatePageRoutes } from '@dunefront/client-common/pages/simulate-evaluate-page/simulate-page.routes';
import { ModuleSelectorDialogComponent } from '@dunefront/client-common/pages/home/module-selector-dialog/module-selector-dialog.component';
import { FormsModule } from '@angular/forms';
import { HomeModule } from '@dunefront/client-common/pages/home/home.module';
import { UnitsTestComponent } from '@dunefront/client-common/common-modules/units/units-test/units-test.component';
import { psdAnalysisRoutes } from '@dunefront/client-common/pages/psd-analysis-page/psd-analysis.routes';
import { RibbonMenuModule } from '@dunefront/client-common/common-modules/ribbon-menu/ribbon-menu.module';
import { DbDependentContentComponent } from '@dunefront/client-common/shared/components/db-dependent-content/db-dependent-content.component';
import { trendAnalysisPageRoutes } from '@dunefront/client-common/pages/trend-analysis-page/trend-analysis-page.routes';
import { dataAnalysisPageRoutes } from '@dunefront/client-common/pages/data-analysis-page/data-analysis-page.routes';
import { HomePageUndoRedoGuard } from '@dunefront/client-common/shared/guards/home-page-undo-redo-guard.service';
import { RouteModuleAdmin } from '@dunefront/common/common/routes-names/admin-routes-names';
import {
  RouteModuleFile,
  RouteModuleHome,
  RouteModuleHomePersonal,
  RouteModuleModuleSelector,
  RouteModuleTestPages,
  RouteModuleUnitsTest,
} from '@dunefront/client-common/pages/home/home-page-routes-names';
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    path: RouteModuleHome,
    component: HomeComponent,
    canActivate: [AuthGuard, HomePageUndoRedoGuard],
    canActivateChild: [AuthGuard],
    data: {
      isRecentProjects: true,
    },
    children: [
      ...homePageRoutes,
      {
        path: '',
        redirectTo: RouteModuleHomePersonal,
        pathMatch: 'full',
      },
      {
        path: `${RouteModuleModuleSelector}/:dbFilename`,
        component: ModuleSelectorDialogComponent,
      },
    ],
  },
  {
    path: `${RouteModuleTestPages}/${RouteModuleUnitsTest}`,
    component: UnitsTestComponent,
  },
  {
    path: `${RouteModuleFile}/:dbFilename`,
    component: DbDependentContentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${RouteModuleFile}/:dbFilename/:scenarioId/:rangeId`,
    canActivate: [AuthGuard],
    component: DbDependentContentComponent,
    children: [
      ...psdAnalysisRoutes,
      ...simulatePageRoutes,
      ...calculationsPageRoutes,
      ...trendAnalysisPageRoutes,
      ...testPageRoutes,
      ...dataAnalysisPageRoutes,
    ],
  },
  {
    path: RouteModuleAdmin,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('@dunefront/client-common/pages/admin-panel/admin-panel.module').then((m) => m.AdminPanelModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: RouteModuleHome,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}), HomeModule, RibbonMenuModule, FormsModule],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
