<ng-container *ngIf="state && state.isLoaded">
  <app-page-panel class="flow-data-panel" header="Flow Data" (click)="onHelpChange('calculators', 'settling-flow-data')">
    <div class="grid-form-container cols-6" appEqualizeSymbols>
      <app-fluid-selector
        [source]="state.SettlingCalculator"
        [key]="'FluidId'"
        label="Fluid"
        appUnwrap
        [errorMessage]="state.SettlingCalculator.error.FluidId"
        (valueChanged)="submitCalcData($event)"
        dataCy="settling-calculator-fluid"
        [showSymbol]="true"
      >
      </app-fluid-selector>
      <app-gravel-selector
        [source]="state.SettlingCalculator"
        [key]="'GravelId'"
        appUnwrap
        label="Gravel"
        [errorMessage]="state.SettlingCalculator.error.GravelId"
        (valueChanged)="submitCalcData($event)"
        dataCy="settling-calculator-gravel"
        [showSymbol]="true"
      >
      </app-gravel-selector>
      <div class="grid-two-one"></div>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        [disabled]="!state.SettlingCalculator.GravelId || state.SettlingCalculator.GravelId === 0"
        label="Concentration"
        [source]="state.SettlingCalculator"
        [key]="'Concentration'"
        [decimalPlaces]="2"
        [unitType]="UnitType.Solid_Concentration"
        (valueChanged)="submitCalcData($event)"
        dataCy="settling-calculator-concentration"
      >
      </app-form-input>
      <app-form-input
        label="Max Rate"
        appUnwrap
        [source]="state.SettlingCalculator"
        [key]="'MaxRate'"
        [decimalPlaces]="2"
        [unitType]="UnitType.Rate"
        (valueChanged)="submitCalcData($event)"
        dataCy="settling-calculator-max-rate"
      >
      </app-form-input>
      <app-form-input
        [hidden]="state.isNewtonian"
        label="Static Viscosity"
        appUnwrap
        [source]="state.SettlingCalculator"
        [key]="'StaticViscosity'"
        [decimalPlaces]="2"
        [unitType]="UnitType.Viscosity"
        (valueChanged)="submitCalcData($event)"
        dataCy="settling-calculator-newtonian-viscosity"
      >
      </app-form-input>
      <app-form-input
        [hidden]="!state.isNewtonian"
        label="Static Viscosity"
        appUnwrap
        [disabled]="true"
        [readOnlyValue]="state.viscosityAtTemperature"
        [decimalPlaces]="2"
        [unitType]="UnitType.Viscosity"
        dataCy="settling-calculator-nonnewtonian-viscosity"
      >
      </app-form-input>
      <app-form-input
        label="Temperature"
        appUnwrap
        [source]="state.SettlingCalculator"
        [key]="'Temperature'"
        [decimalPlaces]="2"
        [unitType]="UnitType.Temperature"
        (valueChanged)="submitCalcData($event)"
        dataCy="settling-calculator-temperature"
      >
      </app-form-input>
      <app-form-input
        label="Deviation"
        appUnwrap
        [source]="state.SettlingCalculator"
        [key]="'Deviation'"
        [decimalPlaces]="2"
        [unitType]="UnitType.Angle"
        (valueChanged)="submitCalcData($event)"
        dataCy="settling-calculator-deviation"
      >
      </app-form-input>
      <app-form-input
        label="Eccentricity"
        appUnwrap
        [source]="state.SettlingCalculator"
        [disabled]="state.SettlingCalculator.FlowPathType !== FlowPathType.Annulus"
        [key]="'Eccentricity'"
        [forceShowSymbol]="true"
        [decimalPlaces]="2"
        [unitType]="UnitType.None"
        (valueChanged)="submitCalcData($event)"
        dataCy="settling-calculator-eccentricity"
      >
      </app-form-input>
      <label>Oroskar and Turian Diameter</label>
      <app-radio-group
        [source]="state.SettlingCalculator"
        [key]="'OroskarAndTurianDiameter'"
        [items]="OroskarAndTurianDiameterValues"
        id="OroskarAndTurianDiameter"
        (valueChanged)="submitCalcData($event)"
        dataCy="settling-calculator-oroskar-and-turian-diameter"
      >
      </app-radio-group>
    </div>
  </app-page-panel>

  <div class="chart-grid-container">
    <app-page-panel
      header="Flow Path Dimensions"
      appEqualizeSymbols
      (click)="onHelpChange('calculators', 'settling-flow-path-dimensions')"
      class="flow-path-panel"
    >
      <div class="grid-form-container cols-1">
        <app-radio-group
          [source]="state.SettlingCalculator"
          [key]="'FlowPathType'"
          [items]="flowPathDimensionsValues"
          [layout]="'vertical'"
          id="FlowPathType"
          (valueChanged)="submitCalcData($event)"
          dataCy="settling-calculator-flow-path-type"
        >
        </app-radio-group>
      </div>
      <div class="grid-form-container flow-path-dimensions-inputs-panel  cols-2">
        <app-form-input
          [label]="MinDiameterLabel"
          [source]="state.SettlingCalculator"
          [key]="'MinDiameter'"
          [decimalPlaces]="3"
          appUnwrap
          [unitType]="UnitType.Diameter"
          (valueChanged)="submitCalcData($event)"
          dataCy="settling-calculator-min-diameter"
        >
        </app-form-input>
        <app-form-input
          [hidden]="state.SettlingCalculator.FlowPathType !== FlowPathType.Annulus"
          label="Annulus OD"
          [source]="state.SettlingCalculator"
          [key]="'MaxDiameter'"
          [decimalPlaces]="3"
          appUnwrap
          [unitType]="UnitType.Diameter"
          (valueChanged)="submitCalcData($event)"
          dataCy="settling-calculator-max-diameter"
        >
        </app-form-input>
      </div>
    </app-page-panel>

    <app-page-panel
      header="Results"
      (click)="onHelpChange('calculators', 'settling-results')"
      class="results-panel"
      [panelHelpMode]="PanelHelpMode.CHART"
    >
      <div class="results-container">
        <div class="buttons-panel">
          <div>
            <label>X-Axis Values</label>
            <app-radio-group
              [source]="state.SettlingCalculator"
              [key]="'IsXAxisRate'"
              [items]="xAxisValues"
              [isUiLockable]="false"
              layout="horizontal"
              id="IsXAxisRate"
              (valueChanged)="submitCalcData($event)"
              dataCy="settling-calculator-x-axis-values"
            >
            </app-radio-group>
          </div>
          <div>
            <label>Stokes' Settling Velocity</label>
            <app-form-input
              [disabled]="true"
              [source]="state.SettlingCalculator"
              [key]="'StokesSettlingVelocity'"
              [decimalPlaces]="3"
              [unitType]="UnitType.Velocity"
              dataCy="settling-calculator-velocity"
            >
            </app-form-input>
          </div>
        </div>

        <div class="chart-container">
          <app-settling-calculator-result-chart
            *ngIf="state.SettlingCalculatorResult.length > 0; else defaultMessage"
            [displayedAxis]="state.SettlingCalculator.IsXAxisRate"
            [settlingCalculatorResult]="state.SettlingCalculatorResult"
          >
          </app-settling-calculator-result-chart>
          <ng-template #defaultMessage>Click the "Calculate" button to generate results.</ng-template>
        </div>
      </div>
    </app-page-panel>
  </div>
</ng-container>
