import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DbDependentComponent } from '../../db-connection/db-dependent.component';
import { CY_BUTTON_OK, defaultModalCancelButton, modalButton, ModalButtonConfig } from '../generic-modal/generic-modal.component';
import { getAccessibleFeaturesAndLicenses } from '../../../+store/licensing/licensing.selectors';
import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { combineLatestWith, map } from 'rxjs/operators';
import { firstValueFrom, Observable } from 'rxjs';
import { PrimitiveChangeValue } from '@dunefront/common/common/common-state.interfaces';
import { getCurrentRangeId, getRangeSelectData } from '../../../+store/range/range.selectors';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { isSimulateBased, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';

@Component({
  selector: 'app-copy-data-from',
  template: ` <app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
    <div class="grid-form-container cols-1">
      <div class="grid-one-one">
        <app-radio-group
          dataCy="copy-data-from-module"
          label="File Type"
          [items]="moduleTypes"
          layout="vertical"
          [value]="moduleType"
          (primitiveValueChanged)="moduleTypeChanged($event.value)"
          [isUiLockable]="false"
        >
        </app-radio-group>
      </div>
      <div *ngIf="selectRangeData$ | async as selectRangeData" class="grid-one-one">
        <app-select
          dataCy="copy-data-from-range"
          [items]="selectRangeData"
          [isUiLockable]="false"
          [value]="currentRange?.Id"
          [disableSorting]="true"
          [disabled]="isSimulateModule"
          [selectPlaceholder]="'Select a Range'"
          (primitiveValueChanged)="onRangeSelected($event)"
        >
        </app-select>
      </div>
    </div>
  </app-generic-modal>`,
  styleUrls: ['./copy-data-from.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyDataFromComponent extends DbDependentComponent implements OnInit {
  public modalTitle = 'Copy data from…';
  public moduleType = ModuleType.Simulate;
  public moduleTypes: ISelectItem<ModuleType>[] = [];
  public ModuleType = ModuleType;

  public modalButtonsConfigs: ModalButtonConfig[] = [
    defaultModalCancelButton((): void => this.closeDialog()),
    modalButton(
      'Copy Data',
      (): void =>
        this.closeDialog({
          moduleType: this.moduleType,
          rangeId: this.moduleType === ModuleType.Evaluate ? this.selectedRangeId : undefined,
        }),
      CY_BUTTON_OK,
    ),
  ];

  public selectRangeData$: Observable<ISelectItem<number>[]> = this.store.select(getRangeSelectData).pipe(
    combineLatestWith(this.store.select(getCurrentRangeId)),
    map(([rangeSelectData, currentRangeId]) =>
      rangeSelectData.filter((rangeId) => rangeId.value !== RangeConstants.EntireRangeId && rangeId.value !== currentRangeId),
    ),
  );

  public selectedRangeId?: number;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
    super(store, cdRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.fillModuleTypes().then();
  }

  private async fillModuleTypes(): Promise<void> {
    const userFeatures = await firstValueFrom(this.store.select(getAccessibleFeaturesAndLicenses));
    this.moduleTypes = [];
    if (userFeatures.features.includes(LicenseFeature.Simulate)) {
      this.moduleTypes.push({
        value: ModuleType.Simulate,
        text: 'Simulate',
      });
    }

    if (userFeatures.features.includes(LicenseFeature.Simulate_CH)) {
      this.moduleTypes.push({
        value: ModuleType.Simulate_CH,
        text: 'Simulate CH',
      });
    }

    if (userFeatures.features.includes(LicenseFeature.Simulate_Disp)) {
      this.moduleTypes.push({
        value: ModuleType.Simulate_Disp,
        text: 'Simulate Disp',
      });
    }

    this.moduleTypes.push({ value: ModuleType.Evaluate, text: 'Evaluate' });
    this.moduleType = this.moduleTypes.find((moduleType) => !moduleType.disabled)?.value as ModuleType;

    this.cdRef.markForCheck();
  }

  public closeDialog(result?: { moduleType: ModuleType; rangeId?: number }): void {
    this.ref.close(result);
  }

  public onRangeSelected(event: PrimitiveChangeValue<number>): void {
    this.selectedRangeId = event.value;
    this.checkCopyDataDisabled();
  }

  public moduleTypeChanged(moduleType: ModuleType): void {
    this.moduleType = moduleType;
    this.checkCopyDataDisabled();
  }

  private checkCopyDataDisabled(): void {
    const okButton = this.modalButtonsConfigs.find((conf) => conf.dataCy === CY_BUTTON_OK);
    if (okButton) {
      okButton.disabled = this.moduleType === ModuleType.Evaluate && this.selectedRangeId == null;
    }
  }

  public get isSimulateModule(): boolean {
    return isSimulateBased(this.moduleType);
  }
}
