import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
  selector: 'app-left-nav-df-logo',
  templateUrl: 'left-nav-df-logo.component.html',
  styleUrls: ['./left-nav-df-logo.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgSwitchCase, NgSwitch],
})
export class LeftNavDfLogoComponent {
  @Input() public isExpanded = false;
}
