import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { ClientAuthService } from '../client-auth.service';
import { HTTP_INTERCEPTOR_CONFIG } from '../tokens';
import { HttpInterceptorConfig } from '@auth0/auth0-angular/lib/auth.config';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(
    private authService: ClientAuthService,
    @Inject(HTTP_INTERCEPTOR_CONFIG) private config: HttpInterceptorConfig,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isAllowed = this.config.allowedList.some((pattern) => {
      const regex = new RegExp(pattern.toString().replace('*', '.*'));
      return regex.test(req.url);
    });

    if (!isAllowed) {
      return next.handle(req);
    }

    const sessionId = this.authService.sessionId;
    if (sessionId == null) {
      return next.handle(req);
    }

    const clonedRequest = req.clone({
      headers: req.headers.set('ppsessionid', sessionId),
    });

    return next.handle(clonedRequest);
  }
}
