import { APP_CODE, IAppTargetConfig } from '@dunefront/common/common/app-target-config';
import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { ASSETS_IMAGES_PATH } from '@dunefront/common/common/constants';

export class AppTargetConfig implements IAppTargetConfig {
  public readonly appName: string;
  public readonly appCode: APP_CODE;
  public readonly fileTypeExtension: string;
  public readonly fileTypeName: string;
  public readonly availableModules: LicenseFeature[];
  public readonly calcEngineLocation = '';
  public readonly documentationFolder: string;
  public readonly demoFolder: string;
  public readonly appFolderName: string;
  constructor(config: IAppTargetConfig) {
    this.appName = config.appName;
    this.appCode = config.appCode;
    this.fileTypeExtension = config.fileTypeExtension;
    this.fileTypeName = config.fileTypeName;
    this.availableModules = config.availableModules;
    this.documentationFolder = config.documentationFolder;
    this.demoFolder = config.demoFolder;
    this.appFolderName = config.appFolderName;
  }

  public isAppCode(appCode: APP_CODE): boolean {
    return this.appCode === appCode;
  }

  public get appIconPath(): string {
    return ASSETS_IMAGES_PATH + this.appCode + '.svg';
  }
}
