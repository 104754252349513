import { SolidConcentrationConverter } from '../unit-converters/converters/solid-concentration/solid-concentration.converter';
import { FluidConcentrationUnit, IUnitSystemDto, NoneUnit, RateUnit, SolidConcentrationUnit } from '../dto/unit-system.dto';
import { RateConverter } from '../unit-converters/converters/rate/rate.converter';
import { CopyChartRatio } from './chart-helpers/chart-copy-options.helpers';
import { Color } from '../modules/reporting/dto/chart.types';
import { RegisterQueueOptions } from '@nestjs/bullmq';

export const MAX_GRAVEL_CONCENTRATION_SI = SolidConcentrationConverter.toSi(15, SolidConcentrationUnit.Pound_per_US_gallon);
export const MAX_RATE_LIMIT_SI = RateConverter.toSi(50, RateUnit.Barrel_per_minute);
export const MAX_PERMEABILITY_SI = 9.869223e-12;
export const MaxRate_BPM = 50.0; //bpm
export const MaxConcentration_PPA = 15.0; //PPA
export const MAX_RATE_CHANGE_TOLERANCE = 0.5; //BPM
export const MAX_GRAVEL_CONCENTRATION_TOLERANCE = 0.5; //Pound_per_US_gallon
export const ICD_RATE_DISTRIBUTION_MINIMUM_TOLERANCE = 0.05; //Pound_per_square_inch
export const MAX_PERFORMATION_DIAMETER = 0.050800000051816;
export const MAX_ANGLE_OF_REPOSE = 45; //SI
export const MAX_PERFORATION_TUNNEL_LENGTH = 1.27; // meters
export const MAX_SQLITE_VARIABLES = 999;
export const COMMON_DATE_TIME_STRING_FORMAT = 'DD-MM-YYYY HH:mm';
export const COMMON_DATE_TIME_SECOND_STRING_FORMAT = 'DD-MM-YYYY HH:mm:ss';
export const COMMON_DATE_TIME_SECOND_MILLISECOND_STRING_FORMAT = 'DD-MM-YYYY HH:mm:ss.SSS';
export const COMMON_DATE_STRING_FORMAT = 'DD-MM-YYYY';

export const LoginScreenUrl = 'login-form';
export const IMPORT_COLUMN_DEFAULT_START_DATE = 1609455600; // Jan 01 2021 00:00:00

export const LOWEST_HANDLED_PROJECT_DB_VERSION = '2.7';
export const CURRENT_PROJECT_DB_VERSION = '6.0135';

export const HANDLED_ERROR_PREFIX = '<HANDLED>';

export const ASSETS_IMAGES_PATH = 'assets/images/';

// region Chart

export const defaultCopyChartRatio: CopyChartRatio = { w: 2, h: 1 };
export const defaultCopyChartScaling = 3;
export const defaultChartSeriesColours = [
  Color.Green,
  Color.Purple,
  Color.Magenta,
  Color.Lime,
  Color.DarkOrange,
  Color.DarkSlateGrey,
  Color.DarkGoldenrod,
  Color.OliveDrab,
  Color.DarkSalmon,
  Color.Maroon,
];

export const defaultPumpPressureColor = Color.Red;
export const defaultPumpRateColor = Color.Blue;
export const defaultReturnRateColor = Color.Cyan;
export const defaultGravelConcColor = Color.Brown;
export const defaultAxisFontColor = Color.DimGray;
export const defaultLegendFontColor = Color.DimGray;
export const defaultVideoSteps = 100;
export const defaultVideoFramerate = 30;

export const defaultMaxSeriesInTooltip = 10;

export const defaultXAxisMarginPercent = 5;
export const defaultYAxisMarginPercent = 10;

// endregion

export const METRIC_UNIT_SYSTEM_ID = 10000;

export const getDefaultMetricUnitSystem = (): IUnitSystemDto => {
  return {
    commonDbType: 'Predefined',
    sourceId: METRIC_UNIT_SYSTEM_ID,
    name: 'Metric',
    Pressure_Rate_Derivative: 9,
    Pressure_Rate_Squared_Derivative: 9,
    Pressure_Rate_Cubed_Derivative: 9,
    Time: 3,
    Long_Length: 1,
    Short_Length: 4,
    Angle: 1,
    Diameter: 4,
    Liquid_Density: 1,
    Temperature: 1,
    Pressure: 2,
    Pressure_Gradient: 2,
    Consistency_Index: 1,
    Viscosity: 1,
    Bulk_Density: 1,
    Rate: 2,
    Liquid_Volume: 1,
    Solid_Volume: 1,
    Linear_Volume: 1,
    Solid_Concentration: 1,
    Mass: 1,
    Linear_Mass: 1,
    Permeability: 3,
    Shot_Density: 1,
    Temperature_Time_Derivative: 3,
    Temperature_Volume_Derivative: 1,
    Velocity: 1,
    Solid_Density: 7,
    Pressure_Time_Derivative: 6,
    Pressure_Volume_Derivative: 6,
    Screen_Opening: 9,
    Small_Mass: 2,
    Thermal_Conductivity: 1,
    Specific_Heat_Capacity: 1,
    Fluid_Loss_Coefficient: 1,
    Mass_Flow_Rate: 2,
    Compressibility: 2,
    Reciprocal_Length: 1,
    Sieve_Opening: 9,
    Force: 2,
    Injectivity: 2,
    Liquid_Concentration: 2,
    Fluid_Concentration: FluidConcentrationUnit.Percent,
    Yield_Stress: 8,
    Other: NoneUnit.None,
    id: 0,
  };
};

export const CALC_ENGINE_LONG_TASK_QUEUE_NAME = 'calc-engine-long-task-queue';
export const CALC_ENGINE_SHORT_TASK_QUEUE_NAME = 'calc-engine-short-task-queue';
export const JS_WORKERS_QUEUE_NAME = 'js-workers-queue';
export const PP_SESSION_PREFIX = 'PP_SESSION_';

export const COPY_NAME_SUFFIX = ' - Copy';

export const TEMP_PROJECT_FOLDER = '_TempProjects_';

export const DEFAULT_INPUT_MAX_LENGTH = 128;

export const BACKEND_CONNECTED_NOTIFICATION_DELAY = 500;

export const BACKEND_TO_CLIENT_CONFIG = 'backend-to-client.config.js';

export const NOTIFICATIONS_LAMBDA_URL = 'https://notifications.dunefrontcloud.com';
export const CHECK_URL_LAMBDA_URL = 'https://check-url.dunefrontcloud.com';
export const NOTIFICATIONS_REMIND_ME_LATER_DAYS = 1;

export type RangeLabel =
  | 'RIH'
  | 'Packer and Service Tool Ops'
  | 'Workstring Pickle'
  | 'Casing Clean Up'
  | 'Spot Fluid'
  | 'Displacement'
  | 'Reverse SRT'
  | 'Circulating SRT'
  | 'Acidizing'
  | 'Injection Test'
  | 'Mini Frac'
  | 'Gravel Pack'
  | 'HRWP'
  | 'Re-stress'
  | 'Reverse Out'
  | 'Breaker'
  | 'Isolation Valve Test'
  | 'POOH';

export const PREDEFINED_RANGE_LABELS: RangeLabel[] = [
  'RIH',
  'Packer and Service Tool Ops',
  'Workstring Pickle',
  'Casing Clean Up',
  'Spot Fluid',
  'Displacement',
  'Reverse SRT',
  'Circulating SRT',
  'Acidizing',
  'Injection Test',
  'Mini Frac',
  'Gravel Pack',
  'HRWP',
  'Re-stress',
  'Reverse Out',
  'Breaker',
  'Isolation Valve Test',
  'POOH',
];

export const registerQueueOptions: Partial<RegisterQueueOptions> = {
  defaultJobOptions: {
    removeOnComplete: { age: 60 }, // 1 min
    removeOnFail: { age: 8 * 60 * 60 }, // 8 hrs
  },
  streams: {
    events: {
      maxLen: 10,
    },
  },
};
