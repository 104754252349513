import { ChangeDetectorRef, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectAvailableUnitSystemLookupData,
  selectCurrentUnitSystem,
  selectSortedUnitSystems,
} from '../../../../../+store/units/units.selectors';
import { chooseUnitSystemAction, deleteUnitSystemAction } from '../../../../../+store/units/units.actions';
import { UnitsCreateEditModalComponent } from '../../../../modals/units-create-edit-modal/units-create-edit-modal.component';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { Subscription } from 'rxjs';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { GeneralMenuProps } from '../../../../../+store/menu-selectors/general/menu-general.selector';
import { ModalService } from '../../../../modals/modal.service';
import { PrimitiveChangeValue } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-unit-system',
  templateUrl: './unit-system.component.html',
  styleUrls: ['./unit-system.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UnitSystemComponent implements OnDestroy {
  public availableUnitSystems$ = this.store.select(selectAvailableUnitSystemLookupData);
  @Input() public generalMenuProps!: GeneralMenuProps;
  @Input() public isRibbonMenu = false;
  public availableUnitSystems!: IUnitSystemDto[];
  public currentUnitSystem: IUnitSystemDto | undefined;
  private subscription = new Subscription();

  constructor(
    private store: Store,
    private modalService: ModalService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.subscription.add(
      notEmpty(store.select(selectCurrentUnitSystem)).subscribe((currentUnitSystem) => {
        this.currentUnitSystem = currentUnitSystem;
        this.cdRef.markForCheck();
      }),
    );
    this.subscription.add(
      this.store.select(selectSortedUnitSystems).subscribe((availableUnitSystems) => {
        this.availableUnitSystems = availableUnitSystems;
        this.cdRef.markForCheck();
      }),
    );
  }

  public valueChanged(event: PrimitiveChangeValue<number>): void {
    const unitSourceId = event.value;
    this.store.dispatch(chooseUnitSystemAction({ unitSystemId: unitSourceId }));
    const unitSystem = this.availableUnitSystems.find((availableUnitSystem) => availableUnitSystem.sourceId === unitSourceId);
    if (!unitSystem) {
      return;
    }
  }

  public createClicked(): void {
    const data = { mode: 'new' };
    this.modalService.open(UnitsCreateEditModalComponent, data, 'units-modal', 'auto');
  }

  public editClicked(): void {
    const data = { mode: 'edit' };
    this.modalService.open(UnitsCreateEditModalComponent, data, 'units-modal', 'auto');
  }

  public async deleteClicked(): Promise<void> {
    if (this.currentUnitSystem == null) {
      return;
    }
    const message = `
      Are you sure you want to delete the unit system <strong>${this.currentUnitSystem.name}</strong>
      from your <strong>${this.currentUnitSystem.commonDbType === 'Organization' ? 'Organization' : 'Personal'}</strong> unit systems?
    `;
    const result = await this.modalService.showConfirm(message, 'Delete custom unit system');
    if (result) {
      this.store.dispatch(deleteUnitSystemAction({ unitSystem: this.currentUnitSystem }));
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
