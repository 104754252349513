<div
  class="grid-form-container main cols-3"
  *ngIf="selectedColumn && !isAddColumnMode"
  #mainGrid
>
  <div class="grid-form-container form-column no-padding inline">
    <ng-container *ngTemplateOutlet="col1"></ng-container>
  </div>
  <div class="grid-form-container form-column no-padding inline">
    <ng-container *ngTemplateOutlet="col2"></ng-container>
  </div>
  <div class="grid-form-container form-column no-padding inline">
    <ng-container *ngTemplateOutlet="col3"></ng-container>
  </div>
</div>

<div class="add-column" *ngIf="selectedColumn && isAddColumnMode">
  <ng-container *ngTemplateOutlet="col1"></ng-container>
  <ng-container *ngTemplateOutlet="col2"></ng-container>
  <ng-container *ngTemplateOutlet="col3"></ng-container>
</div>

<ng-template #col1>
  <ng-container *ngIf="selectedColumn">
    <app-form-input
      [source]="selectedColumn"
      [key]="'ColumnName'"
      appUnwrap
      label="Name"
      [isTextInput]="true"
      (valueChanged)="onValueChanged(selectedColumn, $event)"
      [isStringComparisonStrict]="true"
      [dataCy]="'gauge-data-column-name'"

    ></app-form-input>
    <app-form-input
      *ngIf="!isAddColumnMode"
      [source]="selectedColumn"
      [key]="'MeasuredDepth'"
      [hidden]="isXAxis"
      appUnwrap
      label="MD"
      dataCy="gauge-data-column-props-MD"
      [unitType]="UnitSystem.Long_Length"
      [disabled]="!isBottomhole"

      (valueChanged)="onValueChanged(selectedColumn, $event)"
    ></app-form-input>
    <ng-container *ngIf="isXAxis && startTime">
      <label>Start Time</label>
      <app-date-time-input
        [showTimePicker]="true"
        [value]="startTime"
        [errorMessage]="startDateError"

        (primitiveValueChanged)="onStartTimeChanged(selectedColumn, $event)"
        [attr.data-cy]="'gauge-data-start-date-time-input'"
      ></app-date-time-input>
    </ng-container>
    <app-form-input
      *ngIf="!isAddColumnMode && selectedColumn"
      [source]="selectedColumn"
      [key]="'VerticalOffset'"
      appUnwrap
      label="Offset"
      [offset]="0"

      [unitType]="selectedColumn.UnitSystem"
      [disabled]="isXAxis"
      (valueChanged)="onValueChanged(selectedColumn, $event)"
      [decimalPlaces]="3"
      dataCy="input-gauge-data-offset"
    ></app-form-input>
  </ng-container>
</ng-template>

<ng-template #col2>
  <ng-container *ngIf="selectedColumn">
    <label class="data-type-label">Data Type</label>
    <app-select
      [items]="importColumnTypeItems"
      [source]="selectedColumn"
      [key]="'DataType'"
      [disabled]="isXAxis"
      (valueChanged)="onDataTypeChanged(selectedColumn, $event)"
      [dataCy]="'gauge-data-data-type'"
    ></app-select>
    <label>Unit System</label>
    <app-select
      [source]="selectedColumn"
      [key]="'UnitSystem'"
      [items]="unitSystemItems"
      [disabled]="!isUnitSystemEnabled"
      (valueChanged)="onValueChanged(selectedColumn, $event)"
      [dataCy]="'gauge-data-unit-system'"
    ></app-select>
    <app-form-input
      *ngIf="!isAddColumnMode"
      [source]="selectedColumn"
      [key]="'Multiplier'"
      appUnwrap
      label="Multiplier"
      [disabled]="isXAxis"
      (valueChanged)="onValueChanged(selectedColumn, $event)"
      [decimalPlaces]="3"
      dataCy="input-gauge-data-multiplier"
    ></app-form-input>
  </ng-container>
</ng-template>

<ng-template #col3>
  <ng-container *ngIf="selectedColumn">
    <ng-container *ngIf="!isAddColumnMode">
      <label>Measurement Type</label>
      <app-select
        [source]="selectedColumn"
        [key]="'MeasurementType'"
        [items]="measurementTypes"
        [disabled]="!isBottomhole"
        [overrideErrorMessage]="measurementTypeError"
        (valueChanged)="onValueChanged(selectedColumn, $event)"
        [dataCy]="'gauge-data-measurement-type'"
      ></app-select>
    </ng-container>
    <label>Units Type</label>
    <app-select
      [source]="selectedColumn"
      [key]="'Unit'"
      [items]="unitSymbolsItems"
      [dataCy]="'gauge-data-unit'"
      [disabled]="!isUnitSymbolsEnabled"
      (valueChanged)="onValueChanged(selectedColumn, $event)"
    >
    </app-select>
    <app-form-input
      *ngIf="!isAddColumnMode"
      [source]="selectedColumn"
      [key]="'SmoothingPoints'"
      appUnwrap
      label="Averaging"

      [disabled]="isXAxis"
      (valueChanged)="onValueChanged(selectedColumn, $event)"
      [decimalPlaces]="0"
    ></app-form-input>
  </ng-container>
</ng-template>
