<div class="grid-form-container main cols-2" *ngIf="pumping">
  <div class="grid-form-container cols-2 no-padding">
    <app-fluid-selector
      appUnwrap
      label="Workstring"
      [source]="pumping"
      [key]="'WorkstringFluidId'"
      [disabled]="!isWellFluidSelectionSimple"
      [errorMessage]="pumping.error.WorkstringFluidId"
      (valueChanged)="onValueChange($event)"
      dataCy="simple-fluid-workstring"
    >
    </app-fluid-selector>
    <app-fluid-selector
      appUnwrap
      [source]="pumping"
      [key]="'WashpipeFluidId'"
      label="Washpipe"
      [disabled]="!isWellFluidSelectionSimple"
      [errorMessage]="pumping.error.WashpipeFluidId"
      (valueChanged)="onValueChange($event)"
      dataCy="simple-fluid-washpipe"
    >
    </app-fluid-selector>
  </div>
  <div class="grid-form-container cols-2 no-padding">
    <app-fluid-selector
      appUnwrap
      [source]="pumping"
      [key]="'UpperAnnulusFluidId'"
      label="Upper Annulus"
      [disabled]="!isWellFluidSelectionSimple"
      [errorMessage]="pumping.error.UpperAnnulusFluidId"
      (valueChanged)="onValueChange($event)"
      dataCy="simple-fluid-uper-annulus"
    >
    </app-fluid-selector>
    <app-fluid-selector
      appUnwrap
      [source]="pumping"
      [key]="'LowerAnnulusFluidId'"
      label="Lower Annulus"
      [disabled]="!isWellFluidSelectionSimple"
      [errorMessage]="pumping.error.LowerAnnulusFluidId"
      (valueChanged)="onValueChange($event)"
      dataCy="simple-fluid-lower-annulus"
    >
    </app-fluid-selector>
  </div>
</div>
