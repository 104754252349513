<div class="grid-form-container main with-gap" *ngIf="pipe && flowPorts">
  <div class="grid-form-container no-padding">
    <app-form-input
      appUnwrap
      label="Number Of Ports"
      dataCy="concentric-gauge-carrier-number-of-ports"
      [source]="flowPorts"
      [key]="'NoOfPorts'"
      [decimalPlaces]="0"
      [unitType]="UnitType.None"
      (valueChanged)="onFlowPortsChange($event)"
    ></app-form-input>
    <app-form-input
      appUnwrap
      label="Port Diameter"
      dataCy="concentric-gauge-carrier-port-diameter"
      [source]="flowPorts"
      [key]="'PortDiameter'"
      [decimalPlaces]="3"
      [unitType]="UnitType.Diameter"
      (valueChanged)="onFlowPortsChange($event)"
    ></app-form-input>
  </div>
  <div class="grid-form-container no-padding">
    <app-form-input
      appUnwrap
      label="Port Length"
      dataCy="concentric-gauge-carrier-port-length"
      [source]="flowPorts"
      [key]="'PortLength'"
      [unitType]="UnitType.Short_Length"
      (valueChanged)="onFlowPortsChange($event)"
    ></app-form-input>
    <label>Measurement Type</label>
    <div class="input">
      <app-select
        dataCy="concentric-gauge-carrier-measurement-type"
        [source]="pipe.rowData"
        [key]="'GaugeMeasurementType'"
        [items]="gaugeMeasurementTypeItems"
        (valueChanged)="onPropertyChange($event)"
        [overrideErrorMessage]="pipe.error.GaugeMeasurementType"
      >
      </app-select>
    </div>
  </div>
</div>
