<div class="about-page">
  <div class="dialog-header">
    <h4>About</h4>
    <span class="icon-logo" data-cy="icon-logo"></span>
  </div>
  <div class="dialog-content">
    <div class="about-container">
      <div class="about-block app-version">
        <p>
          <b> DuneFront {{ appTargetConfig.appName }}</b>
          (version {{ version }})
        </p>

        <p><b> CommitId: </b>{{ commitId }}</p>
      </div>
      <div class="about-block">
        <div class="about-buttons-panel">
          <app-button
            *ngIf="electronService.isElectronApp"
            class="check-updates-btn"
            [isUiLockable]="false"
            (buttonClick)="onBtnCheckForUpdatesClick()"
            text="Check Updates"
          >
          </app-button>
          <app-button
            [isUiLockable]="false"
            (buttonClick)="btnCopyVersionClick()"
            [isDisabledWhenCalculationActive]="false"
            text="Copy Version"
          >
          </app-button>
        </div>
      </div>

      <div class="about-block warning">
        <p><b>Warning</b></p>
        <p class="text">
          This computer program is protected by copyright laws and international treaties. Unauthorized reproduction or
          distribution of
          this program, or any portion of it, may result in severe civil and criminal penalties, and will be prosecuted
          to the maximum
          extent possible under the law.
        </p>
      </div>
      <div class="about-block open-source">
        <p><b>Open Source Software</b></p>
        <ul>
          <li>Angular, Angular Material, PrimeNG, Node.js, NestJS, TypeScript, Electron</li>
          <li>Chart.js, RxJS, NgRx, NX, BullMQ, ngx-quill, html2canvas, ts-whammy</li>
          <li>Socket.IO, TypeORM, SQLite, better-sqlite3, ncc, winstonjs</li>
          <li>Auth0, Sentry, Lodash, math.js, dayjs, docx.js</li>
        </ul>
      </div>

      <hr />

      <div class="dev-info" *ngIf="isDevMode">
        <hr />
        <div class="build-info">
          <p><b>Client build data: </b> {{ clientBuildInfo.buildTime | date: 'long' }}</p>
          <ng-container *ngIf="serverInfo">
            <p><b>SocketId: </b>{{ serverInfo.socketId }}</p>
            <p><b>Server build data: </b> {{ serverInfo.buildTime | date: 'long' }}</p>
            <p><b>Server pod name: </b> {{ serverInfo.podName }}</p>
            <p><b>Server pod ns: </b> {{ serverInfo.podNamespace }}</p>
            <p><b>Server pod ip: </b> {{ serverInfo.podIp }}</p>
          </ng-container>
        </div>
        <hr />
        <div class="license-info">
          <table *ngIf="loggedInFeaturesStatus$ | async as loggedInFeatures" class="table table-sm table-bordered">
            <tr *ngFor="let row of loggedInFeatures">
              <td>{{ LicenseFeature[row.licenseFeature] }}</td>
              <td>{{ row.loginStatusMessage }}</td>
              <td>{{ row.expirationMessage }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

  </div>
  <div class="dialog-footer">
    <div class="buttons-panel">
      <div>
        <span>
          For more info, visit us at <a href="https://www.dunefront.com/" target="_blank"><b>www.dunefront.com</b></a>
        </span>
        <p>Copyright 2012-{{ currYear }} DuneFront Limited. All Rights Reserved.</p>
      </div>
      <div>
        <app-button
          [dialogRef]="ref"
          [isUiLockable]="false"
          (buttonClick)="onBtnCancelClick()"
          [isDisabledWhenCalculationActive]="false"
          text="OK"
        >
        </app-button>
      </div>
    </div>
  </div>
</div>
