import { ChangeDetectorRef, Component } from '@angular/core';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { cancelChange } from '../../../../+store/store.helpers';
import { getValidatedResuspensionCalculatorModuleState } from '../../../../+store/calculators/resuspension-calculator/resuspension-calculator.selectors';
import {
  calculateResuspension,
  updateResuspensionCalculatorRow,
} from '../../../../+store/calculators/resuspension-calculator/resuspension-calculator.actions';
import { FlowPathType } from '@dunefront/common/modules/calculators/resuspension-calculator/resuspension-calculator.dto';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { getIsFluidAndGravelValid } from '../../../../+store/app.selector';
import { take } from 'rxjs/operators';
import { ValidatedResuspensionCalculatorModuleState } from '@dunefront/common/modules/calculators/resuspension-calculator/resuspension-calculator-module.state';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { changeProp, ErrorHelper, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { LeftNavHelperService } from '../../../../shared/components/left-nav/left-nav-helper.service';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';

@Component({
  selector: 'app-resuspension-calculator',
  templateUrl: './resuspension-calculator.component.html',
  styleUrls: ['./resuspension-calculator.component.scss'],
})
export class ResuspensionCalculatorComponent extends DbDependentComponent {
  public state!: ValidatedResuspensionCalculatorModuleState;

  public UnitType = UnitSystem;

  public FlowPathType = FlowPathType;

  public flowPathDimensionsValues: IRadioItem<number>[] = [
    {
      value: 0,
      text: 'Pipe',
    },
    {
      value: 1,
      text: 'Annulus',
    },
  ];

  public get MinDiameterLabel(): string {
    if (this.state.FlowPathType === FlowPathType.Pipe) {
      return 'Pipe ID';
    } else if (this.state.FlowPathType === FlowPathType.Annulus) {
      return 'Annulus ID';
    } else {
      return '';
    }
  }

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
    public runCalculationService: LeftNavHelperService,
  ) {
    super(store, cdRef);
    this.subscription.add(
      store.select(getValidatedResuspensionCalculatorModuleState).subscribe((state) => {
        this.state = state;
        cdRef.markForCheck();
      }),
    );
    this.subscription.add(runCalculationService.calculationSubject.subscribe(() => this.calculate()));
    this.onHelpChange('calculators', 'resuspension-calculator');
  }

  public async submitCalcData(props: ObjectChangeProp<ValidatedResuspensionCalculatorModuleState>): Promise<void> {
    if (props.key === 'GravelId' && props.value === 0) {
      await this.modalService.showAlert('Gravel cannot be none');
      const cancelGenerator = cancelChange(this.state, props, this.cdRef);
      this.state = cancelGenerator.next().value;
      this.state = cancelGenerator.next().value;
      if (this.state.GravelId === 0) {
        this.state.GravelId = null;
      }
      this.cdRef.markForCheck();
      return;
    }
    if (this.state[props.key] !== props.value) {
      this.store.dispatch(updateResuspensionCalculatorRow(StoreCrudPropsFactory.updateRow(changeProp(this.state, props), props)));
    }
  }

  public async calculate(): Promise<void> {
    if (!this.state.isValid) {
      await this.modalService.showAlert(ErrorHelper.ERROR_CURRENT_SCREENS_MESSAGE_HEADER, 'Warning');
      return;
    }
    this.store
      .select(getIsFluidAndGravelValid(this.state.FluidId, this.state.GravelId))
      .pipe(take(1))
      .subscribe(async (invalidFields) => {
        if (invalidFields.length > 0) {
          await this.modalService.showAlert(invalidFields, 'Warning');
          return;
        }
        this.store.dispatch(calculateResuspension({ ScenarioId: this.state.ScenarioId }));
      });
  }
}
