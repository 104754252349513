<div class="grid-form-container no-padding">
  <app-form-input
    label="Initial Rows To Skip"
    appUnwrap
    [source]="filePropertiesConfig"
    key="initialRowsToSkip"
    [decimalPlaces]="0"
    [min]="0"
    [unitType]="UnitType.None"
    (valueChanged)="onValueChange($event)"
    [dataCy]="'import-data-initial-rows-to-skip'"
  >
  </app-form-input>

  <app-form-input
    label="Sampling Frequency"
    appUnwrap
    [source]="filePropertiesConfig"
    key="samplingFrequency"
    [decimalPlaces]="0"
    [min]="1"
    [unitType]="UnitType.None"
    (valueChanged)="onValueChange($event)"
    [dataCy]="'import-data-sampling-frequency'"
  >
  </app-form-input>
</div>
