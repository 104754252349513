import { NgModule } from '@angular/core';
import { GaugeDataPageModule } from '../gauge-data-page/gauge-data-page.module';
import { CommonModule } from '@angular/common';
import { TrendAnalysisPageComponent } from './trend-analysis-page.component';
import { RouterModule } from '@angular/router';
import { TrendAnalysisInputsComponent } from './trend-analysis/trend-analysis-inputs/trend-analysis-inputs.component';
import { TrendAnalysisChartComponent } from './trend-analysis/trend-analysis-chart/trend-analysis-chart.component';
import { NoTrendAnalysisMessageComponent } from './trend-analysis/trend-analysis-chart/no-trend-analysis-message/no-trend-analysis-message.component';
import { TrendAnalysisComponent } from './trend-analysis/trend-analysis.component';
import { ChartModule } from '../../common-modules/chart/chart.module';
import { AnalysisDataModule } from '../common/analysis-data/analysis-data.module';
import { SelectModule } from '../../shared/components/form-components/select/select.module';
import { UnwrapDirectiveModule } from '../../shared/directives/unwrap.directive';
import { FormInputModule } from '../../shared/components/form-components/form-input/form-input.component';
import { PagePanelModule } from '../../common-modules/page-panel/page-panel.component';
import { TabbedPageModule } from '../../common-modules/tabbed-page/tabbed-page.component';
import { BreadcrumbsModule } from '../../shared/components/breadcrumbs/breadcrumb.component';
import { LeftNavModule } from '../../shared/components/left-nav/left-nav.component';
import { ButtonModule } from '../../shared/components/button/button.module';
import { PrevNextNavModule } from '../../shared/components/prev-next-nav/prev-next-nav.component';
import { ModalsModule } from '../../common-modules/modals/modals.module';
import { PageContentModule } from '../../shared/page-content-component/page-conent-component';

@NgModule({
  imports: [
    CommonModule,
    GaugeDataPageModule,
    RouterModule,
    ChartModule,
    ModalsModule,
    AnalysisDataModule,
    SelectModule,
    UnwrapDirectiveModule,
    FormInputModule,
    PagePanelModule,
    TabbedPageModule,
    BreadcrumbsModule,
    PrevNextNavModule,
    LeftNavModule,
    ButtonModule,
    PageContentModule,
  ],
  declarations: [
    TrendAnalysisPageComponent,
    TrendAnalysisComponent,
    TrendAnalysisInputsComponent,
    TrendAnalysisChartComponent,
    NoTrendAnalysisMessageComponent,
  ],
})
export class TrendAnalysisPageModule {}
