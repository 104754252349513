<div class="page-container" (click)="onHelpChange('results', 'report')">
  <div *ngIf="isTrendAnalysisRunning$ | async; else reportPanel">
    <div class="activity-overlay-container">
      <app-activity-overlay [message]="'Trend Analysis in progress...'"
                            [activityIndicator]="true"></app-activity-overlay>
    </div>
  </div>
  <ng-template #reportPanel>
    <ng-container *ngIf="isReportPanelVisible$ | async; else pageDisabled">
      <div class="top-grid">
        <app-page-panel header="Report Type" class="grid-one-one">
          <div class="grid-form-container">
            <app-radio-group
              id="report-type-radio"
              [isUiLockable]="false"
              [items]="reportTypeItems"
              [value]="reportInfoFields.ReportType"
              (primitiveValueChanged)="onReportTypeChanged($event)"
              class="grid-two-one"
              dataCy="report-type-radio"
              layout="vertical"
            >
            </app-radio-group>
          </div>
        </app-page-panel>

        <app-page-panel header="Input Data" class="grid-one-one">
          <div class="grid-form-container three-cols no-padding">
            <div class="grid-form-container" *ngIf="isInputDataVisible">
              <app-check-box
                [source]="reportInfoFields"
                [key]="'IsWell'"
                label="Well"
                class="grid-two-one"
                dataCy="report-well-checkbox"
                (valueChanged)="updateReportInfoFields($event)"
                [isUiLockable]="false"
              >
              </app-check-box>
              <app-check-box
                [source]="reportInfoFields"
                [key]="'IsWellSurveyData'"
                label="Survey Data Table"
                class="grid-two-one checkbox-ident"
                dataCy="report-well-checkbox"
                (valueChanged)="updateReportInfoFields($event)"
                [isUiLockable]="false"
                [disabled]="isWellDataDisabled || isPpt"
              >
              </app-check-box>
              <app-check-box
                [source]="reportInfoFields"
                [key]="'IsWellCaliperData'"
                label="Caliper Data Table"
                class="grid-two-one checkbox-ident"
                dataCy="report-well-checkbox"
                (valueChanged)="updateReportInfoFields($event)"
                [isUiLockable]="false"
                [disabled]="isWellDataDisabled || isPpt"
              >
              </app-check-box>
            </div>
            <div class="grid-form-container" *ngIf="isInputDataVisible">
              <app-check-box
                [source]="reportInfoFields"
                [key]="'IsCompletion'"
                label="Completion"
                class="grid-two-one"
                dataCy="report-completion-checkbox"
                (valueChanged)="updateReportInfoFields($event)"
                [isUiLockable]="false"
              >
              </app-check-box>
              <app-check-box
                [source]="reportInfoFields"
                [key]="'IsFluids'"
                label="Fluids"
                class="grid-two-one"
                dataCy="report-fluids-checkbox"
                (valueChanged)="updateReportInfoFields($event)"
                [isUiLockable]="false"
              >
              </app-check-box>
              <app-check-box
                [source]="reportInfoFields"
                [key]="'IsGravel'"
                label="Gravel"
                class="grid-two-one"
                dataCy="report-gravel-checkbox"
                (valueChanged)="updateReportInfoFields($event)"
                [isUiLockable]="false"
              >
              </app-check-box>

            </div>

            <div class="grid-form-container" *ngIf="isImportedDataInfoVisible || isInputDataVisible">
              <app-check-box
                *ngIf="isImportedDataInfoVisible"
                [source]="reportInfoFields"
                [key]="'IsImportedDataInformation'"
                label="Imported Data"
                class="grid-two-one"
                dataCy="report-imported-data-checkbox"
                [value]="reportInfoFields.IsImportedDataInformation"
                (valueChanged)="updateReportInfoFields($event)"
                [isUiLockable]="false"
              >
              </app-check-box>
              <app-check-box
                *ngIf="isInputDataVisible"
                [source]="reportInfoFields"
                [key]="'IsSettings'"
                label="Settings"
                class="grid-two-one"
                dataCy="report-settings-checkbox"
                (valueChanged)="updateReportInfoFields($event)"
                [isUiLockable]="false"
                [disabled]="isPpt"
              >
              </app-check-box>
              <app-check-box
                *ngIf="isInputDataVisible"
                [source]="reportInfoFields"
                [key]="'IsPumping'"
                label="Pumping"
                class="grid-two-one"
                dataCy="report-pumping-checkbox"
                (valueChanged)="updateReportInfoFields($event)"
                [isUiLockable]="false"
              >
              </app-check-box>
            </div>

            <div class="grid-form-container" *ngIf="isPsdDataVisible">
              <app-check-box
                [source]="reportInfoFields"
                [key]="'IsPsdInput'"
                label="PSD Input"
                class="grid-two-one"
                dataCy="report-psd-data-checkbox"
                [value]="reportInfoFields.IsPsdInput"
                (valueChanged)="updateReportInfoFields($event)"
                [isUiLockable]="false"
              >
              </app-check-box>
              <app-check-box
                [source]="reportInfoFields"
                [key]="'IsPsdAnalysis'"
                label="PSD Analysis"
                class="grid-two-one"
                dataCy="report-psd-data-checkbox"
                [value]="reportInfoFields.IsPsdAnalysis"
                (valueChanged)="updateReportInfoFields($event)"
                [isUiLockable]="false"
              >
              </app-check-box>
            </div>
            <div class="grid-form-container" *ngIf="isTrendAnalysisInputsVisible">
              <app-check-box
                [source]="reportInfoFields"
                [key]="'IsTrendAnalysisInputs'"
                label="Trend Analysis Inputs"
                class="grid-two-one"
                dataCy="report-well-checkbox"
                (valueChanged)="updateReportInfoFields($event)"
                [isUiLockable]="false"
              >
              </app-check-box>
            </div>
          </div>
        </app-page-panel>
        <app-page-panel header="Result Data" class="grid-one-one">
          <div class="grid-form-container result-data-grid">
            <!-- Simulation / Evaluation -->
            <app-check-box
              *ngIf="isEvaluationAnimationVisible"
              [source]="reportInfoFields"
              [key]="'IsEvaluationAnimation'"
              label="Evaluation Animation"
              class="grid-one-one"
              dataCy="report-evaluation-animation-checkbox"
              (valueChanged)="updateReportInfoFields($event)"
              [isUiLockable]="false"
            >
            </app-check-box>

            <app-check-box
              *ngIf="isSimulationAnimationVisible"
              [source]="reportInfoFields"
              [key]="'IsSimulationAnimation'"
              label="Simulation Animation"
              class="grid-one-one"
              dataCy="report-simulation-animation-checkbox"
              (valueChanged)="updateReportInfoFields($event)"
              [isUiLockable]="false"
            >
            </app-check-box>

            <app-check-box
              *ngIf="isSimulationAnimationVisible"
              [source]="reportInfoFields"
              [key]="'IsWellVisualization'"
              label="Well Visualization"
              class="grid-one-one"
              dataCy="report-well-visualization-checkbox"
              (valueChanged)="updateReportInfoFields($event)"
              [isUiLockable]="false"
            >
            </app-check-box>

            <app-check-box
              *ngIf="isSimulationSummaryVisible"
              [source]="reportInfoFields"
              [key]="'IsSummary'"
              label="Simulation Summary"
              class="grid-one-one"
              dataCy="report-summary-checkbox"
              (valueChanged)="updateReportInfoFields($event)"
              [isUiLockable]="false"
            >
            </app-check-box>

            <app-check-box
              *ngIf="isChartsVisible"
              [source]="reportInfoFields"
              [key]="'IsCharts'"
              label="Charts"
              class="grid-one-one"
              dataCy="report-charts-checkbox"
              (valueChanged)="updateReportInfoFields($event)"
              [isUiLockable]="false"
            >
            </app-check-box>

            <!-- PSD -->
            <app-check-box
              *ngIf="isPsdDataVisible"
              [source]="reportInfoFields"
              [key]="'IsPsdSummary'"
              label="Summary"
              class="grid-one-one"
              dataCy="report-charts-checkbox"
              (valueChanged)="updateReportInfoFields($event)"
              [isUiLockable]="false"
            >
            </app-check-box>

            <app-check-box
              *ngIf="isPsdDataVisible"
              [source]="reportInfoFields"
              [key]="'IsPsdScreenAndGravelSelection'"
              label="Screen and Gravel Selection"
              class="grid-two-one"
              dataCy="report-charts-checkbox"
              (valueChanged)="updateReportInfoFields($event)"
              [isUiLockable]="false"
            >
            </app-check-box>
          </div>
        </app-page-panel>

        <div class="reporting-info" [class.span-2]="isRangeSelectionComponentVisible$ | async">
          <app-page-panel header="Report Information" class="grid-three-one report-info-panel">

            <div class="grid-form-container cols-4-cross-aligned">
              <app-form-input
                [source]="reportInfo"
                [key]="'Well'"
                appUnwrap
                label="Well"
                dataCy="report-info-well"
                [isTextInput]="true"
                [unitType]="UnitType.None"
                [width]="appFormInputWidth"
                [minWidth]="appFormInputMinWidth"
                [isUiLockable]="false"
                (valueChanged)="emitValue($event)"
              >
              </app-form-input>
              <app-form-input
                [source]="reportInfo"
                [key]="'Field'"
                appUnwrap
                label="Field"
                dataCy="report-info-field"
                [isTextInput]="true"
                [unitType]="UnitType.None"
                [width]="appFormInputWidth"
                [minWidth]="appFormInputMinWidth"
                [isUiLockable]="false"
                (valueChanged)="emitValue($event)"
              >
              </app-form-input>
              <app-form-input
                [source]="reportInfo"
                [key]="'Country'"
                appUnwrap
                label="Country"
                dataCy="report-info-country"
                [isTextInput]="true"
                [unitType]="UnitType.None"
                [width]="appFormInputWidth"
                [minWidth]="appFormInputMinWidth"
                [isUiLockable]="false"
                (valueChanged)="emitValue($event)"
              >
              </app-form-input>
              <label>Date</label>
              <app-date-time-input
                [value]="date"
                [isUiLockable]="false"
                (primitiveValueChanged)="updateReportDateFieldField($event)"
                [attr.data-cy]="'report-date-time-input'"
              ></app-date-time-input>
            </div>
          </app-page-panel>
          <app-page-panel header="Prepared By" class="grid-three-one report-info-panel">
            <div class="grid-form-container cols-4-cross-aligned">
              <app-form-input
                [source]="reportInfo"
                [key]="'PreparedByName'"
                appUnwrap
                label="Name"
                dataCy="prepared-by-name"
                [isTextInput]="true"
                [unitType]="UnitType.None"
                [width]="appFormInputWidth"
                [minWidth]="appFormInputMinWidth"
                [isUiLockable]="false"
                (valueChanged)="emitValue($event)"
              >
              </app-form-input>
              <app-form-input
                [source]="reportInfo"
                [key]="'PreparedByCompany'"
                appUnwrap
                label="Company"
                dataCy="prepared-by-company"
                [isTextInput]="true"
                [unitType]="UnitType.None"
                [width]="appFormInputWidth"
                [minWidth]="appFormInputMinWidth"
                [isUiLockable]="false"
                (valueChanged)="emitValue($event)"
              >
              </app-form-input>
              <app-form-input
                [source]="reportInfo"
                [key]="'PreparedByPhone'"
                appUnwrap
                label="Phone"
                dataCy="prepared-by-phone"
                [isTextInput]="true"
                [unitType]="UnitType.None"
                [width]="appFormInputWidth"
                [minWidth]="appFormInputMinWidth"
                [isUiLockable]="false"
                (valueChanged)="emitValue($event)"
              >
              </app-form-input>
              <app-form-input
                [source]="reportInfo"
                [key]="'PreparedByEmail'"
                appUnwrap
                label="Email"
                dataCy="prepared-by-email"
                [isTextInput]="true"
                [unitType]="UnitType.None"
                [width]="appFormInputWidth"
                [minWidth]="appFormInputMinWidth"
                [isUiLockable]="false"
                (valueChanged)="emitValue($event)"
              >
              </app-form-input>
            </div>
          </app-page-panel>
          <app-page-panel header="Prepared For" class="grid-three-one report-info-panel">
            <div class="grid-form-container cols-4-cross-aligned">
              <app-form-input
                [source]="reportInfo"
                [key]="'PreparedForName'"
                appUnwrap
                label="Name"
                dataCy="prepared-for-name"
                [isTextInput]="true"
                [unitType]="UnitType.None"
                [width]="appFormInputWidth"
                [minWidth]="appFormInputMinWidth"
                [isUiLockable]="false"
                (valueChanged)="emitValue($event)"
              >
              </app-form-input>
              <app-form-input
                [source]="reportInfo"
                [key]="'PreparedForCompany'"
                appUnwrap
                label="Company"
                dataCy="prepared-for-company"
                [isTextInput]="true"
                [unitType]="UnitType.None"
                [width]="appFormInputWidth"
                [minWidth]="appFormInputMinWidth"
                [isUiLockable]="false"
                (valueChanged)="emitValue($event)"
              >
              </app-form-input>
              <app-form-input
                [source]="reportInfo"
                [key]="'PreparedForPhone'"
                appUnwrap
                label="Phone"
                dataCy="prepared-for-phone"
                [isTextInput]="true"
                [unitType]="UnitType.None"
                [width]="appFormInputWidth"
                [minWidth]="appFormInputMinWidth"
                [isUiLockable]="false"
                (valueChanged)="emitValue($event)"
              >
              </app-form-input>
              <app-form-input
                [source]="reportInfo"
                [key]="'PreparedForEmail'"
                appUnwrap
                label="Email"
                dataCy="prepared-for-email"
                [isTextInput]="true"
                [unitType]="UnitType.None"
                [width]="appFormInputWidth"
                [minWidth]="appFormInputMinWidth"
                [isUiLockable]="false"
                (valueChanged)="emitValue($event)"
              >
              </app-form-input>
            </div>
          </app-page-panel>
        </div>
        <app-page-panel header="Range Selection" class="charts-selector-panel" data-cy="range-selection-panel"
                        *ngIf="isRangeSelectionComponentVisible$ | async">
          <app-reporting-range-selection [moduleType]="currentAppModuleType"></app-reporting-range-selection>
        </app-page-panel>
      </div>
      <div class="buttons-panel">
        <div>
          <app-button
            dataCy="report-select-all"
            (buttonClick)="toggleReportInfoCheckboxes(true)"
            [isUiLockable]="false"
            text="Select All"
          >
          </app-button>
          <app-button
            dataCy="report-clear-all"
            (buttonClick)="toggleReportInfoCheckboxes(false)"
            [isUiLockable]="false"
            text="Clear All"
          >
          </app-button>
        </div>
        <div>

          <app-button
            dataCy="report-generate"
            (click)="generateReport()"
            [isUiLockable]="false"
            text="Generate"
          >
          </app-button>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>

<div *ngIf="imageRenderingContainerVisible" class="image-rendering-outer-container">
  <div class="image-rendering-inner-container">
    <ng-container #imageRenderingContainer></ng-container>
  </div>
</div>

<ng-template #pageDisabled>
  <div class="centered-message">The Reporting screen is not available - results are needed.</div>
</ng-template>
