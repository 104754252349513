<div class="psd-chart-container" *ngIf="psdData$ | async as psdData" (click)="onHelpChange('psd', 'psd-chart')">
  <app-page-panel class="small-padding">
    <div class="psd-selector-container">
      {{ psdData.selectedPSD.Description }}
    </div>
  </app-page-panel>
  <app-page-panel header="PSD Chart" [panelHelpMode]="PanelHelpMode.CHART" class="no-padding">
    <app-psd-chart-panel [psdReadings]="psdData.selectedPSDReadings" [psd]="psdData.selectedPSD"></app-psd-chart-panel>
  </app-page-panel>
  <app-page-panel header="PSD Properties">
    <div class="grid-form-container cols-6" *ngIf="psdData.selectedPSD.result" appEqualizeSymbols>
      <app-form-input
        appUnwrap
        label="d10"
        [source]="psdData.selectedPSD.result"
        [key]="'D10'"
        [decimalPlaces]="3"
        [unitType]="UnitType.Screen_Opening"
        [disabled]="true"
        [cssClass]="'pr-1'"
      >
      </app-form-input>
      <app-form-input
        appUnwrap
        label="d40"
        [source]="psdData.selectedPSD.result"
        [key]="'D40'"
        [decimalPlaces]="3"
        [unitType]="UnitType.Screen_Opening"
        [disabled]="true"
        [cssClass]="'pr-1'"
      >
      </app-form-input>
      <app-form-input
        appUnwrap
        label="d50"
        [source]="psdData.selectedPSD.result"
        [key]="'D50'"
        [decimalPlaces]="3"
        [unitType]="UnitType.Screen_Opening"
        [disabled]="true"
      >
      </app-form-input>
      <app-form-input
        appUnwrap
        label="d90"
        [source]="psdData.selectedPSD.result"
        [key]="'D90'"
        [decimalPlaces]="3"
        [unitType]="UnitType.Screen_Opening"
        [disabled]="true"
        [cssClass]="'pr-1'"
      >
      </app-form-input>
      <app-form-input
        appUnwrap
        label="d95"
        [source]="psdData.selectedPSD.result"
        [key]="'D95'"
        [decimalPlaces]="3"
        [unitType]="UnitType.Screen_Opening"
        [disabled]="true"
        [cssClass]="'pr-1'"
      >
      </app-form-input>
      <app-form-input
        appUnwrap
        [label]="percentFinesLabel"
        [source]="psdData.selectedPSD.result"
        [key]="'PercentFines'"
        [decimalPlaces]="2"
        [unitType]="UnitType.None"
        [unitLabel]="'%'"
        [disabled]="true"
      >
      </app-form-input>
      <app-form-input
        appUnwrap
        label="Uniformity Coefficient"
        [source]="psdData.selectedPSD.result"
        [key]="'UniformityCoefficient'"
        [decimalPlaces]="2"
        [unitType]="UnitType.None"
        [disabled]="true"
        [forceShowSymbol]="true"
        [cssClass]="'pr-1'"
      >
      </app-form-input>
      <app-form-input
        appUnwrap
        label="Sorting Coefficient"
        [source]="psdData.selectedPSD.result"
        [key]="'SortingCoefficient'"
        [decimalPlaces]="2"
        [unitType]="UnitType.None"
        [disabled]="true"
        [forceShowSymbol]="true"
        [cssClass]="'pr-1'"
      >
      </app-form-input>
    </div>
  </app-page-panel>
</div>
