import { ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { WarningMessage } from '@dunefront/common/modules/summary/warning-message.factory';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { ColumnType, IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { GridConfig } from '../../../../../shared/components/grid/grid-config';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { IScreenSize, ScreenService } from '../../../../../shared/services';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';

@Component({
  selector: 'app-warning-messages-grid',
  templateUrl: './warning-messages-grid.component.html',
  styleUrls: ['./warning-messages-grid.component.scss'],
})
export class WarningMessagesGridComponent extends GridContainerComponent<WarningMessage> {
  @Input() public warningMessages!: ITableState<WarningMessage>;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    protected convertUnitPipe: ConvertUnitPipe,
    el: ElementRef,
    gridResizeService: GridResizeService,
    protected resizeService: ScreenService,
  ) {
    super(store, cdRef, el, new WarningMessageGridConfig(store, modalService), gridResizeService);
  }

  public getHeight(): number {
    return Math.max(this.height, 100);
  }

  protected getColumns(): IGridColumnConfig<WarningMessage>[] {
    return [
      { disabled: true, visible: true, colId: ' ', type: ColumnType.selection, width: 20 },
      {
        disabled: true,
        visible: true,
        colId: 'SimulationTime',
        headerText: 'Simulation Time',
        unitSystem: UnitSystem.Time,
        type: ColumnType.number,
        align: 'right',
        width: 150,
        castNullToNumber: false,
      },
      {
        disabled: true,
        visible: true,
        colId: 'SimulationVolume',
        headerText: 'Simulation Volume',
        unitSystem: UnitSystem.Liquid_Volume,
        type: ColumnType.number,
        align: 'right',
        width: 150,
        castNullToNumber: false,
      },
      {
        disabled: true,
        visible: true,
        colId: 'MeasuredDepth',
        headerText: 'MD',
        unitSystem: UnitSystem.Long_Length,
        type: ColumnType.number,
        align: 'right',
        width: 150,
        castNullToNumber: false,
      },
      {
        disabled: true,
        visible: true,
        colId: 'MessageText',
        headerText: 'Message',
        type: ColumnType.text,
        align: 'left',
      },
    ];
  }

  public override resize(size?: IScreenSize): void {
    if (size && size.resizeType === 'x') {
      return;
    }

    this.height = 0;
    this.cdRef.markForCheck();
    super.resize();
  }
}

export class WarningMessageGridConfig extends GridConfig<WarningMessage> {
  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
  }
}
