<header class="app-header"  *ngrxLet="file$ as file"  [ngClass]="{
      'shrink-left': isNavPinned,
      'file-opened' : file
    }">
    @if (file) {
      <div class="header-text">
        <span class="error test-mode" *ngIf="isTestMode">(TEST MODE)</span>
        <span class="file-name text-ellipsis" *ngIf="file">{{ file | fileDisplayName }}</span>
        <span class="scenario-name text-ellipsis" *ngIf="scenarioName">&nbsp;{{ scenarioName }}</span>

        <span class="scenario-lock-container" >
          <span class="lock-message" *ngIf="lockMessage.length">
            <span class="icon-lock"></span>
            <span class="text-ellipsis">{{ lockMessage }}</span>
          </span>
        </span>
      </div>

    } @else {
      <p class="header-text"> Hi <span *ngIf="user$ | async as user">{{ user.name }}</span>, what would you like to do?
      </p>
    }


  <div class="app-tools-panel-container">
    <app-user-panel></app-user-panel>
  </div>
</header>