import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TreatmentType } from '@dunefront/common/modules/well/dto/well.dto';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { checkValidationForScheduleGenerator } from '../../../../../+store/pumping/selectors/pumping-schedule-generator-validator.selectors';
import { take } from 'rxjs/operators';
import { generateScheduleAction } from '../../../../../+store/pumping/pumping.actions';
import { Store } from '@ngrx/store';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { InputsHelperService } from '../../../../../shared/services/inputs-helper.service';

@Component({
  selector: 'app-schedule-generator',
  templateUrl: './schedule-generator.component.html',
  styleUrls: ['./schedule-generator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleGeneratorComponent {
  @Input() public pumping: Pumping | undefined;
  @Input() public treatmentType: TreatmentType | undefined;

  @Output() public dataChange = new EventEmitter<ObjectChangeProp<Pumping>>();

  public UnitType = UnitSystem;
  public TreatmentType = TreatmentType;

  constructor(
    private modalService: ModalService,
    private store: Store,
    private inputsHelperService: InputsHelperService,
  ) {}

  public onValueChange(args: ObjectChangeProp<Pumping>): void {
    if (this.pumping && this.pumping[args.key] !== args.value) {
      this.dataChange.emit(args);
    }
  }

  public get pumpRateLabel(): string {
    return this.isTxtAutoScheduleExcessPumpRateEnabled ? 'Max Pump Rate' : 'Pump Rate';
  }

  public get isTxtAutoScheduleTargetPackingEnabled(): boolean {
    return this.treatmentType === TreatmentType.High_Rate_Water_Pack;
  }

  public get isTxtAutoScheduleExcessPumpRateEnabled(): boolean {
    return this.treatmentType === TreatmentType.High_Rate_Water_Pack;
  }

  public async onGenerateScheduleClick(): Promise<void> {
    this.store
      .select(checkValidationForScheduleGenerator)
      .pipe(take(1))
      .subscribe(async (validationMessage) => {
        if (validationMessage) {
          await this.modalService.showAlert(validationMessage);
          return;
        }
        if (await this.modalService.showConfirm('The existing pumping schedule will be deleted - do you want to continue?', 'Information')) {
          if (this.pumping && (await this.inputsHelperService.checkResultsAndDeleteIfNeeded(true))) {
            this.store.dispatch(generateScheduleAction({ scenarioId: this.pumping.ScenarioId }));
          }
        }
      });
  }
}
