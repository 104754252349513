<div class="left-nav-container"  [ngClass]="{'open': isSideNavExpanded, 'datapro': isDataProApp}" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <app-left-nav-app-logo [appCode]="appConfig.appCode" [isPinned]="isSideNavPinned"></app-left-nav-app-logo>
  <div class="nav-items">
    <app-left-nav-item
      [isSideNavExpanded]="isSideNavExpanded"
      [element]="homeButtonItem"
      [isCalculationActive]="isCalculationActive"
      [isDisabledWhenCalculationActive]="true"
    ></app-left-nav-item>

    <div class="link-buttons-container">
      <app-left-nav-item
        *ngIf="areMultipleModulesAvailable && showModuleSwitcher"
        [element]="moduleSelectionItem"
        [isRouterLinkDisabled]="true"
        [isSideNavExpanded]="isSideNavExpanded"
        (navClick)="runCalculationService.moduleSelectionClick()"
        [isCalculationActive]="isCalculationActive"
        [isDisabledWhenCalculationActive]="true"
      ></app-left-nav-item>
      <div class="scrollable">


      <!--regular items-->
      <app-left-nav-item
        #navMenuItem
        [isSideNavExpanded]="isSideNavExpanded"
        [selectedUri]="selectedUri"
        [isSideNavPinned]="isSideNavPinned"
        [element]="$any(menuElement)"
        [initOpenElement]="isInitHover(menuElement)"
        (mouseenter)="onHoverNavItem(menuElement)"
        (reordered)="onReordered($event)"
        id="{{'nav-element-' + menuElement.label}}"
        *ngFor="let menuElement of menuData?.regular; trackBy: trackByFn"
        (activeMenuItemChanged)="activeMenuItemChanged.emit($event); initialHoverItem = null"
      ></app-left-nav-item>

      <div class="white-section" *ngIf="menuData?.results?.length" [ngClass]="{'open' : isSideNavExpanded, 'one-item' : menuData?.results?.length === 1}">


      <ng-template ngFor let-menuElement let-i="index" [ngForOf]="menuData?.results" [ngForTrackBy]="trackByFn">
        <ng-container [ngSwitch]="menuElement.type">
          <app-left-nav-item
            *ngSwitchCase="'NavElement'"
            #navMenuItem
            [isSideNavExpanded]="isSideNavExpanded"
            [selectedUri]="selectedUri"
            [isSideNavPinned]="isSideNavPinned"
            [element]="$any(menuElement)"
            [initOpenElement]="isInitHover(menuElement)"
            [isResultItem]="true"
            (mouseenter)="onHoverNavItem(menuElement)"
            (reordered)="onReordered($event)"
            id="{{'nav-element-' + menuElement.label}}"
            (activeMenuItemChanged)="activeMenuItemChanged.emit($event); initialHoverItem = null"
          ></app-left-nav-item>
          <app-start-stop-button
            *ngSwitchCase="'StartStopButton'"
            (mouseenter)="onHoverNavItem(menuElement)"
            [isSideNavExpanded]="isSideNavExpanded"
          ></app-start-stop-button>
        </ng-container>
      </ng-template>
      </div>

      </div>
      <div class="logo-footer">
        <app-left-nav-df-logo [isExpanded]="isSideNavExpanded"></app-left-nav-df-logo>
      </div>

    </div>
  </div>


  <div class="open-close-btn" (click)="togglePinSideNav()" [class.active]="isSideNavPinned" *ngIf="!isAdminPanel">
    <span class="icon-sidemenu-pinned"></span>
  </div>
</div>


