<ng-container
  *ngrxLet="{items: items$, filteredItems: filteredItems$, selection: selection$, config: config$, filter: filter$, groupedReportingTabs: groupedReportingTabs$} as vm">
  <div class="filters">
    <div class="filters-left">
      <p-checkbox
        [disabled]="isReorderMode"
        [binary]="true"
        [ngModel]="isEverythingVisibleChecked(vm.items, vm.selection)"
        (ngModelChange)="onToggleAll(vm.items, vm.selection, vm.config.RangesSelectorOrder, $event)"
      ></p-checkbox>

      <app-input
        [maxWidth]="225"
        [width]="225"
        [showSymbol]="false"
        [align]="'left'"
        [value]="vm.filter"
        [disabled]="isReorderMode"
        [isTextInput]="true"
        [isUiLockable]="false"
        [placeholder]="'Search'"
        [triggerOnKeyPress]="true"
        (primitiveValueChanged)="filter$.next($event.value.toString())"
        [icon]="'icon-search'"

        dataCy="charts-selector-filter-input"
      ></app-input>
    </div>
    <app-button
      dataCy="range-selection-reorder-button"
      [class.highlight]="isReorderMode"
      [disabled]="vm.items.length <= 1 || vm.filter.length > 0"
      [isDeleteResultsEnabled]="false"
      (buttonClick)="isReorderMode = !isReorderMode"
      text="Reorder"
    >
    </app-button>
  </div>

  <p-listbox
    [multiple]="true"
    [checkbox]="true"
    [metaKeySelection]="false"
    [showToggleAll]="false"
    class="chart-selector-listbox"
    [listStyle]="{ 'max-height': '100%' }"
    cdkDropList
    (cdkDropListDropped)="drop($event, vm.items, vm.selection)"
    [options]="vm.filteredItems"
    [group]="true"
    [ngModel]="vm.selection.ReportingTabIds"
    [class.reorder]="isReorderMode"
    (ngModelChange)="onReportingTabSelection($event, vm.items, vm.selection, vm.config.RangesSelectorOrder)"
  >

    <ng-template let-group pTemplate="group">
      <div class="group" cdkDrag
           cdkDragBoundary=".p-listbox-list"
           [cdkDragLockAxis]="'y'"
           [cdkDragDisabled]="!isReorderMode"
           [class.draggable]="isReorderMode"
           [attr.data-cy]="'range-selection-group-checkbox-' + group.value">
        <i class="icon-arrow-up-down reorder-icon" *ngIf="isReorderMode"></i>
        <p-checkbox [inputId]="group.value" *ngIf="!isReorderMode && !isSimulateBased" [ngModel]="vm.selection.RangeIds"
                    [value]="group.value"
                    (ngModelChange)="onRangeSelection(group.value, vm.groupedReportingTabs, vm.selection, vm.config.RangesSelectorOrder)"></p-checkbox>
        <label [for]="group.value">{{ group.label }}</label>
      </div>
    </ng-template>

    <ng-template let-item pTemplate="item">
      <span class="item-label"
            [attr.data-cy]="'range-selection-item-checkbox-' + item.value">{{ item.label }}</span>
    </ng-template>
  </p-listbox>

</ng-container>
