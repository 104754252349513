<div class="buttons-panel">
  <div class="align-right inline-btns">
    <app-button
      type="button"
      class="small prev-next"
      [disabled]="prevDisabled"
      [isUiLockable]="false"
      [isDisabledWhenCalculationActive]="false"
      (buttonClick)="navigate('prev')"
      text="Prev"
    >

    </app-button>
    <app-button
      type="button"
      class="small prev-next"
      [disabled]="nextDisabled"
      [isUiLockable]="false"
      [isDisabledWhenCalculationActive]="false"
      (buttonClick)="navigate('next')"
      text="Next"
    >

    </app-button>
  </div>
</div>
