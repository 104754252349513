<div class="masp-calculator-container" *ngIf="maspCalculatorData.isLoaded">
  <app-page-panel header="Input Data" (click)="onHelpChange('calculators', 'masp-input-data')">
    <div class="grid-form-container cols-4">
      <app-fluid-selector
        [source]="maspCalculatorData"
        [key]="'FluidId'"
        label="Fluid"
        appUnwrap
        [errorMessage]="maspCalculatorData.error.FluidId"
        (valueChanged)="submitCalcData($event)"
        dataCy="masp-calculator-fluid"
        [showSymbol]="true"
      >
      </app-fluid-selector>
      <app-gravel-selector
        [source]="maspCalculatorData"
        [key]="'GravelId'"
        label="Gravel"
        appUnwrap
        [errorMessage]="maspCalculatorData.error.GravelId"
        (valueChanged)="submitCalcData($event)"
        dataCy="masp-calculator-gravel"
        [showSymbol]="true"
      >
      </app-gravel-selector>
      <app-form-input
        appUnwrap
        label="Gravel Concentration"
        [source]="maspCalculatorData"
        [key]="'Concentration'"
        [disabled]="maspCalculatorData.GravelId === 0 || !maspCalculatorData.GravelId"
        [decimalPlaces]="2"
        [unitType]="UnitType.Solid_Concentration"
        (valueChanged)="submitCalcData($event)"
        dataCy="masp-calculator-concentration"
      >
      </app-form-input>
      <app-form-input
        appUnwrap
        label="TVD"
        [source]="maspCalculatorData"
        [key]="'TVD'"
        [decimalPlaces]="2"
        [unitType]="UnitType.Long_Length"
        (valueChanged)="submitCalcData($event)"
        dataCy="masp-calculator-tvd"
      >
      </app-form-input>
      <div>Calculation Type</div>
      <app-radio-group
        [source]="maspCalculatorData"
        [key]="'MASPCalculationType'"
        [items]="calculationTypes"
        class="grid-three-one"
        id="MASPCalculationType"
        (valueChanged)="submitCalcData($event)"
        dataCy="masp-calculator-calculation-type"
      >
      </app-radio-group>
      <app-form-input
        appUnwrap
        label="Max Pressure at TVD"
        [source]="maspCalculatorData"
        [disabled]="maspCalculatorData.MASPCalculationType === 1"
        [key]="'MaxPressureAtTVD'"
        [decimalPlaces]="2"
        [unitType]="UnitType.Pressure"
        (valueChanged)="submitCalcData($event)"
        dataCy="masp-calculator-max-pressure-tvd"
      >
      </app-form-input>
      <app-form-input
        appUnwrap
        label="Internal Pressure"
        [source]="maspCalculatorData"
        [disabled]="maspCalculatorData.MASPCalculationType === 0"
        [key]="'InternalPressure'"
        [decimalPlaces]="2"
        [unitType]="UnitType.Pressure"
        (valueChanged)="submitCalcData($event)"
        dataCy="masp-calculator-internal-pressure"
      >
      </app-form-input>
      <app-form-input
        appUnwrap
        label="Collapse Rating"
        [source]="maspCalculatorData"
        [disabled]="maspCalculatorData.MASPCalculationType === 0"
        [key]="'CollapseRating'"
        [decimalPlaces]="2"
        [unitType]="UnitType.Pressure"
        (valueChanged)="submitCalcData($event)"
        dataCy="masp-calculator-collapse-rating"
      >
      </app-form-input>
      <app-form-input
        appUnwrap
        label="Safety Factor"
        [source]="maspCalculatorData"
        [disabled]="maspCalculatorData.MASPCalculationType === 0"
        [key]="'SafetyFactor'"
        [decimalPlaces]="2"
        [unitType]="UnitType.None"
        unitLabel="%"
        (valueChanged)="submitCalcData($event)"
        dataCy="masp-calculator-safety-factor"
      >
      </app-form-input>
    </div>
  </app-page-panel>
  <app-page-panel class="panel-container" header="Results" (click)="onHelpChange('calculators', 'masp-results')">
    <div class="grid-form-container">
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Max Allowable Surface Pressure"
        [disabled]="true"
        [source]="maspCalculatorData"
        [key]="'MaxAllowableSurfacePressure'"
        [decimalPlaces]="2"
        [unitType]="UnitType.Pressure"
        dataCy="masp-calculator-max-surface-pressure"
      >
      </app-form-input>
    </div>
  </app-page-panel>
</div>
