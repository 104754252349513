<app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
  <span>Trigger Sentry ignored error</span>
  <div class="btn-row">
    <app-button
            dataCy="btn-trigger-ignored-frontend"
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="triggerIgnoredFrontendError()"
            text="Ignored Frontend"
    >
    </app-button>
    <app-button
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="triggerIgnoredBackendError()"
            dataCy="btn-trigger-ignored-backend"
            text="Ignored Backend"
    >
    </app-button>
    <app-button
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="triggerCalcEngineShortWorkerError(true, 'crash')"
            dataCy="btn-trigger-ignored-short-worker"
            text="Ignored Calc Engine Short Worker"
    >
    </app-button>

    <app-button
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            [disabled]="currentCalculationJob === undefined"
            (click)="triggerIgnoredRunningCalcEngineError()"
            dataCy="btn-trigger-ignored-long-worker"
            text="Ignored Calc Engine Long Worker"
    >
    </app-button>
  </div>

  <span>Trigger Sentry error</span>
  <div class="btn-row">
    <app-button
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="triggerNotIgnoredFrontendError()"
            dataCy="btn-trigger-frontend"
            text="Sentry Frontend"
    >
    </app-button>
    <app-button
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="triggerNotIgnoredBackendError()"
            dataCy="btn-trigger-backend"
            text="Sentry Backend"
    >
    </app-button>
    <app-button
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="triggerCalcEngineShortWorkerError(false, 'crash')"
            dataCy="btn-trigger-short-worker"
            text="Sentry Calc Engine Short Worker"
    >
    </app-button>
    <app-button
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="triggerCalcEngineShortWorkerError(false, 'timeout')"
            dataCy="btn-trigger-short-worker-timeout"
            text="Sentry Calc Engine Short Worker Timeout"
    >

    </app-button>
    <app-button
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            [disabled]="currentCalculationJob === undefined"
            (click)="triggerNotIgnoredRunningCalcEngineError()"
            dataCy="btn-trigger-long-worker"
            text="Sentry Calc Engine Long Worker"
    >
    </app-button>
  </div>

  <span>Other</span>
  <div class="btn-row">
    <app-button
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="freezeBackend()"
            dataCy="btn-freeze-backend"
            text="Freeze Backend"
    >
    </app-button>

    <app-button
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="pingPong()"
            dataCy="btn-ping-pong"
            text="Ping calc engine"
    >
    </app-button>
  </div>
</app-generic-modal>
