<ng-container *ngIf="isPumpingEnabled$ | async; else pageDisabled">
  <div class="pumping-initial-data-grid" *ngIf="data && data.well.isLoaded">
    <app-page-panel
      header="Tool Position"
      class="initial-data-panel tool-position-panel"
      (click)="onHelpChange('pumping', 'tool-position')"
    >
      <app-tool-position
        [pumping]="data.pumping"
        (dataChange)="onInitialDataPropertyChanged($event)"
        class="container-full"
      ></app-tool-position>
    </app-page-panel>

    <app-page-panel header="Subsea BOP" class="initial-data-panel subsea-bop-panel"
                    (click)="onHelpChange('pumping', 'subsea-bop')">
      <app-subsea-bop [data]="data" (dataChange)="onInitialDataPropertyChanged($event)"
                      class="container-full"></app-subsea-bop>
    </app-page-panel>

    <app-page-panel header="Pumping Direction"
      class="initial-data-panel pumping-direction-panel"
      (click)="onHelpChange('pumping', 'pumping-direction')"
    >
      <app-pumping-direction
        [pumping]="data.pumping"
        (dataChange)="onInitialDataPropertyChanged($event)"
        class="container-full"
      ></app-pumping-direction>
    </app-page-panel>

    <app-page-panel header="Annular BOP" class="initial-data-panel annular-bop-panel"
                    (click)="onHelpChange('pumping', 'annular-bop')">
      <app-annular-bop [data]="data" (dataChange)="onInitialDataPropertyChanged($event)"></app-annular-bop>
    </app-page-panel>

    <app-page-panel header="Treating Line / Coiled Tubing"
      class="initial-data-panel treating-line-coiled-tubing-panel"
      (click)="onHelpChange('pumping', 'treating-line-coiled-tubing')"
    >
      <app-treating-line-coiled-tubing
        [pumping]="data.pumping"
        (dataChange)="onInitialDataPropertyChanged($event)"
      ></app-treating-line-coiled-tubing>
    </app-page-panel>

    <app-page-panel header="Choke" class="initial-data-panel app-choke-panel" *ngIf="data"
                    (click)="onHelpChange('pumping', 'choke')">
      <app-choke [data]="data" (dataChange)="onInitialDataPropertyChanged($event)"></app-choke>
    </app-page-panel>

    <app-page-panel header="Pump Pop-Off" class="pump-pop-off-panel" (click)="onHelpChange('pumping', 'pump-pop-off')">
      <app-pump-pop-off
        [pumping]="data.pumping"
        (dataChange)="onInitialDataPropertyChanged($event)"
        class="container-full"
      ></app-pump-pop-off>
    </app-page-panel>

  </div>
  <div *ngIf="currentAppModuleType === ModuleType.Evaluate" class="pumping-footer">
    <app-button
      dataCy="copy-initial-data"
      (buttonClick)="copyFromSimulateEvaluate()"
      [isUiLockable]="true"
      text="Copy data from…"
    >
    </app-button>
  </div>
</ng-container>
<ng-template #pageDisabled>
  <div class="centered-message">
    The Initial Data screen is not available for the Entire Range - select another range to update the data
  </div>
</ng-template>
