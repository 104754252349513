import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getBackendToClientConfig } from '@dunefront/common/backend-to-client-config';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class ApiService {
  private readonly apiUrl: string;

  constructor(public http: HttpClient) {
    this.apiUrl = getBackendToClientConfig().ServerBaseUrl;
  }

  public getActionUrl(controller: string, action: string): string {
    return `${this.apiUrl}/${controller}/${action}`;
  }

  public async resendVerificationEmail(): Promise<void> {
    await firstValueFrom(
      this.http.get(this.getActionUrl('auth', 'resend-verification-email'), { headers: { 'Content-Type': 'application/json' } }),
    );
  }
}
