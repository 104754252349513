import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { APP_CODE } from '@dunefront/common/common/app-target-config';
import { NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
  selector: 'app-left-nav-app-logo',
  templateUrl: 'left-nav-app-logo.component.html',
  styleUrls: ['./left-nav-app-logo.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgSwitchCase, NgSwitch],
})
export class LeftNavAppLogoComponent {
  @Input() public isPinned = false;
  @Input({ required: true }) public appCode!: APP_CODE;
}
