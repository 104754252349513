<app-page-panel class="small-padding">
  <app-searchbar (searchChanged)="searchPhrase$.next($event)"></app-searchbar>
</app-page-panel>
<app-page-panel class="grid-panel" header="Database Gravels" (click)="onHelpChange('gravels', 'gravel-database')">
  <ng-container *ngIf="filteredCommonGravels$ | async as filteredCommonGravels">
    <app-database-gravels-grid
      *ngIf="filteredCommonGravels.rows.length"
      #gridComponent
      [customKeyboardHandlers]="getCustomKeyboardHandlers"
      [commonGravelData]="filteredCommonGravels"
      (selectedRowsChanged)="onSelectedRowChanged($event)"
    ></app-database-gravels-grid>
  </ng-container>
</app-page-panel>

<div class="buttons-panel">
  <div></div>
  <div>
    <app-button
      dataCy="database-gravels-load"
      [isUiLockable]="false"
      (buttonClick)="onLoadFromDatabase()"
      [disabled]="!selectedRow"
      text="Load from Database"
    >

    </app-button>
    <app-button
      dataCy="database-gravels-delete"
      [isUiLockable]="false"
      (buttonClick)="onDelete()"
      [disabled]="!selectedRow || selectedRow.rowData.commonDbType === 'Predefined'"
      text="Delete"
    >

    </app-button>
  </div>
</div>
