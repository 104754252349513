import { v4 as uuidV4 } from 'uuid';
import { CrudResponse, IUndoInfo, UndoOrRedo } from './modules/common.actions';
import { InsertLocation } from './modules/common.interfaces';
import { ICommonStoreCrudProps, IDeleteRowsProps, IInsertRowsPropsCommon } from './common/common-store-crud.interfaces';
import { IAffectedModels } from './dto/dto.common';
import { IActivityOverlayConfig } from './modules/ui/ui.interfaces';

export abstract class WsAction {
  public readonly type!: string;
  public url = '';
  public shouldDeleteResults = false;
  public taskId = uuidV4();
  public undoOrRedo?: UndoOrRedo;
  public undoInfo?: IUndoInfo<any>;
  public isDbDependent?: boolean;
  public payload?: any;
  public activityOverlayConfig?: IActivityOverlayConfig;
  public undoRedoCustomUpdate: Partial<IAffectedModels> = {};
}

export const DEFAULT_ACTIVITY_OVERLAY_CONFIG = {
  taskId: '',
  message: '',
  title: '',
  displayDelayMs: 300,
  minDisplayTimeMs: 300,
  width: 'md',
};

export const hasUndoRedoCustomUpdate = (action: WsAction): boolean => Object.keys(action.undoRedoCustomUpdate).length > 0;

export abstract class InsertRowsWsAction<DTO> extends WsAction {
  public childInsertActions: InsertRowsWsAction<any>[] | undefined = [];

  public readonly scenarioId: number;
  public readonly insertLocation: InsertLocation = 'insert';
  public originalInsertLocation?: InsertLocation; // used in undo to differentiate handling of undo actions (e.g. SortOrder incrementation)
  public noOfRowsToDelete?: number;
  public rows: DTO[] = [];
  public refId?: number;

  constructor(
    action: IInsertRowsWsActionProps<DTO>,
    public rowIdsToDelete?: number[],
    public parentId?: number,
  ) {
    super();
    this.rows = action.rows;
    this.refId = action.refId;
    this.shouldDeleteResults = action.shouldResetResults;
    this.scenarioId = action.scenarioId;
    this.noOfRowsToDelete = action.noOfRowsToDelete;
    if (action.insertLocation != null) {
      this.insertLocation = action.insertLocation;
    }
  }
}

export abstract class UpdateRowsWsAction<DTO> extends WsAction {
  public childUpdateActions: UpdateRowsWsAction<any>[] | undefined = [];

  public rows: DTO[] = [];
  public colIds?: (keyof DTO)[];
  public readonly scenarioId: number;
  public isUndoEnabled?: boolean;

  constructor(action: IUpdateRowsWsActionProps<DTO>) {
    super();
    this.rows = action.rows;
    this.colIds = action.colIds;
    this.shouldDeleteResults = action.shouldResetResults;
    this.scenarioId = action.scenarioId;
    this.isUndoEnabled = action.isUndoEnabled;
  }
}

export abstract class DeleteRowsWsAction extends WsAction {
  public readonly rowIds: number[];
  public readonly scenarioId: number;

  constructor(action: IDeleteRowsProps) {
    super();
    this.rowIds = action.rowIds;
    this.scenarioId = action.scenarioId;
    this.shouldDeleteResults = action.shouldResetResults;
  }
}

export abstract class ReloadRowsWsAction extends WsAction {
  constructor(
    public scenarioId: number,
    public parentId?: number,
  ) {
    super();
  }
}

export abstract class CopyRowsWsAction extends WsAction {
  constructor(
    public id: number,
    public scenarioId: number,
  ) {
    super();
  }
}

export abstract class ReorderRowWsAction<DTO> extends WsAction {
  constructor(
    public reorderRow: DTO,
    public toIndex: number,
  ) {
    super();
  }
}

export class ActionNotification extends WsAction {
  constructor(
    public override readonly type: string,
    public fileHash: string,
    public override payload: CrudResponse,
  ) {
    super();
  }
}

export interface IInsertRowsWsActionProps<DTO> extends IInsertRowsPropsCommon {
  rows: DTO[];
  scenarioId: number;
}

export interface IUpdateRowsWsActionProps<DTO> extends ICommonStoreCrudProps {
  rows: DTO[];
  scenarioId: number;
  colIds?: (keyof DTO)[];
  isUndoEnabled?: boolean;
}

export function getSingleRow<DTO>(updateAction: UpdateRowsWsAction<DTO>): DTO {
  if (updateAction.rows.length !== 1) {
    throw new Error('Wrong number of rows for ' + typeof updateAction.rows);
  }
  return updateAction.rows[0];
}
