<ng-container *ngIf="currentUnitSystem && wellState.isLoaded">
  <app-page-panel class="grid-panel" header="Casing Data" (click)="changeHelp('pipe-properties')"
                  [panelHelpMode]="PanelHelpMode.GRID">
    <app-casing-data-grid #gridComponent (selectedRowsChanged)="onSelectedRowsChanged($event)"
                          [casingGridData]="wellState.CasingData">
    </app-casing-data-grid>
  </app-page-panel>
  <app-page-panel class="options-panel" header="Perforation Properties" *ngIf="showPerforatedCasingProps">
    <app-perforation-properties
      [perforatedCasingPipe]="perforatedCasingPipe"
      (perforationPropertiesChanged)="updateRow($event)"
      (click)="changeHelp('perforated-casing')"
    >
    </app-perforation-properties>
  </app-page-panel>


  <div class="buttons-panel">
    <div>
    </div>
    <div>
      <app-button
        dataCy="pipe-data"
        [isUiLockable]="false"
        (buttonClick)="onShowPipeDataClick()"
        [disabled]="isPipeDataDisabled"
        text="Pipe Data"
      >

      </app-button>
      <app-button
        dataCy="casing-data-import"
        (buttonClick)="onImport()"
        [isDeleteResultsEnabled]="false"
        text="Import"
      >

      </app-button>
      <app-button (buttonClick)="onInsert()" [isDeleteResultsEnabled]="false" text="Insert"></app-button>
      <app-button (buttonClick)="onDelete()" data-cy="casing-data-delete" [isDeleteResultsEnabled]="false"
                  text="Delete"></app-button>
    </div>
  </div>

</ng-container>
