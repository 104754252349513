<ng-container *ngIf="connectionStatus$ | async as connStatus">
  <ng-container *ngIf="connStatus.backendConnectionStatus === 'failed' && isErrorHandled(connStatus)"
    ><div class="disconnected-overlay">
      <app-auth-error [errorMessage]="connStatus.connectionErrorMessage"></app-auth-error>
    </div>
  </ng-container>

  <ng-container *ngIf="(isOverlayDisabled$ | async) === false && !isErrorHandled(connStatus)">
    <app-button
      dataCy="btnErrorOverlayRefresh"
      [isUiLockable]="false"
      class="refresh-btn"
      (buttonClick)="refresh()"
      *ngIf="
        connStatus.backendConnectionStatus === ConnectionStatus.disconnected ||
        connStatus.backendConnectionStatus === ConnectionStatus.failed
      "
      text="Refresh"
    >
    </app-button>
    <div class="disconnected-overlay" *ngIf="connStatus.backendConnectionStatus === ConnectionStatus.disconnected">
      <div class="disconnected-overlay-text">
        <h3 class="text-center">Please wait...</h3>
        <div>You have been disconnected from the server.</div>
      </div>
    </div>
    <div class="disconnected-overlay" *ngIf="connStatus.backendConnectionStatus === ConnectionStatus.connecting">
      <div>
        <h3 class="text-center">Please wait...</h3>
        <div>You are being connected to the server.</div>
      </div>
    </div>
    <div class="disconnected-overlay" *ngIf="connStatus.backendConnectionStatus === ConnectionStatus.failed">
      <div>
        <h3 class="text-center">Couldn't connect to the server...</h3>
        <div class="connection-status-failed-error-message">{{ connStatus.connectionErrorMessage }}</div>
      </div>
    </div>
  </ng-container>
</ng-container>
