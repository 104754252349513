import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { onAuthenticated, onTokenRefreshed, onUnAuthenticated } from '../../+store/auth/auth.actions';
import { IUser } from '../../+store/auth/auth.reducer';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { v4 as uuidV4 } from 'uuid';
import { LicenseLogoutAction, LicensingModuleName } from '@dunefront/common/modules/licensing/licensing-module.actions';
import { getSessionIdLocalStorageKey } from '@dunefront/common/modules/auth/auth.interfaces';
import { firstValueFrom } from 'rxjs';
import { userRoleFromClaims } from '@dunefront/common/modules/auth/auth.helpers';
import { PP_SESSION_PREFIX } from '@dunefront/common/common/constants';
import { setShouldShowBeforeUnloadWarningAction } from '../../+store/ui/ui.actions';
import { AuthService, User } from '@auth0/auth0-angular';

@Injectable()
export class ClientAuthService {
  private _sessionId: string | undefined;
  public get sessionId(): string | undefined {
    return this._sessionId;
  }

  public user$ = this.authService.user$;

  constructor(
    private ngZone: NgZone,
    private store: Store,
    private authService: AuthService,
    private wsService: BackendConnectionService,
  ) {
    authService.isAuthenticated$.subscribe((isAuthenticated) => {
      this.onAuthenticationStateChanged(isAuthenticated).then();
    });
  }

  public async getAccessToken(): Promise<string | undefined> {
    return await firstValueFrom(this.authService.getAccessTokenSilently());
  }

  public async logout(logoutLicense = true): Promise<void> {
    this.store.dispatch(setShouldShowBeforeUnloadWarningAction({ shouldShowWarning: false }));

    if (logoutLicense) {
      try {
        await this.wsService.emitAsync(LicensingModuleName, false, new LicenseLogoutAction());
      } catch (err) {
        console.warn(err);
      }
    }
    await firstValueFrom(this.authService.logout({ logoutParams: { returnTo: window.location.origin } }));

    this.store.dispatch(setShouldShowBeforeUnloadWarningAction({ shouldShowWarning: true }));
  }

  private async onAuthenticationStateChanged(isAuthenticated: boolean): Promise<void> {
    isAuthenticated ? await this.onAuthenticated() : await this.onNotAuthenticated();
  }

  private async onAuthenticated(): Promise<void> {
    let accessToken: string | undefined;
    let authUser: User | undefined | null;
    let isLoginRequired = false;

    try {
      accessToken = await firstValueFrom(this.authService.getAccessTokenSilently());
      authUser = await firstValueFrom(this.authService.user$);
    } catch (err) {
      console.warn(err);
      isLoginRequired = true;
    }

    if (isLoginRequired || !accessToken || !authUser) {
      await this.onNotAuthenticated();
      return;
    }

    this.setUpSessionId(authUser);

    const isNewSocketConnected = await this.wsService.connect(accessToken, this.sessionId as string);

    if (isNewSocketConnected) {
      const user: IUser = {
        email: authUser.email ?? '',
        name: authUser.name ?? '',
        avatarUrl: authUser.picture,
        role: userRoleFromClaims(authUser),
      };

      this.store.dispatch(onAuthenticated({ accessToken, user }));
    } else {
      this.ngZone.run(() => {
        this.store.dispatch(onTokenRefreshed({ accessToken: accessToken as string }));
      });
    }
  }

  private setUpSessionId(user: User): void {
    let sid = window.localStorage.getItem(getSessionIdLocalStorageKey(user));
    if (!sid) {
      sid = uuidV4();
      window.localStorage.setItem(getSessionIdLocalStorageKey(user), sid);
    }

    this._sessionId = sid;
  }

  private clearSessionId(): void {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(PP_SESSION_PREFIX)) {
        localStorage.removeItem(key);
      }
    });
  }

  private async onNotAuthenticated(): Promise<void> {
    this.wsService.disconnect();
    this.store.dispatch(onUnAuthenticated());
    this._sessionId = undefined;
    this.clearSessionId();
    await firstValueFrom(this.authService.logout({ logoutParams: { returnTo: window.location.origin } }));
  }
}
