import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '../../modal.service';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { ImportDataModuleState } from '../../../../+store/import-data/import-data-module.state';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ImportColumnType, ImportDataType } from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppTargetConfig } from '../../../../shared/services/app-target-config';
import { NgxFileDropEntry } from '../../../ngx-file-drop/ngx-file-drop-entry';
import { FileSystemFileEntry } from '../../../ngx-file-drop/dom.types';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-file-loader',
  templateUrl: './file-loader.component.html',
  styleUrls: ['./file-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileLoaderComponent {
  @Output()
  public fileLoaded = new EventEmitter<IFileLoadedArgs>();

  @Input()
  public state!: ImportDataModuleState;

  @Output()
  public stateChange = new EventEmitter<ImportDataModuleState>();

  public UnitType = UnitSystem;
  public dataTypeRadioItems = EnumHelpers.EnumToRadioItems(ImportDataType);
  private workingIndicatorModalRef: DynamicDialogRef | undefined;

  constructor(
    private modalService: ModalService,
    private appConfig: AppTargetConfig,
  ) {}

  public get isMeasuredDepthEnabled(): boolean {
    return !!this.state.fileName && this.state.importDataType === ImportDataType.Bottomhole_Data;
  }

  public onImportDataTypeChange(event: ObjectChangeProp<ImportDataModuleState>): void {
    const dataType = event.value as ImportDataType;
    this.stateChange.emit({
      ...this.state,
      importDataType: dataType,
      ...(dataType !== ImportDataType.Bottomhole_Data && { measuredDepth: 0 }),
    });
  }

  public onMeasuredDepthChanged(prop: ObjectChangeProp<ImportDataModuleState>): void {
    const newState = changeProp(this.state, prop);
    if (prop.key === 'measuredDepth') {
      newState.colConfigs = newState.colConfigs.map((colConfig) => {
        if (colConfig.columnType === ImportColumnType.Bottomhole_Pressure || colConfig.columnType === ImportColumnType.Bottomhole_Temperature) {
          return { ...colConfig, measuredDepth: newState.measuredDepth };
        }
        return colConfig;
      });
    }
    this.stateChange.emit(newState);
  }

  public dropped(files: NgxFileDropEntry[]): void {
    const file = files[0];
    if (file.fileEntry.isFile) {
      const fileEntry = file.fileEntry as FileSystemFileEntry;
      if (!this.checkIsExcelFile(fileEntry.name)) {
        return;
      }

      this.loadFile(fileEntry);
    }
  }

  private loadFile(fileEntry: FileSystemFileEntry): void {
    this.showLoadingModal();
    const fileReader = new FileReader();

    fileReader.onloadend = (): void => {
      const content = fileReader.result;
      if (content == null || typeof content !== 'string') {
        this.onLoadError();
      } else {
        const allLines = content.split(/\r\n|\n/);
        this.onLoaded(fileEntry, allLines);
      }
    };

    fileEntry.file((f) => fileReader.readAsText(f as any));
  }

  private onLoaded(fileEntry: FileSystemFileEntry, allLines: string[]): void {
    this.hideLoadingModal();
    this.fileLoaded.emit({ fileName: fileEntry.name, data: allLines });
  }

  private onLoadError(): void {
    this.hideLoadingModal();
    this.modalService.showAlert('File could not be read.', 'Warning').then();
  }

  private showLoadingModal(): void {
    this.workingIndicatorModalRef = this.modalService.showWorkingIndicator('Loading...');
  }

  private hideLoadingModal(): void {
    this.workingIndicatorModalRef?.close();
  }

  private checkIsExcelFile(filename: string): boolean {
    const fileExtension = filename.split('.').pop()?.toLowerCase().trim() ?? '';
    if (['xls', 'xlsx'].includes(fileExtension)) {
      //This is an excel file so use the excel reader
      this.modalService
        .showAlert(
          `
       Excel file could not be read - please try the following: <br>
       1. Open the file in Excel <br>
       2. Save as a CSV (comma delimited) file <br>
       3. Import the CSV file into ${this.appConfig.appName}
      `,
          'Warning',
        )
        .then();
      return false;
    }
    return true;
  }
}

export interface IFileLoadedArgs {
  fileName: string;
  data: string[];
}
