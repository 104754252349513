<app-page-panel header="Chart Legend" [allowFullScreen]="false" (click)="onHelpChange('settings', 'chart-legend')">
  <div style="flex-direction: column">
    <app-radio-group
      [items]="[
        { value: false, text: 'Default' },
        { value: true, text: 'Custom' }
      ]"
      [source]="globalOptions"
      [key]="'IsOverrideLegend'"
      [isUiLockable]="false"
      id="IsOverrideLegend"
      dataCy="is-override-legend-radio"
      class="grid-two-one"
      layout="horizontal"
      (valueChanged)="onValueChange($event)"
    >
    </app-radio-group>

    <app-chart-legend-style
      class="block m-top-1"
      [disabled]="!globalOptions.IsOverrideLegend"
      [legendStyle]="globalOptions"
      [defaultLegendStyle]="defaultGlobalOptions"
      (valueChanged)="onStyleValueChange($event)"
    >
    </app-chart-legend-style>
  </div>
</app-page-panel>
