import { WsAction } from '../../ws.action';
import { FileMoveMode, IFile, IRecentDto, Repository } from '../../dto/file.dto';
import { IUserProfile } from '../auth/auth.interfaces';

export const FileManagerModuleName = 'FileManagerModule';

export enum FileManagerModuleActionTypes {
  List = '[FileManagerModule] ListFiles',
  ListOrganizationUsers = '[FileManagerModule] ListOrganizationUsers',
  Delete = '[FileManagerModule] Delete',
  AllFolders = '[FileManagerModule] AllFolders',
  AllFilesAndFolders = '[FileManagerModule] AllFilesAndFolders',
  Move = '[FileManagerModule] Move',
  CopyFileReturnList = '[FileManagerModule] CopyFileReturnList',
  CopyFileReturnFile = '[FileManagerModule] CopyFileReturnFile',
  NewFolder = '[FileManagerModule] NewFolder',
  RecentFiles = '[FileManagerModule] RecentFiles',
  ClearRecentFiles = '[FileManagerModule] ClearRecentFiles',
  RestoreFile = '[FileManagerModule] RestoreFile',
  FileDeletedInfo = '[FileManagerModule] FileDeleteInfo',
  FileMoveInfo = '[FileManagerModule] FileMoveInfo',
  FileOverwrite = '[FileManagerModule] FileOverwrite',
  FileOverwriteInfo = '[FileManagerModule] FileOverwriteInfo',
  EmptyTrash = '[FileManagerModule] EmptyTrash',
  DeleteUser = '[FileManagerModule] DeleteUser',
  RestoreBackup = '[FileManagerModule] RestoreBackup',
  UploadFileChunk = '[FileManagerModule] UploadFileChunk',
  UploadFileCancel = '[FileManagerModule] UploadFileCancel',
}

export class ListFilesAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.List;

  constructor(public source: IFile) {
    super();
  }
}

export class ListOrganizationUsersAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.ListOrganizationUsers;
}

export class DeleteAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.Delete;

  constructor(
    public target: IFile,
    public isAdminPanel = false,
    public frontendUser: IUserProfile | null = null,
  ) {
    super();
  }
}

export class AllFoldersAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.AllFolders;

  constructor(public repository: Repository) {
    super();
  }
}

export class AllFilesAndFoldersAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.AllFilesAndFolders;

  constructor(public frontendUser: IUserProfile | null = null) {
    super();
  }
}

export class MoveAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.Move;

  constructor(
    public source: IFile,
    public target: IFile,
    public mode: FileMoveMode,
    public frontendUser: IUserProfile | null = null,
  ) {
    super();
  }
}

export class CopyReturnListAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.CopyFileReturnList;

  constructor(
    public source: IFile,
    public target: IFile,
  ) {
    super();
  }
}

export class CopyReturnFileAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.CopyFileReturnFile;

  constructor(
    public source: IFile,
    public target: IFile,
  ) {
    super();
  }
}

export class NewFolderAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.NewFolder;

  constructor(public target: IFile) {
    super();
  }
}

export class RecentFilesAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.RecentFiles;
}

export class ClearRecentFilesAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.ClearRecentFiles;
}

export class RestoreFileAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.RestoreFile;

  constructor(
    public source: IFile,
    public target: IFile,
  ) {
    super();
  }
}

export class FileDeletedInfoAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.FileDeletedInfo;

  constructor(
    public file: IFile,
    public sourceSocketId: string,
  ) {
    super();
  }
}

export class FileMoveInfoAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.FileMoveInfo;

  constructor(
    public source: IFile,
    public targetFile: IFile,
    public mode: FileMoveMode,
    public sourceSocketId: string,
  ) {
    super();
  }
}

export class FileOverwriteAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.FileOverwrite;

  constructor(public targetFile: IFile) {
    super();
  }
}

export class DeleteUserAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.DeleteUser;

  constructor(public frontendUser: IUserProfile | null = null) {
    super();
  }
}

export class FileOverwriteInfoAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.FileOverwriteInfo;

  constructor(
    public file: IFile,
    public sourceSocketId: string,
  ) {
    super();
  }
}

export class EmptyTrashAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.EmptyTrash;
}

export class RestoreDbBackupAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.RestoreBackup;

  constructor(public db: IFile) {
    super();
  }
}

export type IUploadFile = Pick<IFile, 'Name' | 'Folder' | 'Repository'>;

export class UploadFileChunkAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.UploadFileChunk;

  constructor(
    public file: IUploadFile,
    public chunkIndex: number,
    public chunksTotal: number,
    public chunkData: ArrayBuffer,
  ) {
    super();
  }
}

export enum UploadFileChunkResponseStatus {
  Ok = 'Ok',
  ErrorFileUploadExists = 'ErrorFileUploadExists',
  ErrorFileUploadDoesNotExist = 'ErrorFileUploadDoesNotExist',
  ErrorFileUploadChunkNotProcessed = 'ErrorFileUploadChunkNotProcessed',
  ErrorFileUploadCancelled = 'ErrorFileUploadCancelled',
  ErrorOther = 'ErrorOther',
}

export class UploadFileCancelAction extends WsAction {
  public override readonly type = FileManagerModuleActionTypes.UploadFileCancel;

  constructor(public file: IUploadFile) {
    super();
  }
}

export interface ListFilesActionResponse {
  folder: IFile;
}

export interface AllFoldersActionResponse {
  rootFolder: IFile;
}

export interface AllFilesAndFoldersActionResponse {
  rootFolders: AllRootFolders;
}

export interface AllRootFolders {
  personalRootFolder: IFile;
  sharedRootFolder: IFile;
  demoRootFolder: IFile;
  trashRootFolder: IFile;
  backupRootFolder: IFile;
}

export interface FileActionResponse {
  file: IFile;
}

export interface RecentFilesActionResponse {
  recent: IRecentDto;
}

export type FileManagerActions =
  | ListFilesAction
  | ListOrganizationUsersAction
  | DeleteAction
  | AllFoldersAction
  | AllFilesAndFoldersAction
  | MoveAction
  | CopyReturnListAction
  | CopyReturnFileAction
  | NewFolderAction
  | RecentFilesAction
  | ClearRecentFilesAction
  | RestoreFileAction
  | FileDeletedInfoAction
  | FileMoveInfoAction
  | FileOverwriteInfoAction
  | DeleteUserAction
  | FileOverwriteAction
  | EmptyTrashAction
  | RestoreDbBackupAction
  | UploadFileChunkAction
  | UploadFileCancelAction;
