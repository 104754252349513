import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RangeModuleState } from '@dunefront/common/modules/range/range-module.state';
import { toSelectItem } from '@dunefront/common/common/select.helpers';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { RangeConstants, RangeDto } from '@dunefront/common/dto/range.dto';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { RangeFactory } from '@dunefront/common/modules/range/model/range.factory';
import { getCurrentScenarioId } from '../scenario/scenario.selectors';
import { isSimulateBased } from '@dunefront/common/modules/scenario/scenario.dto';

export const getRangeModuleState = createFeatureSelector<RangeModuleState>('range');
export const getRanges = createSelector(getRangeModuleState, (state) => state.ranges);
export const getRangesAsArray = createSelector(getRanges, (ranges) => DictionaryWithArray.getArray(ranges));
export const getRangeSelectData = createSelector(getRangesAsArray, (ranges) =>
  ranges
    .sort((range1, range2) =>
      range1.Id === RangeConstants.EntireRangeId ? -1 : range2.Id === RangeConstants.EntireRangeId ? 1 : range1.RangeStart - range2.RangeStart,
    )
    .map((range) => toSelectItem(range.Id, range.Name)),
);

// only use on init
export const getSavedRangeId = createSelector(getRangeModuleState, (state) => state.currentRangeId);

export const getCurrentRangeId = createSelector(getRangeModuleState, getCurrentAppModuleType, (...[rangeState, appModuleType]) => {
  if (isSimulateBased(appModuleType)) {
    return RangeConstants.EmptyRangeId;
  } else {
    const stateCurrentRangeId = rangeState.currentRangeId ?? RangeConstants.EntireRangeId;
    if (stateCurrentRangeId !== RangeConstants.EmptyRangeId) {
      // check if range exists
      if (DictionaryWithArray.get(rangeState.ranges, stateCurrentRangeId) == null) {
        return RangeConstants.EntireRangeId;
      }
    }
    return stateCurrentRangeId;
  }
});

export const getRangeByCurrentRangeId = createSelector(getRangeModuleState, (rangeState) => {
  return rangeState.currentRangeId !== undefined ? DictionaryWithArray.get(rangeState.ranges, rangeState.currentRangeId) : undefined;
});

export const getCurrentRange = createSelector(
  getRangeModuleState,
  getCurrentAppModuleType,
  getRangeByCurrentRangeId,
  (rangeState, appModuleType, currentRange) => {
    if (isSimulateBased(appModuleType)) {
      return undefined;
    }
    return rangeState.editingDto ?? currentRange;
  },
);

export const getCurrentScenarioRangeProperties = createSelector(
  getRangeModuleState,
  getCurrentScenarioId,
  (state, currentScenarioId) =>
    DictionaryWithArray.get(state.scenarioRangeProperties, state.currentRangeId) ??
    RangeFactory.newScenarioRangeProperties(currentScenarioId, state.currentRangeId ?? RangeConstants.EmptyRangeId),
);

export const getCurrentRangeDetails = createSelector(getCurrentRangeId, getCurrentRange, (...[getCurrentRangeId, currentRange]) => ({
  currentRangeIdForData: getCurrentRangeId ?? RangeConstants.EntireRangeId,
  currentRange,
}));

export const getEditingRangeState = createSelector(
  getRangeModuleState,
  getCurrentRange,
  (state, currentRange) =>
    ({
      currentRange,
      isEditing: state.editingDto != null,
    }) as IGaugeDataChartInfo,
);

export const selectIsCurrentRangeInEditingState = createSelector(getEditingRangeState, (state) => state.isEditing);

export const getAllVerticalShifts = createSelector(getRangeModuleState, (state) => state.verticalShifts);

export const getCurrentRangeVerticalShifts = createSelector(getAllVerticalShifts, getCurrentRangeId, (allVerticalShifts, currentRangeId) =>
  DictionaryWithArray.getArray(allVerticalShifts).filter((vs) => vs.RangeId === currentRangeId),
);

export const getCurrentRangeHasAnyVerticalShifts = createSelector(getCurrentRangeVerticalShifts, (shifts) =>
  shifts.some((shift) => shift.ShiftValue !== 0),
);

export interface IGaugeDataChartInfo {
  currentRange: RangeDto;
  isEditing: boolean;
}

export const trendAnalysisEnabled = createSelector(getRangeModuleState, (state) => (state?.currentRangeId ?? RangeConstants.EntireRangeId) > 0);
