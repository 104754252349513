<div class="app-page">
  <app-left-nav [menuData]="calculationMenu$ | async"></app-left-nav>
  <div class="app-page-content">
    <div class="breadcrumb-nav-panel">
      <app-breadcrumb></app-breadcrumb>
    </div>
    <app-page-content>
    </app-page-content>
  </div>
</div>
