<h3>{{ header }}</h3>
<div>
  <textarea readonly="true">{{ parsedResult.header }}</textarea>
</div>
<app-page-panel header="Preview Import Data"  class="no-padding">
  <ng-container tooltip>
    <app-check-box
      class="white-checkbox"
      [value]="isShowingHiddenColumns"
      (primitiveValueChanged)="onShowHiddenColumnsChanged($event.value)"
      label="Show excluded columns"
    ></app-check-box>
  </ng-container>
  <app-import-data-grid
    #gridComponent
    *ngIf="!dataError"
    [state]="state"
    [parsedResult]="parsedResult"
    [isInDefineDataMode]="isInDefineDataMode"
    [isShowingHiddenColumns]="isShowingHiddenColumns"
  >
  </app-import-data-grid>
  <div class="error-panel" *ngIf="dataError">{{ dataError }}</div>
</app-page-panel>
<div *ngIf="!isInDefineDataMode" class="grid-2-col bottom-panel">
  <app-page-panel header="Column Delimiters">
    <app-column-delimiter
      [delimiterConfig]="state.delimiterConfig"
      (delimiterConfigChange)="onDelimiterConfigChanged($event)"
    ></app-column-delimiter>
  </app-page-panel>
  <app-page-panel header="File Properties">
    <app-file-properties
      [filePropertiesConfig]="state.filePropertiesConfig"
      [columns]="parsedResult.columnsWithUnits"
      (filePropertiesConfigChange)="onFilePropertiesConfigChange($event)"
    ></app-file-properties>
  </app-page-panel>
</div>
<div *ngIf="isInDefineDataMode" class="bottom-panel">
  <app-page-panel header="Column Properties">
    <app-column-properties
      [state]="state"
      [currentUnitSystem]="currentUnitSystem"
      (stateChange)="onStateChanged($event.state, $event.customTimeFormatColumnId)"
      (dateFormatsHelpClicked)="dateFormatsHelpClicked.emit()"
    >
    </app-column-properties>
  </app-page-panel>
</div>
