<div class="app-page">
  <app-left-nav [menuData]="trendAnalysisMenu$ | async" [selectedUri]="selectedUri" (activeMenuItemChanged)="selectedUri = $event">
  </app-left-nav>
  <div class="app-page-content">
    <div class="breadcrumb-nav-panel">
      <app-breadcrumb class="left-grow"></app-breadcrumb>
      <app-prev-next-nav
        class="right-fix"
        [class.hidden]="!showPrevNextNav"
        [menuData]="trendAnalysisMenu$ | async"
        [moduleType]="moduleType"
        (showButtons)="showPrevNextNav = $event"
        (activeMenuItemChanged)="selectedUri = $event"
      ></app-prev-next-nav>
    </div>
    <app-page-content>
    </app-page-content>
  </div>
</div>
