import { ArrayHelpers } from '../../../common/array-helpers';
import { GeneralCalculations } from '../../../common/general.calculations';
import { IChartDataDto } from './chart-data.dto';
import { ITimeVolDataPoint } from '../reporting-module.actions';

const conversionChartDataSetIndex = 0;

export class PrimarySecondaryArgumentConverter {
  // index of IChartDataSetDto which is used for conversion

  public static shouldConvert(chartData: IChartDataDto): boolean {
    // should convert only if not a PrimaryArgument
    if (chartData.IsPrimaryArgument) {
      return false;
    }

    // checks if conversion is possible
    return this.isToSecondaryArgConversionPossible(chartData);
  }

  public static isToSecondaryArgConversionPossible(chartDataDto: IChartDataDto): boolean {
    const rows = chartDataDto.ChartDataSets[conversionChartDataSetIndex]?.ChartDataRows;

    return rows != null && rows.length >= 2 && rows[0].SecondaryArgument != null;
  }

  public static toSecondaryArgIfNeeded(chartDataDto: IChartDataDto, siArg: number): number {
    return PrimarySecondaryArgumentConverter.convertIfNeededFromChartData(chartDataDto, siArg, 'primary-to-secondary');
  }

  public static toPrimaryArgIfNeeded(chartDataDto: IChartDataDto, siArg: number): number {
    return PrimarySecondaryArgumentConverter.convertIfNeededFromChartData(chartDataDto, siArg, 'secondary-to-primary');
  }

  public static convertIfNeededFromTimeVolData(
    rows: ITimeVolDataPoint[],
    siArg: number,
    mode: 'primary-to-secondary' | 'secondary-to-primary',
  ): number {
    return PrimarySecondaryArgumentConverter.convertIfNeeded(rows, 'PumpTime', 'PumpVolume', siArg, mode);
  }

  public static convertIfNeededFromChartData(
    chartDataDto: IChartDataDto,
    siArg: number,
    mode: 'primary-to-secondary' | 'secondary-to-primary',
  ): number {
    if (chartDataDto.IsPrimaryArgument || chartDataDto.ChartDataSets.length === 0) {
      return siArg;
    }

    const rows = chartDataDto.ChartDataSets[conversionChartDataSetIndex].ChartDataRows;
    if (rows.length < 2) {
      throw new Error('Wrong Data');
    }
    return PrimarySecondaryArgumentConverter.convertIfNeeded(
      chartDataDto.ChartDataSets[conversionChartDataSetIndex].ChartDataRows,
      'Argument',
      'SecondaryArgument',
      siArg,
      mode,
    );
  }

  public static convertIfNeeded<T>(
    rows: T[],
    argKey: keyof T,
    secArgKey: keyof T,
    siArg: number,
    mode: 'primary-to-secondary' | 'secondary-to-primary',
  ): number {
    const primaryToSecondary = mode === 'primary-to-secondary';

    // check if source arg is outside data arguments
    const firstRow = rows[0];
    const lastRow = rows[rows.length - 1];

    const firstArg = firstRow[argKey] as unknown as number;
    const lastArg = lastRow[argKey] as unknown as number;

    const firstSecArg = firstRow[secArgKey] as unknown as number;
    const lastSecArg = lastRow[secArgKey] as unknown as number;

    const firstSrcArg = primaryToSecondary ? firstArg : firstSecArg;
    const lastSrcArg = primaryToSecondary ? lastArg : lastSecArg;

    if (firstSecArg != null && lastSecArg != null && firstSrcArg != null && lastSrcArg != null && (siArg < firstSrcArg || siArg > lastSrcArg)) {
      return primaryToSecondary
        ? this.primaryToSecondaryExtrapolated(siArg, firstArg, firstSecArg, lastArg, lastSecArg)
        : this.secondaryToPrimaryExtrapolated(siArg, firstArg, firstSecArg, lastArg, lastSecArg);
    }

    const srcKey: keyof T = mode === 'primary-to-secondary' ? argKey : secArgKey;
    const closestRow = ArrayHelpers.findClosest<T>(rows, srcKey, siArg);
    const closestRowIndex = rows.indexOf(closestRow);
    const closestRowArg = closestRow[srcKey] as unknown as number | undefined;
    let rowMin: T, rowMax: T;
    try {
      // no linear interpolation needed
      if (closestRowArg === siArg) {
        return closestRow[mode === 'primary-to-secondary' ? secArgKey : argKey] as unknown as number;
      }

      if ((closestRowArg ?? Number.MAX_VALUE) < siArg) {
        rowMin = closestRow;
        rowMax = rows[closestRowIndex + 1];
      } else {
        rowMax = closestRow;
        rowMin = rows[closestRowIndex - 1];
      }
      if (rowMin[secArgKey] == null || rowMax[secArgKey] == null) {
        throw new Error('Missing Secondary argument');
      }

      return mode === 'primary-to-secondary'
        ? GeneralCalculations.LinearInterpolation(
            siArg,
            rowMin[argKey] as unknown as number,
            rowMax[argKey] as unknown as number,
            rowMin[secArgKey] as unknown as number,
            rowMax[secArgKey] as unknown as number,
          )
        : GeneralCalculations.LinearInterpolation(
            siArg,
            rowMin[secArgKey] as unknown as number,
            rowMax[secArgKey] as unknown as number,
            rowMin[argKey] as unknown as number,
            rowMax[argKey] as unknown as number,
          );
    } catch (err) {
      console.error(err);
      return mode === 'primary-to-secondary' ? ((closestRow[secArgKey] as unknown as number) ?? 0) : (closestRow[argKey] as unknown as number);
    }
  }

  public static isToPrimaryArgConversionPossible(secondaryArg: number, chartDataDto: IChartDataDto): boolean {
    const rows = chartDataDto.ChartDataSets[conversionChartDataSetIndex].ChartDataRows;
    if (!this.shouldConvert(chartDataDto) || rows === null || rows.length < 2) {
      return false;
    }

    const firstSecondaryArg = rows[0].SecondaryArgument;
    const lastSecondaryArg = rows[rows.length - 1].SecondaryArgument;
    if (firstSecondaryArg == null || lastSecondaryArg == null) {
      return false;
    }

    return secondaryArg >= firstSecondaryArg && secondaryArg <= lastSecondaryArg;
  }

  public static primaryToSecondaryExtrapolated(
    primaryArg: number,
    firstArg: number,
    firstSecArg: number,
    lastArg: number,
    lastSecArg: number,
  ): number {
    return this.calcFunctionValue(primaryArg, firstArg, lastArg, firstSecArg, lastSecArg);
  }

  public static secondaryToPrimaryExtrapolated(
    secondaryArg: number,
    firstArg: number,
    firstSecArg: number,
    lastArg: number,
    lastSecArg: number,
  ): number {
    return this.calcFunctionValue(secondaryArg, firstSecArg, lastSecArg, firstArg, lastArg);
  }

  private static calcFunctionValue(x: number, x1: number, x2: number, y1: number, y2: number): number {
    const a = this.calcSlope(x1, x2, y1, y2);
    const b = this.calcInitialValue(x1, y1, a);

    return a * x + b;
  }

  /**
   * Calculates slope (a) of linear function: y = ax + b
   * @param x1
   * @param x2
   * @param y1
   * @param y2
   * @private
   */
  private static calcSlope(x1: number, x2: number, y1: number, y2: number): number {
    return (y1 - y2) / (x1 - x2);
  }

  /**
   * Calculates initial value (b) of linear function: y = ax + b
   * @param x1
   * @param y1
   * @param slope
   * @private
   */
  private static calcInitialValue(x1: number, y1: number, slope: number): number {
    return y1 - x1 * slope;
  }
}
