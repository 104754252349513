import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DraggableMenuItem, ILeftNavElement, ILeftNavItem } from '../left-nav.helpers';
import { AppTargetConfig } from '../../../services/app-target-config';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-left-nav-item',
  templateUrl: 'left-nav-item.component.html',
  styleUrls: ['./left-nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftNavItemComponent implements OnChanges {
  private readonly SUB_ITEM_HEIGHT = 35;

  @Input() public isCalculationActive = false;
  @Input() public isDisabledWhenCalculationActive = false;

  @Input()
  public element: ILeftNavElement | undefined;

  @Input()
  public initOpenElement: ILeftNavItem | null = null;

  @Input()
  public isSideNavExpanded = false;

  @Input()
  public isSideNavPinned = false;

  @Input()
  public isResultItem = false;

  @Input()
  public selectedUri: string | null = null;

  @Output()
  public navClick = new EventEmitter();

  @Output()
  public reordered = new EventEmitter<IReorderEventArgs>();

  @Input()
  public isRouterLinkDisabled = false;

  public isDataPro = false;

  public isSubMenuExpanded = false;

  public childrenItems: DraggableMenuItem[] = [];

  @Output()
  private activeMenuItemChanged = new EventEmitter();

  constructor(
    private router: Router,
    public appConfig: AppTargetConfig,
    public cdRef: ChangeDetectorRef,
  ) {
    this.isDataPro = this.appConfig.isAppCode('data-pro');
  }

  public get isDisabled(): boolean {
    return this.isDisabledWhenCalculationActive && this.isCalculationActive;
  }

  public menuItemTrackBy(index: number, menuItem: MenuItem): string | undefined {
    return menuItem.label;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isSideNavExpanded != null && !changes.isSideNavExpanded.currentValue) {
      this.isSubMenuExpanded = false;
    }

    if (changes.element != null) {
      this.childrenItems = this.element?.children[0].items ?? [];
    }

    if (changes.selectedUri != null) {
      this.initOpenElement = null;
      this.isSubMenuExpanded = this.hasSubMenuItems && this.element?.uri === this.selectedUri;
    }

    if (!this.isSideNavPinned && this.initOpenElement) {
      this.isSubMenuExpanded = this.hasSubMenuItems && this.initOpenElement.uri === this.element?.uri;
      this.initOpenElement = null;
    }
  }

  public getRouterLink(): string | null {
    if (this.element == null || this.isRouterLinkDisabled) {
      return null;
    }
    return this.element.isButtonMode ? this.element.uri : null;
  }

  public isSelected(): boolean {
    return this.router.url.split('/')[6] === this.element?.uri;
  }

  public onMenuItemClicked(): void {
    if (this.isSideNavExpanded && this.hasSubMenuItems) {
      this.isSubMenuExpanded = !this.isSubMenuExpanded;
    }

    if (this.element?.isButtonMode) {
      this.navClick.emit();
    }
  }

  public get subMenuItemsHeight(): string {
    return (this.element?.children[0]?.items?.length ?? 0) * this.SUB_ITEM_HEIGHT + 16 + 'px';
  }

  public onDrop(draggableType: string | undefined, event: CdkDragDrop<DraggableMenuItem[]>): void {
    if (draggableType == null) {
      return;
    }
    if (event.currentIndex === 0) {
      event.currentIndex = 1;
    }
    moveItemInArray(this.childrenItems, event.previousIndex, event.currentIndex);
    this.reordered.emit({ draggableType, prevIndex: event.previousIndex, currentIndex: event.currentIndex });
  }

  public onSubMenuItemClicked(): void {
    if (this.element == null) {
      return;
    }
    this.activeMenuItemChanged.emit(this.element.uri);
  }

  public get hasSubMenuItems(): boolean {
    const items = this.element?.children[0]?.items;
    return items !== undefined && items.length > 0;
  }
}

export interface IReorderEventArgs {
  draggableType: string;
  prevIndex: number;
  currentIndex: number;
}
