import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as wellActions from '../../../../../+store/well/well.actions';
import { createTableRow, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { GridConfig } from '../../../../../shared/components/grid/grid-config';
import { WellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { Caliper } from '@dunefront/common/modules/well/model/caliper/caliper';
import { CaliperValidation } from '@dunefront/common/modules/well/model/caliper/caliper.validation';
import { CaliperFactory } from '@dunefront/common/modules/well/model/caliper/caliper.factory';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { caliperDataGridConfig } from './caliper-data-grid.config';
import { getValidatedWellModuleState } from '../../../../../+store/well/validated-well.selectors';
import { InsertLocation } from '@dunefront/common/modules/common.interfaces';

@Component({
  selector: 'app-caliper-data-grid',
  templateUrl: './caliper-data-grid.component.html',
  styleUrls: ['./caliper-data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaliperDataGridComponent extends GridContainerComponent<Caliper> {
  @Input() public caliperData: ITableState<Caliper> | null = { rows: [], isValid: true };

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    el: ElementRef,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new CaliperGridConfig(store, modalService), gridResizeService);
    super.onHelpChange('well', 'caliper-data');
    this.margin = 0;
  }

  protected getColumns(): IGridColumnConfig<Caliper>[] {
    return caliperDataGridConfig;
  }
}

export class CaliperGridConfig extends GridConfig<Caliper> {
  public well!: WellModuleState;

  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
    this.headerText = 'Caliper Data';
    this.subscription.add(notEmpty(store.select(getValidatedWellModuleState)).subscribe((wellData) => (this.well = wellData)));
  }

  public override isCellDisabled(rows: ITableRow<Caliper>[], rowIndex: number, cellIndex: number): boolean {
    const column = this.columns[cellIndex];
    let lastInsertRowIndex = rows.length - 1;

    if (rows[lastInsertRowIndex] != null && rows[lastInsertRowIndex].rowType !== 'insert-row') {
      // when last insert rows index is not 'insert-row' it means that it's from import functionality
      lastInsertRowIndex = -1;
    }

    const lastDataRowIndex = lastInsertRowIndex !== -1 ? lastInsertRowIndex - 1 : rows.length - 1;
    return column.colId === 'Diameter' && rowIndex === lastDataRowIndex;
  }

  public override updateRowsAction(props: IUpdateTableRowsProps<Caliper>): void {
    props.rows.forEach((row) => (row.rowData.Diameter = row.rowData.Diameter / this.well.CaliperMultiplier));
    this.store.dispatch(wellActions.updateCaliperRow(props));
  }

  public override insertRowAction(props: IInsertRowsProps<Caliper>): void {
    props.rows.forEach((row) => (row.rowData.Diameter = row.rowData.Diameter / this.well.CaliperMultiplier));
    this.store.dispatch(wellActions.insertCaliperRow(props));
  }

  public override async canRowsBeDeleted(props: IDeleteRowsProps): Promise<boolean> {
    const errorMessage = CaliperValidation.validateCaliperGridLength(this.well, props.rowIds);
    if (errorMessage.length) {
      this.modalService.showAlert(errorMessage).then();
      return false;
    }
    return true;
  }

  public override deleteRowsAction(props: IDeleteRowsProps): void {
    this.store.dispatch(wellActions.deleteCaliperRows(props));
  }

  public override createEmptyModel(scenarioId: number): Caliper {
    return CaliperFactory.createEmpty(scenarioId);
  }

  public override createDefaultTableRows(scenarioId: number, noOfRows: number): ITableRow<Caliper>[] {
    const newTableRows = [];
    for (let index = 0; index < Math.max(2, noOfRows); index++) {
      newTableRows.push(createTableRow(this.createEmptyModel(scenarioId), 'data', index, false));
    }
    return newTableRows;
  }

  public override replaceGridAction(rows: ITableRow<Caliper>[]): void {
    if (rows.length > 0) {
      const props: IInsertRowsProps<any> = {
        rows,
        refId: 0,
        insertLocation: 'replace' as InsertLocation,
        shouldResetResults: true,
      };
      this.store.dispatch(wellActions.insertCaliperRow(props));
    }
  }
}
