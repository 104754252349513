<div class="dialog-header">
  <h4>Detach License</h4>
</div>
<div class="dialog-content">
  <div class="m-bottom-2" *ngIf="data">
    <p>Detaching product: {{data.product.name}}.</p>
    <app-form-input [label]="daysInputLabel" [source]="{ selectedDetachDays }" [overrideErrorMessage]="validationError" [width]="60"
                    [key]="'selectedDetachDays'" (primitiveValueChanged)="daysValueChanged($event)" [decimalPlaces]="0"
    [dataCy]="'licensing-detach-days'"></app-form-input>
  </div>
</div>
<div class="dialog-footer">
  <app-button
    buttonType="secondary"
    class="cancel"
    [dialogRef]="ref"
    [isUiLockable]="false"
    (buttonClick)="ref.close()"
    [isDisabledWhenCalculationActive]="false"
    text="Cancel"
  >
  </app-button>
  <app-button

    [dialogRef]="ref"
    [isUiLockable]="false"
    (buttonClick)="btnDetachClick()"
    [isDisabledWhenCalculationActive]="false"
    text="Detach"
  >

  </app-button>
</div>
