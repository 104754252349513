<div class="grid-form-container main" *ngIf="pipe">
  <div class="grid-form-container no-padding">
    <label>Measurement Type</label>
    <div class="input">
      <app-select
        [source]="pipe.rowData"
        [key]="'GaugeMeasurementType'"
        [items]="gaugeMeasurementTypeItems"
        (valueChanged)="onPropertyChange($event)"
        [overrideErrorMessage]="pipe.error.GaugeMeasurementType"
      >
      </app-select>
    </div>
  </div>
</div>
