import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap } from 'rxjs/operators';
import { BaseWsEffects } from '../base-ws.effects';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { ModalService } from '../../common-modules/modals/modal.service';
import { Store } from '@ngrx/store';
import {
  HandleAbandonedTempFilesAction,
  HandleAbandonedTempFilesActionResponse,
  TempProjectFileModuleName,
} from '@dunefront/common/modules/temp-project-file/temp-project-file.actions';
import { backendConnectedAction } from '../backend-connection/backend-connection.actions';
import { handleAbandonedTempFiles } from './temp-project-file.actions';
import {
  AbandonedTempFile,
  AbandonedTempFileHandling,
  AbandonedTempFileHandlingOption,
  AbandonedTempFileRestoreIssueType,
} from '@dunefront/common/modules/temp-project-file/temp-project-file.types';
import { ConfirmModalContentComponentButton } from '../../common-modules/modals/alert/confirm-modal-content.component';
import { isElectron } from '@dunefront/common/common/electron/is-electron';

@Injectable()
export class TempProjectFileEffects extends BaseWsEffects {
  constructor(actions$: Actions, wsService: BackendConnectionService, modalService: ModalService, store: Store) {
    super(actions$, wsService, TempProjectFileModuleName, false, false, modalService, store);
  }

  /**
   * Electron only!
   * Once backend connected, dispatch handleAbandonedTempFiles (cleanup abandoned un-restored temp files)
   */
  private backendConnected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backendConnectedAction),
      filter(() => isElectron()),
      map(() => handleAbandonedTempFiles({})),
    ),
  );

  public showFileOpenDialogSuccess$ = createEffect(() =>
    // eslint-disable-next-line @ngrx/avoid-cyclic-effects
    this.actions$.pipe(
      ofType(handleAbandonedTempFiles),
      mergeMap((action) =>
        this.emit<HandleAbandonedTempFilesActionResponse>(new HandleAbandonedTempFilesAction(action.handling)).pipe(
          map((result) => result.payload),
        ),
      ),
      filter((abandonedFiles) => abandonedFiles.length > 0),
      mergeMap((abandonedFiles) => this.handleAbandonedTempFile(abandonedFiles[0])),
      map((handling) => handleAbandonedTempFiles({ handling })),
    ),
  );

  private async handleAbandonedTempFile(file: AbandonedTempFile): Promise<AbandonedTempFileHandling> {
    const buttons: ConfirmModalContentComponentButton<AbandonedTempFileHandlingOption>[] = [
      {
        text: AbandonedTempFileHandlingOption.Discard,
        type: 'cancel',
        result: AbandonedTempFileHandlingOption.Discard,
      },
      {
        text: AbandonedTempFileHandlingOption.SaveAs,
        type: 'primary',
        result: AbandonedTempFileHandlingOption.SaveAs,
      },
    ];

    const handlingOption = await this.modalService.showCustomConfirm<AbandonedTempFileHandlingOption>(
      this.getMessageForFile(file),
      'Temporary File',
      buttons,
      false,
      'md',
    );

    if (handlingOption === AbandonedTempFileHandlingOption.Discard) {
      const confirmResult = await this.modalService.showConfirm(
        `All changes in the temporary file will be lost and can't be restored.`,
        'Warning',
      );

      // user confirmed to discard changes
      if (confirmResult) {
        return { file, handlingOption };
      }

      return await this.handleAbandonedTempFile(file);
    }

    return { file, handlingOption };
  }

  private getMessageForFile(file: AbandonedTempFile): string {
    let msg: string;
    switch (file.restoreIssueType) {
      case AbandonedTempFileRestoreIssueType.OrgFileDoesNotExist:
        msg = `The original file has been moved or deleted:<br/>${file.sourceProjectFilePath}`;
        break;

      case AbandonedTempFileRestoreIssueType.OrgFileExistsUnableToOverwrite:
        msg = `The original file is locked and can’t be updated:<br/>${file.sourceProjectFilePath}`;
        break;

      case AbandonedTempFileRestoreIssueType.OrgFileExistsHasBeenModified:
        msg = `The original file has been modified and can’t be updated:<br/>${file.sourceProjectFilePath}`;
        break;
    }

    return `The application was not shut down properly and there is a temporary file with unsaved changes.<br/><br/>
            ${msg} <br/><br/>
            You can save the temporary file to review any changes, or discard it (all changes will be lost).`;
  }
}
