import { MenuItem } from 'primeng/api';
import { createSelector } from '@ngrx/store';
import { getJobForCurrentContext } from '../../../+store/calculation-engine/calculation-engine.selectors';
import { CalculationStatus, ICalculationJob } from '../../../+store/calculation-engine/calculation-engine-module.state';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { getUiState } from '../../../+store/ui/ui.selectors';
import { isCalculator, isPsdAnalysis } from '../../../+store/menu-selectors/menu-selectors.helpers';
import { getCurrentRangeId } from '../../../+store/range/range.selectors';
import { UiState } from '../../../+store/ui/ui-module.state';
import { getBackendActiveRequestsCount } from '../../../+store/backend-connection/backend-connection.selectors';
import { getCurrentCalculationStatusProgress } from '../../../+store/reporting/reporting.selectors';

export interface DraggableMenuItem extends MenuItem {
  isDraggable?: boolean;
  items?: DraggableMenuItem[];
  isError?: boolean;
  isWarning?: boolean;
  type?: any;
}

export class LeftNavHelpers {
  public static getNavElement(
    label: string,
    uri: string,
    icon: string,
    isValid = true,
    isWarning = false,
    items: MenuItem[] = [],
    isDraggable = false,
    draggableType = '',
  ): ILeftNavElement {
    return this.toNavElement({
      label,
      uri,
      icon,
      isError: !isValid,
      isWarning,
      items,
      isDraggable,
      draggableType,
    });
  }

  public static toNavElement({
    label,
    uri,
    icon,
    isError,
    isWarning,
    items,
    isDraggable,
    draggableType,
  }: {
    label: string;
    uri: string;
    icon: string;
    isError?: boolean;
    isWarning?: boolean;
    items?: MenuItem[];
    isDraggable?: boolean;
    draggableType?: string;
  }): ILeftNavElement {
    return {
      label,
      uri,
      icon,
      isValid: isError != null ? !isError : true,
      isWarning: isWarning != null ? isWarning : false,
      children: [{ label, items: items ?? [] }],
      isButtonMode: (items ?? []).length === 0,
      type: 'NavElement',
      isDraggable: isDraggable ?? false,
      draggableType: draggableType ?? '',
    };
  }

  public static getStartStopButton(): ILeftNavStartStopButton {
    return { type: 'StartStopButton', uri: 'StartStopButton', icon: '', enabled: true, state: 'start', progress: 0 };
  }

  public static getItem(
    label: string,
    routerLink: string,
    isValid = true,
    isDisabled = false,
    isDraggable = false,
    isWarning = false,
  ): DraggableMenuItem {
    return {
      label,
      routerLink,
      disabled: isDisabled,
      isDraggable,
      isError: !isValid,
      isWarning,
    };
  }
}

export const isStartStopButtonEnabled = (activeJob: ICalculationJob | undefined, uiState: UiState, areActiveRequests: boolean): boolean => {
  if (areActiveRequests) {
    return false;
  }

  // psdAnalysis don't use workers, so it should be enabled all the time
  if (isPsdAnalysis(uiState.appModuleType)) {
    return true;
  }

  // Calculators module - start is enabled only on calculation tabs
  if (isCalculator(uiState.appModuleType)) {
    return uiState.calculatorsTab != null;
  }

  // button should be enabled when there is no job, or job is in active status. Disabled when starting, stopping
  return activeJob == null || activeJob?.status === CalculationStatus.running || activeJob.status === CalculationStatus.completed;
};

export const getStartStopButton = createSelector(
  getJobForCurrentContext,
  getUiState,
  getBackendActiveRequestsCount,
  getCurrentCalculationStatusProgress,
  (activeJob, uiState, activeRequests, progress): ILeftNavStartStopButton => {
    return {
      enabled: isStartStopButtonEnabled(activeJob, uiState, activeRequests > 0),
      icon: activeJob?.status === CalculationStatus.running ? '&#9632;' : '&#9658;',
      type: 'StartStopButton',
      uri: 'StartStopButton',
      state: activeJob?.status === CalculationStatus.running ? 'stop' : 'start',
      progress: progress ?? 0,
    };
  },
);

export const getLeftNavProps = createSelector(
  getJobForCurrentContext,
  getCurrentRangeId,
  (activeJob, currentRangeId): ILeftNavProps => ({
    activeJob,
    currentRangeId: currentRangeId ?? RangeConstants.EmptyRangeId,
  }),
);

export interface ILeftNavElement {
  label: string;
  uri: string;
  icon: string;
  isValid: boolean;
  isWarning: boolean;
  isButtonMode: boolean;
  children: DraggableMenuItem[];
  type: 'NavElement';
  isDraggable: boolean;
  draggableType: string;
}

export interface ILeftNavStartStopButton {
  type: 'StartStopButton';
  uri: 'StartStopButton';
  icon: string;
  enabled: boolean;
  state: 'start' | 'stop';
  label?: string;
  progress: number;
}

export interface ILeftNavBreak extends MenuItem {
  type: 'Break';
  uri: 'Break';
  label?: string;
}

export interface ILeftNavProps {
  activeJob?: ICalculationJob;
  currentRangeId: number;
}

export type ILeftNavItem = ILeftNavElement | ILeftNavStartStopButton | ILeftNavBreak;

export interface ILeftNavItems {
  regular: ILeftNavItem[];
  results: ILeftNavItem[];
}
