import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocationType, TemperatureProfileType } from '@dunefront/common/modules/well/dto/well.dto';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { TemperatureProfile } from '@dunefront/common/modules/well/model/temperature-profile/temperature-profile';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-temperature-profile',
  templateUrl: './temperature-profile.component.html',
  styleUrls: ['./temperature-profile.component.scss'],
})
export class TemperatureProfileComponent {
  @Input() public temperatureProfile: TemperatureProfile | undefined;
  @Input() public locationType: LocationType | undefined;
  @Output() public temperatureProfileChanged = new EventEmitter<ObjectChangeProp<TemperatureProfile>>();

  public temperatureProfileTypeRadioItems = EnumHelpers.EnumToRadioItems(TemperatureProfileType);
  public TemperatureProfileType = TemperatureProfileType;
  public UnitType = UnitSystem;

  constructor(
    protected store: Store,
    protected cdRef: ChangeDetectorRef,
  ) {}

  public isLand(): boolean {
    return this.locationType === LocationType.Land;
  }

  public get bhctLabel(): string {
    return this.temperatureProfile?.TemperatureProfileType === TemperatureProfileType.Fixed ? 'BHCT' : 'Initial BHCT';
  }

  public onValueChange(props: ObjectChangeProp<TemperatureProfile>): void {
    const triggerChanges = this.temperatureProfile && this.temperatureProfile[props.key] !== props.value;
    if (triggerChanges) {
      this.temperatureProfileChanged.emit(props);
    }
  }
}
