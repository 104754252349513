import { Component, Input } from '@angular/core';
import { SummaryDto } from '@dunefront/common/dto/summary.dto';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { Store } from '@ngrx/store';
import { getIsOpenHole, getWellTreatmentType } from '../../../../../+store/well/well.selectors';
import { TreatmentType } from '@dunefront/common/modules/well/dto/well.dto';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { isSimulateDisp } from '../../../../../+store/menu-selectors/menu-selectors.helpers';

@Component({
  selector: 'app-summary-panels',
  templateUrl: './summary-panels.component.html',
  styleUrls: ['./summary-panels.component.scss'],
})
export class SummaryPanelsComponent {
  @Input() public summary!: SummaryDto;
  @Input() public currentAppModuleType!: ModuleType;

  public UnitType = UnitSystem;
  public wellTreatmentType$ = this.store.select(getWellTreatmentType);
  public isOpenHole$ = this.store.select(getIsOpenHole);
  public treatmentType = TreatmentType;

  constructor(private store: Store) {}

  public get isFluidPro(): boolean {
    return isSimulateDisp(this.currentAppModuleType);
  }
}
