import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { InputsHelperService } from '../../services/inputs-helper.service';

import { ButtonBaseComponent } from './button.base.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appButton]',
})
export class ButtonDirective extends ButtonBaseComponent {
  @HostListener('document:keydown', ['$event'])
  public async onKeyDown(event: KeyboardEvent): Promise<void> {
    if (this.dialogRef) {
      await super.onKeyPressed(event);
    }
  }

  constructor(
    public override el: ElementRef,
    renderer: Renderer2,
    store: Store,
    inputsHelperService: InputsHelperService,
  ) {
    super(store, inputsHelperService, renderer, el);
  }
}
