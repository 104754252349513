import { IPumpingValidationDependencies, ValidatedPumpingModuleState } from '../../pumping-module.state';
import { Pumping } from '../pumping/pumping';
import { IDictionaryWithArray } from '../../../../common/state.helpers';
import { RateConverter } from '../../../../unit-converters/converters/rate/rate.converter';
import { RateUnit, UnitSystem } from '../../../../dto/unit-system.dto';
import { TreatmentType } from '../../../well/dto/well.dto';
import { ConvertUnitPipe, IEncodedValueAndSymbol } from '../../../units/convert-unit.pipe/convert-unit.pipe';
import { IError } from '../../../../common/common-state.interfaces';
import { MAX_GRAVEL_CONCENTRATION_SI, MaxRate_BPM } from '../../../../common/constants';
import { ValidateGravel } from '../../../gravel/model/gravel.validation';
import { Gravel } from '../../../gravel/model/gravel';
import { isFluidValid } from '../../../fluid/model/fluid.validation';
import { Fluid } from '../../../fluid/model/fluid';
import { ModuleType } from '../../../scenario/scenario.dto';
import { WellModuleState } from '../../../well/well-module.state';
import { ChokeSetting, ToolPosition, VariableChokeSetting } from '../../dto/pumping.dto';
import { CompletionModuleState } from '../../../completion/completion-module.state';
import { PipeType } from '../../../../dto/pipe.dto';
import { validateFeatureRequired } from '../../../licensing/licensing.validator';
import { IGetCurrentFeaturesResult, LicenseFeature } from '../../../licensing/licensing.interfaces';
import { CompletionCalculations } from '../../../completion/model/completion.calculations';

export class ScheduleOptionsValidation {
  public static validateScheduleOptionsTab(
    pumpingState: ValidatedPumpingModuleState,
    deps: IPumpingValidationDependencies,
  ): ValidatedPumpingModuleState {
    const { pumping } = pumpingState;

    if (deps.appModuleType === ModuleType.Evaluate || deps.appModuleType === ModuleType.Simulate_Disp) {
      return {
        ...pumpingState,
        isAutomaticSchedulingPanelValid: true,
      };
    }

    const maxRateSi = RateConverter.toSi(MaxRate_BPM, RateUnit.Barrel_per_minute);
    const encodedMaxRate = ConvertUnitPipe.encodeValueAndSymbol(UnitSystem.Rate, maxRateSi);
    const encodedMaxConcentration = ConvertUnitPipe.encodeValueAndSymbol(UnitSystem.Solid_Concentration, MAX_GRAVEL_CONCENTRATION_SI);

    const error: IError<Pumping> = {
      AutoScheduleCarrierFluidId: this.ValidateAutoScheduleCarrierFluid(pumping, deps.fluids),
      AutoScheduleFlushFluidId: this.ValidateAutoScheduleFlushFluid(pumping, deps.fluids),
      AutoScheduleGravelId: this.ValidateAutoScheduleGravelName(pumping, deps.gravels),
      AutoSchedulePumpRate: this.ValidateAutoSchedulePumpRate(pumping, deps, encodedMaxRate),
      AutoScheduleExcessPumpRate: this.ValidateAutoScheduleExcessPumpRate(pumping, deps, encodedMaxRate),
      AutoScheduleReturnRate: this.ValidateAutoScheduleReturnRate(pumping, encodedMaxRate),
      AutoScheduleMaxGravelConc: this.ValidateAutoScheduleMaxGravelConc(pumping, encodedMaxConcentration),
      AutoScheduleExcessGravelPercentage: this.ValidateAutoScheduleExcessGravel(pumping),
      AutoPressureAttenuatorActivation: this.ValidateAutoPressureAttenuatorActivation(pumping),
      AutoScheduleTargetPacking: this.ValidateAutoScheduleTargetPacking(pumping, deps),
      AutoFracSafetyMargin: this.ValidateAutoFracSafetyMargin(pumping),
      AutoAlphaSafetyMargin: this.ValidateAutoAlphaSafetyMargin(pumping),
      AutoScheduleRateStep: this.ValidateAutoScheduleRateStep(pumping),
      AutoScheduleMinRate: this.ValidateAutoScheduleMinRate(pumping),
      IsAutoFracSchedule: this.ValidateIsAutoFracSchedule(pumping, deps.well, deps.currentLicenseFeatures),
      IsAutoPumpPressureSchedule: this.ValidateIsAutoPumpPressureSchedule(pumping, deps.currentLicenseFeatures),
      IsAutoAlphaSchedule: this.ValidateIsAutoAlphaSchedule(pumping, deps.well, deps.currentLicenseFeatures),
      IsAutoPressureAttenuatorLocation: this.ValidateIsAutoPressureAttenuatorLocation(
        pumping,
        deps.well,
        deps.completion,
        deps.currentLicenseFeatures,
      ),
      IsAutoManagePressure: this.ValidateIsAutoManagePressure(pumping, deps.currentLicenseFeatures),
      AutoManagePressureMD: this.ValidateAutoManagePressureMD(pumping, deps.completion),
      AutoManagePressureLocation: this.ValidateAutoManagePressureLocation(pumping),
      AutoManagePressureTarget: this.ValidateAutoManagePressureTarget(pumping),
      AutoManagePressureTolerance: this.ValidateAutoManagePressureTolerance(pumping),
      AutoManagePressureEMWTarget: this.ValidateAutoManagePressureEMWTarget(pumping),
      AutoManagePressureEMWTolerance: this.ValidateAutoManagePressureEMWTolerance(pumping),
      AutoPressureAttenuatorSafetyMargin: this.ValidateAutoPressureAttenuatorSafetyMargin(pumping),
    };

    const isAutomaticSchedulingPanelValid = !(
      error.AutoFracSafetyMargin ||
      error.AutoAlphaSafetyMargin ||
      error.AutoScheduleRateStep ||
      error.AutoScheduleMinRate ||
      error.IsAutoManagePressure ||
      error.AutoManagePressureMD ||
      error.AutoManagePressureLocation ||
      error.AutoManagePressureTarget ||
      error.AutoManagePressureTolerance ||
      error.AutoPressureAttenuatorSafetyMargin ||
      error.AutoPressureAttenuatorActivation ||
      error.IsAutoFracSchedule ||
      error.IsAutoPumpPressureSchedule ||
      error.IsAutoAlphaSchedule ||
      error.IsAutoPressureAttenuatorLocation
    );

    const isScheduleGeneratorPanelValid = !(
      error.AutoScheduleCarrierFluidId ||
      error.AutoScheduleFlushFluidId ||
      error.AutoScheduleGravelId ||
      error.AutoScheduleTargetPacking ||
      error.IsAutoScheduleRamped ||
      error.AutoSchedulePumpRate ||
      error.AutoScheduleExcessPumpRate ||
      error.AutoScheduleReturnRate ||
      error.AutoScheduleMaxGravelConc ||
      error.AutoScheduleExcessGravelPercentage
    );

    const newPumping: Pumping = { ...pumpingState.pumping, error: { ...pumpingState.pumping.error, ...error } };
    return { ...pumpingState, pumping: newPumping, isAutomaticSchedulingPanelValid, isScheduleGeneratorPanelValid };
  }

  private static ValidateAutoScheduleCarrierFluid(pumping: Pumping, fluids: IDictionaryWithArray<Fluid>): string {
    if (pumping.AutoScheduleCarrierFluidId === null) {
      return 'Carrier fluid must be selected';
    }

    return isFluidValid(fluids, pumping.AutoScheduleCarrierFluidId);
  }

  private static ValidateAutoScheduleFlushFluid(pumping: Pumping, fluids: IDictionaryWithArray<Fluid>): string {
    if (pumping.AutoScheduleFlushFluidId === null) {
      return 'Flush fluid must be selected';
    }

    return isFluidValid(fluids, pumping.AutoScheduleFlushFluidId);
  }

  private static ValidateAutoScheduleGravelName(pumping: Pumping, gravels: IDictionaryWithArray<Gravel>): string {
    if (pumping.AutoScheduleGravelId === null || pumping.AutoScheduleGravelId === 0) {
      return 'Gravel must be selected';
    }
    return ValidateGravel(gravels, pumping.AutoScheduleGravelId);
  }

  private static ValidateAutoSchedulePumpRate(
    pumping: Pumping,
    deps: IPumpingValidationDependencies,
    encodedMaxRate: IEncodedValueAndSymbol,
  ): string {
    const { well } = deps;

    if (well.TreatmentType === TreatmentType.High_Rate_Water_Pack) {
      if (pumping.AutoSchedulePumpRate <= 0 || pumping.AutoSchedulePumpRate > encodedMaxRate.siValue) {
        return `Max pump rate must be between 0 and ${encodedMaxRate.encodedValue} ${encodedMaxRate.symbol}`;
      } else if (pumping.AutoSchedulePumpRate <= pumping.AutoScheduleReturnRate + pumping.AutoScheduleExcessPumpRate) {
        return 'Max pump rate must be greater than return rate plus excess pump rate';
      }
    } else {
      if (pumping.AutoSchedulePumpRate <= 0 || pumping.AutoSchedulePumpRate > encodedMaxRate.siValue) {
        return `Pump rate must be between 0 and ${encodedMaxRate.encodedValue} ${encodedMaxRate.symbol}`;
      } else if (pumping.AutoSchedulePumpRate < pumping.AutoScheduleReturnRate) {
        return 'Pump rate must be greater than or equal to return rate';
      }
    }

    return '';
  }

  private static ValidateAutoScheduleExcessPumpRate(
    pumping: Pumping,
    deps: IPumpingValidationDependencies,
    encodedMaxRate: IEncodedValueAndSymbol,
  ): string {
    const { well } = deps;

    if (well.TreatmentType === TreatmentType.High_Rate_Water_Pack) {
      if (pumping.AutoScheduleExcessPumpRate <= 0 || pumping.AutoScheduleExcessPumpRate > encodedMaxRate.siValue) {
        return `Excess pump rate must be between 0 and ${encodedMaxRate.encodedValue} ${encodedMaxRate.symbol}`;
      }
    }

    return '';
  }

  private static ValidateAutoScheduleReturnRate(pumping: Pumping, encodedMaxRate: IEncodedValueAndSymbol): string {
    if (pumping.AutoScheduleReturnRate < 0 || pumping.AutoScheduleReturnRate > encodedMaxRate.siValue) {
      return `Return rate must be between 0 and ${encodedMaxRate.encodedValue} ${encodedMaxRate.symbol}`;
    }

    return '';
  }

  private static ValidateAutoScheduleMaxGravelConc(pumping: Pumping, encodedMaxConcentration: IEncodedValueAndSymbol): string {
    if (pumping.AutoScheduleMaxGravelConc <= 0 || pumping.AutoScheduleMaxGravelConc > encodedMaxConcentration.siValue) {
      return `Max gravel concentration must be between 0 and ${encodedMaxConcentration.encodedValue} ${encodedMaxConcentration.symbol}`;
    }

    return '';
  }

  private static ValidateAutoScheduleExcessGravel(pumping: Pumping): string {
    if (pumping.AutoScheduleExcessGravelPercentage <= 0) {
      return 'Excess gravel must be greater than 0';
    }

    return '';
  }

  private static ValidateAutoScheduleTargetPacking(pumping: Pumping, deps: IPumpingValidationDependencies): string {
    const { well } = deps;
    if (well.TreatmentType === TreatmentType.High_Rate_Water_Pack && pumping.AutoScheduleTargetPacking <= 0) {
      return 'Target packing must be greater than 0';
    }

    return '';
  }

  // old name ValidateAutoPressureSafetyMargin
  private static ValidateAutoFracSafetyMargin(pumping: Pumping): string {
    if (this.isTxtAutoFracSafetyMarginEnabled(pumping) && pumping.AutoFracSafetyMargin <= 0) {
      return 'Pressure margin must be greater than 0';
    }
    return '';
  }

  private static ValidateAutoAlphaSafetyMargin(pumping: Pumping): string {
    if (this.isTxtAutoAlphaSafetyMarginEnabled(pumping) && pumping.AutoAlphaSafetyMargin <= 0) {
      return 'Length margin must be greater than 0';
    }

    return '';
  }

  private static ValidateAutoScheduleRateStep(pumping: Pumping): string {
    if (this.isTxtAutoScheduleRateStepEnabled(pumping) && pumping.AutoScheduleRateStep <= 0) {
      return 'Rate step must be greater than 0';
    }

    return '';
  }

  private static ValidateAutoScheduleMinRate(pumping: Pumping): string {
    if (this.isTxtAutoScheduleMinRateEnabled(pumping) && pumping.AutoScheduleMinRate <= 0) {
      return 'Minimum rate must be greater than 0';
    }

    return '';
  }

  private static ValidateIsAutoFracSchedule(pumping: Pumping, well: WellModuleState, currentLicenseFeatures: IGetCurrentFeaturesResult): string {
    if (pumping.IsAutoFracSchedule) {
      const requiredFeatureError = validateFeatureRequired([LicenseFeature.Simulate, LicenseFeature.Evaluate], currentLicenseFeatures);
      if (requiredFeatureError) {
        return requiredFeatureError;
      }
    }

    const isMoreThanOneAutoManageChecked = this.ValidateOnlyOneAutoSchedulingChecked(pumping);

    if (pumping.IsAutoFracSchedule && isMoreThanOneAutoManageChecked.length) {
      return isMoreThanOneAutoManageChecked;
    }

    if (well.TreatmentType === TreatmentType.High_Rate_Water_Pack && pumping.IsAutoFracSchedule) {
      return 'This option is not supported for High Rate Water Pack treatments';
    }

    return '';
  }

  private static ValidateIsAutoPumpPressureSchedule(pumping: Pumping, currentLicenseFeatures: IGetCurrentFeaturesResult): string {
    if (!pumping.IsAutoPumpPressureSchedule) {
      return '';
    } else {
      const requiredFeatureError = validateFeatureRequired([LicenseFeature.Simulate, LicenseFeature.Evaluate], currentLicenseFeatures);
      if (requiredFeatureError) {
        return requiredFeatureError;
      }
    }
    return this.ValidateOnlyOneAutoSchedulingChecked(pumping);
  }

  private static ValidateIsAutoAlphaSchedule(
    pumping: Pumping,
    well: WellModuleState,
    currentLicenseFeatures: IGetCurrentFeaturesResult,
  ): string {
    if (pumping.IsAutoAlphaSchedule) {
      const requiredFeatureError = validateFeatureRequired([LicenseFeature.Simulate, LicenseFeature.Evaluate], currentLicenseFeatures);
      if (requiredFeatureError) {
        return requiredFeatureError;
      }
    }

    const isMoreThanOneAutoManageChecked = this.ValidateOnlyOneAutoSchedulingChecked(pumping);

    if (pumping.IsAutoAlphaSchedule && isMoreThanOneAutoManageChecked.length) {
      return isMoreThanOneAutoManageChecked;
    }

    if (well.TreatmentType === TreatmentType.High_Rate_Water_Pack && pumping.IsAutoAlphaSchedule) {
      return 'This option is not supported for High Rate Water Pack treatments';
    }

    return '';
  }

  private static ValidateIsAutoPressureAttenuatorLocation(
    pumping: Pumping,
    well: WellModuleState,
    completion: CompletionModuleState,
    currentLicenseFeatures: IGetCurrentFeaturesResult,
  ): string {
    if (pumping.IsAutoPressureAttenuatorLocation) {
      const requiredFeatureError = validateFeatureRequired([LicenseFeature.Simulate, LicenseFeature.Evaluate], currentLicenseFeatures);
      if (requiredFeatureError) {
        return requiredFeatureError;
      }
    }

    const isMoreThanOneAutoManageChecked = this.ValidateOnlyOneAutoSchedulingChecked(pumping);

    if (pumping.IsAutoPressureAttenuatorLocation && isMoreThanOneAutoManageChecked.length) {
      return isMoreThanOneAutoManageChecked;
    }

    if (pumping.IsAutoPressureAttenuatorLocation && CompletionCalculations.HasShunts(completion)) {
      return "Pressure attenuators can't be run in shunted completions";
    }

    if (well.TreatmentType === TreatmentType.High_Rate_Water_Pack && pumping.IsAutoPressureAttenuatorLocation) {
      return 'This option is not supported for High Rate Water Pack treatments';
    }

    return '';
  }

  private static ValidateAutoPressureAttenuatorActivation(pumping: Pumping): string {
    if (this.isTxtAutoPressureAttenuatorActivationEnabled(pumping) && pumping.AutoPressureAttenuatorActivation <= 0) {
      return 'Activation pressure must be greater than 0';
    }

    return '';
  }

  private static ValidateIsAutoManagePressure(pumping: Pumping, currentLicenseFeatures: IGetCurrentFeaturesResult): string {
    if (pumping.IsAutoManagePressure) {
      const requiredFeatureError = validateFeatureRequired([LicenseFeature.MPGP], currentLicenseFeatures);
      if (requiredFeatureError) {
        return requiredFeatureError;
      }
    }

    const isMoreThanOneAutoManageChecked = this.ValidateOnlyOneAutoSchedulingChecked(pumping);

    if (pumping.IsAutoManagePressure && isMoreThanOneAutoManageChecked.length) {
      return isMoreThanOneAutoManageChecked;
    }

    if (
      pumping.IsAutoManagePressure &&
      (pumping.ChokePosition === ChokeSetting.None || pumping.VariableChoke !== VariableChokeSetting.Managed_Pressure)
    ) {
      return 'Managed pressure choke is required for this option';
    }
    return '';
  }

  private static ValidateAutoManagePressureMD(pumping: Pumping, completion: CompletionModuleState): string {
    const completionBottomMD = Math.max(...completion.LowerCompletion.rows.map((r) => r.rowData.BottomMD));
    const gravelPackerPipe = completion.LowerCompletion.rows.find((row) => row.rowData.PipeType === PipeType.Gravel_Pack_Packer);

    if (!pumping.IsAutoManagePressure) {
      return '';
    }

    if (
      pumping.ToolPosition === ToolPosition.Reverse &&
      gravelPackerPipe &&
      (pumping.AutoManagePressureMD <= 0 || pumping.AutoManagePressureMD >= gravelPackerPipe.rowData.TopMD)
    ) {
      return 'Anchor point MD must be between 0 and gravel pack packer top MD';
    } else if (
      gravelPackerPipe &&
      pumping.AutoManagePressureMD >= gravelPackerPipe.rowData.TopMD &&
      pumping.AutoManagePressureMD <= gravelPackerPipe.rowData.BottomMD
    ) {
      return "Anchor point MD can't be located inside the packer";
    } else if (pumping.AutoManagePressureMD <= 0 || pumping.AutoManagePressureMD >= completionBottomMD) {
      return 'Anchor point MD must be between 0 and lower completion bottom MD';
    }
    return '';
  }

  private static ValidateAutoManagePressureLocation(pumping: Pumping): string {
    if (!pumping.IsAutoManagePressure) {
      return '';
    }
    if (pumping.AutoManagePressureLocation == null) {
      return 'Reference location must be selected';
    }
    return '';
  }

  private static ValidateAutoManagePressureTarget(pumping: Pumping): string {
    if (!pumping.IsAutoManagePressure || pumping.IsAutoManagePressureEMW) {
      return '';
    }
    if (pumping.AutoManagePressureTarget <= 0) {
      return 'Target pressure must be greater than 0';
    }
    return '';
  }

  private static ValidateAutoManagePressureTolerance(pumping: Pumping): string {
    if (!pumping.IsAutoManagePressure || pumping.IsAutoManagePressureEMW) {
      return '';
    }
    if (pumping.AutoManagePressureTolerance <= 0) {
      return 'Pressure tolerance must be greater than 0';
    }
    return '';
  }

  private static ValidateAutoManagePressureEMWTarget(pumping: Pumping): string {
    if (!pumping.IsAutoManagePressure || !pumping.IsAutoManagePressureEMW) {
      return '';
    }
    if (pumping.AutoManagePressureEMWTarget <= 0) {
      return 'Target EMW must be greater than 0';
    }
    return '';
  }

  private static ValidateAutoManagePressureEMWTolerance(pumping: Pumping): string {
    if (!pumping.IsAutoManagePressure || !pumping.IsAutoManagePressureEMW) {
      return '';
    }
    if (pumping.AutoManagePressureEMWTolerance <= 0) {
      return 'EMW tolerance must be greater than 0';
    }
    return '';
  }

  private static ValidateAutoPressureAttenuatorSafetyMargin(pumping: Pumping): string {
    if (!pumping.IsAutoPressureAttenuatorLocation) {
      return '';
    }
    if (pumping.AutoPressureAttenuatorSafetyMargin <= 0) {
      return 'Pressure margin must be greater than 0';
    }
    return '';
  }

  public static isTxtAutoFracSafetyMarginEnabled(pumping: Pumping): boolean {
    return pumping.IsAutoPumpPressureSchedule || pumping.IsAutoFracSchedule;
  }

  public static isTxtAutoScheduleRateStepEnabled(pumping: Pumping): boolean {
    return pumping.IsAutoPumpPressureSchedule || pumping.IsAutoFracSchedule || pumping.IsAutoAlphaSchedule;
  }

  public static isTxtAutoPressureAttenuatorActivationEnabled(pumping: Pumping): boolean {
    return pumping.IsAutoPressureAttenuatorLocation;
  }

  public static isTxtAutoAlphaSafetyMarginEnabled(pumping: Pumping): boolean {
    return pumping.IsAutoAlphaSchedule;
  }

  public static isTxtAutoScheduleMinRateEnabled(pumping: Pumping): boolean {
    return pumping.IsAutoPumpPressureSchedule || pumping.IsAutoFracSchedule || pumping.IsAutoAlphaSchedule;
  }

  private static ValidateOnlyOneAutoSchedulingChecked(pumping: Pumping): string {
    const isAnyReduceOptionChecked = pumping.IsAutoPumpPressureSchedule || pumping.IsAutoFracSchedule || pumping.IsAutoAlphaSchedule;
    const isAutoLocatePressureChecked = pumping.IsAutoPressureAttenuatorLocation;
    const isAutoManagePressure = pumping.IsAutoManagePressure;

    const autoScheduleOptionsChecked = [isAnyReduceOptionChecked, isAutoLocatePressureChecked, isAutoManagePressure].filter(
      (checked) => checked,
    ).length;

    if (autoScheduleOptionsChecked > 1) {
      return 'Only one type of auto scheduling option can be used at a time';
    }
    return '';
  }
}
