import { Component, OnDestroy } from '@angular/core';
import { getUser } from '../../../+store/auth/auth.selectors';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppTargetConfig } from '../../../shared/services/app-target-config';
import { getConnectedFile } from '../../../+store/backend-connection/backend-connection.selectors';
import { getBackendToClientConfig } from '@dunefront/common/backend-to-client-config';
import { getCurrentScenario } from '../../../+store/scenario/scenario.selectors';
import { getFileSettings } from '../../../+store/file-settings/file-settings.selectors';
import dayjs from 'dayjs';
import { UserPanelModule } from '../../../shared/components';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { FileDisplayNamePipeModule } from '../../../shared/pipes/file/file-display-name.pipe.module';
import { LetDirective } from '@ngrx/component';
import { getIsSideNavPinned } from '../../../+store/ui/ui.selectors';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [UserPanelModule, AsyncPipe, FileDisplayNamePipeModule, NgIf, LetDirective, NgClass],
  standalone: true,
})
export class HeaderComponent implements OnDestroy {
  // home screen
  public user$ = this.store.select(getUser);

  // db dependent screen
  public file$ = this.store.select(getConnectedFile);
  public isTestMode = getBackendToClientConfig().IsRunningInTestMode;
  public scenarioName!: string;
  public lockMessage!: string;
  public isNavPinned = false;

  protected subscription = new Subscription();

  constructor(
    private store: Store,
    public appConfig: AppTargetConfig,
  ) {
    this.subscription.add(
      this.store.select(getCurrentScenario).subscribe((currentScenario) => {
        this.scenarioName = currentScenario ? currentScenario.Name : '';
      }),
    );

    this.subscription.add(
      this.store.select(getIsSideNavPinned).subscribe((isNavPinned) => {
        this.isNavPinned = isNavPinned;
      }),
    );
    this.subscription.add(
      store.select(getFileSettings).subscribe((fileSettings) => {
        this.lockMessage = fileSettings.IsFileLocked
          ? `Locked by ${fileSettings.LockUserName} on ${dayjs(fileSettings.LockDate as number).format('DD-MMM-YYYY')}`
          : '';
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
