import { Injectable } from '@angular/core';
import { BaseWsEffects } from '../base-ws.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { deleteRowsSuccess, insertRowsSuccess, loadScenarioDataSuccessAction, updateRowSuccess } from '../app.actions';
import {
  autoYAxisShiftSaveAction,
  changeCurrentRangeIdAction,
  deleteRangeAction,
  deleteRangeDataAction,
  insertVerticalShiftDtosAction,
  resetYAxisShiftAction,
  saveRangeAction,
  updateScenarioRangePropertiesAction,
  updateVerticalShiftDtosAction,
} from './range.actions';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { getAllScenarios, getCurrentScenarioId } from '../scenario/scenario.selectors';
import { ModalService } from '../../common-modules/modals/modal.service';
import {
  RangeDeleteRangeDataAction,
  RangeDeleteRowsAction,
  RangeInsertRowsAction,
  RangeModuleName,
  RangeUpdateRowAction,
  ScenarioRangePropertiesUpdateRowAction,
  VerticalShiftDeleteRowsAction,
  VerticalShiftInsertRowsAction,
  VerticalShiftUpdateRowAction,
} from '@dunefront/common/modules/range/range-module.actions';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { getCurrentRangeId, getCurrentRangeVerticalShifts, getSavedRangeId } from './range.selectors';
import { WsActionPropsFactory } from '@dunefront/common/common/ws-action/ws-action-props.factory';
import { RouterHelperService } from '../../shared/services/router-helper.service';
import { RangeValidation } from '@dunefront/common/modules/range/model/range.validation';
import { getStorageFilesArray } from '../data-storage/data-storage.selectors';
import { ImportFileDto } from '@dunefront/common/modules/data-storage/dto/import-file.dto';
import { updateXAxisShiftSuccessAction } from '../reporting/reporting.actions';

@Injectable()
export class RangeEffects extends BaseWsEffects {
  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    private routerHelperService: RouterHelperService,
  ) {
    super(actions$, wsService, RangeModuleName, false, true, modalService, store);
  }

  public loadScenarioDataSuccessAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadScenarioDataSuccessAction),
        concatLatestFrom(() => [this.store.select(getAllScenarios), this.store.select(getSavedRangeId)]),
        tap(async ([action, allScenarios, currentRangeIdInStore]) => {
          const currentScenario = allScenarios.find((sc) => sc.Id === action.loadScenarioResponse.scenarioId);
          if (currentScenario === undefined) {
            return;
          }
          const urlParts = this.routerHelperService.getDecodedUrlParts();
          const currentUrlRangeId = Number(urlParts[4]);
          const currentScenarioRangeId = currentScenario.CurrentRangeId;
          const rangeId = currentUrlRangeId > 0 ? currentUrlRangeId : currentScenarioRangeId;

          if (action.loadScenarioResponse.rangeModule.payload?.ranges.findIndex((range) => range.Id === rangeId) === -1) {
            // range id not found. Use full range
            urlParts[4] = RangeConstants.EntireRangeId + '';
            await this.routerHelperService.navigate(urlParts);
          } else if (currentUrlRangeId !== rangeId) {
            // range id should be different that one in url
            urlParts[4] = rangeId + '';
            await this.routerHelperService.navigate(urlParts);
          } else if (currentUrlRangeId !== currentRangeIdInStore) {
            // url range is different than one from store
            this.store.dispatch(changeCurrentRangeIdAction({ rangeId: currentUrlRangeId }));
          }
        }),
      ),
    { dispatch: false },
  );

  public saveRangeActionUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveRangeAction),
      filter((action) => action.rows[0].Id !== RangeConstants.NewRangeId),
      concatLatestFrom(() => this.store.select(getCurrentScenarioId)),
      mergeMap(([action, currentScenarioId]) => this.emitUpdate(new RangeUpdateRowAction(action, currentScenarioId))),
    ),
  );

  public updateScenarioRangePropertiesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateScenarioRangePropertiesAction),
      mergeMap((action) => this.emitUpdate(new ScenarioRangePropertiesUpdateRowAction(action))),
    ),
  );

  public saveRangeActionNew$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveRangeAction),
      filter((action) => action.rows[0].Id === RangeConstants.NewRangeId),
      concatLatestFrom(() => this.store.select(getCurrentScenarioId)),
      mergeMap(([action, currentScenarioId]) => this.emitInsert(new RangeInsertRowsAction(action, currentScenarioId))),
    ),
  );

  public deleteRangeAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteRangeAction),
      concatLatestFrom(() => [this.store.select(getStorageFilesArray), this.store.select(getCurrentScenarioId)]),
      filter(([action, files]) => this.validateBeforeDelete(action.rowIds[0], files)),
      mergeMap(([action, , currentScenarioId]) => this.emitDelete(new RangeDeleteRowsAction(action, currentScenarioId))),
    ),
  );

  public deleteRangeDataAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteRangeDataAction),
      concatLatestFrom(() => this.store.select(getStorageFilesArray)),
      filter(([action, files]) => this.validateBeforeDelete(action.range.Id, files)),
      mergeMap(([action]) => this.emitDelete(new RangeDeleteRangeDataAction(action.range.Id), 'Deleting data', '', false, true)),
    ),
  );

  public deleteInsertRowsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteRowsSuccess, insertRowsSuccess),
        filter(
          (action) => !action.isTriggeredFromUiUpdate && (action.affectedRows.range != null || action.affectedRows.deleteRangeData != null),
        ),
        concatLatestFrom(() => this.store.select(getSavedRangeId)),
        tap(([action, rangeId]) => {
          if (rangeId != null) {
            this.routerHelperService.changeRange(rangeId).then();
          }
        }),
      ),
    { dispatch: false },
  );

  public reloadDataChartWhenRangeChangedOnAnotherTab$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateRowSuccess),
        filter(
          (action) =>
            this.routerHelperService.isDataChartVisible() && action.isTriggeredFromUiUpdate == true && action.affectedRows.range != null,
        ),
        concatLatestFrom(() => [this.store.select(getCurrentRangeId)]),
        filter(([action, currentRangeId]) => currentRangeId === action.affectedRows.range?.rows[0]?.Id),
        tap(async ([action, currentRangeId]) => {
          await this.routerHelperService.changeRange(currentRangeId);
        }),
      ),
    { dispatch: false },
  );

  public updateXAxisShiftOnAnotherTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRowSuccess),
      filter(
        (action) =>
          this.routerHelperService.isDataChartVisible() && action.isTriggeredFromUiUpdate == true && action.affectedRows.importFiles != null,
      ),
      map((action) => updateXAxisShiftSuccessAction({ fileIds: (action.affectedRows.importFiles?.rows ?? []).map((file) => file.Id) })),
    ),
  );

  public resetYAxisShiftAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetYAxisShiftAction),
      concatLatestFrom(() => [this.store.select(getCurrentRangeVerticalShifts), this.store.select(getCurrentScenarioId)]),
      filter(([, verticalShifts]) => verticalShifts.length > 0),
      mergeMap(([, verticalShifts, scenarioId]) =>
        this.emitDelete(
          new VerticalShiftDeleteRowsAction({
            rowIds: verticalShifts.map((vs) => vs.Id),
            scenarioId,
            shouldResetResults: true,
          }),
        ),
      ),
    ),
  );

  public insertVerticalShiftDtosAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(insertVerticalShiftDtosAction),
      mergeMap((action) => this.emitInsert(new VerticalShiftInsertRowsAction(WsActionPropsFactory.insertDtos(action.verticalShifts, true)))),
    ),
  );

  public updateVerticalShiftDtosAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateVerticalShiftDtosAction),
      mergeMap((action) => this.emitUpdate(new VerticalShiftUpdateRowAction(WsActionPropsFactory.update(action.verticalShifts, true, [])))),
    ),
  );

  public autoYAxisShiftSaveAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(autoYAxisShiftSaveAction),
        map((action) => {
          if (action.updateVerticalShifts.length) {
            this.store.dispatch(updateVerticalShiftDtosAction({ verticalShifts: action.updateVerticalShifts }));
          }
          if (action.insertVerticalShifts.length) {
            this.store.dispatch(insertVerticalShiftDtosAction({ verticalShifts: action.insertVerticalShifts }));
          }
        }),
      ),
    { dispatch: false },
  );

  private validateBeforeDelete(rangeIdToDelete: number, importFiles: ImportFileDto[]): boolean {
    const validationMessage = RangeValidation.validateBeforeDelete(rangeIdToDelete, importFiles);
    if (validationMessage) {
      this.modalService.showAlert(validationMessage).then();
      return false;
    }
    return true;
  }
}
