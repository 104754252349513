<app-page-panel class="small-padding">
  <div class="grid-form-container main with-gap" *ngIf="data$ | async as data">
    <ng-container *ngIf="data.selectedFluid !== undefined">
      <app-fluid-selector
        dataCy="project-fluids-select-fluid"
        label="Select Fluid"
        [source]="data.selectedFluid"
        [key]="'Id'"
        [disabled]="isReadOnly"
        [isUiLockable]="false"
        (valueChanged)="changeSelectedFluid($event)"
      ></app-fluid-selector>
      <ng-container *ngIf="!isReadOnly">
        <app-fluid-color-selector
          [selectedFluid]="data.selectedFluid"
        ></app-fluid-color-selector>
      </ng-container>
    </ng-container>
  </div>
</app-page-panel>
