import { Injectable } from '@angular/core';
import {
  CloseDbConnectionAction,
  DbConnectionModuleName,
  DbConnectionResponse,
  IDbInfo,
  OpenDbConnectionAction,
} from '@dunefront/common/modules/db-connection/db-connection.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import * as actions from './backend-connection.actions';
import { dbDisconnectAction } from './backend-connection.actions';
import * as dbMigrationActions from '../db-migration/db-migration.actions';
import { DbMigrationComponent, DbMigrationComponentConfigData } from '../../common-modules/db-migration/db-migration.component';
import { BaseWsEffects } from '../base-ws.effects';
import { Title } from '@angular/platform-browser';
import { IFile } from '@dunefront/common/dto/file.dto';
import { ModalService } from '../../common-modules/modals/modal.service';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { ProjectFileHelpers } from '@dunefront/common/common/project-file-helpers';
import { AppTargetConfig } from '../../shared/services/app-target-config';
import { resetScenarioState } from '../app.actions';
import {
  ERROR_DB_NOT_EXISTS,
  ERROR_DB_READ_ONLY,
  ERROR_DB_ROOT_FILE,
  ERROR_DB_TEMP_FILE,
  ERROR_DB_USED,
  LicensingErrorType,
} from '@dunefront/common/exceptions/errors';
import * as Sentry from '@sentry/angular';
import { getSentryLicenseInfo, getSentryUser } from '@dunefront/common/common/sentry';
import { RouterHelperService } from '../../shared/services/router-helper.service';
import { getCurrentFileActiveJsWorkerJob } from '../calculation-engine/js-worker-jobs.selectors';
import { MigrationStatus } from '@dunefront/common/modules/db-migration/db-migration-module.actions';
import { getDbInfo } from './backend-connection.selectors';
import { ElectronService } from '../../shared/services/electron-service/electron.service';
import { filterNil } from '@dunefront/common/common/state.helpers';
import { ELECTION_ACTION_BACKEND_CONNECTED } from '@dunefront/common/common/electron/electron.actions';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';
import { JsWorkerActionType } from '@dunefront/common/modules/worker-types/js-workers-common';
import { licenseFailedWhenOpeningFile } from '../licensing/licensing.actions';

@Injectable()
export class BackendConnectionEffects extends BaseWsEffects {
  private dbConnectNoMigrationInProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.dbConnectAction),
      concatLatestFrom(() => this.store.select(getCurrentFileActiveJsWorkerJob)),
      filter(([action, job]) => job == null || job.type !== JsWorkerActionType.RunDbMigration),
      mergeMap(([action]) =>
        this.emit<DbConnectionResponse>(new OpenDbConnectionAction(action.dbFile, action.licensingConfig), 'Opening file...').pipe(
          tap((result) => {
            if (result.payload.dbConnectionResult) {
              Sentry.setUser(getSentryUser(result.payload.userId, result.payload.dbConnectionResult.dbInfo.file.Name));
            }
            const sentryLicenseInfo = getSentryLicenseInfo(result.payload.licensingLoginResponse);
            Sentry.setTag('licenseMainFeature', sentryLicenseInfo.mainFeature);
            Sentry.setTag('licenseId', sentryLicenseInfo.id);
          }),
          map((result) => {
            if (!result.payload.licensingLoginResponse.isLoggedIn) {
              return licenseFailedWhenOpeningFile({ licensingLoginResponse: result.payload.licensingLoginResponse });
            }

            const dbInfo = result.payload.dbConnectionResult?.dbInfo;
            if (dbInfo == null) {
              return actions.dbConnectionFailedAction({
                fileName: action.dbFile.Name,
                error: 'Db Info not found',
              });
            }

            switch (dbInfo.dbVersionStatus) {
              case 'dbVersionTooLow':
              case 'dbVersionTooHigh':
                return actions.wrongDbVersion({
                  error: {
                    name: dbInfo.dbVersionStatus,
                    message: dbInfo.dbVersionStatus,
                    stack: dbInfo.dbVersionStatus,
                  },
                });
              case 'dbMigrationRequired':
              case 'dbMigrationRequiredWithBackup':
                return actions.dbConnectedMigrationRequiredAction({
                  dbInfo: dbInfo,
                  licensingConfig: action.licensingConfig,
                });
              default:
                return actions.dbConnectedSuccessAction({
                  payload: result.payload,
                  licensingConfig: action.licensingConfig,
                  scenarioId: action.scenarioId != null && !isNaN(action.scenarioId) ? action.scenarioId : -1,
                  rangeId:
                    action.rangeId != null && action.rangeId > 0 && !isNaN(action.rangeId) ? action.rangeId : RangeConstants.EntireRangeId,
                });
            }
          }),
          catchError((error) =>
            of(
              actions.dbConnectionFailedAction({
                fileName: action.dbFile.Name,
                error: JSON.parse(JSON.stringify(error)),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  private dbConnectMigrationInProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.dbConnectAction),
      concatLatestFrom(() => [this.store.select(getCurrentFileActiveJsWorkerJob), this.store.select(getDbInfo)]),
      filter(([, job, dbInfo]) => dbInfo != null && job != null && job.type == JsWorkerActionType.RunDbMigration),
      map(([action, job, dbInfo]) =>
        actions.dbConnectedMigrationRequiredAction({
          dbInfo: dbInfo as IDbInfo,
          licensingConfig: action.licensingConfig,
        }),
      ),
    ),
  );

  private dbConnectionFailedAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.dbConnectionFailedAction),
        tap(async (action) => {
          const errorCode = action.error.toString();
          let message = 'Cannot connect to database';

          if (errorCode === ERROR_DB_USED) {
            message = `File "${action.fileName}" is already open in another application`;
          }

          if (errorCode === ERROR_DB_READ_ONLY) {
            message = `File "${action.fileName}" is read only`;
          }

          if (errorCode === ERROR_DB_ROOT_FILE) {
            message = `File can't be open from the root directory of the drive`;
          }

          if (errorCode === ERROR_DB_TEMP_FILE) {
            message = `File can't be open from the temp directory`;
          }

          if (errorCode === ERROR_DB_NOT_EXISTS || errorCode.includes('SQLITE_ERROR: no such table')) {
            message = `File "${action.fileName}" not found.`;
          }

          if (errorCode === LicensingErrorType.ERROR_LICENSE_FEATURE_NOT_FOUND) {
            message = `You don't have license to access this module`;
          }

          if (errorCode === LicensingErrorType.ERROR_LICENSE_HASP) {
            message = 'Cannot connect to license';
          }

          await this.modalService.showAlert(message);
          this.store.dispatch(dbDisconnectAction());
          await this.routerHelperService.navigateToHome();
        }),
      ),
    { dispatch: false },
  );

  private dbConnectedMigrationRequired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.dbConnectedMigrationRequiredAction),
        concatLatestFrom(() => [this.store.select(getCurrentFileActiveJsWorkerJob), this.store.select(getDbInfo).pipe(filterNil())]),
        tap(([action, job, dbInfo]) => {
          const migrationWithBackup = dbInfo.dbVersionStatus === 'dbMigrationRequiredWithBackup';

          let status: MigrationStatus;

          if (job?.type === JsWorkerActionType.RunDbMigration) {
            status = 'migrationInProgress';
          } else {
            status = migrationWithBackup ? 'migrationRequiredWithBackup' : 'migrationRequired';
          }

          // initialize db-migration store
          this.store.dispatch(
            dbMigrationActions.progressDbMigration({
              taskId: '',
              migrationServerResponse: { status, fileHash: action.dbInfo.fileHash },
            }),
          );
          // open db-migration modal dialog
          this.modalService.open<DbMigrationComponentConfigData>(
            DbMigrationComponent,

            { licensingConfig: action.licensingConfig },
            undefined,
            undefined,
            undefined,
            false,
          );
        }),
      ),
    { dispatch: false },
  );

  private dbDisconnect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dbDisconnectAction),
      tap(() => this.setPageTitle()),
      mergeMap(() =>
        this.emit<DbConnectionResponse>(new CloseDbConnectionAction(), 'closing file...').pipe(
          map(() => actions.dbDisconnectSuccessAction()),
          catchError(() => of(actions.dbConnectionFailedAction)),
        ),
      ),
    );
  });

  private backendConnected$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.backendConnectedAction),
        tap(() => {
          if (this.electronService.instance) {
            this.electronService.instance.ipcRenderer.send(ELECTION_ACTION_BACKEND_CONNECTED);
          }
        }),
      ),
    { dispatch: false },
  );

  private dbDisconnectSuccessAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.dbDisconnectSuccessAction),
      map(() => resetScenarioState()),
    ),
  );

  private setPageTitle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.setPageTitle),
        map((action) => this.setPageTitle(action.file, action.scenario)),
      ),
    { dispatch: false },
  );

  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    private titleService: Title,
    private appTargetConfig: AppTargetConfig,
    private routerHelperService: RouterHelperService,
    private electronService: ElectronService,
  ) {
    super(actions$, wsService, DbConnectionModuleName, false, true, modalService, store);
  }

  private setPageTitle(file?: IFile, scenario?: Scenario): void {
    let title = this.appTargetConfig.appName;

    if (file) {
      title += ` - ${ProjectFileHelpers.getFileDisplayName(file)}`;
    }

    if (scenario) {
      title += ` - ${scenario.Name}`;
    }

    this.titleService.setTitle(title);
  }
}
