<div class="psd-data-container" *ngIf="psdData?.selectedPSD">
  <app-page-panel header="PSD Definition" (click)="onHelpChange('psd', 'psd-definition')">
    <div class="grid-form-container psd-def-grid">
      <label>Select PSD</label>
      <app-psd-selector></app-psd-selector>
      <app-form-input
        class="grid-two-one"
        label="Description"
        [source]="psdData.selectedPSD"
        key="Description"
        [isTextInput]="true"
        [unitType]="UnitType.None"
        (valueChanged)="updatePSD($event)"
        dataCy="psd-description"
        [showSymbol]="false"
        appUnwrap
      >
      </app-form-input>
      <app-form-input
        appUnwrap
        class="grid-two-one"
        label="Depth"
        [source]="psdData.selectedPSD"
        key="Depth"
        [decimalPlaces]="2"
        [unitType]="UnitType.Long_Length"
        (valueChanged)="updatePSD($event)"
        dataCy="psd-depth"
      >
      </app-form-input>
      <div class="buttons-panel col-span-2">
        <div>
          <app-button (buttonClick)="addPSD()" dataCy="add-psd-button" text="Add PSD"></app-button>
          <app-button (buttonClick)="deletePSD()" text="Delete PSD"></app-button>
        </div>
      </div>
    </div>
  </app-page-panel>
  <app-page-panel class="grid-panel" header="PSD Test Data" (click)="onHelpChange('psd', 'psd-test-data')"
                  [panelHelpMode]="PanelHelpMode.GRID">
    <app-psd-data-grid
      #gridComponent
      [psdReadings]="psdData.selectedPSDReadings"
      [psd]="psdData.selectedPSD"
      (psdInputTypeValueChange)="updatePSD($event)"
    >
    </app-psd-data-grid>
  </app-page-panel>
</div>
