import { createSelector } from '@ngrx/store';
import { isPSDModuleValid } from '../../+store/psd-analysis/psd-analysis.selectors';
import { ILeftNavItems, LeftNavHelpers } from '../../shared/components/left-nav/left-nav.helpers';
import { getSimulateEvaluateReportingLeftNavElement } from '../simulate-evaluate-page/simulate-evaluate-module.menu';
import { getSettingsLeftNavElement } from '../common/settings/settings.menu';

export const getPsdAnalysisModuleMenuData = createSelector(
  isPSDModuleValid,
  getSimulateEvaluateReportingLeftNavElement,
  getSettingsLeftNavElement,
  (isValid, reportingLeftNavElement, settings): ILeftNavItems => {
    return {
      regular: [
        LeftNavHelpers.toNavElement({
          label: 'PSD input',
          uri: 'psd-input',
          icon: 'icon-layers',
          isError: !isValid.isAllSelectedPSDValid || !isValid.isAllSelectedPSDReadingsValid,
          isWarning: !isValid.isAllUnselectedPSDValid || !isValid.isAllUnselectedPSDReadingsValid,
          items: [
            {
              label: 'PSD data',
              routerLink: 'psd-input/psd-data',
              isError: !isValid.isAllSelectedPSDValid || !isValid.isAllSelectedPSDReadingsValid,
              isWarning: !isValid.isAllUnselectedPSDValid || !isValid.isAllUnselectedPSDReadingsValid,
            },
            LeftNavHelpers.getItem('PSD chart', 'psd-input/psd-chart'),
          ],
        }),
        LeftNavHelpers.toNavElement({
          label: 'PSD analysis',
          uri: 'psd-analysis',
          icon: 'icon-measure-ruler',
          isError:
            !isValid.isAllSelectedPSDValid ||
            !isValid.isAllSelectedPSDReadingsValid ||
            !isValid.isAtLeastOneSelected ||
            !isValid.isPSDAnalysisValid,
          isWarning: !isValid.isAllUnselectedPSDValid || !isValid.isAllUnselectedPSDReadingsValid,
          items: [
            {
              label: 'PSD comparison',
              routerLink: 'psd-analysis/psd-comparison',
              isError: !isValid.isAllSelectedPSDValid || !isValid.isAllSelectedPSDReadingsValid || !isValid.isAtLeastOneSelected,
              isWarning: !isValid.isAllUnselectedPSDValid || !isValid.isAllUnselectedPSDReadingsValid,
            },
            LeftNavHelpers.getItem('Sand production', 'psd-analysis/sand-production', isValid.isPSDAnalysisValid),
          ],
        }),
        settings,
      ],
      results: [
        LeftNavHelpers.getStartStopButton(),
        LeftNavHelpers.getNavElement('Results', 'results', 'icon-analysis', true, false, [
          LeftNavHelpers.getItem('Summary', 'results/summary', true, !isValid.hasResults),
          LeftNavHelpers.getItem('Screen and Gravel Selection', 'results/screen-and-gravel-selection', true, !isValid.hasResults),
        ]),
        reportingLeftNavElement,
      ],
    };
  },
);
