<ng-container *ngIf="isPumpingEnabled$ | async; else pageDisabled">
  <ng-container *ngIf="pumpingState.isLoaded">
    <app-page-panel  (click)="onHelpChange('pumping', 'schedule-evaluate')" class="no-padding">
      <app-schedule-grid
        #gridComponent
        *ngIf="scheduleTableState.rows.length; else scheduleMessage"
        [pumpingSchedule]="scheduleTableState"
        [isReadOnlyMode]="true"
      ></app-schedule-grid>
      <ng-template #scheduleMessage>
        <div class="schedule-message">Click the preview schedule button to generate a schedule from the imported pumping
          data
        </div>
      </ng-template>
    </app-page-panel>
    <div class="bottom-panel">
      <div class="left-bottom-panel">
        <div class="schedule-buttons-panel">
          <app-button
            dataCy="preview-schedule-btn"
            [isUiLockable]="true"
            [isDeleteResultsEnabled]="false"
            (buttonClick)="onPreviewScheduleClick()"
            text="Preview Schedule"
          >
          </app-button>
          <app-button
            dataCy="copy-to-simulate-btn"
            [isDeleteResultsEnabled]="false"
            [disabled]="scheduleTableState.rows.length === 0"
            (buttonClick)="onCopyToSimulateClick()"
            text="Copy to Simulate"
          >
          </app-button>
        </div>
        <app-page-panel header="Analysis Data" (click)="onHelpChange('pumping', 'schedule-evaluate-analysis')">
          <app-analysis-data></app-analysis-data>
        </app-page-panel>
      </div>
      <app-page-panel header="Pumped Fluid and Gravel" class="no-padding"
                      (click)="onHelpChange('pumping', 'schedule-evaluate-fluid-and-gravel')">
        <app-pumped-fluid-and-gravel-grid #gridComponent
                                          [tableState]="pumpingState.pumpedFluidAndGravel"></app-pumped-fluid-and-gravel-grid>
      </app-page-panel>
    </div>
  </ng-container>
</ng-container>
<ng-template #pageDisabled>
  <div class="centered-message">The Schedule screen is not available for the Entire Range - select another range to
    update the data
  </div>
</ng-template>
