<div class="dx-field dropdown-panel">
  <div class="dx-field-label">Scenario</div>
  <div class="dx-field-value">
    <app-scenario-manager-selector
      class="ribbon-select"
      [value]="currentScenarioId"
      (valueChanged)="scenarioChange($event)"
      [dataCy]="'ribbon-scenario-manager-selector'"
    >
    </app-scenario-manager-selector>
  </div>
</div>
<div class="small-button-panel">
  <ng-container *ngrxLet="hasAnyUIBlockingJobs$ as hasAnyUIBlockingJobs">
    <app-button
      textIcon="icon-business-chart"
      class="top-menu-button"
      text="Scenario Manager"
      dataCy="scenario-manager-btn"
      [class.ribbon-button]="isRibbonMenu"

      [isUiLockable]="false"
      [disabled]="hasAnyUIBlockingJobs"
      (buttonClick)="openScenarioManager()"
    ></app-button>
  </ng-container>
</div>
