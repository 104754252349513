<div class="recent" *ngIf="recentFilesState$ | async as recentFilesState">

  <div class="recent-tabs" *ngIf="!isElectron()">
    <li [class.active]="isFilesMode" (click)="selectedRecentTabMode = recentTabModes.FILES" data-cy="recent-files-tab">Recent projects</li>
    <li [class.active]="isFoldersMode" (click)="selectedRecentTabMode = recentTabModes.FOLDERS" data-cy="recent-folders-tab">Recent folders</li>
  </div>

  <app-file-list [isRecentMode]="true" *ngIf="isFilesMode"></app-file-list>

  <div class="recent-folders" *ngIf="isFoldersMode">
    <div class="recent-folder-contents" data-cy="recent-folder-contents">
      <app-files-list-file
        *ngFor="let recentFolderDto of recentFilesState.recent.recentFolders; let i = index;"
        class="recent-folders-item"
        [style.animation-delay]="i * .03 +'s'"
        [file]="recentFolderDto"
        (click)="recentFolderClick(recentFolderDto)"
      ></app-files-list-file>
    </div>
  </div>
</div>
