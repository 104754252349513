<app-generic-modal [title]="getModalTitle()" [modalButtonsConfigs]="modalButtonsConfigs">
  <div class="grid-form-container no-padding">
    <app-form-input
      appUnwrap
      [isUiLockable]="false"
      dataCy="unit-name"
      label="Custom Unit Name"
      [source]="workingUnitSystem"
      [key]="'name'"
      [maxWidth]="200"
      [isTextInput]="true"
      [unitType]="UnitType.None"
      [autoFocus]="true"
      (valueChanged)="emitValue($event)"
      class="unit-system-name"
    >
    </app-form-input>
  </div>

  <div class="unit-groups">
    <div class="first-unit-group">
      <div class="unit">
        <label>Angle</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-angle'"
                      [items]="getUnitSymbols(UnitType.Angle)"
                      [source]="workingUnitSystem"
                      [key]="'Angle'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Bulk Density</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-bulk-density'"
                      [items]="getUnitSymbols(UnitType.Bulk_Density)"
                      [source]="workingUnitSystem"
                      [key]="'Bulk_Density'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Compressibility</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-compressibility'"
                      [items]="getUnitSymbols(UnitType.Compressibility)"
                      [source]="workingUnitSystem"
                      [key]="'Compressibility'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>

      <div class="unit">
        <label>Consistency Index</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-consistency-index'"
                      [items]="getUnitSymbols(UnitType.Consistency_Index)"
                      [source]="workingUnitSystem"
                      [key]="'Consistency_Index'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Diameter</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-diameter'"
                      [items]="getUnitSymbols(UnitType.Diameter)"
                      [source]="workingUnitSystem"
                      [key]="'Diameter'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Fluid Loss Coefficient</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-fluid-loss-coefficient'"
                      [items]="getUnitSymbols(UnitType.Fluid_Loss_Coefficient)"
                      [source]="workingUnitSystem"
                      [key]="'Fluid_Loss_Coefficient'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Force</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-force'"
                      [items]="getUnitSymbols(UnitType.Force)"
                      [source]="workingUnitSystem"
                      [key]="'Force'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Injectivity</label>
        <div class="unit-selector">
          <app-select [dataCy]="'injectivity'"
                      [items]="getUnitSymbols(UnitType.Injectivity)"
                      [source]="workingUnitSystem"
                      [key]="'Injectivity'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Linear Mass</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-linear-mass'"
                      [items]="getUnitSymbols(UnitType.Linear_Mass)"
                      [source]="workingUnitSystem"
                      [key]="'Linear_Mass'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Linear Volume</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-linear-volume'"
                      [items]="getUnitSymbols(UnitType.Linear_Volume)"
                      [source]="workingUnitSystem"
                      [key]="'Linear_Volume'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Liquid Concentration</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-liquid-concentration'"
                      [items]="getUnitSymbols(UnitType.Liquid_Concentration)"
                      [source]="workingUnitSystem"
                      [key]="'Liquid_Concentration'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Liquid Density</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-liquid-density'"
                      [items]="getUnitSymbols(UnitType.Liquid_Density)"
                      [source]="workingUnitSystem"
                      [key]="'Liquid_Density'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Liquid Volume</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-liquid-volume'"
                      [items]="getUnitSymbols(UnitType.Liquid_Volume)"
                      [source]="workingUnitSystem"
                      [key]="'Liquid_Volume'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Long Length</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-long-length'"
                      [items]="getUnitSymbols(UnitType.Long_Length)"
                      [source]="workingUnitSystem"
                      [key]="'Long_Length'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Mass</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-mass'"
                      [items]="getUnitSymbols(UnitType.Mass)"
                      [source]="workingUnitSystem"
                      [key]="'Mass'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Mass Flow Rate</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-mass-flow-rate'"
                      [items]="getUnitSymbols(UnitType.Mass_Flow_Rate)"
                      [source]="workingUnitSystem"
                      [key]="'Mass_Flow_Rate'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Permeability</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-permeability'"
                      [items]="getUnitSymbols(UnitType.Permeability)"
                      [source]="workingUnitSystem"
                      [key]="'Permeability'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Pressure</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-pressure'"
                      [items]="getUnitSymbols(UnitType.Pressure)"
                      [source]="workingUnitSystem"
                      [key]="'Pressure'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Pressure Gradient</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-pressure-gradient'"
                      [items]="getUnitSymbols(UnitType.Pressure_Gradient)"
                      [source]="workingUnitSystem"
                      [key]="'Pressure_Gradient'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Pressure Rate Derivative</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-pressure-rate-derivative'"
                      [items]="getUnitSymbols(UnitType.Pressure_Rate_Derivative)"
                      [source]="workingUnitSystem"
                      [key]="'Pressure_Rate_Derivative'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Pressure Rate² Derivative</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-pressure-rate-squared-derivative'"
                      [items]="getUnitSymbols(UnitType.Pressure_Rate_Squared_Derivative)"
                      [source]="workingUnitSystem"
                      [key]="'Pressure_Rate_Squared_Derivative'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Pressure Rate³ Derivative</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-pressure-rate-cubed-derivative'"
                      [items]="getUnitSymbols(UnitType.Pressure_Rate_Cubed_Derivative)"
                      [source]="workingUnitSystem"
                      [key]="'Pressure_Rate_Cubed_Derivative'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
    </div>
    <div class="second-unit-group">
      <div class="unit">
        <label>Pressure Time Derivative</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-pressure-time-derivative'"
                      [items]="getUnitSymbols(UnitType.Pressure_Time_Derivative)"
                      [source]="workingUnitSystem"
                      [key]="'Pressure_Time_Derivative'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Pressure Volume Derivative</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-pressure-volume-derivative'"
                      [items]="getUnitSymbols(UnitType.Pressure_Volume_Derivative)"
                      [source]="workingUnitSystem"
                      [key]="'Pressure_Volume_Derivative'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Rate</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-rate'"
                      [items]="getUnitSymbols(UnitType.Rate)"
                      [source]="workingUnitSystem"
                      [key]="'Rate'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Reciprocal Length</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-reciprocal-length'"
                      [items]="getUnitSymbols(UnitType.Reciprocal_Length)"
                      [source]="workingUnitSystem"
                      [key]="'Reciprocal_Length'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Screen Opening</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-screen-opening'"
                      [items]="getUnitSymbols(UnitType.Screen_Opening)"
                      [source]="workingUnitSystem"
                      [key]="'Screen_Opening'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Short Length</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-short-length'"
                      [items]="getUnitSymbols(UnitType.Short_Length)"
                      [source]="workingUnitSystem"
                      [key]="'Short_Length'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Shot Density</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-shot-density'"
                      [items]="getUnitSymbols(UnitType.Shot_Density)"
                      [source]="workingUnitSystem"
                      [key]="'Shot_Density'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Sieve Opening</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-sieve-opening'"
                      [items]="getUnitSymbols(UnitType.Sieve_Opening)"
                      [source]="workingUnitSystem"
                      [key]="'Sieve_Opening'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Small Mass</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-small-mass'"
                      [items]="getUnitSymbols(UnitType.Small_Mass)"
                      [source]="workingUnitSystem"
                      [key]="'Small_Mass'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Solid Concentration</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-solid-concentration'"
                      [items]="getUnitSymbols(UnitType.Solid_Concentration)"
                      [source]="workingUnitSystem"
                      [key]="'Solid_Concentration'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Solid Density</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-solid-density'"
                      [items]="getUnitSymbols(UnitType.Solid_Density)"
                      [source]="workingUnitSystem"
                      [key]="'Solid_Density'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Solid Volume</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-solid-volume'"
                      [items]="getUnitSymbols(UnitType.Solid_Volume)"
                      [source]="workingUnitSystem"
                      [key]="'Solid_Volume'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Specific Heat Capacity</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-specific-heat-capacity'"
                      [items]="getUnitSymbols(UnitType.Specific_Heat_Capacity)"
                      [source]="workingUnitSystem"
                      [key]="'Specific_Heat_Capacity'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Temperature</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-temperature'"
                      [items]="getUnitSymbols(UnitType.Temperature)"
                      [source]="workingUnitSystem"
                      [key]="'Temperature'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Temperature Time Derivative</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-temperature-time-derivative'"
                      [items]="getUnitSymbols(UnitType.Temperature_Time_Derivative)"
                      [source]="workingUnitSystem"
                      [key]="'Temperature_Time_Derivative'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Temperature Volume Derivative</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-temperature-volume-derivative'"
                      [items]="getUnitSymbols(UnitType.Temperature_Volume_Derivative)"
                      [source]="workingUnitSystem"
                      [key]="'Temperature_Volume_Derivative'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Thermal Conductivity</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-thermal-conductivity'"
                      [items]="getUnitSymbols(UnitType.Thermal_Conductivity)"
                      [source]="workingUnitSystem"
                      [key]="'Thermal_Conductivity'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Time</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-time'"
                      [items]="getUnitSymbols(UnitType.Time)"
                      [source]="workingUnitSystem"
                      [key]="'Time'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>

        </div>
      </div>
      <div class="unit">
        <label>Velocity</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-velocity'"
                      [items]="getUnitSymbols(UnitType.Velocity)"
                      [source]="workingUnitSystem"
                      [key]="'Velocity'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>
        </div>
      </div>
      <div class="unit">
        <label>Viscosity</label>
        <div class="unit-selector">
          <app-select [dataCy]="'unit-viscosity'"
                      [items]="getUnitSymbols(UnitType.Viscosity)"
                      [source]="workingUnitSystem"
                      [key]="'Viscosity'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>
        </div>
      </div>
      <div class="unit">
        <label>Yield Stress</label>
        <div class="unit-selector">
          <app-select [dataCy]="'yield-stress'"
                      [items]="getUnitSymbols(UnitType.Yield_Stress)"
                      [source]="workingUnitSystem"
                      [key]="'Yield_Stress'"
                      [isUiLockable]=false
                      (valueChanged)="onUnitValueChange($event)" [showSymbol]="false"
          ></app-select>
        </div>
      </div>
    </div>
  </div>
</app-generic-modal>
