import { Auth0Config } from '@dunefront/client-common/auth0.config';
import { getBackendToClientConfig } from '@dunefront/common/backend-to-client-config';

export interface IAuthConfig {
  domain: string;
  clientId: string;
  authorizationParams: {
    audience?: string;
    redirect_uri: string;
  };
  apiUri: string;
  appUri: string;
  errorPath: string;
}

export const authConfig: IAuthConfig = Auth0Config;

export const httpInterceptor = {
  allowedList: [`${getBackendToClientConfig().ServerBaseUrl}/*`],
};
