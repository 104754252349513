import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { modalButton, ModalButtonConfig } from '../../../../common-modules/modals/generic-modal/generic-modal.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { getAllUserJobs } from '../../../../+store/calculation-engine/calculation-engine.selectors';
import { isSimulateBased, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { getAllScenarios } from '../../../../+store/scenario/scenario.selectors';
import { Subscription } from 'rxjs';
import {
  CalculationStatus,
  calculationStatusToString,
  ICalculationJob,
} from '../../../../+store/calculation-engine/calculation-engine-module.state';
import { cancelJobsAction } from '../../../../+store/calculation-engine/calculation-engine.actions';
import { RouterHelperService } from '../../../../shared/services/router-helper.service';
import { IFile } from '@dunefront/common/dto/file.dto';
import { ProjectFileHelpers } from '@dunefront/common/common/project-file-helpers';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';

@Component({
  selector: 'app-calculation-queue',
  templateUrl: './calculation-queue.component.html',
  styleUrls: ['./calculation-queue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculationQueueComponent implements OnInit, OnDestroy {
  public readonly modalButtonsConfigs: ModalButtonConfig[] = [
    modalButton('Close', (): void => this.closeClicked(), 'calculation-queue-close-btn', 'cancel'),
  ];
  public readonly modalTitle = 'Calculation Queue';
  public readonly allJobs$ = this.store.select(getAllUserJobs);
  public readonly CalculationStatus = CalculationStatus;

  private allScenarios: Scenario[] = [];
  private cancelledJobIds = new Set<string>();
  private subscription = new Subscription();
  public isHelpOpen = false;

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly store: Store,
    private readonly routerHelperService: RouterHelperService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.store.select(getAllScenarios).subscribe((scenarios) => {
        this.allScenarios = scenarios;
        this.cdr.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public closeClicked(): void {
    this.ref.close();
  }

  public moduleName(moduleType: ModuleType): string {
    return EnumHelpers.enumToText(ModuleType[moduleType]);
  }

  public fileName(file: IFile): string {
    return ProjectFileHelpers.getFileDisplayName(file);
  }

  public scenarioName(scenarioId: number): string {
    const scenario = this.allScenarios.find((scenario) => scenario.Id === scenarioId);
    return scenario?.Name ?? '';
  }

  public onCancelJob({ fileHash, jobId }: ICalculationJob): void {
    this.cancelledJobIds.add(jobId);
    this.store.dispatch(cancelJobsAction({ fileHash, jobIds: [jobId] }));
  }

  public onGoTo(job: ICalculationJob): Promise<boolean> {
    // close popup
    this.ref.close();

    return this.routerHelperService.navigateToAnimationChart(job);
  }

  public isGoToAvailable({ jobId, status, moduleType }: ICalculationJob): boolean {
    if (this.cancelledJobIds.has(jobId)) {
      return false;
    }

    if (status !== CalculationStatus.running) {
      return false;
    }

    return isSimulateBased(moduleType) || moduleType === ModuleType.Evaluate;
  }

  public statusAsString({ status }: ICalculationJob): string {
    return calculationStatusToString(status);
  }

  public isActionButtonDisabled({ jobId }: ICalculationJob): boolean {
    return this.cancelledJobIds.has(jobId);
  }

  public trackByJob(index: number, job: ICalculationJob): string {
    return job.jobId;
  }
}
