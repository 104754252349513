<ng-container *ngIf="data$ | async as data">
  <label [style.width.px]="labelWidth">{{ label }}</label>
  <app-select
    [source]="source"
    [key]="key"
    [items]="data.items"
    [disabled]="disabled"
    [overrideErrorMessage]="errorMessage"
    selectPlaceholder="Select Gravel"
    (valueChanged)="valueChanged.emit($event)"
    [isUiLockable]="isUiLockable"
    [dataCy]="dataCy"
    [showSymbol]="showSymbol"
  >
  </app-select>
</ng-container>
