import { IScenarioIdBasedEntity } from '../../../dto/common-dto.interfaces';
import { TreatingReturnLines } from './treating-return-lines';
import { PressureConverter } from '../../../unit-converters/converters/pressure/pressure.converter';
import { PressureUnit } from '../../../dto/unit-system.dto';

export interface PumpingDto extends IScenarioIdBasedEntity, TreatingReturnLines {
  Id: number;
  ScenarioId: number;
  WorkstringFluidId: number | null;
  UpperAnnulusFluidId: number | null;
  WashpipeFluidId: number | null;
  LowerAnnulusFluidId: number | null;
  PumpingDirection: PumpingDirection;
  ToolPosition: ToolPosition;
  RangeId: number;
  ChokePosition: ChokeSetting;
  IsChokeOpenAtVolume: boolean;
  ChokeOpenVolume: number;
  IsWellFluidSelectionSimple: boolean;
  IsWellFluidDepthByVolume: boolean;
  IsCoiledTubing: boolean;
  ReelDiameter: number;
  IsAutoPumpPressureSchedule: boolean;
  IsAutoFracSchedule: boolean;
  IsAutoAlphaSchedule: boolean;
  AutoFracSafetyMargin: number;
  AutoAlphaSafetyMargin: number;
  AutoScheduleMinRate: number;
  AutoScheduleRateStep: number;
  AutoScheduleCarrierFluidId: number | null;
  AutoScheduleFlushFluidId: number | null;
  AutoScheduleGravelId: number | null;
  AutoSchedulePumpRate: number;
  AutoScheduleMaxGravelConc: number;
  AutoScheduleExcessGravelPercentage: number;
  IsAutoScheduleRamped: boolean;
  IsAutoPressureAttenuatorLocation: boolean;
  VariableChoke: VariableChokeSetting;
  MaxSurfacePressure: number;

  AutoScheduleTargetPacking: number;
  AutoScheduleExcessPumpRate: number;
  AutoPressureAttenuatorActivation: number;
  AutoScheduleReturnRate: number;

  BlankPackingPercentageForGravelRequired: number;
  IsBlankPackingForGravelRequired: boolean;

  SortOrder: number;

  AutoPressureAttenuatorSafetyMargin: number;
  IsAutoManagePressure: boolean;
  AutoManagePressureMD: number;
  AutoManagePressureLocation: number | null;
  AutoManagePressureTarget: number;
  AutoManagePressureTolerance: number;
  IsAutoManagePressureEMW: boolean;
  AutoManagePressureEMWTarget: number;
  AutoManagePressureEMWTolerance: number;
}

export const createEmptyPumpingDto = (scenarioId: number, rangeId: number): PumpingDto => ({
  Id: -1,
  RangeId: rangeId,
  ScenarioId: scenarioId,

  WorkstringFluidId: null,
  UpperAnnulusFluidId: null,
  WashpipeFluidId: null,
  LowerAnnulusFluidId: null,
  SurfaceChokeLineLength: 0,
  SurfaceChokeLineID: 0,
  ChokeKillLineID: 0,
  ChokeOrificeID: 0,
  HydrilState: HydrilState.Open,
  PumpingDirection: PumpingDirection.In_Workstring,
  IsSubseaBOP: false,
  ToolPosition: ToolPosition.Circulating_GP,
  IsTreatingLine: false,
  SurfaceTreatingLineLength: 0,
  TreatingLineID: 0,

  ChokePosition: ChokeSetting.None,
  VariableChoke: VariableChokeSetting.Fixed,
  IsChokeOpenAtVolume: false,
  ChokeOpenVolume: 0,
  IsWellFluidSelectionSimple: true,
  IsWellFluidDepthByVolume: true,
  IsCoiledTubing: false,
  ReelDiameter: 0,
  IsAutoPumpPressureSchedule: false,
  IsAutoFracSchedule: false,
  IsAutoAlphaSchedule: false,
  AutoFracSafetyMargin: 0,
  AutoAlphaSafetyMargin: 0,
  AutoScheduleMinRate: 0,
  AutoScheduleRateStep: 0,
  AutoScheduleCarrierFluidId: null,
  AutoScheduleFlushFluidId: null,
  AutoScheduleGravelId: null,
  AutoSchedulePumpRate: 0,
  AutoScheduleMaxGravelConc: 0,
  AutoScheduleExcessGravelPercentage: 0,
  IsAutoScheduleRamped: false,
  IsAutoPressureAttenuatorLocation: false,

  MaxSurfacePressure: PressureConverter.toSi(10000, PressureUnit.Pound_per_square_inch),

  AutoScheduleTargetPacking: 0,
  AutoScheduleExcessPumpRate: 0,
  AutoPressureAttenuatorActivation: 0,
  AutoScheduleReturnRate: 0,

  BlankPackingPercentageForGravelRequired: 0,
  IsBlankPackingForGravelRequired: false,

  AutoPressureAttenuatorSafetyMargin: 0,
  IsAutoManagePressure: false,
  AutoManagePressureMD: 0,
  AutoManagePressureLocation: null,
  AutoManagePressureTarget: 0,
  AutoManagePressureTolerance: 0,

  IsAutoManagePressureEMW: false,
  AutoManagePressureEMWTarget: 0,
  AutoManagePressureEMWTolerance: 0,

  SortOrder: 0,
});

export enum HydrilState {
  // Numbering provides back compatibility with previous versions (prior to v2.0)
  One_Line = 0,
  Open = 1,
  Two_Lines = 2,
}

export enum PumpingDirection {
  In_Workstring = 0,
  In_Annulus = 1,
}

export enum ToolPosition {
  Circulating_GP = 0,
  Reverse = 1,
  Washdown = 2,
  Reverse_Port_GP = 3,
}

export enum ChokeSetting {
  Closed = 0,
  Open = 1,
  None = 2,
}

export enum VariableChokeSetting {
  Fixed = 0,
  Managed_Rate = 1,
  Managed_Pressure = 2,
}

export class PumpingExtensions {
  public static getVariableChoke(pumping: PumpingDto): VariableChokeSetting {
    return pumping.ChokePosition === ChokeSetting.None ? VariableChokeSetting.Fixed : pumping.VariableChoke;
  }
}
