<ng-container *ngIf="isGravelModuleLoaded$ | async">
  <ng-container *ngIf="currentGravel">
    <app-gravel-header (click)="onHelpChange('gravels', 'gravel-properties')"></app-gravel-header>

    <app-page-panel header="Gravel Properties" (click)="onHelpChange('gravels', 'gravel-properties')"
                    class="gravel-props-panel">
      <app-gravel-properties *ngIf="currentGravel" [gravel]="currentGravel"></app-gravel-properties>
    </app-page-panel>
  </ng-container>

  <div class="buttons-panel">
    <div>
      <app-button
        dataCy="gravel-to-database"
        [isUiLockable]="false"
        (buttonClick)="saveToDatabase()"
        text="Save to Database"
      >
      </app-button>
    </div>
    <div>
      <app-button dataCy="new-gravel" (buttonClick)="newGravel()" text="New Gravel"></app-button>
      <app-button dataCy="copy-gravel" (buttonClick)="copyGravel()" text="Copy Gravel"></app-button>
      <app-button dataCy="delete-gravel" (buttonClick)="deleteGravel()" text="Delete Gravel"></app-button>
    </div>
  </div>
</ng-container>
