<div *ngIf="psdData$ | async as psdData">
  <app-select
    *ngIf="psdData.selectedPSD"
    [items]="psdData.items"
    [value]="psdData.selectedPSD.Id"
    [disabled]="disabled"
    (primitiveValueChanged)="changePSD($event.value)"
    dataCy="select-psd"
  >
  </app-select>
</div>
