<ng-container *ngIf="completionState.isLoaded">
  <app-page-panel
    class="grid-panel"
    header="Running String"
    (click)="onPipeTypeHelpChange('workstring')"
    [panelHelpMode]="PanelHelpMode.GRID">
    <app-running-string-grid
      #gridComponent
      [runningStringData]="completionState.RunningString"
      (selectedRowsChanged)="onSelectedRowsChanged($event)"
    >
    </app-running-string-grid>
  </app-page-panel>

  <app-page-panel
    class="options-panel"
    [header]="pipeTypeName"
    *ngIf="selectedRow && shouldShowOptionsPanel"
    (click)="setSelectedRow(); onPipeTypeHelpChange()"
  >
    <ng-container [ngSwitch]="selectedRow.rowData.PipeType">
      <app-workstring-properties *ngSwitchCase="PipeType.Workstring" [pipe]="$any(selectedRow)"
                                 (rowUpdated)="updateRow($event)">
      </app-workstring-properties>

      <app-service-tool-properties *ngSwitchCase="PipeType.Service_Tool" [pipe]="$any(selectedRow)"
                                   (rowUpdated)="updateRow($event)">
      </app-service-tool-properties>

      <app-concentric-gauge-carrier-properties
        *ngSwitchCase="PipeType.Concentric_Gauge_Carrier"
        [pipe]="$any(selectedRow)"
        (rowUpdated)="updateRow($event)"
      >
      </app-concentric-gauge-carrier-properties>

      <app-eccentric-gauge-carrier-properties
        *ngSwitchCase="PipeType.Eccentric_Gauge_Carrier"
        [pipe]="$any(selectedRow)"
        (rowUpdated)="updateRow($event)"
      >
      </app-eccentric-gauge-carrier-properties>

      <app-pressure-attenuator-properties
        *ngSwitchCase="PipeType.Pressure_Attenuator"
        [pipe]="$any(selectedRow)"
        (rowUpdated)="updateRow($event)"
      >
      </app-pressure-attenuator-properties>
    </ng-container>
  </app-page-panel>

  <div class="bottom-panel-button-lg">
    <app-page-panel
      header="Washpipe Properties"
      class="washpipe-properties-panel"
      (click)="onHelpChange('completion', 'washpipe-properties')"
    >
      <app-washpipe-properties [completion]="completionState" (propertyChanged)="onCompletionPropertyChanged($event)">
      </app-washpipe-properties>
    </app-page-panel>

    <div class="buttons-panel">
      <div></div>
      <div>
        <app-button
          dataCy="pipe-data"
          (click)="onShowPipeDataClick()"
          [isUiLockable]="false"
          [disabled]="isPipeDataDisabled"
          text="Pipe Data"
        >

        </app-button>
        <app-button
          dataCy="running-string-import"
          (buttonClick)="onImport()"
          [isDeleteResultsEnabled]="false"
          text="Import"
        >

        </app-button>
        <app-button
          dataCy="running-string-insert-btn"
          (buttonClick)="onInsert()"
          [isDeleteResultsEnabled]="false"
          text="Insert"
        >

        </app-button>
        <app-button
          dataCy="running-string-delete-btn"
          (buttonClick)="onDelete()"
          [isDeleteResultsEnabled]="false"
          text="Delete"
        >
        </app-button>
      </div>
    </div>
  </div>
</ng-container>
