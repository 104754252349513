<app-grid
  [dataSource]="tableState"
  [gridConfig]="gridConfig"
  [columns]="gridConfig.columns"
  (selectedRowsChanged)="selectedRowsChanged.emit($event)"
  [showCrudControls]="false"
  [height]="height"
  [isFirstRowInsertingAllowed]="true"
  [isFirstRowDeleteAllowed]="true"
  [minRowCount]="1"
  [headerTopRowHeight]="30"
  dataCy="pumped-fluid-and-gravel-grid"
>
</app-grid>
<div class="buttons-panel">
  <div class="align-right">
    <app-button
      dataCy="pumped-fluid-and-gravel-import"
      (buttonClick)="onImportClick()"
      text="Import"
    >
    </app-button>
    <app-button (buttonClick)="onInsertClick()" text="Insert"></app-button>
    <app-button (buttonClick)="onDeleteClick()" text="Delete"></app-button>
  </div>
</div>
