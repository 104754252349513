<div class="zone-definition-panels" *ngIf="wellState.isLoaded"
     [class.no-loss-definition]="isLossDefinitionAvailable === false">
  <app-page-panel
    header="Depth Definition"
    class="depth-definition-panel"
    (click)="onHelpChange('well-reservoir-data', 'zone-depth-definition')"
  >
    <app-depth-definition
      [state]="wellState"
      *ngIf="developerSettings$ | async as developerSettings"
      [developerSettings]="developerSettings"
      (propertyChanged)="onWellPropertyChanged($event)"
    ></app-depth-definition>
  </app-page-panel>
  <app-page-panel
    header="Pore/Frac Pressure Definition"
    class="pressure-definition-panel"
    (click)="onHelpChange('well-reservoir-data', 'zone-pressure-definition')"
  >
    <app-pressure-definition [state]="wellState"
                             (propertyChanged)="onWellPropertyChanged($event)"></app-pressure-definition>
  </app-page-panel>
  <app-page-panel *ngIf="isLossDefinitionAvailable"
                  header="Loss Definition"
                  class="loss-definition-panel"
                  (click)="onHelpChange('well-reservoir-data', 'zone-loss-definition')"
  >
    <app-loss-definition [state]="wellState" (propertyChanged)="onWellPropertyChanged($event)"></app-loss-definition>
  </app-page-panel>
</div>

<app-page-panel
  header="Reservoir Data"
  (click)="onHelpChange('well-reservoir-data', 'zone-properties')"
  [panelHelpMode]="PanelHelpMode.GRID"
  class="grid-panel"
>
  <app-reservoir-grid
    #gridComponent
    [zoneData]="wellState.ZoneData"
    [zonePressureType]="wellState.ZonePressureType"
    [isZoneDepthByMd]="wellState.IsZoneDepthByMD"
    (selectedRowsChanged)="onSelectedRowsChanged($event)"
  ></app-reservoir-grid>
</app-page-panel>

<app-page-panel
  header="Zone Properties"
  class="options-panel"
  [class.visible]="selectedRow && selectedRow.rowData.Id >= 0"
  *ngIf="isLossDefinitionAvailable && selectedRow"
  (click)="onHelpChange('well-reservoir-data', wellState.IsLossRateCalculated ? 'zone-calculate-losses' : 'zone-specify-losses')"
>
  <app-zone-properties [zoneRow]="selectedRow" [well]="wellState"
                       (rowUpdated)="updateRow($event)"></app-zone-properties>
</app-page-panel>


<div class="buttons-panel">
  <div></div>
  <div>
    <app-button
      dataCy="reservoir-import"
      (buttonClick)="onImport()"
      [isDeleteResultsEnabled]="false"
      text="Import"
    >
    </app-button>
    <app-button (buttonClick)="onInsert()" [isDeleteResultsEnabled]="false" text="Insert"></app-button>
    <app-button (buttonClick)="onDelete()" [isDeleteResultsEnabled]="false" text="Delete"></app-button>
  </div>
</div>

