<app-page-panel class="small-padding">
  <app-searchbar (searchChanged)="searchPhrase$.next($event)"></app-searchbar>
</app-page-panel>
<app-page-panel header="Database Fluids" class="grid-panel" (click)="onHelpChange('fluids', 'fluid-database')">
  <ng-container *ngIf="filteredCommonFluids$ | async as filteredCommonFluids">
    <app-database-fluids-grid
      *ngIf="filteredCommonFluids.rows.length"
      #gridComponent
      [customKeyboardHandlers]="getCustomKeyboardHandlers"
      [commonFluidData]="filteredCommonFluids"
      (selectedRowsChanged)="onSelectedRowChanged($event)"
    ></app-database-fluids-grid>
  </ng-container>
</app-page-panel>

<div class="buttons-panel">
  <div></div>
  <div>
    <app-button
      dataCy="database-fluids-load"
      (buttonClick)="onLoadFromDatabase()"
      [disabled]="!selectedRow"
      [isUiLockable]="false"
      text="Load from Database"
    >
    </app-button>
    <app-button
      dataCy="database-fluids-delete"
      (buttonClick)="onDelete()"
      [disabled]="!selectedRow || selectedRow.rowData.commonDbType === 'Predefined'"
      [isUiLockable]="false"
      text="Delete"
    >
    </app-button>
  </div>

</div>
