<div class="dx-field dropdown-panel">
  <div class="dx-field-label">Units</div>
  <div class="dx-field-value">
    <app-select
      [items]="availableUnitSystems$ | async"
      [value]="currentUnitSystem?.sourceId"
      class="ribbon-select"
      [isUiLockable]="false"
      (primitiveValueChanged)="valueChanged($event)"
      dataCy="unit-system"
      [showSymbol]="false"
    >
    </app-select>
  </div>
</div>
<div class="small-button-panel">
  <app-button
    dataCy="unit-create"
    textIcon="icon-plus-add"
    text="Add"
    class="top-menu-button"
    [class.ribbon-button]="isRibbonMenu"
    [isUiLockable]="false"
    (buttonClick)="createClicked()"
  ></app-button>
  <app-button
    dataCy="unit-edit"
    textIcon="icon-pen"
    text="Edit"
    [class.ribbon-button]="isRibbonMenu"
    class="top-menu-button"
    [isUiLockable]="false"
    (buttonClick)="editClicked()"
    [disabled]="!generalMenuProps.editUnitEnabled"
  ></app-button>
  <app-button
    dataCy="unit-delete"
    textIcon="icon-trashcan"
    text="Delete"
    class="top-menu-button"
    [isUiLockable]="false"
    [class.ribbon-button]="isRibbonMenu"
    (buttonClick)="deleteClicked()"
    [disabled]="!generalMenuProps.deleteUnitEnabled"
  ></app-button>
</div>
