<div class="top-menu-container" [ngClass]="{ pinned: isMenuDisplayed, 'shrink-left': isNavPinned,
      'shrink-right': isHelpPinned }">

  <div class="ribbon-button-row">
    <div class="undo-redo-wrapper">
      <app-button
        class="undo-redo"
        textIcon="icon-undo "
        [disabled]="!generalMenuProps.undoEnabled"
        [dataCy]="'undo-btn'"
        [isDeleteResultsEnabled]="false"
        (buttonClick)="undoClick()"
      ></app-button>
      <app-button
        class="undo-redo"
        textIcon="icon-undo reversed"
        [disabled]="!generalMenuProps.redoEnabled"
        [dataCy]="'redo-btn'"
        [isDeleteResultsEnabled]="false"
        (buttonClick)="redoClick()"
      ></app-button>
    </div>


    <app-button
      class="ribbon-tab-button"
      *ngFor="let tab of tabs"
      [attr.data-cy]="'ribbon-menu-tab-' + tab.id"
      [isUiLockable]="false"
      [ngClass]="{ 'selected-tab': tab.id === openTab && isMenuDisplayed }"
      (click)="changeTab('toolbars', tab.id)"
      [isDisabledWhenCalculationActive]="false"
      [text]="tab.name"
    >
      <!--      <span class="{{ tab.icon }} button-icon"></span>-->
      <!--      <span class="button-text">{{ tab.name }}</span>-->
    </app-button>
    <app-help class="help-button"></app-help>
  </div>
  <div
    #ribbonContent
    [ngClass]="{
      visible: isMenuDisplayed,

    }"
    class="ribbon-content"
  >
    <app-menu-panel *ngIf="openTab === 'general'">
      <app-general-menu-panel class="menu-panel"></app-general-menu-panel>
    </app-menu-panel>
    <app-menu-panel *ngIf="openTab === 'analysis'">
      <app-analysis-menu-panel class="menu-panel"></app-analysis-menu-panel>
    </app-menu-panel>
    <app-menu-panel *ngIf="openTab === 'charts'">
      <app-charts-menu-panel class="menu-panel"></app-charts-menu-panel>
    </app-menu-panel>
    <app-menu-panel *ngIf="openTab === 'view'">
      <app-display-menu-panel class="menu-panel"></app-display-menu-panel>
    </app-menu-panel>
    <app-menu-panel *ngIf="openTab === 'docs'">
      <app-help-menu-panel class="menu-panel"></app-help-menu-panel>
    </app-menu-panel>
  </div>
</div>
