<div class="wrapper" #wrapper *ngIf="isStateLoaded$ | async">

  <ng-container *ngIf="trendAnalysisEnabled$ | async; else pageDisabled">
    <div class="top-panel" (click)="onHelpChange('trend', 'trend-analysis')">
      <app-page-panel header="Trend Analysis Inputs">
        <app-trend-analysis-inputs></app-trend-analysis-inputs>
      </app-page-panel>
    </div>
    <div class="chart-panel" (click)="onHelpChange('results', 'chart')">
      <app-trend-analysis-chart></app-trend-analysis-chart>
    </div>
  </ng-container>
  <ng-template #pageDisabled>
    <div class="centered-message">
      The Trend Analysis screen is not available for the Entire Range - select another range to update the data
    </div>
  </ng-template>
</div>
