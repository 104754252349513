import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { getFluidsSelectData } from '../../../+store/fluid/fluid.selectors';
import { Store } from '@ngrx/store';
import { DataSource, DataSourceKey, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-fluid-selector',
  templateUrl: './fluid-selector.component.html',
  styleUrls: ['./fluid-selector.component.scss'],
})
export class FluidSelectorComponent<T> {
  @Input() public source: DataSource<T> | undefined;
  @Input() public key: DataSourceKey<T> | undefined;

  @Input() public label = 'Select Fluid';
  @Input() public disabled!: boolean;
  @Input() public errorMessage: string | undefined | null = '';
  @Input() public labelWidth: number | undefined;
  @Input() public showSymbol = false;

  @HostBinding('class') public classAttribute = 'grid-form-container no-padding';
  @Input() public isUiLockable = true;
  @Input() public dataCy = '';

  @Output() public valueChanged = new EventEmitter<ObjectChangeProp<T>>();

  public data$ = this.store.select(getFluidsSelectData);

  constructor(private store: Store) {}
}
