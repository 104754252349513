<div class="grid-form-container no-padding">
  <label [class.highlight]="isValueDiffThanDefault('GradientLineStyle')">Line Style</label>
  <app-select
    [source]="lineStyle"
    [sourceDefaults]="defaultLineStyle"
    [key]="'GradientLineStyle'"
    [items]="lineStyles"
    [isUiLockable]="false"
       [disabled]="disabled"
    [highlight]="isValueDiffThanDefault('GradientLineStyle')"
    (valueChanged)="valueChanged.emit($event)"
    dataCy="customize-gradient-line-style"
  ></app-select>

  <label [class.highlight]="isValueDiffThanDefault('GradientLineThickness')">Line
    Thickness</label>
  <app-select
    [source]="lineStyle"
    [sourceDefaults]="defaultLineStyle"
    [key]="'GradientLineThickness'"
    [items]="lineThicknessSelect"
    [isUiLockable]="false"
    [disableSorting]="true"
    [disabled]="disabled"
    [highlight]="isValueDiffThanDefault('GradientLineThickness')"
    (valueChanged)="valueChanged.emit($event)"
    dataCy="customize-gradient-line-thickness"
  ></app-select>

  <label [class.highlight]="isValueDiffThanDefault('GradientLineColor')">Line Color</label>
  <app-select
    [source]="lineStyle"
    [sourceDefaults]="defaultLineStyle"
    [key]="'GradientLineColor'"
    [items]="colors"
    [colorPicker]="true"
    [isUiLockable]="false"
    [disabled]="disabled"
    [highlight]="isValueDiffThanDefault('GradientLineColor')"
    (valueChanged)="valueChanged.emit($event)"
    dataCy="customize-gradient-line-color"
  ></app-select>

  <label [class.highlight]="isValueDiffThanDefault('GradientLineDecimalPlaces')">Decimal Places</label>

  <app-select
    [source]="lineStyle"
    [sourceDefaults]="defaultLineStyle"
    [key]="'GradientLineDecimalPlaces'"
    [items]="decimalPlacesSelect"
    [isUiLockable]="false"
    [disabled]="disabled"
    [highlight]="isValueDiffThanDefault('GradientLineDecimalPlaces')"
    (valueChanged)="valueChanged.emit($event)"
    dataCy="customize-gradient-decimal-places"
  ></app-select>
</div>
