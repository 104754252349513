import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  NgModule,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UnitsModule } from '../../../../common-modules/units/units.module';
import { implementsError } from '@dunefront/common/common/common-grid.interfaces';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { TooltipModule } from 'primeng/tooltip';
import {
  DataSource,
  DataSourceKey,
  DataSourceValue,
  ObjectChangeProp,
  PrimitiveChangeValue,
} from '@dunefront/common/common/common-state.interfaces';
import { FormDataHelper } from '../base-form-component';
import { AutoFocusPosition } from '../../../../common-modules/units/components/input-2/input-2.component';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormInputComponent<T> implements OnChanges {
  @Input() public label!: string;
  @Input() public labelWidth: number | undefined;
  @Input() public name: string | undefined;
  @Input() public unitType!: UnitSystem;
  @Input() public source: DataSource<T> | undefined;
  @Input() public sourceDefaults: DataSource<Partial<T>> | undefined;
  @Input() public key: DataSourceKey<T> | undefined;
  @Input() public unitLabel?: string;
  @Input() public disabled = false;
  @Input() public highlight = false;
  @Input() public hidden = false;
  @Input() public decimalPlaces = 2;
  @Input() public readOnlyValue?: number | string | null;
  @Input() public isTextInput = false;
  @Input() public inputClass?: string | undefined;
  @Input() public maxWidth?: number;
  @Input() public minWidth?: number;
  @Input() public width?: number;
  @Input() public showSymbol = true;
  @Input() public forceShowSymbol = false;
  @Input() public cssClass = '';
  @Input() public labelPaddingLeft = false;
  @Input() public isStringComparisonStrict = false;
  @Input() public min: number | undefined;
  @Input() public max: number | undefined;
  @Input() public offset: number | undefined;
  @Input() public isUiLockable = true;
  @Input() public dataCy = '';
  @Input() public autoFocus: boolean | AutoFocusPosition = false;
  @Input() public isFullWidth = false;
  @Input() public triggerOnKeyPress = false;
  @Input() public trim = false;

  @Input() public tooltipText: string | undefined;
  @Input() public overrideErrorMessage: string | undefined;

  @Output() public valueChanged = new EventEmitter<ObjectChangeProp<T>>();
  @Output() public primitiveValueChanged = new EventEmitter<PrimitiveChangeValue<DataSourceValue<T>>>();
  public currValue = 0;

  constructor(private cd: ChangeDetectorRef) {}

  @HostBinding('class.no-label')
  public get noLabel(): boolean {
    return !this.label;
  }

  public get inputCssClass(): string[] {
    return ['input', this.cssClass];
  }

  public get value(): any {
    if (this.readOnlyValue != null) {
      return this.readOnlyValue;
    }

    if (this.source != null && this.key != null) {
      return FormDataHelper.getSourceValue(this.source, this.key) ?? '';
    }
    return '';
  }

  public get errorMessage(): string {
    if (this.overrideErrorMessage) {
      return this.overrideErrorMessage;
    }

    if (this.key == null || this.source == null) {
      return '';
    }

    return !this.disabled && implementsError(this.source) ? (this.source.error[this.key] ?? '') : '';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.updateCurrentValue(changes);
  }

  private updateCurrentValue(changes: SimpleChanges): void {
    // this is a workaround for updating value for input component. Without this, in some cases, our input component
    // will not trigger change detection, and will display wrong value ( see PC-3421 )
    if (
      ((this.source != null && this.currValue !== this.value) ||
        changes.source?.currentValue !== changes.source?.previousValue ||
        this.readOnlyValue != null) &&
      !isNaN(this.value)
    ) {
      this.currValue = Number.NEGATIVE_INFINITY;
      this.cd.detectChanges();
      this.currValue = this.value;
      this.cd.markForCheck();
    } else {
      this.currValue = this.value;
    }
  }

  public get tooltipStyle(): string {
    return this.errorMessage != '' ? 'error-tooltip' : this.highlight ? 'warning-tooltip' : 'regular-tooltip';
  }
}

@NgModule({
  imports: [CommonModule, FormsModule, UnitsModule, TooltipModule, DropdownModule],
  declarations: [FormInputComponent],
  exports: [FormInputComponent],
})
export class FormInputModule {}
