<app-fluid-header [isReadOnly]="true" (click)="onHelpChange('fluids', 'fluid-advanced-properties')"></app-fluid-header>
<app-page-panel header="Advanced Fluid Properties" *ngIf="fluid"
                (click)="onHelpChange('fluids', 'fluid-advanced-properties')">
  <div class="grid-form-container properties-container">
    <app-form-input
      [source]="fluid"
      [key]="'ThermalConductivity'"
      class="grid-two-one"
      appUnwrap
      label="Thermal Conductivity"
      dataCy="fluid-thermal-conductivity"

      [unitType]="UnitType.Thermal_Conductivity"
      (valueChanged)="emitValue($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="fluid"
      [key]="'SpecificHeat'"
      class="grid-two-one"
      appUnwrap
      label="Specific Heat Capacity"
      dataCy="fluid-specific-heat-capacity"

      [unitType]="UnitType.Specific_Heat_Capacity"
      (valueChanged)="emitValue($event)"
    >
    </app-form-input>
  </div>

  <div class="grid-form-container viscosity">
    <app-check-box
      [source]="fluid"
      [key]="'IsMinViscosity'"
      [disabled]="fluid.Type === FluidType.Newtonian"
      label="Min Viscosity"
      dataCy="fluid-is-min-visc"
      (valueChanged)="emitValue($event)"
    >
    </app-check-box>
    <app-form-input
      [source]="fluid"
      [key]="'MinViscosity'"
      [disabled]="!fluid.IsMinViscosity"
      [decimalPlaces]="4"

      dataCy="fluid-min-visc"
      [unitType]="UnitType.Viscosity"
      (valueChanged)="emitValue($event)"
    >
    </app-form-input>
  </div>
  <div class="grid-form-container viscosity">
    <app-check-box
      [source]="fluid"
      [key]="'IsMaxViscosity'"
      [disabled]="fluid.Type === FluidType.Newtonian"
      label="Max Viscosity"
      dataCy="fluid-is-max-visc"
      (valueChanged)="emitValue($event)"
    >
    </app-check-box>
    <app-form-input
      [source]="fluid"
      [disabled]="!fluid.IsMaxViscosity"
      [key]="'MaxViscosity'"

      [decimalPlaces]="4"
      dataCy="fluid-max-visc"
      [unitType]="UnitType.Viscosity"
      (valueChanged)="emitValue($event)"
    >
    </app-form-input>
  </div>

  <div class="grid-form-container viscous-degradation">Viscous Fluid Degradation Factor</div>
  <div class="grid-form-container">
    <app-form-input
      [source]="fluid"
      [key]="'WashpipeViscousDegradationFactor'"
      appUnwrap
      [labelPaddingLeft]="true"
      label="Washpipe (Internal)"
      [decimalPlaces]="4"

      [unitType]="UnitType.None"
      [disabled]="fluid.Type === FluidType.Newtonian"
      (valueChanged)="emitValue($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="fluid"
      [key]="'AnnulusViscousDegradationFactor'"
      appUnwrap
      [labelPaddingLeft]="true"
      label="Annulus (Above Packer)"
      [decimalPlaces]="4"
      [unitType]="UnitType.None"

      [disabled]="fluid.Type === FluidType.Newtonian"
      (valueChanged)="emitValue($event)"
    >
    </app-form-input>
  </div>
</app-page-panel>
