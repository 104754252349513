import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { LicensingModuleState } from '@dunefront/common/modules/licensing/licensing-module.state';
import {
  Feature,
  IGetCurrentFeaturesResult,
  isAddonFeature,
  LicenseFeature,
  LicenseInfo,
  LoginInfo,
} from '@dunefront/common/modules/licensing/licensing.interfaces';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { intersection, uniq } from 'lodash';
import { getAboutServer } from '../about/about.selectors';
import { AboutModuleState } from '../about/about.reducer';

export const getLicensingState = createFeatureSelector<LicensingModuleState>('licensing');

export const getLicenseInfo = createSelector(getLicensingState, (state) => state.licenseInfo);

/**
 * Returns selector which returns true if any of products with proper appName has at least one usable feature. Looks only for
 * 'standalone' (non addon) features.
 */

export const getIsAnyFeatureUsableFactory = (
  appName: string,
): MemoizedSelector<object, boolean, (s1: LicenseInfo, s2: AboutModuleState) => boolean> => {
  return createSelector(getLicenseInfo, getAboutServer, (state, aboutServer) => {
    const isTestVersion = aboutServer.serverInfo?.isTestEnv ?? false;

    for (const product of state.products) {
      if (
        (isTestVersion && product.name.includes('Test')) ||
        (product.name.includes(appName) &&
          product.productDetails.features.some((feature) => isFeatureUsable(feature) && !isAddonFeature(feature.id)))
      ) {
        return true;
      }
    }

    return false;
  });
};

/**
 * Returns all features that can be selected by user - only from products that contain selected addons
 */
export const findAccessibleFeaturesAndLicenses = (
  state: LicensingModuleState,
): {
  features: LicenseFeature[];
  licenseIds: string[];
} => {
  if (!state.isLoaded) {
    return { features: [], licenseIds: [] };
  }
  const selectedAddons = state.selectedAddons;
  if (selectedAddons.length === 0) {
    // no addons selected - return all features;
    return { features: state.licenseInfo.features, licenseIds: state.licenseInfo.licenseIds };
  }

  const features: LicenseFeature[] = [];
  const licenseIds: string[] = [];
  for (const product of state.licenseInfo.products) {
    const productFeatureIds = product.productDetails.features.map((f) => f.id);
    if (intersection(productFeatureIds, selectedAddons).length === selectedAddons.length) {
      features.push(...productFeatureIds);
      licenseIds.push(product.haspId);
    }
  }

  return { features: uniq(features), licenseIds: uniq(licenseIds) };
};
export const getAccessibleFeaturesAndLicenses = createSelector(getLicensingState, (state) => findAccessibleFeaturesAndLicenses(state));
export const getLicensingProducts = createSelector(getLicenseInfo, (licenseInfo) => licenseInfo.products);
export const getLicensingSelectedAddons = createSelector(getLicensingState, (state) => state.selectedAddons);
export const getAccessibleFeaturesText = createSelector(getAccessibleFeaturesAndLicenses, (featuresWithLicenseIds) =>
  featuresWithLicenseIds.features.map((feature) => LicenseFeature[feature]),
);
export const getLicensingLogin = createSelector(getLicensingState, (state) => state.licensingLogin);

export const getMainFeatureLoginInfo = createSelector(getLicensingLogin, (licensingLogin) =>
  licensingLogin?.loginInfos.find((feature) => feature.licenseFeature === licensingLogin?.mainFeature),
);

export const getCurrentLicensingProduct = createSelector(getMainFeatureLoginInfo, getLicensingProducts, (loginInfo, products) => {
  return loginInfo == null ? undefined : products.find((p) => p.id === loginInfo.productId && p.haspId === loginInfo.licenseId);
});

export const getSelectedAddonFeatures = createSelector(getLicensingState, (state) => state.selectedAddons);

export const getLicenseExpirationWarning = createSelector(getMainFeatureLoginInfo, (mainFeature) => mainFeature?.expirationWarning);

export const getLoggedInFeaturesStatus = createSelector(getLicensingLogin, (licensingLogin) =>
  licensingLogin === undefined ? [] : licensingLogin.loginInfos,
);

export const getCurrentFeatures = createSelector(
  getCurrentAppModuleType,
  getLoggedInFeaturesStatus,
  getSelectedAddonFeatures,
  (moduleType, loggedInFeatures, addonFeatures) => {
    const getFeatureFromModuleType = (): LicenseFeature | undefined => {
      switch (moduleType) {
        case ModuleType.Simulate:
          return LicenseFeature.Simulate;
        case ModuleType.Evaluate:
          return LicenseFeature.Evaluate;
        case ModuleType.Trend_Analysis:
          return LicenseFeature.Trend_Analysis;
        case ModuleType.PSD_Analysis_Full:
        case ModuleType.PSD_Analysis_Basic:
          return LicenseFeature.PSD_Analysis;
        case ModuleType.Data_Analysis:
          return LicenseFeature.Data_Analysis;
        case ModuleType.Friction_Calc:
        case ModuleType.Settling_Calc:
        case ModuleType.Resuspension_Calc:
        case ModuleType.MASP_Calc:
        case ModuleType.Injection_Test_Calc:
        case ModuleType.Leakoff_Coefficient_Calc:
          return LicenseFeature.Calculators;
        case ModuleType.Simulate_CH:
          return LicenseFeature.Simulate_CH;
        case ModuleType.Simulate_Disp:
          return LicenseFeature.Simulate_Disp;
        case ModuleType.Evaluate_Disp:
          return LicenseFeature.Evaluate_Disp;
        default:
          return undefined;
      }
    };
    const mainFeature = getFeatureFromModuleType();
    const features: IGetCurrentFeaturesResult = {
      accessibleFeatures: loggedInFeatures.map((f) => f.licenseFeature),
      mainFeature,
      addonFeatures,
    };
    return features;
  },
);

export interface IFeatureInfo {
  feature: LicenseFeature;
  name: string;
  loginInfo?: LoginInfo;
  isMainFeature: boolean;
}

/*
 * Feature is usable when is neither disabled not expired
 */
const isFeatureUsable = (feature: Feature): boolean => !feature.disabled && !feature.expired;
