import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { defaultModalCancelButton, ModalButtonConfig } from '../generic-modal/generic-modal.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppTargetConfig } from '../../../shared/services/app-target-config';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { getBackendToClientConfig } from '@dunefront/common/backend-to-client-config';
import { AssetService } from '../../../shared/services/asset.service';

@Component({
  selector: 'app-release-notes',
  template: ` <app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
    <p *ngIf="isTestMode" class="error">This is test version! Release notes might be not up to date.</p>

    <ng-container *ngIf="!showIFrame">
      <div *ngIf="latestReleaseContent" class="release-notes-wrapper" [innerHTML]="latestReleaseContent"></div>
    </ng-container>

    <ng-container *ngIf="showIFrame">
      <iframe *ngIf="getIFrameURL() | async as iFrameURL" style="width: 100%; height: 500px" [src]="iFrameURL"></iframe>
    </ng-container>
  </app-generic-modal>`,
  styleUrls: ['release-notes-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReleaseNotesModalComponent {
  public modalTitle = 'Release Notes';
  public modalButtonsConfigs: ModalButtonConfig[] = [defaultModalCancelButton((): void => this.ref.close(), 'Close')];
  public latestReleaseContent?: string;
  public showIFrame = false;
  public isTestMode = getBackendToClientConfig().IsRunningInTestMode;

  constructor(
    public ref: DynamicDialogRef,
    private cdRef: ChangeDetectorRef,
    private appConfig: AppTargetConfig,
    private domSanitizer: DomSanitizer,
    private assetsService: AssetService,
  ) {
    this.prepareReleaseNotesContent().then();
  }

  public async prepareReleaseNotesContent(): Promise<void> {
    const assetUrl = await this.assetsService.getAssetUrl(`assets/${this.appConfig.documentationFolder}release-notes.html`);
    const releaseNotesDocument = await fetch(assetUrl);

    const releaseNotesHTML = document.createElement('html');
    releaseNotesHTML.innerHTML = await releaseNotesDocument.text();
    const latestRelease = releaseNotesHTML.querySelector('.release');
    if (latestRelease == null) {
      this.showIFrame = true;
      return;
    }

    this.latestReleaseContent = latestRelease.innerHTML;
    this.cdRef.markForCheck();
  }

  public async getIFrameURL(): Promise<SafeResourceUrl> {
    const assetUrl = await this.assetsService.getAssetUrl(`assets/${this.appConfig.documentationFolder}release-notes.html`);

    return this.domSanitizer.bypassSecurityTrustResourceUrl(assetUrl);
  }
}
