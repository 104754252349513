import { BackendConnectionModuleState } from './backend-connection.reducer';
import { IDbInfo } from '@dunefront/common/modules/db-connection/db-connection.actions';
import { MigrationStatus } from '@dunefront/common/modules/db-migration/db-migration-module.actions';

export class BackendConnectionReducerHelper {
  public static dbClearMigrationRequired(state: BackendConnectionModuleState, status: MigrationStatus): BackendConnectionModuleState {
    if (status !== 'migrationComplete') {
      return state;
    }
    if (state.dbInfo) {
      const dbInfo: IDbInfo = { ...state.dbInfo, dbVersionStatus: 'ok' };
      return { ...state, dbInfo };
    } else {
      return { ...state };
    }
  }
}
