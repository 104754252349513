import { LoadSettingsActionResponse } from '../settings-module.actions';
import { SettingsModuleState } from '../settings-module.state';
import { IEngineOptions, SettingsDto } from '../dto/settingsDto';

export class SettingsFactory {
  public static create(payload: LoadSettingsActionResponse): SettingsModuleState {
    return {
      settings: { ...payload.settingsDto, isLoaded: true },
      developerSettings: { ...payload.developerSettingsDto, isLoaded: true },
      developerSettingsDefaults: payload.developerSettingsDefaults,
      defaults: payload.settingsDefaults,
      wellVisualizationType: undefined,
    };
  }

  public static createSettingsFromDefaults(defaults: IEngineOptions, scenarioId: number): SettingsDto {
    const { settings } = defaults;
    return {
      ScenarioId: scenarioId,
      StokesSettlingMultiplier: settings.StokesSettlingMultiplier,
      SimulationAnimationWellLayout: settings.SimulationAnimationWellLayout,
      WellVisualizationWellLayout: settings.WellVisualizationWellLayout,
      WellVisualizationWellPart: settings.WellVisualizationWellPart,
      AreLossesRedistributed: settings.AreLossesRedistributed,
      BlankAnnulusRoughness: settings.Roughness.BlankAnnulusRoughness,
      BottomholeGravelConcMD: settings.BottomholeGravelConcMD,
      BridgingRatio: settings.BridgingRatio,
      CementThermalConductivity: settings.CementThermalConductivity,
      CementThickness: settings.CementThickness,
      ChokeLineRoughness: settings.Roughness.ChokeLineRoughness,
      DischargeCoefficient: settings.DischargeCoefficient,
      DoesPackCureLosses: settings.DoesPackCureLosses,
      FilterCakeThermalConductivity: settings.FilterCakeThermalConductivity,
      FilterCakeThickness: settings.FilterCakeThickness,
      GravelConcentrationChangeTolerance: settings.GravelConcentrationChangeTolerance,
      ChokePressureChangeTolerance: settings.ChokePressureChangeTolerance,
      HorizontalThermalMultiplier: settings.HorizontalThermalMultiplier,
      ICDRateDistributionMinTolerance: settings.ICDRateDistributionMinTolerance,
      IsBottomholeGravelConcMD: settings.IsBottomholeGravelConcMD,
      IsDiameterValidationDisabled: settings.IsDiameterValidationDisabled,
      IsFrictionEccentric: settings.IsFrictionEccentric,
      IsNozzleLeakoffBeta: settings.IsNozzleLeakoffBeta,
      IsNozzleLeakoffConvergence: settings.IsNozzleLeakoffConvergence,
      IsRopingPercentage: settings.IsRopingPercentage,
      IsRoughness: settings.Roughness.IsRoughness,
      IsThirdAnnulusAnalysed: settings.IsThirdAnnulusAnalysed,
      IsToolJointAnalysed: settings.IsToolJointAnalysed,
      MaxNumberOfReservoirCells: settings.MaxNumberOfReservoirCells,
      MaxThermalCellLength: settings.MaxThermalCellLength,
      MaxVolumeStep: settings.MaxVolumeStep,
      MinDeviationForHorizontal: settings.MinDeviationForHorizontal,
      MinThermalCellLength: settings.MinThermalCellLength,
      ModelScreenHandlingLength: settings.ModelScreenHandlingLength,
      NetPressureForBridging: settings.NetPressureForBridging,
      NozzleLeakoffBeta: settings.NozzleLeakoffBeta,
      NumberOfGrainDiametersToBridge: settings.NumberOfGrainDiametersToBridge,
      OroskarAndTurianDiameter: settings.OroskarAndTurianDiameter,
      PerfAutoPackPercentage: settings.PerfAutoPackPercentage,
      PermeabilityRatio: settings.PermeabilityRatio,
      PipeThermalConductivity: settings.PipeThermalConductivity,
      RateChangeTolerance: settings.RateChangeTolerance,
      RopingPercentage: settings.RopingPercentage,
      RopingVolume: settings.RopingVolume,
      ScreenAnnulusRoughness: settings.Roughness.ScreenAnnulusRoughness,
      ScreenThermalConductivity: settings.ScreenThermalConductivity,
      ShearRate: settings.ShearRate,
      ThirdAnnulusRoughness: settings.Roughness.ThirdAnnulusRoughness,
      TreatingLineRoughness: settings.Roughness.TreatingLineRoughness,
      UseNumberOfGrainDiametersForBridging: settings.UseNumberOfGrainDiametersForBridging,
      UseShuntVESFrictionCorrelations: settings.UseShuntVESFrictionCorrelations,
      WashpipeAnnulusRoughness: settings.Roughness.WashpipeAnnulusRoughness,
      WashpipeGaugeMeasurementFlowPath: settings.WashpipeGaugeMeasurementFlowPath,
      WashpipeRoughness: settings.Roughness.WashpipeRoughness,
      WorkstringAnnulusRoughness: settings.Roughness.WorkstringAnnulusRoughness,
      WorkstringRoughness: settings.Roughness.WorkstringRoughness,

      FpNumParamsNz: settings.FpNumParamsNz,
      FpNumParamsNtheta: settings.FpNumParamsNtheta,
      FpNumParamsCFL: settings.FpNumParamsCFL,
      FpNumParamsTolDP: settings.FpNumParamsTolDP,
      FpNumParamsTimeout: settings.FpNumParamsTimeout,
      FpOptionsIshade: settings.FpOptionsIshade,
      FpOptionsContentrationTolerance: settings.FpOptionsContentrationTolerance,
      FpOptionsFlowRegimeMethod: settings.FpOptionsFlowRegimeMethod,
      FpOptionsKinertialValue: settings.FpOptionsKinertialValue,
      FpOptionsKinertialHandlingMethod: settings.FpOptionsKinertialHandlingMethod,
      FpOptionsDDF: settings.FpOptionsDDF,
      FpOptionsAnnularDragReductionCalculationMethod: settings.FpOptionsAnnularDragReductionCalculationMethod,
      FpPipeToAnnulusSectionTransition: settings.FpPipeToAnnulusSectionTransition,

      FpMinCellLength: settings.FpMinCellLength,
      FpMergeCellLength: settings.FpMergeCellLength,
      FpMergeVolumeRatio: settings.FpMergeVolumeRatio,
      FpMinComputationalSections: settings.FpMinComputationalSections,
      FpIsMergeDifferentTubulars: settings.FpIsMergeDifferentTubulars,
      FpDataComparisonTolerance: settings.FpDataComparisonTolerance,
    };
  }
}
