<app-page-panel>
  <div class="grid-form-container cols-1">
    <div class="app-logo">
      <span class="icon-logo icon-white"></span>
      {{ appName }}
    </div>
    <div class="info-panel" [innerHtml]="getHandledBackendConnectionErrorMessage(errorMessage, appName)"></div>
    <div class="button-panel">
      <app-button
        *ngIf="isEmailVerificationErrorMessage"
        [isUiLockable]="false"
        (click)="resendVerificationEmail()"
        text="Resend verification email"
      >
      </app-button>
      <app-button [isUiLockable]="false" (click)="logoutClick()" text="Logout"></app-button>
    </div>
  </div>
</app-page-panel>
