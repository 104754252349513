<app-page-panel header="General" *ngIf="settings && defaultSettings" [allowFullScreen]="false">
  <div class="grid-form-container">
    <ng-container *hideOnFluidPro>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Vertical to Horizontal Perm Ratio"
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'PermeabilityRatio'"
        [decimalPlaces]="4"
        [unitType]="UnitType.None"

        [highlight]="settings.PermeabilityRatio !== defaultSettings.PermeabilityRatio"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-check-box
        class="grid-two-one"
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'IsBottomholeGravelConcMD'"
        [highlight]="settings.IsBottomholeGravelConcMD !== defaultSettings.IsBottomholeGravelConcMD"
        label="Specify Bottomhole Gravel Conc MD"
        (valueChanged)="onValueChange($event)"
      >
      </app-check-box>
      <app-form-input
        class="grid-two-one"
        [labelPaddingLeft]="true"
        appUnwrap
        label="Bottomhole Gravel Conc MD"
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'BottomholeGravelConcMD'"
        [decimalPlaces]="4"

        [unitType]="UnitType.Long_Length"
        [disabled]="!settings.IsBottomholeGravelConcMD"
        [highlight]="settings.IsBottomholeGravelConcMD && settings.BottomholeGravelConcMD !== defaultSettings.BottomholeGravelConcMD"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <div class="grid-two-one">Workstring Roping</div>
      <div class="label-padding-left">Roping Definition</div>
      <app-radio-group
        [items]="[
        { value: false, text: 'Volume' },
        { value: true, text: 'Percentage' }
      ]"
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'IsRopingPercentage'"
        id="IsRopingPercentage"
        [highlight]="settings.IsRopingPercentage !== defaultSettings.IsRopingPercentage"
        (valueChanged)="onValueChange($event)"
      >
      </app-radio-group>
      <app-form-input
        *ngIf="settings.IsRopingPercentage"
        class="grid-two-one percentage"
        label="Roping Percentage"
        [labelPaddingLeft]="true"
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'RopingPercentage'"
        [decimalPlaces]="4"
        [unitType]="UnitType.None"
        unitLabel="%"

        dataCy="options-roping-percentage"
        [highlight]="settings.RopingPercentage !== defaultSettings.RopingPercentage"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        *ngIf="!settings.IsRopingPercentage"
        class="grid-two-one volume"
        label="Roping Volume"
        [labelPaddingLeft]="true"
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'RopingVolume'"
        [decimalPlaces]="4"

        [unitType]="UnitType.Liquid_Volume"
        [highlight]="settings.RopingVolume !== defaultSettings.RopingVolume"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
    </ng-container>
    <app-check-box
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'IsDiameterValidationDisabled'"
      class="grid-two-one"
      appUnwrap
      label="Disable Diameter Validation"
      [highlight]="settings.IsDiameterValidationDisabled !== defaultSettings.IsDiameterValidationDisabled"
      (valueChanged)="onValueChange($event)"
    >
    </app-check-box>
  </div>
</app-page-panel>
