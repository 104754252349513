<div class="project-options-grid" *ngIf="developerSettings.isLoaded">
  <app-general-dev-options
    [developerSettings]="developerSettings"
    [defaultOptions]="defaultOptions"
    (developerSettingsValueChange)="submitOption($event)"

  >
  </app-general-dev-options>

  <app-debug-dev-options></app-debug-dev-options>

  <!--    Todo: work in progress - button disabled-->
  <div class="w-100 d-flex flex-row justify-content-end">
    <app-button (buttonClick)="resetDefault()" [disabled]="true" text="Reset Developer Options">
    </app-button>
  </div>
</div>
