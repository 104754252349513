import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { AssetService } from '../services/asset.service';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'helpUrl',
})
export class HelpUrlPipe implements PipeTransform {
  constructor(
    protected sanitizer: DomSanitizer,
    private assetsService: AssetService,
  ) {}

  public transform(value: any): Observable<SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl> {
    return from(this.assetsService.getAssetUrl(value)).pipe(map((url) => this.sanitizer.bypassSecurityTrustResourceUrl(url)));
  }
}

@NgModule({
  declarations: [HelpUrlPipe],
  exports: [HelpUrlPipe],
})
export class HelpUrlPipeModule {}
