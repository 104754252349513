<app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
  <div class="grid-panel">
    <app-grid
      *ngIf="height > 0"
      [dataSource]="icdPortData"
      [gridConfig]="gridConfig"
      [columns]="gridConfig.columns"
      (selectedRowsChanged)="selectedRowsChanged.emit($event)"
      [showCrudControls]="false"
      [height]="height"
      [isFirstRowDeleteAllowed]="true"
    ></app-grid>
  </div>
  <div class="search">
    <app-form-input
      label="Port Length"
      *ngIf="firstRow"
      [source]="firstRow"
      [key]="'PortLength'"
      [unitType]="UnitType.Short_Length"
      (valueChanged)="onPortLengthChange($event)"
      dataCy="input-port-length"
    >
    </app-form-input>
  </div>
</app-generic-modal>
