import {
  CalculationEngineMessageType,
  SimulateEvaluateCompleteAdditionalData,
} from '@dunefront/common/modules/calculation-engine/calculation-engine.interfaces';
import {
  CalculationEngineState,
  CalculationStatus,
  CurrentFileCalculationEngineState,
  getEmptyFileCalculationEngineState,
  ICalculationJob,
} from './calculation-engine-module.state';
import { ICalculationEngineUpdatePayload, UserJobs } from '@dunefront/common/modules/calculation-engine/calculation-engine.actions';
import { DbConnectionResponse } from '@dunefront/common/modules/db-connection/db-connection.actions';
import { DeleteResultsFilter, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { CalculationResultsInfoDto } from '@dunefront/common/modules/calculation-engine/dto/calculation-results-info.dto';
import { findResultInfoFilter } from './calculation-engine-results.selectors';
import { uniq } from 'lodash';
import { CrudResponse } from '@dunefront/common/modules/common.actions';

export class CalculationEngineReducerHelper {
  public static insertRowsSuccess(state: CalculationEngineState, action: CrudResponse): CalculationEngineState {
    const userJobs = action.affectedRows.userJobs;
    if (userJobs == null) {
      return state;
    }

    return this.userJobsUpdated({ ...state }, userJobs);
  }

  public static onDbConnectedAction(
    state: CalculationEngineState,
    response: { payload: DbConnectionResponse; scenarioId: number },
  ): CalculationEngineState {
    const dbInfo = response.payload.dbConnectionResult?.dbInfo;
    if (dbInfo == null) {
      return state;
    }

    const projectFileInfo = response.payload.dbConnectionResult?.projectFileInfo;
    if (projectFileInfo == null) {
      return state;
    }

    const fileHash = dbInfo.fileHash;
    const currentFileState = this.getCurrentFileState(state, fileHash);

    // we should always replace all results infos for current file
    const resultsInfos = this.upsertResultsInfosIfNeeded([], projectFileInfo.resultsInfos);

    return {
      ...state,
      fileHash: {
        ...state.fileHash,
        [fileHash]: { ...currentFileState, resultsInfos },
      },
    };
  }

  public static userJobsUpdated(
    state: CalculationEngineState,
    { calcEngineJobs, status: redisConnectionStatus }: UserJobs,
  ): CalculationEngineState {
    const { waitingJobs, activeJobs } = calcEngineJobs;
    const allJobs = [...waitingJobs, ...activeJobs];
    const uniqueFileHashes = uniq(allJobs.map((job) => job.data.databaseFileHash));
    let updatedState = state;

    for (const fileHash of uniqueFileHashes) {
      const currentFileState = this.getCurrentFileState(state, fileHash);
      const fileRemoteJobs = allJobs.filter((jobInfo) => jobInfo.data.databaseFileHash === fileHash);

      // map remote jobs ICalcEngineJobInfo => ICalculationJob
      const jobs: ICalculationJob[] = fileRemoteJobs.map((remoteJob) => ({
        jobId: remoteJob.id,
        scenarioId: remoteJob.data.scenarioId,
        rangeId: remoteJob.data.rangeId,
        fileHash: remoteJob.data.databaseFileHash,
        status: activeJobs.includes(remoteJob) ? CalculationStatus.running : CalculationStatus.waiting,
        dbFile: remoteJob.data.dbFile,
        moduleType: remoteJob.data.calculationType,
        positionInRemoteQueue: remoteJob.positionInQueue,
      }));

      // create new result infos
      const newResultInfos: CalculationResultsInfoDto[] = jobs.map((job) => ({
        scenarioId: job.scenarioId,
        moduleType: job.moduleType,
        rangeId: job.rangeId,
        isCompleted: false,
        fileHash: job.fileHash,
        hasCalcEngineGeneratedRunningString: false,
        hasCalcEngineGeneratedSchedule: false,
      }));

      // update results infos
      const resultsInfos = this.upsertResultsInfosIfNeeded(currentFileState.resultsInfos, newResultInfos);

      // update state for current file
      updatedState = {
        ...updatedState,
        fileHash: {
          ...updatedState.fileHash,
          [fileHash]: { ...currentFileState, jobs, resultsInfos },
        },
      };
    }

    // remove old jobs present in local files, but not present in calcEngineJobs anymore
    // updates only files not present in uniqueFileHashes
    for (const fileHash in state.fileHash) {
      if (!uniqueFileHashes.includes(fileHash)) {
        const fileState = this.getCurrentFileState(state, fileHash);
        updatedState = {
          ...updatedState,
          fileHash: {
            ...updatedState.fileHash,
            [fileHash]: { ...fileState, jobs: [] },
          },
        };
      }
    }

    return { ...updatedState, redisConnectionStatus };
  }

  public static onDeleteResultsSuccessAction(
    state: CalculationEngineState,
    fileHash: string,
    deleteResultsFilter: DeleteResultsFilter,
  ): CalculationEngineState {
    const currentFileState = this.getCurrentFileState(state, fileHash);
    const { scenarioIds, moduleTypes, rangeIds } = deleteResultsFilter;

    // resultInfos will be removed when their scenarioId, rangeId and moduleType match deleteResultsFilter.
    // If filter doesn't contain scenarioIds, rangeIds or moduleTypes it means all values should be deleted.
    return {
      ...state,
      fileHash: {
        ...state.fileHash,
        [fileHash]: {
          ...currentFileState,
          resultsInfos: currentFileState.resultsInfos.filter(
            (result) =>
              !(
                (scenarioIds == null || scenarioIds.includes(result.scenarioId)) &&
                (rangeIds == null || rangeIds.includes(result.rangeId)) &&
                (moduleTypes == null || moduleTypes.includes(result.moduleType))
              ),
          ),
        },
      },
    };
  }

  public static updateJob(
    state: CalculationEngineState,
    calcEngineUpdatePayload: ICalculationEngineUpdatePayload,
    fileHash: string,
  ): CalculationEngineState {
    const { jobId, messageType, calcEngineMessage } = calcEngineUpdatePayload;
    const currentFileState = this.getCurrentFileState(state, fileHash);
    const job = currentFileState.jobs.find((job) => job.jobId === jobId);
    if (!job) {
      console.warn('Missing jobId ', jobId);
      return state;
    }

    let reportMessage = '';
    let resultsInfos = currentFileState.resultsInfos;
    let status: CalculationStatus = job.status;
    let progress: undefined | number;
    let updateState = false;

    switch (messageType) {
      case CalculationEngineMessageType.dataUpdate:
        status = job.status === CalculationStatus.waiting ? CalculationStatus.running : job.status;
        progress = calcEngineMessage.progress;
        if (status !== job.status || progress !== job.progress) {
          updateState = true;
        }
        break;

      case CalculationEngineMessageType.complete: {
        status = job.status === CalculationStatus.canceling ? CalculationStatus.canceled : CalculationStatus.completed;
        reportMessage = calcEngineMessage.data?.reportMessage ?? '';

        const additionalData = calcEngineMessage.data?.additionalData as SimulateEvaluateCompleteAdditionalData | undefined;

        resultsInfos = this.setResultInfoCompletedStatus(resultsInfos, job.scenarioId, job.moduleType, job.rangeId, true, additionalData);
        updateState = true;

        break;
      }

      case CalculationEngineMessageType.error:
        status = CalculationStatus.failed;
        updateState = true;
        break;

      case CalculationEngineMessageType.ping:
        return state;
    }

    if (!updateState) {
      return state;
    }

    const jobs = this.upsertJob(currentFileState.jobs, {
      ...job,
      status,
      progress,
    });

    return {
      ...state,
      fileHash: {
        ...state.fileHash,
        [fileHash]: {
          ...currentFileState,
          jobs,
          resultsInfos,
          lastCalculationJobReport: reportMessage ? reportMessage : currentFileState.lastCalculationJobReport,
        },
      },
    };
  }

  // region Private helpers

  private static getCurrentFileState(state: CalculationEngineState, fileHash: string): CurrentFileCalculationEngineState {
    let currentFileState = state.fileHash[fileHash];
    if (currentFileState === undefined) {
      currentFileState = getEmptyFileCalculationEngineState();
    }
    return currentFileState;
  }

  private static upsertResultsInfosIfNeeded(
    existingResultInfos: CalculationResultsInfoDto[],
    newResultInfos: CalculationResultsInfoDto[],
  ): CalculationResultsInfoDto[] {
    const resInfos = [...existingResultInfos];

    for (const newResultInfo of newResultInfos) {
      const foundIndex = resInfos.findIndex((info) =>
        findResultInfoFilter(info, newResultInfo.scenarioId, newResultInfo.moduleType, newResultInfo.rangeId),
      );

      // results info doesn't exist
      if (foundIndex === -1) {
        resInfos.push(newResultInfo);
        continue;
      }

      // don't update if same completion status
      if (resInfos[foundIndex].isCompleted === newResultInfo.isCompleted) {
        continue;
      }

      resInfos[foundIndex] = {
        ...resInfos[foundIndex],
        isCompleted: newResultInfo.isCompleted,
        hasCalcEngineGeneratedRunningString: newResultInfo.hasCalcEngineGeneratedRunningString,
        hasCalcEngineGeneratedSchedule: newResultInfo.hasCalcEngineGeneratedRunningString,
      };
    }

    return resInfos;
  }

  private static upsertJob(jobs: ICalculationJob[], jobToUpsert: ICalculationJob): ICalculationJob[] {
    return [...jobs.filter((job) => job.jobId !== jobToUpsert.jobId), jobToUpsert];
  }

  private static setResultInfoCompletedStatus(
    resultsInfos: CalculationResultsInfoDto[],
    scenarioId: number,
    moduleType: ModuleType,
    rangeId: number,
    isCompleted: boolean,
    additionalData: SimulateEvaluateCompleteAdditionalData | undefined,
  ): CalculationResultsInfoDto[] {
    return resultsInfos.map((info) =>
      findResultInfoFilter(info, scenarioId, moduleType, rangeId)
        ? {
            ...info,
            isCompleted,
            hasCalcEngineGeneratedSchedule: additionalData?.shouldUploadPumpingSchedule || false,
            hasCalcEngineGeneratedRunningString: additionalData?.shouldUploadRunningString || false,
          }
        : info,
    );
  }

  // endregion
}
