import { Action, Store } from '@ngrx/store';
import { from, of } from 'rxjs';

import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseWsEffects } from '../base-ws.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { ModalService } from '../../common-modules/modals/modal.service';
import {
  addUserDefinedFileAction,
  addUserDefinedFormulaColumnAction,
  applyEquationFormulaCalculationsSuccessAction,
  editUserDefinedFileAction,
  editUserDefinedFormulaColumnAction,
  fetchDataForEquationFailed,
  fetchDataForEquationSuccess,
  generateSourceVariablesAction,
  insertNewVariable,
  insertNewVariableFailure,
  insertNewVariableSuccess,
  recalculateAllEquationsAction,
  saveEquationColumnAction,
  setColumnForEdit,
  updateEquationColumnAction,
  updateEquationVariable,
} from './equation.actions';

import { selectCorrectEquationVariables, selectEquationColumn, selectEquationSourceHash, selectEquationVariables } from './equation.selectors';
import { EquationPreviewRow, IEquationVariable } from '@dunefront/common/modules/equation/equation-variable.dto';
import { dataFailed, insertRowsSuccess, updateRowSuccess } from '../app.actions';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { EquationColumnFactory } from '@dunefront/common/modules/equation/equation-column';
import { JobidHelper } from '@dunefront/common/common/jobid-helper';
import { getCurrentScenarioId } from '../scenario/scenario.selectors';
import { AddEditColumnDialogComponent } from '../../pages/gauge-data-page/gauge-data/add-column-dialog/add-edit-column-dialog.component';
import { getValidatedStorageFilesWithColumns } from '../data-storage/data-storage.selectors';
import { DictionaryWithArray, filterNil, IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { ArgumentType } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { EquationStateValidation } from '@dunefront/common/modules/equation/equation-module.validation';
import { IValidatedStorageFileWithColumns } from '@dunefront/common/modules/data-storage/data-storage.validation';
import { loadFilesAction, updateEquationFileHash } from '../data-storage/data-storage.actions';
import { WsActionPropsFactory } from '@dunefront/common/common/ws-action/ws-action-props.factory';
import { getValidatedDeveloperSettings } from '../settings/validated-settings.selectors';
import {
  EquationCreateColumnAction,
  EquationCreateFileAction,
  EquationFetchPreviewAction,
  EquationGenerateSourceVariablesAction,
  EquationsModuleName,
  EquationUpdateColumnAction,
  EquationUpdateFileAction,
} from '@dunefront/common/modules/equations/equations-module.actions';
import { EquationRecalculationHelper } from '@dunefront/common/modules/equation/equation-recalculation.helper';
import {
  INewEquationFileResult,
  IUpsertEquationConfigData,
  NewEquationFileDialogComponent,
} from '../../pages/gauge-data-page/gauge-data/new-equation-file-dialog/new-equation-file-dialog.component';
import { getGaugeDataSelectedFile, getGaugeDataSelectedFileId } from '../gauge-data/gauge-data.selectors';
import { ImportFileDto, ImportFileWithMinMaxArgumentsDto } from '@dunefront/common/modules/data-storage/dto/import-file.dto';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { loadGaugeDataAction, selectGaugeDataFileAction } from '../gauge-data/gauge-data.actions';
import { getCurrentRangeId } from '../range/range.selectors';

@Injectable()
export class EquationEffects extends BaseWsEffects {
  public fetchPreviewDataForEquation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(insertNewVariableSuccess, updateEquationVariable, setColumnForEdit),
      concatLatestFrom(() => [
        this.store.select(selectCorrectEquationVariables),
        this.store.select(getGaugeDataSelectedFileId),
        this.store.select(getCurrentRangeId),
      ]),
      filter(([, variables]) => variables.length > 0),
      mergeMap(([, variables, equationFileId, currentRangeId]) =>
        this.emit<EquationPreviewRow[]>(new EquationFetchPreviewAction(variables, equationFileId, currentRangeId)).pipe(
          map((res) => fetchDataForEquationSuccess({ equationPreview: res.payload })),
          catchError((error) => of(fetchDataForEquationFailed())),
        ),
      ),
    ),
  );
  public insertNewVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(insertNewVariable),
      concatLatestFrom(() => this.store.select(selectEquationVariables)),
      mergeMap(([action, vars]) => {
        // map letters to charCodes
        const varsCharCodesArr = vars.map((variable) => variable.Name.charCodeAt(0));
        const latestCharCode = varsCharCodesArr[varsCharCodesArr.length - 1];

        // find missing number
        let missingCharCode;
        if (!varsCharCodesArr.includes(65)) {
          missingCharCode = 65;
        } else {
          missingCharCode = (varsCharCodesArr.find((x, i) => varsCharCodesArr[i + 1] - x > 1) ?? latestCharCode) + 1;
        }

        // Stop at "Z"
        if (missingCharCode === 90) {
          return of(insertNewVariableFailure({ error: 'Limit of variables exceeded' }));
        }

        const newVarId = missingCharCode - 65; // so A always equals 0, B == 1 etc.
        const variable: IEquationVariable = {
          Id: newVarId,
          ColId: -1,
          FileId: -1,
          Unit: -1,
          Name: String.fromCharCode(missingCharCode),
        };
        return of(insertNewVariableSuccess({ variable }));
      }),
    ),
  );
  public saveEquationColumnAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveEquationColumnAction),
      concatLatestFrom(() => [this.store.select(selectEquationColumn)]),
      filter(([, equationColumn]) => equationColumn.FileId > 0),
      mergeMap(([, equationColumn]) =>
        this.emit<CrudResponse>(
          new EquationCreateColumnAction(WsActionPropsFactory.insertDto(EquationColumnFactory.toDto(equationColumn), false, 'add')),
        ).pipe(
          mergeMap((result) => [insertRowsSuccess(result.payload), generateSourceVariablesAction({ fileId: equationColumn.FileId })]),
          catchError((error) => of(dataFailed(error))),
        ),
      ),
    ),
  );
  public updateEquationColumnAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEquationColumnAction),
      concatLatestFrom(() => [this.store.select(selectEquationColumn)]),
      mergeMap(([, equationColumn]) =>
        this.emitUpdate(new EquationUpdateColumnAction(equationColumn)).pipe(
          mergeMap((result) => [result, generateSourceVariablesAction({ fileId: equationColumn.FileId })]),
        ),
      ),
    ),
  );
  public recalculateAllEquationsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recalculateAllEquationsAction),
      concatLatestFrom(() => [this.store.select(getValidatedStorageFilesWithColumns)]),
      map(([action, filesWithColumns]) => {
        const isValid = this.validateEquationFiles(filesWithColumns, action.currentUiFileId);
        return { action, filesWithColumns, isValid };
      }),
      filter(({ action, filesWithColumns, isValid }) => isValid),
      map(({ action, filesWithColumns, isValid }) => {
        const recalculationOrder = EquationRecalculationHelper.findDependencyOrder(filesWithColumns).map((fileId) => +fileId);
        return generateSourceVariablesAction({
          fileId: recalculationOrder[0],
          bulkAction: {
            nextFilesIdsToProcess: recalculationOrder.slice(1),
            currentUiFileId: action.currentUiFileId,
          },
        });
      }),
    ),
  );

  public calculateUserDefinedFormulasAction$ = createEffect(() =>
    // eslint-disable-next-line @ngrx/avoid-cyclic-effects
    this.actions$.pipe(
      ofType(generateSourceVariablesAction),
      concatLatestFrom(() => [
        this.store.select(getCurrentScenarioId),
        this.store.select(getValidatedStorageFilesWithColumns),
        this.store.select(selectEquationSourceHash),
        this.store.select(getValidatedDeveloperSettings),
      ]),
      map(([action, currentScenarioId, filesWithColumns, fileHashes, developerSettings]) => {
        const isValid = this.validateEquationFiles(filesWithColumns, action.fileId, [action.fileId]);
        return {
          action,
          currentScenarioId,
          filesWithColumns,
          isValid,
          fileHashes,
          developerSettings: developerSettings,
        };
      }),
      filter(({ isValid }) => isValid),
      mergeMap(({ action, currentScenarioId, fileHashes, developerSettings }) => {
        const { fileId, bulkAction } = action;
        const fileHash = fileHashes[fileId]?.equationSourceHash ?? '';
        const updateDate = new Date().toISOString();

        return this.emit(
          new EquationGenerateSourceVariablesAction(
            fileId,
            JobidHelper.generateJobId(),
            currentScenarioId,
            developerSettings,
            fileHash,
            updateDate,
          ),
          'Calculating equation data...',
          '',
          true,
        ).pipe(
          map(() =>
            this.store.dispatch(
              updateEquationFileHash({
                fileId,
                fileHash,
                updateDate,
              }),
            ),
          ),
          map(() =>
            bulkAction != null && bulkAction.nextFilesIdsToProcess.length
              ? generateSourceVariablesAction({
                  fileId: bulkAction.nextFilesIdsToProcess[0],
                  bulkAction: {
                    ...bulkAction,
                    nextFilesIdsToProcess: bulkAction.nextFilesIdsToProcess.slice(1),
                  },
                })
              : applyEquationFormulaCalculationsSuccessAction({ fileId: bulkAction?.currentUiFileId ?? fileId }),
          ),
          catchError((error) => of(loadGaugeDataAction({ fileId }), dataFailed(error))),
        );
      }),
    ),
  );

  public addUserDefinedFormulaColumnAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addUserDefinedFormulaColumnAction),
        tap(() => this.modalService.open(AddEditColumnDialogComponent, {}, 'add-column-modal', '1200px')),
      ),
    { dispatch: false },
  );

  public addUserDefinedFileAction$ = createEffect(() => {
    const data: IUpsertEquationConfigData = {
      message: '',
      title: 'New User Equation File',
      label: '',
      rangeId: null,
      fileName: '',
    };
    return this.actions$.pipe(
      ofType(addUserDefinedFileAction),
      mergeMap((action) =>
        from(this.modalService.open(NewEquationFileDialogComponent, data as unknown as Record<string, unknown>, '', 'sm').onClose).pipe(
          filterNil(),
          mergeMap((result: INewEquationFileResult) => {
            return this.emit<CrudResponse>(
              new EquationCreateFileAction(result.fileName, result.scenarioId, result.rangeId),
              'Calculating equation data...',
              '',
              true,
            ).pipe(
              map((result) => insertRowsSuccess(result.payload)),
              catchError((error) => of(dataFailed(error))),
            );
          }),
        ),
      ),
    );
  });

  public editUserDefinedFileAction$ = createEffect(() => {
    const getData = (file: ImportFileWithMinMaxArgumentsDto): IUpsertEquationConfigData => ({
      title: 'Edit User Equation File',
      message: '',
      rangeId: file.RangeId,
      fileName: file.FileName,
      label: '',
    });

    return this.actions$.pipe(
      ofType(editUserDefinedFileAction),
      concatLatestFrom(() => this.store.select(getGaugeDataSelectedFile)),
      filter(([, file]) => file != null),
      mergeMap(([, file]) =>
        from(
          this.modalService.open(
            NewEquationFileDialogComponent,
            getData(file as ImportFileWithMinMaxArgumentsDto) as unknown as Record<string, unknown>,
            '',
            'sm',
          ).onClose,
        ).pipe(
          filterNil(),
          mergeMap((result: INewEquationFileResult) => {
            const currentFile = file as ImportFileDto;

            const colIds: (keyof ImportFileDto)[] = [];
            if (currentFile.FileName !== result.fileName) {
              colIds.push('FileName');
            }
            if (currentFile.RangeId !== result.rangeId) {
              colIds.push('RangeId');
            }

            const updateFile: ImportFileDto = {
              ...(file as ImportFileDto),
              FileName: result.fileName,
              RangeId: result.rangeId ?? RangeConstants.EntireRangeId,
            };
            return this.emit<CrudResponse>(new EquationUpdateFileAction(updateFile, colIds), 'Calculating equation data...', '', true).pipe(
              mergeMap((result) => {
                const actions: Action[] = [updateRowSuccess(result.payload)];
                if (colIds.includes('RangeId')) {
                  actions.push(generateSourceVariablesAction({ fileId: file?.Id ?? -1 }));
                }
                return actions;
              }),
              catchError((error) => of(dataFailed(error))),
            );
          }),
        ),
      ),
    );
  });

  public applyEquationFormulaCalculationsSuccessAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(applyEquationFormulaCalculationsSuccessAction),
      mergeMap((action) => [
        loadFilesAction(),
        selectGaugeDataFileAction({
          fileId: action.fileId,
          src: 'applyEquationFormulaCalculationsSuccessAction$',
        }),
        loadGaugeDataAction({ fileId: action.fileId }),
      ]),
    ),
  );

  public editUserDefinedFormulaColumnAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editUserDefinedFormulaColumnAction),
      tap(() => this.modalService.open(AddEditColumnDialogComponent, { isEditMode: true }, 'add-column-modal', '1200px')),
      map((action) => setColumnForEdit({ column: action.selectedColumn })),
    ),
  );

  constructor(actions$: Actions, store: Store, wsService: BackendConnectionService, modalService: ModalService) {
    super(actions$, wsService, EquationsModuleName, true, true, modalService, store);
  }

  private validateEquationFiles(
    filesWithColumns: IDictionaryWithArray<IValidatedStorageFileWithColumns>,
    fileId: number,
    fileIdsToValidate?: number[],
  ): boolean {
    const columns = DictionaryWithArray.get(filesWithColumns, fileId)?.columns ?? [];
    const valueColumnsExists = columns.filter((col) => col.IsXAxis === ArgumentType.Value).length > 0;
    const errorMessages = valueColumnsExists
      ? EquationStateValidation.validateEquationFiles(filesWithColumns, fileIdsToValidate)
      : ['There are no equation columns defined in current file'];
    const isValid = errorMessages.length === 0;
    if (!isValid) {
      this.modalService
        .showAlert(`Please fix errors in following files and columns: <br> ${errorMessages.join('<br>')}`, 'Validation error')
        .then();
    }
    return isValid;
  }
}
