<div>
  <div class="panel-wrapper">
    <app-chart-axis-style-options *ngIf="globalOptions && defaultGlobalOptions" [globalOptions]="globalOptions"
                                  [defaultGlobalOptions]="defaultGlobalOptions"
                                  (settingsValueChange)="submitOption($event)">
    </app-chart-axis-style-options>
  </div>

  <div class="panel-wrapper">
    <app-chart-marker-options *ngIf="globalOptions && defaultGlobalOptions" [globalOptions]="globalOptions"
                              [defaultGlobalOptions]="defaultGlobalOptions"
                              (settingsValueChange)="submitOption($event)">
    </app-chart-marker-options>
  </div>

  <div class="panel-wrapper">
    <app-chart-annotation-options *ngIf="globalOptions && defaultGlobalOptions" [globalOptions]="globalOptions"
                                  [defaultGlobalOptions]="defaultGlobalOptions"
                                  (settingsValueChange)="submitOption($event)">
    </app-chart-annotation-options>
  </div>

  <div class="panel-wrapper">
    <app-chart-gradient-line-options *ngIf="globalOptions && defaultGlobalOptions" [globalOptions]="globalOptions"
                                     [defaultGlobalOptions]="defaultGlobalOptions"
                                     (settingsValueChange)="submitOption($event)">
    </app-chart-gradient-line-options>
  </div>

  <div class="panel-wrapper">
    <app-chart-legend-options *ngIf="globalOptions && defaultGlobalOptions" [globalOptions]="globalOptions"
                              [defaultGlobalOptions]="defaultGlobalOptions"
                              (settingsValueChange)="submitOption($event)">
    </app-chart-legend-options>
  </div>

  <div class="panel-wrapper">
    <app-chart-series-options *ngIf="globalOptions && defaultGlobalOptions" [globalOptions]="globalOptions"
                              [defaultGlobalOptions]="defaultGlobalOptions"
                              (settingsValueChange)="submitOption($event)"
                              [isOrganization]="isOrganization"
                              [orgSeriesColours]="organizationSeriesColours$ | async"
    >
    </app-chart-series-options>
  </div>

  <div class="panel-wrapper">
    <app-chart-performance-options *ngIf="globalOptions && defaultGlobalOptions" [globalOptions]="globalOptions"
                                   [defaultGlobalOptions]="defaultGlobalOptions"
                                   (settingsValueChange)="submitOption($event)"
    >
    </app-chart-performance-options>
  </div>

  <div class="panel-wrapper" *hideOnDataPro>
    <app-chart-video-performance-options *ngIf="globalOptions && defaultGlobalOptions" [globalOptions]="globalOptions"
                                         [defaultGlobalOptions]="defaultGlobalOptions"
                                         (settingsValueChange)="submitOption($event)"
    >
    </app-chart-video-performance-options>
  </div>

  <!--  To be restored  -->

  <!--  <app-chart-copy-options-->
  <!--    *ngIf="globalOptions"-->
  <!--    [globalOptions]="globalOptions"-->
  <!--    (advancedOptionsValueChange)="submitOption($event)"-->
  <!--  ></app-chart-copy-options>-->

  <div class="buttons-panel">
    <div></div>
    <div>
      <app-button dataCy="global-options-reset-btn" [isDeleteResultsEnabled]="false" (buttonClick)="resetDefault()"
                  text="Reset"
      ></app-button>
    </div>
  </div>
</div>
