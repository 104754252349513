<app-generic-modal [modalButtonsConfigs]="modalButtonsConfigs" dataCy="notifications-modal" [title]="title" [showHelpBtn]="true" (helpClick)="isHelpOpen = $event">

  <div class="notifications-wrapper" #notificationsWrapper>
    <div *ngIf="unreadNotifications.length"  data-cy="new-messages" #newMessagesSection>
      <p>New messages:</p>


      <app-single-notification
              *ngFor="let notification of unreadNotifications"
              [notification]="notification"
              [showExtraButtons]="true"
              (buttonClicked)="openNotification(notification, $event)"
      ></app-single-notification>
    </div>

    <div class="no-messages" *ngIf="!unreadNotifications.length && !showReadMessages">

    <p>No new messages.
      <span *ngIf="seenNotifications.length">Click 'Show read messages' to display older notifications</span></p>
    </div>

    <div *ngIf="showReadMessages && seenNotifications.length"  data-cy="read-messages" #readMessagesSection>
      <p>Read messages</p>
      <app-single-notification
              *ngFor="let notification of seenNotifications"
              [notification]="notification"
              [showExtraButtons]="false"
              (buttonClicked)="openNotification(notification, $event)"
      ></app-single-notification>
    </div>
  </div>
</app-generic-modal>

<div class="modal-help" [class.open]="isHelpOpen">
  <div class="help-content">
    <ng-container *ngIf="'assets/help-files/top-bar/notifications.html' | helpUrl | async as url">
      <iframe [src]="url"></iframe>
    </ng-container>
  </div>
</div>
