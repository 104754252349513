<ng-container *ngIf="chartZoomProps">
  <div>
    <app-radio-group
      [source]="chartZoomProps"
      [key]="'chartZoomMode'"
      [items]="zoomModeRadio"
      layout="horizontal"
      [isUiLockable]="false"
      id="zoom-radio"
      (primitiveValueChanged)="zoomModeChanged($event.value)"
      [disabled]="!chartZoomProps.chartZoomEnabled"
    >
    </app-radio-group>
  </div>
  <div class="small-button-panel">
    <app-button
      textIcon="icon-search-loupe-delete"
      text="Reset Zoom"
      [dataCy]="'reset-zoom-button'"
      class="top-menu-button"
      [class.ribbon-button]="isRibbonMenu"
      (buttonClick)="resetZoomClicked()"
      [isUiLockable]="false"
      [disabled]="!chartZoomProps.chartZoomEnabled"
    ></app-button>
    <app-button
      textIcon="icon-resize-arrows"
      text="Auto Size"
      [dataCy]="'auto-size-button'"
      [class.ribbon-button]="isRibbonMenu"
      class="top-menu-button"
      (buttonClick)="autoSizeClicked()"
      [isUiLockable]="false"
      [disabled]="!chartZoomProps.chartZoomEnabled"
    ></app-button>
  </div>
</ng-container>
