import { Injectable } from '@angular/core';
import { isElectron } from '@dunefront/common/common/electron/is-electron';
import { ClientAuthService } from '../../common-modules/auth/client-auth.service';

@Injectable()
export class AssetService {
  constructor(private clientAuthService: ClientAuthService) {}

  public async getAssetUrl(filePath: string, openInNewTab = false): Promise<string> {
    if (isElectron()) {
      return filePath;
    }

    if (openInNewTab) {
      return `help-files/doc.html?path=${filePath}`;
    }

    const token = await this.clientAuthService.getAccessToken();
    const sessionId = this.clientAuthService.sessionId;

    return filePath + `?ppSessionId=${sessionId}&jwt=${token}`;
  }
}
