<ng-container *ngIf="wellState.isLoaded">
  <ng-container *ngIf="CaliperValidation.IsCaliperScreenEnabled(wellState); else caliperDisabled">
    <app-page-panel header="Caliper Data" (click)="onHelpChange('well', 'caliper-data')"
                    [panelHelpMode]="PanelHelpMode.GRID" class="no-padding">
      <div class="caliper-data-panel">
        <app-form-input
          label="Caliper Multiplier"
          [source]="wellState"
          [key]="'CaliperMultiplier'"
          [unitType]="UnitType.None"
          [showSymbol]="false"
          [decimalPlaces]="3"
          (valueChanged)="changeWellProperty($event)"
          [width]="100"
          dataCy="caliper-multiplier"
          class="caliper-multiplier-input"
        >
        </app-form-input>
        <app-caliper-data-grid #gridComponent [caliperData]="wellState.CaliperData"></app-caliper-data-grid>
        <div class="buttons-panel">
          <div></div>
          <div>
            <app-button dataCy="caliper-data-import" (buttonClick)="onImport()" [isDeleteResultsEnabled]="false"
                        text="Import">
            </app-button>

            <app-button dataCy="caliper-insert-btn" (buttonClick)="onInsert()"
                        [isDeleteResultsEnabled]="false" text="Insert">

            </app-button>
            <app-button dataCy="caliper-delete-btn" (buttonClick)="onDelete()"
                        [isDeleteResultsEnabled]="false" text="Delete">

            </app-button>
          </div>
        </div>
      </div>
    </app-page-panel>

    <app-page-panel header="Caliper Preview" (click)="onHelpChange('well', 'caliper-preview')"
                    [panelHelpMode]="PanelHelpMode.CHART" class="no-padding" >
      <div class="caliper-preview-panel">
        <app-form-input
          label="Averaging Tolerance"
          [source]="wellState"
          [key]="'CaliperTolerance'"
          [unitType]="UnitType.Diameter"
          [width]="150"
          [decimalPlaces]="3"
          (valueChanged)="changeWellProperty($event)"
          dataCy="caliper-tolerance"
          class="average-tolerance-input"
        >
        </app-form-input>
        <app-caliper-preview-chart [well]="wellState" [caliperData]="wellState.CaliperData"></app-caliper-preview-chart>
      </div>
    </app-page-panel>
  </ng-container>

  <ng-template #caliperDisabled>
    <div class="centered-message">There is no open hole defined on the casing data screen</div>
  </ng-template>
</ng-container>
