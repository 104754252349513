<div class="chart-grid-container" (click)="onHelpChange('calculators', 'injection-test-calculator')">
  <app-page-panel header="Input Data" class="no-padding">
    <app-injection-test-input-data-grid #gridComponent
                                        [inputData]="state.InjectionTestReadings"
                                        (selectedRowsChanged)="onSelectedRowsChanged($event)"></app-injection-test-input-data-grid>
  </app-page-panel>
  <app-page-panel header="Chart" [panelHelpMode]="PanelHelpMode.CHART">
    <app-injection-test-chart *ngIf="state.isLoaded"></app-injection-test-chart>
  </app-page-panel>
</div>
<app-page-panel header="Results" (click)="onHelpChange('calculators', 'injection-test-results')">
  <div class="grid-form-container cols-4" *ngIf="state.isLoaded">
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Est. Reservoir Pressure"
      [disabled]="true"
      [source]="state.InjectionTestCalculator"
      [key]="'ReservoirPressure'"
      [decimalPlaces]="2"
      [unitType]="UnitType.Pressure"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Extension Rate"
      [disabled]="true"
      [source]="state.InjectionTestCalculator"
      [key]="'ExtensionRate'"
      [decimalPlaces]="2"
      [unitType]="UnitType.Rate"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Est. Closure Pressure"
      [disabled]="true"
      [source]="state.InjectionTestCalculator"
      [key]="'ClosurePressure'"
      [decimalPlaces]="2"
      [unitType]="UnitType.Pressure"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Extension Pressure"
      [disabled]="true"
      [source]="state.InjectionTestCalculator"
      [key]="'ExtensionPressure'"
      [decimalPlaces]="2"
      [unitType]="UnitType.Pressure"
    >
    </app-form-input>
  </div>
</app-page-panel>

