<app-page-panel
  header="Test Data"
  *ngIf="selectedFluidData && selectedFluidData.rheometer"
  [allowFullScreen]="false"
  [panelHelpMode]="PanelHelpMode.GRID"
  class="no-padding"
>
  <div class="page-container">
    <div class="buttons-panel">
      <div>
        <app-form-input
          label="Temperature"
          [source]="selectedFluidData.rheometer"
          key="Temperature"
          [decimalPlaces]="2"
          [unitType]="UnitType.Temperature"
          (valueChanged)="emitRheometerValue($event)"
        >
        </app-form-input>
      </div>
    </div>

    <div class="grid-and-chart-panel">
      <app-rheometer-test-data-grid
        #gridComponent
        [rheometerReadingData]="selectedFluidData.rheometerReading"
        [fluid]="selectedFluidData.fluid"
        [rheometer]="selectedFluidData.rheometer"
      >
      </app-rheometer-test-data-grid>
    </div>

    <div class="buttons-panel">
      <div>
        <app-button
          dataCy="rheometer-test-data-import"
          (buttonClick)="onImport()"
          text="Import Reading"
        >
        </app-button>
        <app-button
          (buttonClick)="onDelete()"
          [disabled]="selectedFluidData.rheometer.rowData.SpeedType !== SpeedType.Custom"
          text="Delete Reading"
        >
        </app-button>
      </div>
    </div>
  </div>
</app-page-panel>

<app-page-panel header="Test Data Chart" [panelHelpMode]="PanelHelpMode.CHART"
                class="no-padding-bottom no-padding-top">
  <div class="page-container">
    <div class="buttons-panel">
      <div></div>
      <app-check-box
        [source]="selectedFluidData.rheometer.rowData"
        [key]="'IsShuntRefCurveShown'"
        [isUiLockable]="false"
        label="Show Shunt Target Rheology (IPTC 12549)"
        (valueChanged)="emitRheometerValue($event)"
      >
      </app-check-box>
    </div>

    <app-rheometer-test-data-chart></app-rheometer-test-data-chart>

    <div class="align-right" *ngIf="rheology">
      <div class="props-panel">
        <app-form-input
          label="R&#xb2;"
          class="ml-auto"
          [disabled]="true"
          [readOnlyValue]="rheology.RSquared"
          [decimalPlaces]="3"
          [unitType]="UnitType.None"
        >
        </app-form-input>
        <app-form-input label="n'" [disabled]="true" [readOnlyValue]="rheology.NPrime" [decimalPlaces]="4" [unitType]="UnitType.None">
        </app-form-input>
        <app-form-input
          label="k'"
          [disabled]="true"
          [readOnlyValue]="rheology.KPrime"
          [decimalPlaces]="6"
          [unitType]="UnitType.Consistency_Index"
        >
        </app-form-input>
      </div>
    </div>
  </div>
</app-page-panel>
