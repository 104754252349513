<div class="grid-form-container cols-2 main-grid">
  <div class="grid-form-container cols-2 no-padding" *ngIf="pumping">
    <app-fluid-selector
      [source]="pumping"
      [key]="'AutoScheduleCarrierFluidId'"
      label="Carrier Fluid"
      appUnwrap
      [isUiLockable]="false"
      [errorMessage]="pumping.error.AutoScheduleCarrierFluidId"
      (valueChanged)="onValueChange($event)"
      dataCy="carrier-fluid"
      [showSymbol]="true"
    >
    </app-fluid-selector>

    <app-fluid-selector
      [source]="pumping"
      [key]="'AutoScheduleFlushFluidId'"
      label="Flush Fluid"
      appUnwrap
      [isUiLockable]="false"
      [errorMessage]="pumping.error.AutoScheduleFlushFluidId"
      (valueChanged)="onValueChange($event)"
      dataCy="flush-fluid"
      [showSymbol]="true"
    >
    </app-fluid-selector>

    <app-gravel-selector
      [source]="pumping"
      [key]="'AutoScheduleGravelId'"
      label="Gravel"
      appUnwrap
      [isUiLockable]="false"
      [errorMessage]="pumping.error.AutoScheduleGravelId"
      (valueChanged)="onValueChange($event)"
      dataCy="gravel-selector"
      [showSymbol]="true"
    >
    </app-gravel-selector>

    <app-form-input
      [source]="pumping"
      [key]="'AutoScheduleTargetPacking'"
      label="Target Packing"
      appUnwrap
      [isUiLockable]="false"
      [decimalPlaces]="2"
      [unitType]="UnitType.Linear_Mass"
      [disabled]="!isTxtAutoScheduleTargetPackingEnabled"
      (valueChanged)="onValueChange($event)"
      dataCy="target-packing"
    ></app-form-input>

    <app-check-box
      [source]="pumping"
      [key]="'IsAutoScheduleRamped'"
      class="col-span-2"
      [isUiLockable]="false"
      (valueChanged)="onValueChange($event)"
      label="Ramp Gravel Concentration"
      [attr.data-cy]="'ramp-gravel-concentration'"
    >
    </app-check-box>
  </div>

  <div class="grid-form-container cols-2 no-padding" appEqualizeSymbols>
    <app-form-input
      [source]="pumping"
      [key]="'AutoSchedulePumpRate'"
      [label]="pumpRateLabel"
      appUnwrap
      [isUiLockable]="false"
      [decimalPlaces]="2"
      [unitType]="UnitType.Rate"
      (valueChanged)="onValueChange($event)"
      dataCy="pump-rate"
    ></app-form-input>

    <app-form-input
      [source]="pumping"
      [key]="'AutoScheduleExcessPumpRate'"
      label="Excess Pump Rate"
      appUnwrap
      [isUiLockable]="false"
      [decimalPlaces]="2"
      [unitType]="UnitType.Rate"
      [disabled]="!isTxtAutoScheduleExcessPumpRateEnabled"
      (valueChanged)="onValueChange($event)"
      dataCy="excess-pump-rate"
    ></app-form-input>

    <app-form-input
      [source]="pumping"
      [key]="'AutoScheduleReturnRate'"
      label="Return Rate"
      appUnwrap
      [isUiLockable]="false"
      [decimalPlaces]="2"
      [unitType]="UnitType.Rate"
      (valueChanged)="onValueChange($event)"
      dataCy="return-rate"
    ></app-form-input>

    <app-form-input
      [source]="pumping"
      [key]="'AutoScheduleMaxGravelConc'"
      label="Max Gravel Conc."
      appUnwrap
      [isUiLockable]="false"
      [decimalPlaces]="2"
      [unitType]="UnitType.Solid_Concentration"
      (valueChanged)="onValueChange($event)"
      dataCy="max-gravel-concentration"
    ></app-form-input>

    <app-form-input
      [source]="pumping"
      [key]="'AutoScheduleExcessGravelPercentage'"
      label="Excess Gravel"
      appUnwrap
      [isUiLockable]="false"
      [decimalPlaces]="2"
      [unitType]="UnitType.None"
      unitLabel="%"
      (valueChanged)="onValueChange($event)"
      dataCy="excess-gravel"
    ></app-form-input>
    <app-button
      id="btnGenerateSchedule"
      [isDeleteResultsEnabled]="false"
      (buttonClick)="onGenerateScheduleClick()"
      dataCy="generate-schedule"
      text="Generate Schedule"
    >

    </app-button>
  </div>
</div>
