<div class="resuspension-calculator-container" *ngIf="state.isLoaded">
  <app-page-panel header="Flow Path Dimensions" (click)="onHelpChange('calculators', 'resuspension-flow-path-dimensions')">
    <div class="grid-form-container">
      <app-radio-group
        [items]="flowPathDimensionsValues"
        [source]="state"
        [key]="'FlowPathType'"
        id="FlowPathType"
        (valueChanged)="submitCalcData($event)"
        dataCy="resuspension-calculator-flow-path-type"
      >
      </app-radio-group>
      <div class="grid-form-container no-padding">
        <app-form-input
          [label]="MinDiameterLabel"
          [source]="state"
          [key]="'MinDiameter'"
          [decimalPlaces]="3"
          [unitType]="UnitType.Diameter"
          (valueChanged)="submitCalcData($event)"
          dataCy="resuspension-calculator-min-diameter"
        >
        </app-form-input>

        <app-form-input
          *ngIf="state.FlowPathType === FlowPathType.Annulus"
          label="Annulus OD"
          [source]="state"
          [key]="'MaxDiameter'"
          [decimalPlaces]="3"
          [unitType]="UnitType.Diameter"
          (valueChanged)="submitCalcData($event)"
          dataCy="resuspension-calculator-max-diameter"
        >
        </app-form-input>
      </div>
    </div>
  </app-page-panel>
  <app-page-panel header="Flow Data" (click)="onHelpChange('calculators', 'resuspension-flow-data')">
    <div class="grid-form-container cols-4">
      <app-fluid-selector
        [source]="state"
        [key]="'FluidId'"
        label="Fluid"
        appUnwrap
        [errorMessage]="state.error.FluidId"
        (valueChanged)="submitCalcData($event)"
        dataCy="resuspension-calculator-fluid"
        [showSymbol]="true"
      >
      </app-fluid-selector>
      <app-gravel-selector
        [source]="state"
        [key]="'GravelId'"
        label="Gravel"
        appUnwrap
        [errorMessage]="state.error.GravelId"
        (valueChanged)="submitCalcData($event)"
        dataCy="resuspension-calculator-gravel"
        [showSymbol]="true"
      >
      </app-gravel-selector>
      <app-form-input
        appUnwrap
        label="Concentration"
        [source]="state"
        [key]="'Concentration'"
        [decimalPlaces]="2"
        [unitType]="UnitType.Solid_Concentration"
        (valueChanged)="submitCalcData($event)"
        dataCy="resuspension-calculator-concentration"
      >
      </app-form-input>
      <app-form-input
        appUnwrap
        label="Temperature"
        [source]="state"
        [key]="'Temperature'"
        [decimalPlaces]="2"
        [unitType]="UnitType.Temperature"
        (valueChanged)="submitCalcData($event)"
        dataCy="resuspension-calculator-temperature"
      >
      </app-form-input>
    </div>
  </app-page-panel>
  <app-page-panel class="panel-container" header="Results" (click)="onHelpChange('calculators', 'resuspension-results')">
    <div class="grid-form-container">
      <app-form-input
        label="Resuspension Rate"
        class="mr-3"
        [source]="state"
        [disabled]="true"
        [key]="'ResuspensionRate'"
        [decimalPlaces]="2"
        [unitType]="UnitType.Rate"
        dataCy="resuspension-calculator-rate"
      >
      </app-form-input>
      <app-form-input
        label="Resuspension Velocity"
        [source]="state"
        [disabled]="true"
        [key]="'ResuspensionVelocity'"
        [decimalPlaces]="2"
        [unitType]="UnitType.Velocity"
        dataCy="resuspension-calculator-velocity"
      >
      </app-form-input>
    </div>
  </app-page-panel>
</div>
