import { IDbInfo } from '@dunefront/common/modules/db-connection/db-connection.actions';
import { createReducer, on } from '@ngrx/store';
import * as actions from './backend-connection.actions';
import { backendRequestStartedAction, backendResponseReceivedAction } from './backend-connection.actions';
import { BackendConnectionReducerHelper } from './backend-connection.reducer.helper';
import { cancelMigration, progressDbMigration } from '../db-migration/db-migration.actions';
import { getFileHashFromFile } from '@dunefront/common/dto/file.dto';
import { licenseFailedWhenOpeningFile } from '../licensing/licensing.actions';

export const BackendConnectionModuleFeatureName = 'backend-connection';

export enum ConnectionStatus {
  disconnected = 'disconnected',
  connected = 'connected',
  connecting = 'connecting',
  failed = 'failed',
}

export interface BackendConnectionModuleState {
  dbConnectionStatus: ConnectionStatus;
  backendConnectionStatus: ConnectionStatus;
  backendConnectionActiveRequests: string[];
  dbInfo?: IDbInfo;
  connectionErrorMessage: string | undefined;
}

const initialState: BackendConnectionModuleState = {
  dbConnectionStatus: ConnectionStatus.disconnected,
  backendConnectionStatus: ConnectionStatus.connecting,
  backendConnectionActiveRequests: [],
  dbInfo: undefined,
  connectionErrorMessage: undefined,
};

export const backendConnectionReducer = createReducer<BackendConnectionModuleState>(
  initialState,
  on(
    actions.backendConnectFailedAction,
    (state, action): BackendConnectionModuleState => ({
      ...state,
      backendConnectionStatus: ConnectionStatus.failed,
      connectionErrorMessage: action.error,
    }),
  ),
  on(
    actions.backendConnectedAction,
    (state): BackendConnectionModuleState => ({
      ...state,
      backendConnectionStatus: ConnectionStatus.connected,
      connectionErrorMessage: undefined,
    }),
  ),
  on(
    actions.backendDisconnectedAction,
    (state): BackendConnectionModuleState => ({
      ...initialState,
      backendConnectionStatus:
        state.backendConnectionStatus === ConnectionStatus.connected ? ConnectionStatus.disconnected : ConnectionStatus.connecting,
      connectionErrorMessage: undefined,
      backendConnectionActiveRequests: [],
    }),
  ),
  on(
    actions.dbConnectAction,
    (state, action): BackendConnectionModuleState => ({
      ...state,
      dbConnectionStatus: ConnectionStatus.connecting,
      dbInfo: {
        fileHash: getFileHashFromFile(action.dbFile),
        file: action.dbFile,
        dbVersionStatus: 'connecting',
      },
    }),
  ),
  on(licenseFailedWhenOpeningFile, (state: BackendConnectionModuleState, action): BackendConnectionModuleState => {
    return { ...state, dbConnectionStatus: ConnectionStatus.disconnected, dbInfo: undefined };
  }),
  on(actions.dbConnectedSuccessAction, (state: BackendConnectionModuleState, action): BackendConnectionModuleState => {
    const dbInfo = action.payload.dbConnectionResult?.dbInfo;
    if (dbInfo == null) {
      return { ...state, dbConnectionStatus: ConnectionStatus.disconnected, dbInfo: undefined };
    }

    return {
      ...state,
      dbConnectionStatus: ConnectionStatus.connected,
      dbInfo,
    };
  }),
  on(
    actions.dbDisconnectSuccessAction,
    cancelMigration,
    (state): BackendConnectionModuleState => ({
      ...state,
      dbConnectionStatus: ConnectionStatus.disconnected,
      dbInfo: undefined,
      backendConnectionActiveRequests: [],
    }),
  ),
  on(
    actions.dbConnectedMigrationRequiredAction,
    (state, action): BackendConnectionModuleState => ({
      ...state,
      dbInfo: action.dbInfo,
    }),
  ),
  on(
    progressDbMigration,
    (state, action): BackendConnectionModuleState =>
      BackendConnectionReducerHelper.dbClearMigrationRequired(state, action.migrationServerResponse.status),
  ),
  on(backendRequestStartedAction, (state, { taskId }): BackendConnectionModuleState => {
    if (state.backendConnectionActiveRequests.includes(taskId)) {
      return state;
    }

    return {
      ...state,
      backendConnectionActiveRequests: [...state.backendConnectionActiveRequests, taskId],
    };
  }),
  on(backendResponseReceivedAction, (state, { taskId }): BackendConnectionModuleState => {
    if (!state.backendConnectionActiveRequests.includes(taskId)) {
      return state;
    }

    const backendConnectionActiveRequests = state.backendConnectionActiveRequests.filter((existingTaskId) => existingTaskId !== taskId);
    return {
      ...state,
      backendConnectionActiveRequests,
    };
  }),
);
