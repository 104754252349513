<ng-container *ngIf="chartMenuProps">
  <ng-container *ngIf="(currentAppModuleType$ | async) !== ModuleType.Data_Analysis">
    <app-ribbon-menu-group groupName="Axis Selection" (click)="changeHelp('toolbars', 'view-axis-selection')">
      <div class="ribbon-menu-button-group">
        <app-axis-selection></app-axis-selection>
      </div>
    </app-ribbon-menu-group>
    <hr />
  </ng-container>

  <app-ribbon-menu-group groupName="Zoom" (click)="changeHelp('toolbars', 'view-zoom')">
    <div class="ribbon-menu-button-group">
      <app-zoom [isRibbonMenu]="true"></app-zoom>
    </div>
  </app-ribbon-menu-group>
  <hr />
  <app-ribbon-menu-group groupName="Crosshair" (click)="changeHelp('toolbars', 'view-crosshair')">
    <div class="ribbon-menu-button-group" [class.disabled] = !chartMenuProps.isCrosshairOptionsEnabled>
      <div class="grid-form-container crosshair-grid" >
        <label>Mode</label>
        <app-select
          class="ribbon-select crosshair-mode-select"
          [items]="crosshairModes"
          [source]="chartMenuProps"
          [key]="'crossHairMode'"
          [isUiLockable]="false"
          [disableSorting]="true"
          [showSymbol]="false"
          [dataCy]="'chart-axis-props-crosshair-mode'"
          [disabled]="!chartMenuProps.isCrosshairOptionsEnabled"
          (valueChanged)="onCrosshairSectionChanged($event)"
        ></app-select>

        <!-- Max series in tooltip  -->
        <label class="max-series-text">Max Series</label>
        <app-input
                appUnwrap
                [isUiLockable]="false"
                dataCy="chart-max-series-in-tooltip"
                [source]="chartMenuProps"
                class="ribbon-input"
                [width]="50"
                [decimalPlaces]="0"
                [key]="'maxSeriesInTooltip'"
                [disabled]="isMaxSeriesDisabled || !chartMenuProps.isCrosshairOptionsEnabled"
                (valueChanged)="onCrosshairSectionChanged($event)"

        ></app-input>
        <!-- /Max series in tooltip -->

        <label>Position</label>
        <app-select
          class="ribbon-select"
          [items]="tooltipPositions"
          [source]="chartMenuProps"
          [key]="'tooltipPosition'"
          [isUiLockable]="false"
          [disableSorting]="true"
          [showSymbol]="false"
          [dataCy]="'chart-axis-props-tooltip-position'"
          (valueChanged)="onCrosshairSectionChanged($event)"
          [disabled]="!chartMenuProps.isCrosshairOptionsEnabled"
        ></app-select>
      </div>
    </div>
  </app-ribbon-menu-group>
  <hr />
  <app-ribbon-menu-group groupName="Other" (click)="changeHelp('toolbars', 'view-other')">
    <div class="ribbon-menu-button-group">
      <app-button
        class="big-button wide"
        text="Show Annotations"
        textIcon="icon-pin-location-more-dots"
        dataCy="reporting-tools-show-annotations-button"
        [isUiLockable]="false"
        [isToggled]="chartMenuProps.annotationsVisible"
        [disabled]="!chartMenuProps.annotateChartEnabled"
        (buttonClick)="setAnnotationsVisible(!chartMenuProps.annotationsVisible)"
      >
      </app-button>
      <app-button
        class="big-button"
        text="Show Markers"
        textIcon="icon-pin-basket"
        dataCy="reporting-tools-show-markers-button"
        [isUiLockable]="false"
        [isToggled]="chartMenuProps.markersVisible"
        [disabled]="!chartMenuProps.markChartEnabled"
        (buttonClick)="setMarkersVisible(!chartMenuProps.markersVisible)"
      >
      </app-button>
      <app-button
        class="big-button wide"
        text="Show Slope Lines"
        textIcon="icon-gradient-line"
        dataCy="reporting-tools-show-gradient-lines-button"
        [isUiLockable]="false"
        [isToggled]="chartMenuProps.gradientLinesVisible"
        [disabled]="!chartMenuProps.gradientLineEnabled"
        (buttonClick)="setGradientLinesVisible(!chartMenuProps.gradientLinesVisible)"
      >
      </app-button>
    </div>
  </app-ribbon-menu-group>
</ng-container>
