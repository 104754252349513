<div *ngIf="parsedResult?.isParsed && dataSource && headerDataSource && height > 0" class="preview-grid"
     [style.height.px]="height"
     [style.overflow]="'hidden'"
     data-cy="import-data-grid">

  <!--    There are several elements defined in this template. The reason is it is not possible to change the content of-->
  <!--    a table header without re-rendering it. The solution is to define a layout as a 2 simpler tables and 3 divs which -->
  <!--    act as a table elements. It make it easier to keep styling consistent(tables part) and make it possible to -->
  <!--    just replace needed parts.-->

  <!--  1. Original Data Header bar - to just show group bar-->
  <div class='expandable-group-header'>
    <span class="smaller-icon icon-top-chevron" (click)="pastedRowsExpanded = false" *ngIf="pastedRowsExpanded"></span>
    <span class="smaller-icon icon-bottom-chevron" (click)="pastedRowsExpanded = true"
          *ngIf="!pastedRowsExpanded"></span>
    <strong>Original Data Headers</strong>
  </div>

  <!--  2. Original Header - to show pasted header data if they exist-->
  <cdk-virtual-scroll-viewport [tvsItemSize]="20" [headerHeight]="48" [headerEnabled]="true"
                               class="wrapper"
                               [style.height.px]="48" [style.overflow]="'hidden'"
                               *ngIf="pastedRowsExpanded && (isHeaderOrigNamesRowVisible() || isHeaderOrigUnitsRowVisible())">
    <table
      mat-table
      [dataSource]="headerDataSource"
      class="table table-sm table-bordered with-selection-col"
      data-cy="original-headers-generic-paste-table"
    >
      <ng-container *ngIf="pastedRowsExpanded">
        <tr mat-header-row *matHeaderRowDef="origColNames"></tr>
      </ng-container>

      <ng-container *ngIf="pastedRowsExpanded">
        <tr mat-header-row *matHeaderRowDef="origColUnits"></tr>
      </ng-container>

      <ng-container [matColumnDef]="origColName" *ngFor="let origColName of origColNames; let cellIndex = index">
        <ng-container *matHeaderCellDef>
          <th mat-header-cell>
            <ng-container *ngIf="cellIndex > 0">
              <div class="orig-col-names">{{ columnsWithUnits[cellIndex + 1].originalColumnName }}</div>
            </ng-container>
          </th>
        </ng-container>
      </ng-container>

      <ng-container [matColumnDef]="origColUnit" *ngFor="let origColUnit of origColUnits; let cellIndex = index">
        <ng-container *matHeaderCellDef>
          <th mat-header-cell>
            <ng-container *ngIf="cellIndex > 0">
              <div class="orig-col-units">{{ columnsWithUnits[cellIndex + 1].originalUnit }}</div>
            </ng-container>
          </th>
        </ng-container>
      </ng-container>
    </table>
  </cdk-virtual-scroll-viewport>

  <!--  3. Original Header missing - to show missing data information-->
  <div class='original-headers-missing-info'
       *ngIf="pastedRowsExpanded && !isHeaderOrigNamesRowVisible() && !isHeaderOrigUnitsRowVisible()">
    <strong data-cy="original-headers-missing-text">Column types and units not recognized in the original data.</strong>
  </div>

  <!--  4. Mapped Data Header bar - to just show group bar-->
  <div class='expandable-group-header'>
    <span class="smaller-icon icon-top-chevron disabled"></span>
    <strong>Mapped Data Headers</strong>
  </div>

  <!--  5. Pasted Data - to show the whole grid-->
  <cdk-virtual-scroll-viewport [tvsItemSize]="20" [headerHeight]="52" [headerEnabled]="true"
                               class="wrapper"
                               [style.height.px]="height - (pastedRowsExpanded ? 96 : 48)">
    <table
      mat-table
      [dataSource]="dataSource"
      class="table table-sm table-bordered with-selection-col"
      data-cy="mapped-generic-paste-table"
    >
      <tr mat-header-row *matHeaderRowDef="colNames; sticky: true"></tr>
      <tr mat-row [class.alternate]="rowIndex % 2 === 0"
          *matRowDef="let row; columns: colNames; let rowIndex = index"></tr>

      <ng-container [matColumnDef]="col" *ngFor="let col of colNames; let cellIndex = index">
        <ng-container *matHeaderCellDef>
          <th mat-header-cell>
            <ng-container *ngIf="cellIndex > 0">
              <app-select
                [value]="getColumnSelectionKeyByIndex(cellIndex)"
                [items]="configColumnItems"
                [selectPlaceholder]="'Select column'"
                [disableSorting]="true"
                [isGridInput]="true"
                [overrideErrorMessage]="columnTypeSelectError(cellIndex)"
                [dataCy]="'paste-grid-column-type-select-col-' + cellIndex"
                (primitiveValueChanged)="updateTypeSelection($event.value, cellIndex)"
              >
              </app-select>
              <app-select
                [value]="columnSelections[cellIndex]?.unit"
                [items]="getUnitSymbolItemsByIndex(cellIndex)"
                [disabled]="getUnitSymbolItemsByIndex(cellIndex).length === 0"
                [selectPlaceholder]="getUnitSymbolItemsByIndex(cellIndex).length === 0 || getColumnSelectionKeyByIndex(cellIndex) === 'ignore' ? 'Not applicable': 'Select unit'"
                [disableSorting]="true"
                [isGridInput]="true"
                [dataCy]="'paste-grid-column-unit-select-col-' + cellIndex"
                (primitiveValueChanged)="updateUnitSelection($event.value, cellIndex)"
                [overrideErrorMessage]="columnUnitSelectError(cellIndex)"
              >
              </app-select>
            </ng-container>
          </th>
        </ng-container>
        <ng-container *matCellDef="let row; let rowIndex = index">
          <td
            mat-cell
            class="cell"
          >
            <ng-container *ngIf="cellIndex === 0">
              <app-check-box
                [value]="rowsToInclude[rowIndex]"
                [dataCy]="'paste-grid-row-to-include-checkbox-row-' + rowIndex"
                (primitiveValueChanged)="updateRowsToIgnore(!rowsToInclude[rowIndex], rowIndex)"
              >
              </app-check-box>
            </ng-container>
            <span *ngIf="cellIndex > 0">{{ row[cellIndex] }}</span>
          </td>
        </ng-container>
      </ng-container>
    </table>
  </cdk-virtual-scroll-viewport>
</div>
