<app-gravel-header [isReadOnly]="true"
                   (click)="onHelpChange('gravels', 'gravel-advanced-properties')"></app-gravel-header>
<app-page-panel header="Advanced Gravel Properties" *ngIf="gravel"
                (click)="onHelpChange('gravels', 'gravel-advanced-properties')">
  <div class="grid-form-container properties-container">
    <app-form-input
      [source]="gravel"
      [key]="'OroskarAndTurianCoefficient'"
      appUnwrap
      label="Oroskar and Turian Coefficient"
      dataCy="gravel-oroskar-and-turian-coefficient"
      [unitType]="UnitType.None"

      (valueChanged)="onValueChanged($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="gravel"
      [key]="'AngleOfRepose'"
      appUnwrap
      label="Angle of Repose"
      dataCy="gravel-angle-of-repose"
      [unitType]="UnitType.Angle"

      (valueChanged)="onValueChanged($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="gravel"
      [key]="'ThermalConductivity'"
      appUnwrap
      label="Thermal Conductivity"
      dataCy="gravel-thermal-conductivity"
      [unitType]="UnitType.Thermal_Conductivity"

      (valueChanged)="onValueChanged($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="gravel"
      [key]="'SpecificHeatCapacity'"
      appUnwrap
      label="Specific Heat Capacity"
      dataCy="gravel-specific-heat-capacity"
      [unitType]="UnitType.Specific_Heat_Capacity"

      (valueChanged)="onValueChanged($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="gravel"
      [key]="'ForchheimerBeta'"
      appUnwrap
      label="Forchheimer Beta"
      dataCy="gravel-forchheimer-beta"
      [unitType]="UnitType.Reciprocal_Length"

      [disabled]="true"
    >
    </app-form-input>
    <app-form-input
      [source]="gravel"
      [key]="'FrictionMultiplier'"
      class="grid-two-one"
      appUnwrap
      label="Friction Multiplier"
      [decimalPlaces]="2"

      [unitType]="UnitType.None"
      [highlight]="gravel.FrictionMultiplier !== gravel.FrictionMultiplier"
      (valueChanged)="onValueChanged($event)"
    >
    </app-form-input>
    <app-check-box
      [source]="gravel"
      [key]="'IsFrictionMultiplierByDeviation'"
      label="Multiplier Min Deviation"
      [highlight]="gravel.IsFrictionMultiplierByDeviation !== gravel.IsFrictionMultiplierByDeviation"
      (valueChanged)="onValueChanged($event)"
    >
    </app-check-box>

    <app-form-input
      [source]="gravel"
      [key]="'FrictionMultiplierDeviation'"
      [labelPaddingLeft]="false"
      [labelWidth]="113"
      [decimalPlaces]="2"
      [unitType]="UnitType.Angle"

      [highlight]="gravel.FrictionMultiplierDeviation !== gravel.FrictionMultiplierDeviation"
      [disabled]="!gravel.IsFrictionMultiplierByDeviation"
      (valueChanged)="onValueChanged($event)"
    >
    </app-form-input>
  </div>
</app-page-panel>
