import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ThemeService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public loadStyle(elementId: string, styleName: string): void {
    const head = this.document.getElementsByTagName('head')[0];

    const themeLink = this.document.getElementById(elementId) as HTMLLinkElement | null;
    if (themeLink != null) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = elementId;
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  public removeStyle(elementId: string): void {
    const head = this.document.getElementsByTagName('head')[0];
    const themeLink = this.document.getElementById(elementId) as HTMLLinkElement | null;
    if (themeLink != null) {
      head.removeChild(themeLink);
    }
  }
}
