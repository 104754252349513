import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { getChartId } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { DataFileType, DataType } from '@dunefront/common/dto/data-storage';
import { ReportingCalculationJobStatus } from './reporting.actions';
import { ChartIds, ReportingModuleName, ReportingModuleState } from './reporting-module.state';
import { getCurrentRangeId, getRanges, trendAnalysisEnabled } from '../range/range.selectors';
import {
  getCurrentAppModuleType,
  getIsCompareScenariosEnabled,
  getIsEvaluationAnimationVisible,
  getIsSimulationAnimationVisible,
  getIsWellVisualizationVisible,
  getUiState,
  isSimulateEvaluateTrendAnalysis,
} from '../ui/ui.selectors';
import { isSimulateBased, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { getAllStorageColumnsArray, getAnyFilesImported, getStorageColumnNames } from '../data-storage/data-storage.selectors';
import { getCompletionModuleState } from '../completion/completion.selectors';
import { isShuntedPipe } from '@dunefront/common/modules/pipes/lower-completion-pipes/pipes/shunted-screen-pipe';
import { isGaugeCarrierPipe } from '@dunefront/common/modules/pipes/running-string-pipes/pipes/gauge-carrier-pipe';
import { getHasPerforatedCasing, getWellModuleState } from '../well/well.selectors';
import { ChartTemplateType, IChartTemplateDto } from '@dunefront/common/dto/chart-templates.dto';
import { isPerforatedCasingPipe } from '@dunefront/common/modules/pipes/casing-pipes/pipes/perforated-casing-pipe';
import { ArrayHelpers } from '@dunefront/common/common/array-helpers';
import {
  getAreCurrentResultsPresentAndCompleted,
  getCompletedResultsForCurrentModule,
} from '../calculation-engine/calculation-engine-results.selectors';
import { ChartDataSourceType, ChartDto } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { isTrendAnalysis } from '../menu-selectors/menu-selectors.helpers';
import { DictionaryWithArray, isDefined } from '@dunefront/common/common/state.helpers';
import { IAxisProps } from '../../common-modules/chart/chart-component-helpers/chart-types';
import { getCurrentScenarioId, getScenariosToCompare } from '../scenario/scenario.selectors';
import { ChartTimeVolMode, ResultsSourceKey } from '@dunefront/common/modules/reporting/reporting-module.actions';
import { PrimarySecondaryArgumentConverter } from '@dunefront/common/modules/reporting/dto/primary-secondary-argument-converter';
import { ChartLoadingStatus } from './model/reporting.factory';
import { ChartMdTvdMode, CrosshairMode } from '@dunefront/common/modules/reporting/reporting.settings';
import { isPSDModuleValid } from '../psd-analysis/psd-analysis.selectors';
import { getCurrentPumpingModuleState } from '../pumping/selectors/pumping.selectors';
import { ToolPosition } from '@dunefront/common/modules/pumping/dto/pumping.dto';
import { GroupedReportingTabDto } from '@dunefront/common/dto/reporting-tab.dto';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { ChartTemplateHelpers } from '@dunefront/common/common/templates/chart-template.helpers';

export const getCanRenderDepthChart = createSelector(
  getHasPerforatedCasing,
  getCurrentPumpingModuleState,
  (hasPerforatedCasing, pumpingModuleState) => hasPerforatedCasing && pumpingModuleState.pumping.ToolPosition !== ToolPosition.Reverse,
);

export const getReportingDataState = createFeatureSelector<ReportingModuleState>(ReportingModuleName);

export const getChartState = createSelector(getReportingDataState, (state) => state.chartState);

export const getCurrentRangeBasedChartsAxisProperties = createSelector(getReportingDataState, (state) => {
  const chartsIds = [
    state.chartState.chartData?.ChartId,
    state.resultsEvaluateFrictionChartState.chartData?.ChartId,
    state.resultsEvaluatePressureChartState.chartData?.ChartId,
  ].filter((id) => id !== undefined);

  const allChartsProps = chartsIds.map((chartId) => DictionaryWithArray.get(state.chartUserAddonsDict, chartId as number)?.chartAxisProperties);
  const result: IAxisProps[] = [];

  for (const props of allChartsProps) {
    if (props == null) {
      continue;
    }
    result.push(...props);
  }

  return result;
});

export const getResultsTimeChartDataState = createSelector(getReportingDataState, (state) => state.resultsTimeChartState);
export const getResultsDepthChartDataState = createSelector(getReportingDataState, (state) => state.resultsDepthChartState);
export const getResultsDepthChartId = createSelector(getResultsDepthChartDataState, (state) => state.chartData?.ChartId);

export const getResultsEvaluatePressureChartState = createSelector(getReportingDataState, (state) => state.resultsEvaluatePressureChartState);
export const getResultsEvaluateFrictionChartState = createSelector(getReportingDataState, (state) => state.resultsEvaluateFrictionChartState);
export const getResultsSourceKey = createSelector(
  getCurrentAppModuleType,
  getCurrentScenarioId,
  getCurrentRangeId,
  (moduleType, scenarioId, rangeId) => ({ moduleType, scenarioId, rangeId }) as ResultsSourceKey,
);

export const getReportingTabDtos = createSelector(getReportingDataState, (state) => state.reportingTabDtos);
export const getSourceReportingTabDtos = createSelector(getReportingDataState, (state) => state.sourceReportingTabDtos);
export const getGroupedReportingTabs = createSelector(
  getRanges,
  getReportingTabDtos,
  getCurrentAppModuleType,
  (ranges, reportingTabs, moduleType) => {
    const reportingTabsByRange: GroupedReportingTabDto = {};
    const rangesToDisplay = isSimulateBased(moduleType) ? [RangeConstants.EmptyRangeId] : ranges.ids.map((id) => parseInt(id));

    for (const rangeId of rangesToDisplay) {
      reportingTabsByRange[rangeId] = reportingTabs
        .filter((tab) => {
          return tab.RangeId === +rangeId && tab.ModuleType === moduleType;
        })
        .sort((tab1, tab2) => tab1.SortOrder - tab2.SortOrder);
    }

    return reportingTabsByRange;
  },
);

export const getSortedReportingTabsForCurrentRange = createSelector(
  getGroupedReportingTabs,
  getCurrentRangeId,
  (reportingTabsByRange, rangeId) => reportingTabsByRange[rangeId] ?? [],
);

export const getChartUserAddonsDict = createSelector(getReportingDataState, (state) => state.chartUserAddonsDict);
export const getChartConfig = createSelector(getReportingDataState, (state) => state.chartConfig);
export const getChartDtos = createSelector(getReportingDataState, (state) => state.chartDtos);
export const getChartIds = createSelector(getChartDtos, (chartDtos) => buildChartIds(chartDtos));
export const getChartById = (chartId: number): MemoizedSelector<any, ChartDto | undefined> =>
  createSelector(getChartDtos, (charts) => {
    return charts.find((chart) => chart.Id === chartId);
  });

export const getSelectedReportingTabId = createSelector(getReportingDataState, (state) => state.selectedReportingTabId);
export const getPrevSelectedReportingTabIds = createSelector(getReportingDataState, (state) => state.prevSelectedReportingTabIds);
export const getSelectedSimulationTime = createSelector(getReportingDataState, (state) => state.selectedSimulationTime);
export const getIsCurrentScenarioCalculationActive = createSelector(getReportingDataState, (state) => state.calculationJobId !== undefined);
export const getCurrentCalculationStatus = createSelector(getReportingDataState, (state) => getCurrentCalculationStatusDetails(state));

export const getDepthData = createSelector(getReportingDataState, (reportingState) => reportingState.depthDataForScenarios);
export const getIsFetchingCurrentReportingTab = createSelector(
  getReportingDataState,
  (state) =>
    (state.selectedReportingTabId != null &&
      state.selectedReportingTabId > 0 &&
      state.reportingTabsIdsRequested.findIndex((tabId) => tabId === state.selectedReportingTabId) !== -1) as boolean,
);
export const getDepthDataScenarioIds = createSelector(getReportingDataState, (reportingState) => reportingState.depthDataForScenarios.ids);

export const getGaugeDataRequestedAfterFileImport = createSelector(getReportingDataState, (state) => state.gaugeDataRequestedAfterFileImport);

export const getReportingChartHasAnyImportData = createSelector(
  getChartState,
  (chartState) => chartState.chartData?.ChartDataSets.some((dataSet) => dataSet.IsImportedData) === true,
);

export const getReportingChartHasOnlyImportData = createSelector(
  getChartState,
  (chartState) => chartState.chartData?.ChartDataSets.every((dataSet) => dataSet.IsImportedData) === true,
);

export const getReportingLowerCompletionRange = createSelector(getReportingDataState, (state) => state.lowerCompletionRange);
export const getIsExportDataJobActive = createSelector(getReportingDataState, (state) => state.exportDataJobPayload != null);

export const selectChartSeriesTemplates = createSelector(getReportingDataState, (state) => state.chartSeriesTemplates);

export const getCurrentCalculationStatusProgress = createSelector(getReportingDataState, (state) => state.calculationJobProgress);

function getCurrentCalculationStatusDetails(state: ReportingModuleState): ICurrentCalculationStatusDetails {
  return {
    calculationJobId: state.calculationJobId,
    calculationJobStatus: state.calculationJobStatus,
    calculationJobMessage: state.calculationJobMessage,
    calculationJobProgress: state.calculationJobProgress,
  };
}

export interface IChartResultColumn {
  Id: number;
  GroupName: string;
  ColumnName: string;
  PrimaryLeft: boolean;
  SecondaryLeft: boolean;
  PrimaryRight: boolean;
  SecondaryRight: boolean;
  DataType: DataType;
  IsImportData: boolean;
  FileName: string;
  FileType: DataFileType;
}

export interface ICurrentCalculationStatusDetails {
  calculationJobId: string | undefined;
  calculationJobStatus: ReportingCalculationJobStatus;
  calculationJobMessage: string | undefined;
  calculationJobProgress: number | undefined;
}

export const getSelectedReportingTab = createSelector(
  getSortedReportingTabsForCurrentRange,
  getSelectedReportingTabId,
  (reportingTabs, selectedReportingTabId) => {
    return reportingTabs.find((tab) => tab.Id === selectedReportingTabId);
  },
);
export const getReportingTabChartIsTimeVol = createSelector(getSelectedReportingTab, (reportingTab) => reportingTab?.IsChartTimeVolume === true);

export const getIsReportingSectionChart = createSelector(
  getUiState,
  isSimulateEvaluateTrendAnalysis,
  (state, isSimulateEvaluateTrendAnalysis): boolean =>
    isSimulateEvaluateTrendAnalysis && state.appModuleSection === 'reporting' && state.reportingTab !== 'report',
);

export const getIsReportingChartDataLoaded = createSelector(getChartState, (chartState) => {
  return chartState.chartLoadingStatus === ChartLoadingStatus.loaded && chartState.chartData != null;
});

export const getIsSecondaryArgAvailableForTimeVolChart = createSelector(getChartState, (chartState) => {
  if (!chartState.chartData) {
    return false;
  }
  return PrimarySecondaryArgumentConverter.isToSecondaryArgConversionPossible(chartState.chartData);
});

export const getCurrentlyVisibleDrawables = createSelector(getReportingDataState, (state) => state.currentlyVisibleDrawables);

export const getCurrentlyVisibleChartIds = createSelector(getCurrentlyVisibleDrawables, (visibleDrawables) =>
  visibleDrawables.map((d) => d.chartId).filter(isDefined),
);

export const getAreAnyImageProvidersAvailable = createSelector(getCurrentlyVisibleDrawables, (visibleDrawables) =>
  visibleDrawables.some((d) => d.isImageProvider),
);

export const getAreAnyVideoProvidersAvailable = createSelector(getCurrentlyVisibleDrawables, (visibleDrawables) =>
  visibleDrawables.some((d) => d.isVideoProvider),
);

export const getAreAnyDataProvidersAvailable = createSelector(getCurrentlyVisibleDrawables, (visibleDrawables) =>
  visibleDrawables.some((d) => d.isDataProvider),
);

export const getCurrentlyVisibleChartDtos = createSelector(
  getCurrentlyVisibleChartIds,
  getSelectedReportingTab,
  getChartDtos,
  getResultsDepthChartId,
  (registeredChartIds, selectedReportingTab, chartDtos, resultsDepthChartId) => {
    let chartIds: number[] = [];
    if (selectedReportingTab) {
      chartIds = [selectedReportingTab.ChartId];
    } else if (registeredChartIds.length > 0) {
      chartIds = registeredChartIds;
    } else if (resultsDepthChartId != null) {
      chartIds = [resultsDepthChartId];
    }

    return chartDtos.filter((dto) => chartIds.includes(dto.Id));
  },
);

export const getMdTvdEnabled = createSelector(
  getIsReportingSectionChart,
  getReportingTabChartIsTimeVol,
  getCanRenderDepthChart,
  getCurrentlyVisibleChartDtos,
  getIsSimulationAnimationVisible,
  (isReportingSectionChart, reportingTabChartIsTimeVol, canRenderDepthChart, currentlyVisibleChartDtos, isSimulationAnimationVisible) => {
    // disable when no charts are visible
    if (currentlyVisibleChartDtos.length === 0) {
      return false;
    }

    return (isReportingSectionChart && !reportingTabChartIsTimeVol) || (isSimulationAnimationVisible && canRenderDepthChart);
  },
);

export const getTimeVolEnabled = createSelector(
  getIsReportingSectionChart,
  getReportingTabChartIsTimeVol,
  getReportingChartHasAnyImportData,
  getIsSecondaryArgAvailableForTimeVolChart,
  getCurrentlyVisibleChartDtos,
  getIsSimulationAnimationVisible,
  getIsEvaluationAnimationVisible,
  getIsWellVisualizationVisible,
  (
    isReportingSectionChart,
    reportingTabChartIsTimeVol,
    chartHasAnyImportData,
    isSecondaryArgAvailable,
    currentlyVisibleChartDtos,
    isSimulationAnimationVisible,
    isEvaluationAnimationVisible,
    isWellVisualizationVisible,
  ) => {
    // disable when no charts are visible
    if (currentlyVisibleChartDtos.length === 0) {
      return false;
    }

    return (
      (isReportingSectionChart && reportingTabChartIsTimeVol && !chartHasAnyImportData && isSecondaryArgAvailable) ||
      (isReportingSectionChart && !reportingTabChartIsTimeVol) ||
      isSimulationAnimationVisible ||
      isEvaluationAnimationVisible ||
      isWellVisualizationVisible
    );
  },
);

export const getAllChartTemplates = createSelector(getReportingDataState, (state) => state.chartTemplates);
export const getAllSortedChartTemplates = createSelector(getAllChartTemplates, (chartTemplates) =>
  [...chartTemplates].sort((a, b) => ArrayHelpers.stringCompare(a.Name, b.Name)),
);

export const getFilteredChartTemplates = createSelector(
  getAllSortedChartTemplates,
  getAllStorageColumnsArray,
  getCurrentAppModuleType,
  getCompletionModuleState,
  getWellModuleState,
  getAreCurrentResultsPresentAndCompleted,
  (templates, allColumns, moduleType, completion, well, resultsPresent) => {
    const filteredTemplates = templates
      .filter((t) => t.Modules.includes(moduleType))
      // when results not present - filter out results only templates
      .filter((t) => isImportDataBasedTemplate(t) || resultsPresent);

    if (isSimulateBased(moduleType)) {
      const hasShunts = completion.LowerCompletion.rows.some((row) => isShuntedPipe(row.rowData));
      const hasGaugeCarriers = completion.RunningString.rows.some((row) => isGaugeCarrierPipe(row.rowData));
      const hasPerforatedCasing = well.CasingData.rows.some((row) => isPerforatedCasingPipe(row.rowData));

      return filteredTemplates.filter(
        (chart) =>
          !(
            (chart.ChartType === ChartTemplateType.ShuntTubeTotalPressures && !hasShunts) ||
            (chart.ChartType === ChartTemplateType.DuneFrontLocation && hasShunts) ||
            (chart.ChartType === ChartTemplateType.FluidVelocity && !hasGaugeCarriers) ||
            (chart.ChartType === ChartTemplateType.PerforationPacking && !hasPerforatedCasing)
          ),
      );
    } else if (moduleType === ModuleType.Evaluate) {
      const isBottomHolePressurePresent = allColumns.some((col) => col.DataType === DataType.Bottomhole_Pressure);
      const isBottomHoleTemperaturePresent = allColumns.some((col) => col.DataType === DataType.Bottomhole_Temperature);

      return filteredTemplates.filter(
        (chart) =>
          !(
            (chart.ChartType === ChartTemplateType.TotalPressures && !isBottomHolePressurePresent) ||
            (chart.ChartType === ChartTemplateType.EquivalentCirculatingDensity && !isBottomHolePressurePresent) ||
            (chart.ChartType === ChartTemplateType.TotalFrictionPressures && !isBottomHolePressurePresent) ||
            (chart.ChartType === ChartTemplateType.Temperatures && !isBottomHoleTemperaturePresent)
          ),
      );
    } else if (moduleType === ModuleType.Trend_Analysis) {
      const isTreatingPressurePresent = allColumns.some((col) => col.DataType === DataType.Pump_Pressure);
      const isPumpRatePresent = allColumns.some((col) => col.DataType === DataType.Pump_Rate);
      const isBottomHoleTemperaturePresent = allColumns.some((col) => col.DataType === DataType.Bottomhole_Temperature);
      const bottomHolePressureCount = allColumns.filter((col) => col.DataType === DataType.Bottomhole_Pressure).length;

      return filteredTemplates.filter(
        (chart) =>
          !(
            (chart.ChartType === ChartTemplateType.SurfacePumpingData && !isTreatingPressurePresent) ||
            (chart.ChartType === ChartTemplateType.SectionFrictionPressures && !isTreatingPressurePresent && bottomHolePressureCount < 2) ||
            (chart.ChartType === ChartTemplateType.NormalizedSectionFrictionPressures &&
              !isTreatingPressurePresent &&
              bottomHolePressureCount < 2) ||
            (chart.ChartType === ChartTemplateType.TotalPressures && bottomHolePressureCount === 0) ||
            (chart.ChartType === ChartTemplateType.Temperatures && !isBottomHoleTemperaturePresent) ||
            (chart.ChartType === ChartTemplateType.TemperatureTimeDerivative && !isBottomHoleTemperaturePresent) ||
            (chart.ChartType === ChartTemplateType.TemperatureVolumeDerivative && !isPumpRatePresent) ||
            (chart.ChartType === ChartTemplateType.PressureVolumeDerivative && !isPumpRatePresent)
          ),
      );
    }

    return filteredTemplates;
  },
);

const isImportDataBasedTemplate = ({ Series, DefaultFileTypes }: IChartTemplateDto): boolean => {
  for (const s of Series) {
    if (ChartTemplateHelpers.isFileTypeMatching(DataFileType.ImportedData, DefaultFileTypes, s.fileType)) {
      return true;
    }
  }

  return false;
};

const buildChartIds = (chartDtos: ChartDto[]): ChartIds => {
  return {
    wellSurveyChartId: getChartId(chartDtos, ChartDataSourceType.WellSurvey),
    caliperChartId: getChartId(chartDtos, ChartDataSourceType.Caliper),
    frictionCalculatorChartId: getChartId(chartDtos, ChartDataSourceType.FrictionCalculator),
    settlingCalculatorChartId: getChartId(chartDtos, ChartDataSourceType.SettlingCalculator),
    psdAChartId: getChartId(chartDtos, ChartDataSourceType.Psd),
    consolidatedPsd: getChartId(chartDtos, ChartDataSourceType.ConsolidatedPsd),
    rheometerChartId: getChartId(chartDtos, ChartDataSourceType.Rheometer),
    injectionTestCalculatorChartId: getChartId(chartDtos, ChartDataSourceType.InjectionTestCalculator),
    trendAnalysisChartId: getChartId(chartDtos, ChartDataSourceType.ChartSourceTrendAnalysis),
    gaugeDataChartId: getChartId(chartDtos, ChartDataSourceType.ChartSourceGaugeData),
    wellVisualizationId: getChartId(chartDtos, ChartDataSourceType.WellVisualization),
    simulationVisualizationId: getChartId(chartDtos, ChartDataSourceType.SimulationVisualization),
  };
};
export const getIsTrendAnalysisDisplayed = createSelector(
  getUiState,
  trendAnalysisEnabled,
  (uiState, isTrendAnalysisEnabled) => isTrendAnalysis(uiState.appModuleType) && isTrendAnalysisEnabled,
);

export const getCrosshairMode = createSelector(getReportingDataState, (state) => state.crosshairMode);
export const getTooltipPosition = createSelector(getReportingDataState, (state) => state.tooltipPosition);
export const getMaxSeriesInTooltip = createSelector(getReportingDataState, (state) => state.maxSeriesInTooltip);
export const getIsCrosshairVisible = createSelector(
  getCrosshairMode,
  (mode) => mode === CrosshairMode.MULTIPLE || mode === CrosshairMode.SINGLE,
);
export const getIsReportPanelVisible = createSelector(
  getAreCurrentResultsPresentAndCompleted,
  getStorageColumnNames,
  getCurrentAppModuleType,
  isPSDModuleValid,
  (areResultsPresentAndCompleted, storageColumnNames, appModuleType, isPSDValid): boolean =>
    areResultsPresentAndCompleted || storageColumnNames.length > 0 || (appModuleType === ModuleType.PSD_Analysis_Full && isPSDValid.hasResults),
);

export const getAnimationChartState = createSelector(
  getResultsTimeChartDataState,
  getResultsEvaluatePressureChartState,
  getResultsEvaluateFrictionChartState,

  (timeChartState, evaluatePressureChartState, evaluateFrictionChartState) => ({
    timeChartState,
    evaluatePressureChartState,
    evaluateFrictionChartState,
  }),
);

export const getIsReportingEnabled = createSelector(
  getAreCurrentResultsPresentAndCompleted,
  getAnyFilesImported,
  getCurrentAppModuleType,
  getIsCurrentScenarioCalculationActive,
  isPSDModuleValid,
  (...[resultsPresentAndCompleted, anyFilesImported, appModuleType, isCalculationActive, isPSDModuleValid]) =>
    !isCalculationActive &&
    ((isSimulateBased(appModuleType) && resultsPresentAndCompleted) ||
      (appModuleType === ModuleType.Trend_Analysis && anyFilesImported) ||
      (appModuleType === ModuleType.Evaluate && (resultsPresentAndCompleted || anyFilesImported)) ||
      (appModuleType === ModuleType.Data_Analysis && anyFilesImported) ||
      ([ModuleType.PSD_Analysis_Basic, ModuleType.PSD_Analysis_Full].includes(appModuleType) &&
        isPSDModuleValid.isAtLeastOneSelected &&
        isPSDModuleValid.isAllSelectedPSDValid &&
        isPSDModuleValid.hasResults)),
);

// Return compareScenarioIds for currently visible chart that can be used in compare scenario mode
export const getCurrentlyVisibleChartCompareScenarioIds = createSelector(
  getCurrentlyVisibleChartDtos,
  getCompletedResultsForCurrentModule,
  getCurrentScenarioId,
  getScenariosToCompare,
  getIsCompareScenariosEnabled,
  (chartDtos, completedResults, currentScenarioId, scenariosToCompare, isCompareScenariosEnabled) => {
    if (!isCompareScenariosEnabled || !chartDtos.every((chart) => chart.IsCompareScenarioActive)) {
      return [];
    }

    const scenarioIdsWithResults = completedResults.map((r) => r.scenarioId);
    return scenariosToCompare.scenarioIds.filter(
      (compareScenarioId) => compareScenarioId !== currentScenarioId && scenarioIdsWithResults.includes(compareScenarioId),
    );
  },
);

export const getReportingIsOptimizeActive = createSelector(
  getIsReportingSectionChart,
  getCurrentlyVisibleChartDtos,
  (isReportingChart, chartDtos) => {
    // there is always one chart in reporting tab
    return isReportingChart ? chartDtos[0]?.IsOptimizeActive : false;
  },
);

export const getReportingIsCompareScenarioActive = createSelector(
  getIsReportingSectionChart,
  getCurrentlyVisibleChartDtos,
  (isReportingChart, chartDtos) => {
    // there is always one chart in reporting tab
    return isReportingChart ? chartDtos[0]?.IsCompareScenarioActive : false;
  },
);

export const getChartTimeVolModeOrUndefined = createSelector(getCurrentlyVisibleChartDtos, (chartDtos): ChartTimeVolMode | undefined => {
  if (chartDtos == null || chartDtos.length === 0) {
    return undefined;
  }

  return chartDtos.every((chart) => chart.TimeVolumeMode === ChartTimeVolMode.volume) ? ChartTimeVolMode.volume : ChartTimeVolMode.time;
});

export const getChartTimeVolMode = createSelector(getChartTimeVolModeOrUndefined, (chartTimeVolMode): ChartTimeVolMode => {
  if (chartTimeVolMode == null) {
    return ChartTimeVolMode.time;
  }

  return chartTimeVolMode;
});

export const getReportingChartMdTvdMode = createSelector(getCurrentlyVisibleChartDtos, (chartDtos): ChartMdTvdMode => {
  if (chartDtos == null || chartDtos.length === 0) {
    return ChartMdTvdMode.md;
  }

  return chartDtos.every((chart) => chart.MdTvdMode === ChartMdTvdMode.tvd) ? ChartMdTvdMode.tvd : ChartMdTvdMode.md;
});

export const getCalculationChartTimeVolMode = createSelector(
  getChartDtos,
  getCurrentAppModuleType,
  (chartDtos, moduleType): ChartTimeVolMode => {
    const chartDataSourceType = isSimulateBased(moduleType)
      ? ChartDataSourceType.ChartSourceResultsTimeBased
      : ChartDataSourceType.ChartSourceEvaluatePressure;

    const chartDto = chartDtos.find((dto) => dto.Source === chartDataSourceType);

    return chartDto?.TimeVolumeMode === ChartTimeVolMode.volume ? ChartTimeVolMode.volume : ChartTimeVolMode.time;
  },
);

export const getRestrictedReportingChartTimeVolMode = createSelector(
  getReportingDataState,
  getReportingChartHasAnyImportData,
  getIsReportingSectionChart,
  getIsSecondaryArgAvailableForTimeVolChart,
  getIsReportingChartDataLoaded,
  getReportingTabChartIsTimeVol,
  getChartTimeVolMode,
  (
    state,
    chartHasAnyImportData,
    isReportingSectionChart,
    isSecondaryArgAvailable,
    isReportingChartDataLoaded,
    reportingTabChartIsTimeVol,
    timeVolMode,
  ) => {
    const volumeNotAvailable =
      isReportingSectionChart &&
      isReportingChartDataLoaded &&
      (chartHasAnyImportData || (reportingTabChartIsTimeVol && !isSecondaryArgAvailable));

    return volumeNotAvailable ? ChartTimeVolMode.time : timeVolMode;
  },
);

/**
 * Returns ChartTimeVolMode | undefined.
 * When time/vol can't be unambiguously determined (eg: no data yet loaded) - undefined is returned
 */
export const getUnmappedRestrictedReportingChartTimeVolMode = createSelector(
  getReportingChartHasAnyImportData,
  getIsReportingSectionChart,
  getIsSecondaryArgAvailableForTimeVolChart,
  getIsReportingChartDataLoaded,
  getReportingTabChartIsTimeVol,
  getChartTimeVolMode,
  (
    chartHasAnyImportData,
    isReportingSectionChart,
    isSecondaryArgAvailable,
    isReportingChartDataLoaded,
    reportingTabChartIsTimeVol,
    chartTimeVolMode,
  ): ChartTimeVolMode | undefined => {
    if (!isReportingSectionChart || !isReportingChartDataLoaded) {
      return undefined;
    }

    const volumeNotAvailable = chartHasAnyImportData || (reportingTabChartIsTimeVol && !isSecondaryArgAvailable);

    return volumeNotAvailable ? ChartTimeVolMode.time : chartTimeVolMode;
  },
);
