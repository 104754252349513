<div class="grid-form-container cols-2 main-grid">
  <div class="grid-form-container cols-2 no-padding" appEqualizeSymbols>
    <app-check-box
      [source]="pumping"
      [key]="'IsAutoPumpPressureSchedule'"
      label="Reduce Rate for Pump Pop-Off"
      class="col-span-2"
      (valueChanged)="onValueChange($event)"
    >
    </app-check-box>

    <app-check-box
      [source]="pumping"
      [key]="'IsAutoFracSchedule'"
      label="Reduce Rate for Fracturing"
      class="col-span-2"
      (valueChanged)="onValueChange($event)"
    >
    </app-check-box>

    <app-form-input
      [source]="pumping"
      [key]="'AutoFracSafetyMargin'"
      label="Pressure Margin"
      appUnwrap
      [decimalPlaces]="2"
      [disabled]="!isTxtAutoFracSafetyMarginEnabled"
      [unitType]="UnitType.Pressure"
      (valueChanged)="onValueChange($event)"
      dataCy="pressure-margin"
    ></app-form-input>

    <app-form-input
      [source]="pumping"
      [key]="'AutoScheduleRateStep'"
      label="Rate Step"
      appUnwrap
      [decimalPlaces]="2"
      [unitType]="UnitType.Rate"
      [disabled]="!isTxtAutoScheduleRateStepEnabled"
      (valueChanged)="onValueChange($event)"
    ></app-form-input>

    <div class="spacer col-span-2 half-size"></div>
    <!--  Auto locate pressure attenuators left column  -->
    <app-check-box
      [source]="pumping"
      [key]="'IsAutoPressureAttenuatorLocation'"
      label="Auto Locate Pressure Attenuators"
      class="col-span-2"
      (valueChanged)="onValueChange($event)"
      dataCy="auto-locate-pressure-attenuators"
    >
    </app-check-box>
    <app-form-input
      [source]="pumping"
      [key]="'AutoPressureAttenuatorSafetyMargin'"
      label="Pressure Margin"
      appUnwrap
      [decimalPlaces]="2"
      [unitType]="UnitType.Pressure"
      (valueChanged)="onValueChange($event)"
      [disabled]="!pumping.IsAutoPressureAttenuatorLocation"
      dataCy="auto-locate-pressure-margin"
    ></app-form-input>
    <!--  End auto locate pressure attenuators left column  -->

    <div class="spacer col-span-2 half-size"></div>

    <!--  Auto manage pressure left column  -->
    <app-check-box
      [source]="pumping"
      [key]="'IsAutoManagePressure'"
      label="Auto Manage Pressure"
      class="col-span-2"
      (valueChanged)="onValueChange($event)"
      dataCy="auto-manage-pressure"
    >
    </app-check-box>

    <app-radio-group
      [items]="[
        { value: false, text: 'Pressure' },
        { value: true, text: 'Equivalent Mud Weight'}
      ]"
      [source]="pumping"
      [key]="'IsAutoManagePressureEMW'"
      (valueChanged)="onValueChange($event)"
      [disabled]="!pumping.IsAutoManagePressure"
      class="grid-two-one"
      dataCy="is-auto-manage-pressure-emw"
      layout="horizontal"
    >
    </app-radio-group>

    <app-form-input
      [source]="pumping"
      [key]="'AutoManagePressureMD'"
      label="Anchor point MD"
      appUnwrap
      [decimalPlaces]="2"
      [disabled]="!pumping.IsAutoManagePressure"
      [unitType]="UnitType.Long_Length"
      (valueChanged)="onValueChange($event)"
      dataCy="auto-manage-pressure-md"
    ></app-form-input>

    <app-form-input [source]="pumping"
                    [key]="'AutoManagePressureTarget'"
                    label="Target Pressure"
                    appUnwrap
                    [decimalPlaces]="2"
                    [unitType]="UnitType.Pressure"
                    [disabled]="!pumping.IsAutoManagePressure"
                    [hidden]="pumping.IsAutoManagePressureEMW"
                    (valueChanged)="onValueChange($event)"
                    dataCy="auto-manage-pressure-target"
    ></app-form-input>

    <app-form-input [source]="pumping"
                    [key]="'AutoManagePressureEMWTarget'"
                    label="Target EMW"
                    appUnwrap
                    [decimalPlaces]="3"
                    [unitType]="UnitType.Liquid_Density"
                    [disabled]="!pumping.IsAutoManagePressure"
                    [hidden]="!pumping.IsAutoManagePressureEMW"
                    (valueChanged)="onValueChange($event)"
                    dataCy="auto-manage-pressure-emw-target"
    ></app-form-input>
    <!--  End auto manage pressure left column  -->
  </div>

  <div class="grid-form-container cols-2 no-padding" appEqualizeSymbols>
    <app-check-box
      [source]="pumping"
      [key]="'IsAutoAlphaSchedule'"
      label="Reduce Rate for Alpha/Alpha"
      class="col-span-2"
      (valueChanged)="onValueChange($event)"
      dataCy="reduce-rate-alpha-alpha"
    >
    </app-check-box>

    <div class="spacer col-span-2"></div>

    <app-form-input
      [source]="pumping"
      [key]="'AutoAlphaSafetyMargin'"
      label="Length Margin"
      appUnwrap
      [decimalPlaces]="2"
      [unitType]="UnitType.Long_Length"
      [disabled]="!isTxtAutoAlphaSafetyMarginEnabled"
      (valueChanged)="onValueChange($event)"
    ></app-form-input>

    <app-form-input
      [source]="pumping"
      [key]="'AutoScheduleMinRate'"
      label="Minimum Rate"
      appUnwrap
      [decimalPlaces]="2"
      [unitType]="UnitType.Rate"
      [disabled]="!isTxtAutoScheduleMinRateEnabled"
      (valueChanged)="onValueChange($event)"
    ></app-form-input>

    <div class="spacer col-span-2 half-size"></div>
    <div class="spacer col-span-2"></div>

    <!--  Auto locate pressure attenuators right column  -->
    <app-form-input
      [source]="pumping"
      [key]="'AutoPressureAttenuatorActivation'"
      label="Activation Pressure"
      appUnwrap
      [decimalPlaces]="2"
      [unitType]="UnitType.Pressure"
      [disabled]="!isTxtAutoPressureAttenuatorActivationEnabled"
      (valueChanged)="onValueChange($event)"
      dataCy="activation-pressure"
    ></app-form-input>
    <!--  End auto locate pressure attenuators right column  -->

    <div class="spacer col-span-2 half-size"></div>
    <div class="spacer col-span-2"></div>
    <div class="spacer col-span-2"></div>

    <!--  Auto manage pressure right column  -->

    <label>Reference Location</label>
    <app-select
      [source]="pumping"
      [key]="'AutoManagePressureLocation'"
      [items]="referenceLocationOptions"
      [disabled]="!pumping.IsAutoManagePressure"
      dataCy="auto-manage-reference-location"
      (valueChanged)="onValueChange($event)"
    ></app-select>

    <app-form-input [source]="pumping"
                    [key]="'AutoManagePressureTolerance'"
                    label="Pressure Tolerance"
                    appUnwrap
                    [decimalPlaces]="2"
                    [unitType]="UnitType.Pressure"
                    [disabled]="!pumping.IsAutoManagePressure"
                    [hidden]="pumping.IsAutoManagePressureEMW"
                    (valueChanged)="onValueChange($event)"
                    dataCy="auto-manage-pressure-tolerance"
    ></app-form-input>

    <app-form-input [source]="pumping"
                    [key]="'AutoManagePressureEMWTolerance'"
                    label="EMW Tolerance"
                    appUnwrap
                    [decimalPlaces]="3"
                    [unitType]="UnitType.Liquid_Density"
                    [disabled]="!pumping.IsAutoManagePressure"
                    [hidden]="!pumping.IsAutoManagePressureEMW"
                    (valueChanged)="onValueChange($event)"
                    dataCy="auto-manage-pressure-emw-tolerance"
    ></app-form-input>
    <!--  End auto manage pressure right column  -->
  </div>
  <div class="spacer col-span-2 half-size"></div>

  <div class="buttons-panel" *ngIf="copyButtonsDisabled$ | async as copyButtonsDisabled">
    <div>
      <app-button
        id="btnCopyGeneratedSchedule"
        dataCy="btn-copy-generated-schedule"
        [disabled]="copyButtonsDisabled.hasCalcEngineGeneratedScheduleDisabled"
        (buttonClick)="onCopyGeneratedScheduleClick()"
        [isDeleteResultsEnabled]="false"
        text="Override Pump Schedule"
      >
      </app-button>
      <app-button
        id="btnCopyGeneratedRunningString"
        dataCy="btn-copy-generated-running-string"
        [disabled]="copyButtonsDisabled.hasCalcEngineGeneratedRunningStringDisabled"
        (buttonClick)="onCopyGeneratedRunningStringClick()"
        [isDeleteResultsEnabled]="false"
        text="Override Running String"
      >
      </app-button>
    </div>
  </div>
</div>
