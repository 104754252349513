import { ChangeDetectorRef, Component, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import * as dbConnectionActions from '../../../+store/backend-connection/backend-connection.actions';
import { dbConnectedSuccessAction, setPageTitle } from '../../../+store/backend-connection/backend-connection.actions';
import { BackendConnectionModuleState, ConnectionStatus } from '../../../+store/backend-connection/backend-connection.reducer';
import {
  getConnectedFile,
  getConnectionStatusWithScenarioId,
  IConnectionStatusWithScenarioId,
} from '../../../+store/backend-connection/backend-connection.selectors';
import { changeCurrentScenarioIdAction } from '../../../+store/scenario/scenario.actions';
import { IFile } from '@dunefront/common/dto/file.dto';
import { FileUrlHelper } from '../../../pages/home/file-url.helper';
import { Actions, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { getSavedRangeId } from '../../../+store/range/range.selectors';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { changeCurrentRangeIdAction } from '../../../+store/range/range.actions';
import { getCurrentScenario, getScenarioState } from '../../../+store/scenario/scenario.selectors';
import {
  findAccessibleFeaturesAndLicenses,
  getIsAnyFeatureUsableFactory,
  getLicensingState,
} from '../../../+store/licensing/licensing.selectors';
import { ScenarioConstants } from '@dunefront/common/modules/scenario/scenario.dto';
import { isElectron } from '@dunefront/common/common/electron/is-electron';
import { LicensingModuleState } from '@dunefront/common/modules/licensing/licensing-module.state';
import {
  licenseInfoLoadedAction,
  licenseSwitchSuccessAction,
  openModuleSelectorDialog,
  updateMainLicenseAction,
} from '../../../+store/licensing/licensing.actions';
import { AppTargetConfig } from '../../services/app-target-config';
import { getIsHelpPinned, getIsReady, getIsSideNavPinned } from '../../../+store/ui/ui.selectors';
import { filterNil } from '@dunefront/common/common/state.helpers';
import { LicensingEffects } from '../../../+store/licensing/licensing.effects';
import { VideoRecorderService } from '../../../common-modules/chart/video-recorder.service';
import { RouterHelperService } from '../../services/router-helper.service';
import { FileManagerHelper } from '../../../+store/file-manager/file-manager.helper';
import { ModalService } from '../../../common-modules/modals/modal.service';

@Component({
  selector: 'app-file-dependent-content',
  templateUrl: './db-dependent-content.component.html',
  styleUrls: ['./db-dependent-content.component.scss'],
})
export class DbDependentContentComponent implements OnDestroy {
  public isMenuVisible = false;

  private subscription = new Subscription();

  public isSideNavPinned$ = this.store.select(getIsSideNavPinned);
  public isHelpPinned$ = this.store.select(getIsHelpPinned);

  public isReady$ = this.store.select(getIsReady);
  public recordingState$ = this.videoRecorder.getCurrentProviderIdRecording();

  @HostListener('dragover', ['$event'])
  public onDragOver(e: DragEvent): void {
    e.preventDefault();
    if (e.dataTransfer) {
      e.dataTransfer.dropEffect = 'none';
    }
  }

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    protected appConfig: AppTargetConfig,
    protected licensingEffects: LicensingEffects,
    private videoRecorder: VideoRecorderService,
    private readonly routerHelperService: RouterHelperService,
    actions$: Actions,
    private modalService: ModalService,
  ) {
    const connectedConnectionStatus$ = this.store
      .select(getConnectionStatusWithScenarioId)
      .pipe(filter((status) => status.connectionState.backendConnectionStatus === ConnectionStatus.connected));

    const currentRangeId$ = this.store.select(getSavedRangeId);
    const licensingState$ = this.store.select(getLicensingState);

    // this is triggered when backend is connected (when page refresh)
    this.subscription.add(
      actions$
        .pipe(
          ofType(licenseInfoLoadedAction, licenseSwitchSuccessAction),
          concatLatestFrom(() => [connectedConnectionStatus$, currentRangeId$, licensingState$]),
        )
        .subscribe(async ([action, connState, currentRangeId, licensingState]) => {
          const params = this.route.snapshot.params;
          await this.connectToDbIfNeeded(
            params,
            connState,
            currentRangeId ?? RangeConstants.EntireRangeId,
            false,
            licensingState,
            action.type === licenseSwitchSuccessAction.type,
          );
        }),
    );

    // this is triggered when file is opened
    this.subscription.add(
      actions$
        .pipe(
          ofType(dbConnectedSuccessAction),
          //filter((action) => action.payload.scenarios.length > 0),
          concatLatestFrom(() => [licensingState$]),
        )
        .subscribe(async ([action, licensingState]) => {
          // check licensing here
          const selectedFeature = action.payload.licensingLoginResponse.mainFeature;
          if (!(await this.licensingEffects.isLicenseValid(selectedFeature, licensingState))) {
            return;
          }

          if (action.payload.dbConnectionResult == null || action.payload.dbConnectionResult.projectFileInfo == null) {
            console.warn('DbDependentContentComponent - no dbConnection result');
            return;
          }

          const scenarios = action.payload.dbConnectionResult.projectFileInfo.scenarios;

          if (scenarios.length == 0) {
            console.warn('DbDependentContentComponent - no scenarios');
            return;
          }

          const dbInfo = action.payload.dbConnectionResult.dbInfo;
          const projectFileInfo = action.payload.dbConnectionResult.projectFileInfo;

          const scenarioIdParam = this.route.snapshot.params.scenarioId
            ? this.route.snapshot.params.scenarioId
            : this.route.snapshot.firstChild
              ? this.route.snapshot.firstChild.params.scenarioId
              : null;

          if (scenarioIdParam && !scenarios.find((scenario) => scenario.Id === parseInt(scenarioIdParam))) {
            return FileUrlHelper.scenarioNavigate(dbInfo.file, scenarios[0].Id, RangeConstants.EmptyRangeId, selectedFeature, this.router);
          }

          if (!scenarioIdParam) {
            let scenarioId = projectFileInfo.fileSettings?.LastUsedScenarioId ?? ScenarioConstants.EmptyScenarioId;
            if (!projectFileInfo.scenarios.find((sc) => sc.Id === scenarioId)) {
              scenarioId = scenarios[0].Id;
            }

            FileUrlHelper.scenarioNavigate(dbInfo.file, scenarioId, RangeConstants.EmptyRangeId, selectedFeature, this.router);
          } else {
            const dbFilename = this.route.snapshot.params.dbFilename;
            const file = isElectron()
              ? FileUrlHelper.getElectronFileFromUrlParam(dbFilename)
              : FileUrlHelper.getPersonalFileFromUrlParam(dbFilename);

            // this is the case when working on Temp File and SaveAs action was performed
            if (dbInfo.file.Name !== file.Name) {
              FileManagerHelper.switchFile(this.router, dbInfo.file).then();
            }
          }

          this.isMenuVisible = true;
          this.cdRef.markForCheck();
        }),
    );

    // this is triggered when navigation has ended
    this.subscription.add(
      this.router.events
        .pipe(
          filter((e: any) => e instanceof NavigationEnd),
          distinctUntilChanged((e1: NavigationEnd, e2: NavigationEnd) => e1.urlAfterRedirects === e2.urlAfterRedirects),
          concatLatestFrom(() => [connectedConnectionStatus$, currentRangeId$, this.store.select(getScenarioState), licensingState$]),
        )
        .subscribe(async ([_, connState, currentRangeId, scenarioState, licensingState]) => {
          await this.connectToDbIfNeeded(
            this.route.snapshot.params,
            connState,
            currentRangeId ?? RangeConstants.EntireRangeId,
            scenarioState.isScenarioLoaded,
            licensingState,
            false,
          );
        }),
    );

    // watch changes of file name and current scenario for page title
    this.subscription.add(
      combineLatest([this.store.select(getCurrentScenario).pipe(filterNil()), this.store.select(getConnectedFile).pipe(filterNil())]).subscribe(
        ([scenario, file]) => this.store.dispatch(setPageTitle({ file, scenario })),
      ),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private async connectToDbIfNeeded(
    params: Params,
    connState: IConnectionStatusWithScenarioId,
    storeRangeId: number,
    isScenarioLoaded: boolean,
    licensingState: LicensingModuleState,
    isConnectAfterLicenseSwitch: boolean,
  ): Promise<void> {
    const { connectionState } = connState;
    this.isMenuVisible = false;

    const storeScenarioId = connState.currentScenarioId;
    const storeLicensingMainFeature = licensingState.mainFeature;
    let selectedFeature = isConnectAfterLicenseSwitch
      ? licensingState.licensingLogin?.mainFeature
      : FileUrlHelper.getLicenseFeatureFromActivatedRoute(this.route);

    const dbFile = isElectron()
      ? FileUrlHelper.getElectronFileFromUrlParam(params.dbFilename)
      : FileUrlHelper.getPersonalFileFromUrlParam(params.dbFilename);

    const paramScenarioId = !isNaN(+params.scenarioId) ? +params.scenarioId : undefined;
    const paramRangeId = !isNaN(+params.rangeId) ? +params.rangeId : RangeConstants.EmptyRangeId;

    if (selectedFeature == null) {
      // feature is not selected
      if (this.appConfig.availableModules.length === 1) {
        // when only one module, don't show selector
        selectedFeature = this.appConfig.availableModules[0];
      } else {
        this.store.dispatch(openModuleSelectorDialog({ redirectHomeAfterCancel: true, file: dbFile }));
        return;
      }
    }

    if (connectionState.backendConnectionStatus !== ConnectionStatus.connected) {
      return;
    }
    if (connectionState.dbConnectionStatus === ConnectionStatus.connecting) {
      return;
    }

    if (!connectionState.dbInfo || connectionState.dbInfo.file.Name !== dbFile.Name || !this.checkFolderMatch(dbFile, connectionState)) {
      // file not connected

      // Check if user has any valid license specific for the app type. If not show message and don't allow to open a file.
      const isAnyFeatureAvailable = await firstValueFrom(this.store.select(getIsAnyFeatureUsableFactory(this.appConfig.appName)));
      if (!isAnyFeatureAvailable) {
        await this.modalService.showAlert(`You need to have ${this.appConfig.appName} license to open a file`, 'Warning');
        await this.routerHelperService.navigateToHome();
        return;
      }

      // Continue connecting the file
      this.store.dispatch(
        dbConnectionActions.dbConnectAction({
          dbFile,
          scenarioId: paramScenarioId,
          rangeId: paramRangeId,
          licensingConfig: {
            feature: selectedFeature,
            addonFeatures: licensingState.selectedAddons,
            licenseIds: findAccessibleFeaturesAndLicenses(licensingState).licenseIds,
          },
        }),
      );
    } else {
      // file is connected

      const isLicenseValid = await this.licensingEffects.isLicenseValid(selectedFeature, licensingState, true);
      if (!isLicenseValid) {
        return;
      }

      if (selectedFeature !== storeLicensingMainFeature) {
        this.store.dispatch(updateMainLicenseAction({ feature: selectedFeature }));
      }

      if (paramScenarioId !== undefined) {
        if (!isScenarioLoaded || paramScenarioId !== storeScenarioId) {
          //set scenario if in store
          this.store.dispatch(changeCurrentScenarioIdAction({ scenarioId: paramScenarioId }));
        }
        if (paramRangeId !== undefined && paramRangeId !== storeRangeId) {
          //set range id in store
          this.store.dispatch(changeCurrentRangeIdAction({ rangeId: paramRangeId < 0 ? RangeConstants.EntireRangeId : paramRangeId }));
        }
      } else {
        // when there is no scenarioId and rangeId in url
        // after clicking to file that was already loaded
        // load it from store and redirect

        FileUrlHelper.scenarioNavigate(dbFile, storeScenarioId, storeRangeId, selectedFeature, this.router);
      }
      this.isMenuVisible = true;
      this.cdRef.markForCheck();
    }
  }

  private checkFolderMatch(dbFile: IFile, connectionState: BackendConnectionModuleState): boolean {
    if (!connectionState.dbInfo) {
      return false;
    } else {
      if (connectionState.dbInfo.file.Folder.length !== dbFile.Folder.length) {
        return false;
      } else {
        for (let i = 0; i < connectionState.dbInfo.file.Folder.length; i++) {
          if (connectionState.dbInfo.file.Folder[i] !== dbFile.Folder[i]) {
            return false;
          }
        }
      }
    }
    return true;
  }
}
