import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ILegendStyle } from '@dunefront/common/modules/reporting/dto/chart-legend';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { defaultModalCancelButton, defaultModalConfirmButton, ModalButtonConfig } from '../../modals/generic-modal/generic-modal.component';
import { defaultLegendFontColor } from '@dunefront/common/common/constants';

@Component({
  template: ` <app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
    <div *ngIf="editLegendProps">
      <app-chart-legend-style [legendStyle]="editLegendProps" (valueChanged)="onValueChanged($event)"></app-chart-legend-style>
      <app-button
        class="btn btn-primary btn-sm reset-btn"
        appButton
        type="button"
        buttonType="primary"
        [isUiLockable]="false"
        (buttonClick)="resetLegend()"
        text="Reset to default"
      >
      </app-button>
    </div>
  </app-generic-modal>`,
  styleUrls: ['./chart-legend-props.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartLegendPropsComponent {
  public editLegendProps!: ILegendStyle;
  public modalTitle = 'Legend Properties';
  public modalButtonsConfigs: ModalButtonConfig[] = [
    defaultModalCancelButton(() => this.cancelClicked(), 'Cancel'),
    defaultModalConfirmButton(() => this.onConfirm()),
  ];

  constructor(
    public activeModal: DynamicDialogRef,
    config: DynamicDialogConfig,
    private cdRef: ChangeDetectorRef,
  ) {
    this.editLegendProps = config.data.editLegendProps;
  }

  public onValueChanged(event: ObjectChangeProp<ILegendStyle>): void {
    if (event.key in this.editLegendProps) {
      Object.assign(this.editLegendProps, { [event.key]: event.value });
    }
    this.cdRef.markForCheck();
  }

  public onConfirm(): void {
    this.activeModal.close(this.editLegendProps);
  }

  public cancelClicked(): void {
    this.activeModal.close();
  }

  public resetLegend(): void {
    this.editLegendProps.LegendLocation = 0;
    this.editLegendProps.IsLegendFontBold = false;
    this.editLegendProps.IsLegendFontItalic = false;
    this.editLegendProps.LegendFontSize = 0;
    this.editLegendProps.LegendFontColor = defaultLegendFontColor;
    this.onConfirm();
  }
}
