<div #sliderWrapperElement class="slider-wrapper" [attr.data-cy]="'slider-wrapper-div'">
  <div class="btn-wrapper" [class.disabled]="disabled">
    <ng-container *ngIf="(recordingState$ | async) === null; else pause">
      <span class="slider-button icon-play-circle" [attr.data-cy]="'slider-play-button'" (click)="onPlay()"></span>
      <span class="slider-button icon-record" [attr.data-cy]="'slider-record-button'" (click)="onRecord()"></span>
    </ng-container>
    <ng-template #pause>
      <span class="slider-button icon-pause" [attr.data-cy]="'slider-pause-button'" (click)="onStop()"></span>
    </ng-template>
  </div>

  <input
    #inputElement
    type="range"
    min="0"
    max="1000000"
    (input)="sliderValueChanged($event)"
    (mousedown)="onMouseDown()"
    [value]="sliderValue"
    [disabled]="disabled"
  />

  <div #sliderLabelContainer class="sliderLabelContainer" [style]="labelStyle">
    <div class="sliderLabelContent" data-cy="slider-value-label">
      {{ labelDisplayValue }}
    </div>
  </div>
</div>
