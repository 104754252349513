<ng-container *ngIf="state">
  <div *ngIf="!state.SurveyData.isValid" class="error-message">
    Survey data validation errors must be corrected before survey preview can be generated
  </div>

  <ng-container *ngIf="state.SurveyData.isValid">
    <div *ngIf="!noMenu" class="chart-menu">
      <app-radio-group
        id="md-tvd-chart"
        dataCy="md-tvd-chart-radio"
        [items]="items"
        [source]="state"
        [key]="'IsSurveyDepthByMD'"
        [isUiLockable]="false"
        layout="horizontal"
        (valueChanged)="changeSurveyType($event)"
      >
      </app-radio-group>
    </div>

    <app-chart-controller
      class="app-chart-controller"
      [class.no-menu]="noMenu"
      *ngIf="chartState"
      [chartDisplayName]="chartDisplayName"
      [drawableProviderId]="drawableProviderId ?? chartDisplayName"
      [chartId]="chartId"
      [chartState]="chartState"
      [reverseArgument]="true"
      [rotateChart]="true"
      [dataCy]="'survey-chart'"
    ></app-chart-controller>
  </ng-container>
</ng-container>
