import { Pumping } from '../pumping/pumping';
import { IPumpingValidationDependencies, ValidatedPumpingModuleState } from '../../pumping-module.state';
import { noErrors } from '../../../../common/state.helpers';
import { ChokeSetting, HydrilState, PumpingExtensions, ToolPosition, VariableChokeSetting } from '../../dto/pumping.dto';
import { LocationType, TreatmentType } from '../../../well/dto/well.dto';
import { WellModuleState } from '../../../well/well-module.state';
import { IError } from '../../../../common/common-state.interfaces';
import { RangeConstants } from '../../../../dto/range.dto';
import { IGetCurrentFeaturesResult, LicenseFeature } from '../../../licensing/licensing.interfaces';
import { validateFeatureNotSupportedInFluidPro, validateFeatureRequired } from '../../../licensing/licensing.validator';
import { ModuleType } from '../../../../modules/scenario/scenario.dto';

export class PumpingInitialDataValidation {
  public static validatePumpingInitialDataTab(
    pumpingState: ValidatedPumpingModuleState,
    deps: IPumpingValidationDependencies,
  ): ValidatedPumpingModuleState {
    const { pumping } = pumpingState;
    if (deps.rangeId === RangeConstants.EntireRangeId) {
      return { ...pumpingState, pumping: { ...pumping, error: {} }, isInitialScreenValid: true };
    }
    const error: IError<Pumping> = {
      ReelDiameter: this.validateReelDiameter(pumping),
      IsCoiledTubing: this.validateIsCoiledTubing(pumping, deps.appModuleType),
      TreatingLineID: this.validateTreatingLineID(pumping),
      SurfaceTreatingLineLength: this.validateSurfaceTreatingLineLength(pumping),
      MaxSurfacePressure: this.validateMaxSurfacePressure(pumping),
      SurfaceChokeLineID: this.validateSurfaceChokeLineID(pumping),
      SurfaceChokeLineLength: this.validateSurfaceChokeLineLength(pumping),
      ChokeKillLineID: this.validateChokeKillLineID(pumping),
      ChokeOrificeID: this.validateChokeOrificeID(pumping),
      ChokeOpenVolume: this.validateChokeOpenVolume(pumping),
      IsSubseaBOP: this.ValidateIsSubseaBOP(pumping, deps.well),
      ChokePosition: this.ValidateChokePosition(pumping, deps),
      VariableChoke: this.ValidateIsVariableChoke(pumping, deps),
      IsChokeOpenAtVolume: this.ValidateIsChokeOpenAtVolume(pumping),
      HydrilState: this.ValidateHydrilState(pumping, deps),
      ToolPosition: this.ValidateToolPosition(pumping, deps.currentLicenseFeatures),
    };

    const isInitialScreenValid = noErrors(error);
    const newPumping = { ...pumpingState.pumping, error: { ...pumpingState.pumping.error, ...error } };
    return { ...pumpingState, pumping: newPumping, isInitialScreenValid };
  }

  private static validateReelDiameter(pumping: Pumping): string {
    if (pumping.IsCoiledTubing && pumping.ReelDiameter <= 0) {
      return 'Coiled tubing reel diameter must be greater than zero.';
    }
    return '';
  }

  private static validateTreatingLineID(pumping: Pumping): string {
    if (pumping.IsTreatingLine && pumping.TreatingLineID <= 0) {
      const tubing = pumping.IsCoiledTubing ? 'Coiled tubing' : 'Treating line';
      return `${tubing} ID must be greater than zero`;
    }
    return '';
  }

  private static validateSurfaceTreatingLineLength(pumping: Pumping): string {
    if (this.isTxtTreatLineLengthEnabled(pumping) && pumping.SurfaceTreatingLineLength <= 0) {
      const tubing = pumping.IsCoiledTubing ? 'Coiled tubing' : 'Treating line';
      return `${tubing} length must be greater than zero`;
    }
    return '';
  }

  private static validateMaxSurfacePressure(pumping: Pumping): string {
    if (pumping.MaxSurfacePressure <= 0) {
      return 'Max surface pressure must be greater than zero.';
    }
    return '';
  }

  private static validateSurfaceChokeLineID(pumping: Pumping): string {
    if (this.isTxtChokeLineIDEnabled(pumping) && pumping.SurfaceChokeLineID <= 0) {
      return 'Surface line ID must be greater than zero.';
    }
    return '';
  }

  private static validateSurfaceChokeLineLength(pumping: Pumping): string {
    if (this.isTxtSurfaceChokeLineLengthEnabled(pumping) && pumping.SurfaceChokeLineLength <= 0) {
      return 'Surface line length must be greater than zero.';
    }
    return '';
  }

  private static validateChokeKillLineID(pumping: Pumping): string {
    if (this.isTxtChokeKillLineIDEnabled(pumping) && pumping.ChokeKillLineID <= 0) {
      return 'Choke/Kill line ID must be greater than zero.';
    }
    return '';
  }

  private static validateChokeOrificeID(pumping: Pumping): string {
    if (!this.isTxtChokeOrificeIDEnabled(pumping)) {
      return '';
    }
    if (pumping.ChokeOrificeID <= 0) {
      return 'Choke orifice ID must be greater than zero.';
    }
    if (pumping.HydrilState !== HydrilState.Open && pumping.ChokeOrificeID > pumping.SurfaceChokeLineID) {
      return 'Choke orifice ID must be less than or equal to Surface line ID';
    }
    return '';
  }

  private static validateChokeOpenVolume(pumping: Pumping): string {
    if (pumping.IsChokeOpenAtVolume && pumping.ChokeOpenVolume <= 0) {
      return 'Choke open volume must be greater than zero.';
    }
    return '';
  }

  public static isTxtChokeKillLineIDEnabled(pumping: Pumping): boolean {
    return pumping.IsSubseaBOP && pumping.HydrilState !== HydrilState.Open;
  }

  public static isTxtChokeLineIDEnabled(pumping: Pumping): boolean {
    return pumping.HydrilState !== HydrilState.Open;
  }

  public static isTxtSurfaceChokeLineLengthEnabled(pumping: Pumping): boolean {
    return pumping.HydrilState !== HydrilState.Open;
  }

  public static isTxtChokeOpenVolumeEnabled(pumping: Pumping): boolean {
    return pumping.IsChokeOpenAtVolume;
  }

  public static isVariableChokeEnabled(pumping: Pumping): boolean {
    return pumping.ChokePosition !== ChokeSetting.None;
  }

  public static isTxtChokeOrificeIDEnabled(pumping: Pumping): boolean {
    return pumping.ChokePosition !== ChokeSetting.None;
  }

  public static isCheckEditReelDiameterEnabled(pumping: Pumping): boolean {
    return pumping.IsTreatingLine;
  }

  public static isTxtReelDiameterEnabled(pumping: Pumping): boolean {
    return pumping.IsCoiledTubing && pumping.IsTreatingLine;
  }

  public static isTxtTreatLineIDEnabled(pumping: Pumping): boolean {
    return pumping.IsTreatingLine;
  }

  public static isTxtTreatLineLengthEnabled(pumping: Pumping): boolean {
    return pumping.IsTreatingLine;
  }

  private static ValidateIsSubseaBOP(pumping: Pumping, well: WellModuleState): string {
    if (well.LocationType === LocationType.Land && pumping.IsSubseaBOP) {
      return 'Subsea BOP is not supported for land operations';
    }

    return '';
  }

  private static ValidateChokePosition(pumping: Pumping, { appModuleType, well }: IPumpingValidationDependencies): string {
    const fluidProNotSupportedError = validateFeatureNotSupportedInFluidPro(appModuleType);
    if (pumping.ChokePosition === ChokeSetting.Closed && fluidProNotSupportedError) {
      return fluidProNotSupportedError;
    }

    if (
      pumping.ChokePosition === ChokeSetting.None &&
      pumping.ToolPosition !== ToolPosition.Reverse &&
      well.TreatmentType === TreatmentType.High_Rate_Water_Pack
    ) {
      return 'Choke can’t be none for high rate water pack treatments (except in reverse tool position)';
    }

    if (pumping.ChokePosition === ChokeSetting.Closed && !well.IsLossRateCalculated) {
      return 'Closed choke is not supported when using specify losses';
    }

    if (pumping.ChokePosition === ChokeSetting.Closed && pumping.ToolPosition === ToolPosition.Reverse) {
      return 'Closed choke is not supported in reverse tool position';
    }

    return '';
  }

  private static ValidateIsVariableChoke(
    pumping: Pumping,
    { appModuleType, well, currentLicenseFeatures }: IPumpingValidationDependencies,
  ): string {
    const fluidProNotSupportedError = validateFeatureNotSupportedInFluidPro(appModuleType);
    if (PumpingExtensions.getVariableChoke(pumping) !== VariableChokeSetting.Fixed && fluidProNotSupportedError) {
      return fluidProNotSupportedError;
    }

    if (PumpingExtensions.getVariableChoke(pumping) === VariableChokeSetting.Managed_Pressure) {
      const requiredFeatureError = validateFeatureRequired([LicenseFeature.MPGP], currentLicenseFeatures);
      if (requiredFeatureError) {
        return requiredFeatureError;
      }
    }

    if (!well.IsLossRateCalculated && PumpingExtensions.getVariableChoke(pumping) === VariableChokeSetting.Managed_Rate) {
      return 'Managed Rate is not supported when using specify losses';
    }

    if (well.IsLossRateCalculated && PumpingExtensions.getVariableChoke(pumping) === VariableChokeSetting.Managed_Pressure) {
      return 'Managed pressure is not supported when using calculate losses';
    }

    if (
      pumping.ChokePosition === ChokeSetting.Closed &&
      PumpingExtensions.getVariableChoke(pumping) === VariableChokeSetting.Managed_Pressure &&
      !pumping.IsChokeOpenAtVolume
    ) {
      return 'Managed pressure is not supported when the choke is permanently closed';
    }

    if (
      pumping.ChokePosition === ChokeSetting.Closed &&
      PumpingExtensions.getVariableChoke(pumping) === VariableChokeSetting.Managed_Rate &&
      !pumping.IsChokeOpenAtVolume
    ) {
      return 'Managed Rate is not supported when the choke is permanently closed';
    }

    if (
      pumping.ToolPosition !== ToolPosition.Reverse &&
      (pumping.ChokePosition === ChokeSetting.Open || (pumping.ChokePosition === ChokeSetting.Closed && pumping.IsChokeOpenAtVolume)) &&
      well.TreatmentType === TreatmentType.High_Rate_Water_Pack &&
      PumpingExtensions.getVariableChoke(pumping) !== VariableChokeSetting.Managed_Rate
    ) {
      return (
        'Managed Rate is required for High Rate Water Pack treatments' +
        ' (except in reverse tool position or when the choke is permanently closed)'
      );
    }

    if (pumping.ToolPosition === ToolPosition.Reverse && PumpingExtensions.getVariableChoke(pumping) === VariableChokeSetting.Managed_Rate) {
      return 'Managed Rate is not supported in reverse tool position';
    }

    return '';
  }

  private static ValidateIsChokeOpenAtVolume(pumping: Pumping): string {
    if (pumping.IsChokeOpenAtVolume && pumping.ChokePosition !== ChokeSetting.Closed) {
      return 'This option is only supported when choke is closed';
    }

    return '';
  }

  private static ValidateHydrilState(pumping: Pumping, { appModuleType, well }: IPumpingValidationDependencies): string {
    const fluidProNotSupportedError = validateFeatureNotSupportedInFluidPro(appModuleType);
    if (pumping.HydrilState === HydrilState.Two_Lines && fluidProNotSupportedError) {
      return fluidProNotSupportedError;
    }

    if (
      pumping.ToolPosition !== ToolPosition.Reverse &&
      well.TreatmentType === TreatmentType.High_Rate_Water_Pack &&
      pumping.HydrilState === HydrilState.Open
    ) {
      return 'Open BOP is only supported in reverse tool position for High Rate Water Pack treatments';
    }

    if (!pumping.IsSubseaBOP && pumping.HydrilState === HydrilState.Two_Lines) {
      return 'Two lines are not supported for non-subsea BOPs';
    }
    return '';
  }

  private static ValidateToolPosition(pumping: Pumping, currentLicenseFeatures: IGetCurrentFeaturesResult): string {
    if ([ToolPosition.Washdown, ToolPosition.Reverse_Port_GP].includes(pumping.ToolPosition)) {
      return validateFeatureRequired([LicenseFeature.Simulate, LicenseFeature.Evaluate, LicenseFeature.Simulate_Disp], currentLicenseFeatures);
    }
    return '';
  }

  private static validateIsCoiledTubing(pumping: Pumping, appModuleType: ModuleType): string {
    const fluidProNotSupportedError = validateFeatureNotSupportedInFluidPro(appModuleType);
    if (pumping.IsCoiledTubing && fluidProNotSupportedError) {
      return fluidProNotSupportedError;
    }
    return '';
  }
}
