import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { LoadScenarioAction, LoadScenarioActionResponse, ScenarioModuleName } from '@dunefront/common/modules/scenario/scenario-module.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { firstValueFrom, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, tap } from 'rxjs/operators';
import { BackendConnectionService } from '../shared/backend-connection/backend-connection.service';
import { SettingsEffects } from './settings/settings-effects.service';
import {
  dataFailed,
  deleteRowsSuccess,
  insertRowsSuccess,
  loadScenarioDataAction,
  loadScenarioDataSuccessAction,
  progressUpdateAction,
  refreshAppAction,
  resetScenarioState,
  scenarioOrRangeLoadedAction,
  switchModuleAction,
  updateRowSuccess,
} from './app.actions';
import * as actions from './backend-connection/backend-connection.actions';
import {
  backendConnectFailedAction,
  dbConnectedSuccessAction,
  dbConnectionFailedAction,
  dbDisconnectAction,
  dbDisconnectSuccessAction,
} from './backend-connection/backend-connection.actions';
import { CalculationEngineEffects } from './calculation-engine/calculation-engine.effects';
import { CompletionEffects } from './completion/completion.effects';
import { FluidEffects } from './fluid/fluid.effects';
import { GravelEffects } from './gravel/gravel.effects';
import { PumpingEffects } from './pumping/pumping.effects';
import { ReportingEffects } from './reporting/reporting.effects';
import { changeCurrentScenarioIdAction } from './scenario/scenario.actions';
import { ScenarioEffects } from './scenario/scenario.effects';
import { WellEffects } from './well/well.effects';
import { AboutEffects } from './about/about.effects';
import { DbMigrationEffects } from './db-migration/db-migration.effects';
import { FileManagerEffects } from './file-manager/file-manager.effects';
import { ClientErrorHandlerService, IErrorState } from '../shared/components/error/client-error-handler.service';
import { UndoRedoEffects } from './undo-redo/undo-redo.effects';
import { ActionNotification } from '@dunefront/common/ws.action';
import { UiActionTypes, UpdateNotificationActionName } from '@dunefront/common/modules/common.actions';
import { getFileHash } from './backend-connection/backend-connection.selectors';
import { getAllScenarios, getCurrentScenarioId } from './scenario/scenario.selectors';
import { UnitsEffects } from './units/units.effects';
import { DbDependentCommonDbEffects } from './common-db/db-dependent-common-db-effects.service';
import { AuthEffects } from './auth/auth.effects';
import { MASPCalculatorEffects } from './calculators/masp-calculator/masp-calculator.effects';
import { ResuspensionCalculatorEffects } from './calculators/resuspension-calculator/resuspension-calculator.effects';
import { FrictionCalculatorEffects } from './calculators/friction-calculator/friction-calculator.effects';
import { SettlingCalculatorEffects } from './calculators/settling-calculator/settling-calculator.effects';
import { InjectionTestCalculatorEffects } from './calculators/injection-test-calculator/injection-test-calculator.effects';
import { LeakoffCoefficientCalculatorEffects } from './calculators/leakoff-coefficient-calculator/leakoff-coefficient-calculator.effects';
import { PSDAnalysisEffects } from './psd-analysis/psd-analysis.effects';
import { ImportDataEffects } from './import-data/import-data.effects';
import { CalculationEngineDbDependentEffects } from './calculation-engine/calculation-engine-db-dependent.effects';
import { GaugeDataEffects } from './gauge-data/gauge-data.effects';
import { DataStorageEffects } from './data-storage/data-storage.effects';
import { RangeEffects } from './range/range.effects';
import { FileSettingsEffects } from './file-settings/file-settings.effects';
import { SummaryEffects } from './summary/summary.effects';
import { ReportInfoEffects } from './report-info/report-info.effects';
import { ReferenceVariablesEffects } from './reference-variables/reference-variables.effects';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { LicensingEffects } from './licensing/licensing.effects';
import { ElectronMainEffects } from './electron-main/electron-main.effects';
import { EquationEffects } from './equation/equation.effects';
import { ErrorTestingEffects } from './error-testing/error-testing.effects';
import { BaseWsEffects } from './base-ws.effects';
import { ModalService } from '../common-modules/modals/modal.service';
import { ReportingTabEffects } from './reporting/reporting-tab.effects';

import { setShouldShowBeforeUnloadWarningAction } from './ui/ui.actions';
import { UiEffects } from './ui/ui.effects';
import { ElectronService } from '../shared/services/electron-service/electron.service';
import { isLongCalculationModuleType, ScenarioConstants } from '@dunefront/common/modules/scenario/scenario.dto';
import { RouterHelperService } from '../shared/services/router-helper.service';
import { JsWorkerJobsEffects } from './calculation-engine/js-worker-jobs.effects';
import { getScenarioOrRangeLoaded } from './app.selector';
import { NoteEffects } from './note/note.effects';
import { CommonDbEffects } from './common-db/common-db-effects.service';
import { TempProjectFileEffects } from './temp-project-file/temp-project-file.effects';
import { ClientAuthService } from '../common-modules/auth/client-auth.service';
import { getHandledBackendConnectionErrorMessage, shouldHandledMessageTriggerLogout } from '@dunefront/common/exceptions/errors';
import { ELECTION_ACTION_RELOAD, ELECTRON_ACTION_UNREGISTER_ALL_MODULES } from '@dunefront/common/common/electron/electron.actions';
import { FileUrlHelper } from '../pages/home/file-url.helper';
import { Router } from '@angular/router';
import { removeDataResultsFromStore } from './data-storage/data-storage.actions';

@Injectable()
export class AppEffects extends BaseWsEffects {
  public onDataFailedError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(dataFailed),
        tap((error) => this.errorHandlerService.handleStoreError(error)),
      ),
    { dispatch: false },
  );

  public backendConnectFailedAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(backendConnectFailedAction),
        tap(async (error) => {
          if (shouldHandledMessageTriggerLogout(error.error)) {
            this.clientAuthService.logout().then();
            return;
          } else if (getHandledBackendConnectionErrorMessage(error.error)) {
            return;
          }
          this.errorHandlerService.handleError(error, false);
        }),
      ),
    { dispatch: false },
  );

  public changeCurrentScenarioId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeCurrentScenarioIdAction, dbConnectedSuccessAction),
      filter((action) => action.scenarioId >= 0),
      map((action) =>
        loadScenarioDataAction({
          scenarioId: action.scenarioId,
          rangeId: action.type === dbConnectedSuccessAction.type ? action.rangeId : undefined,
        }),
      ),
    ),
  );

  public resetScenarioState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadScenarioDataAction),
      map(() => resetScenarioState()),
    ),
  );

  public switchModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(switchModuleAction),
      tap((action) => {
        FileUrlHelper.fileNavigate(action.file, this.router, this.modalService, action.selectedLicenseFeature);
      }),
      map(() => removeDataResultsFromStore()),
    ),
  );

  public loadScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadScenarioDataAction),
      concatLatestFrom(() => this.store.select(getAllScenarios)),
      mergeMap(([action, allScenarios]) =>
        this.emit<LoadScenarioActionResponse>(new LoadScenarioAction(action.scenarioId), 'Loading ...').pipe(
          map((result) =>
            loadScenarioDataSuccessAction({
              loadScenarioResponse: result.payload,
              rangeId:
                action.rangeId ??
                allScenarios.find((scenario) => scenario.Id === action.scenarioId)?.CurrentRangeId ??
                RangeConstants.EntireRangeId,
            }),
          ),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public refreshApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refreshAppAction),
        map(async (action) => {
          if (this.electronService.instance) {
            // hide error overlay
            this.errorHandlerService.clearError();

            // close db + wait for success
            this.store.dispatch(dbDisconnectAction());
            await firstValueFrom(this.actions$.pipe(ofType(dbDisconnectSuccessAction, dbConnectionFailedAction)));

            // dispatching dbDisconnectAction results in resetting scenario state  (dbDisconnectSuccessAction triggers resetScenarioState)
            // therefore setShouldShowBeforeUnloadWarningAction needs to get dispatch afterward
            this.store.dispatch(setShouldShowBeforeUnloadWarningAction({ shouldShowWarning: false }));

            // force electron main window cleanup and reload
            this.electronService.instance.ipcRenderer.send(ELECTRON_ACTION_UNREGISTER_ALL_MODULES);
            this.electronService.instance.ipcRenderer.send(ELECTION_ACTION_RELOAD);
          } else {
            // if it's a problem with database, return to homepage and then refresh
            if (action.error && action.error.type === '[app] dataFailed') {
              this.routerHelperService.navigateToHome().then(() => location.reload());
            } else {
              location.reload();
            }
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    private clientAuthService: ClientAuthService,
    private electronService: ElectronService,
    @Inject(ErrorHandler) private errorHandlerService: ClientErrorHandlerService,
    private routerHelperService: RouterHelperService,
    private router: Router,
  ) {
    super(actions$, wsService, ScenarioModuleName, false, true, modalService, store);

    let uiNotificationSubscription: Subscription | undefined;

    this.actions$.pipe(ofType(actions.dbConnectedSuccessAction)).subscribe(() => {
      if (uiNotificationSubscription == null) {
        // when we unsubscribe subscription, it stays in 'closed' state, so when we later add new subscription, they are not working.
        // so every tine on dbConnectedSuccess, we need to recreate subscription object
        uiNotificationSubscription = new Subscription();
      }
      uiNotificationSubscription.add(
        this.wsService
          .on(UpdateNotificationActionName, true)
          .pipe(
            //tap((action) => console.log('ACTION!!!', action)), // leave it for debugging
            concatLatestFrom(() => [this.store.select(getFileHash), this.store.select(getCurrentScenarioId)]),
            //tap(([action, fileHash, scenarioId]) => console.log('ACTION', action, fileHash, scenarioId)), // leave it for debugging
            filter(([action, fileHash, scenarioId]) => this.actionNotificationFilter(action as ActionNotification, fileHash, scenarioId)),
          )
          .subscribe(([action, fileHash]) => this.onIncomingAction(action as ActionNotification)),
      );
    });

    this.actions$.pipe(ofType(actions.dbDisconnectSuccessAction)).subscribe(() => {
      uiNotificationSubscription?.unsubscribe();
      uiNotificationSubscription = undefined;
    });

    this.store
      .select(getScenarioOrRangeLoaded)
      .pipe(
        filter((payload) => payload.appStateLoaded),
        distinctUntilChanged(
          (prev, curr) => prev.scenario === curr.scenario && prev.range === curr.range && prev.appModuleType === curr.appModuleType,
        ),
      )
      .subscribe((payload) =>
        this.store.dispatch(
          scenarioOrRangeLoadedAction({
            scenarioId: payload.scenario,
            rangeId: payload.range,
            moduleType: payload.appModuleType,
          }),
        ),
      );
  }

  private actionNotificationFilter(action: ActionNotification | undefined, fileHash: string | undefined, scenarioId: number): boolean {
    if (!action) {
      return false;
    }
    return (
      !!action.payload.progress ||
      ((action.payload.scenarioId === scenarioId || action.payload.scenarioId === ScenarioConstants.AllScenarioId) &&
        (!!action.payload.error || (!!fileHash && !!action.fileHash && action.fileHash === fileHash)))
    );
  }

  private onIncomingAction(action: ActionNotification): void {
    //console.log('INCOMING ACTION', action); // leave it for debugging
    if (action.type === UiActionTypes.UiActivityProgressUpdate && action.payload.progress && action.taskId) {
      this.store.dispatch(
        progressUpdateAction({
          taskId: action.taskId,
          fileHash: action.fileHash,
          progressPayload: action.payload.progress,
        }),
      );
    } else if (action.payload.error) {
      if (!isLongCalculationModuleType(action.payload.error.moduleType)) {
        // calculation engine errors are handled in calculation-engine.effects
        this.modalService.dismissAll();
        try {
          const message: IErrorState = JSON.parse(action.payload.error.message);
          if (message.status !== 'handled') {
            // those kind of error is handled in other effects
            this.store.dispatch(dataFailed(message));
          }
        } catch {
          this.store.dispatch(dataFailed({ message: action.payload.error.message, status: 'backend' }));
        }
      }
    } else if (action.type.includes('Update')) {
      this.store.dispatch(updateRowSuccess({ ...action.payload, isTriggeredFromUiUpdate: true }));
    } else if (action.type.includes('Insert')) {
      this.store.dispatch(insertRowsSuccess({ ...action.payload, isTriggeredFromUiUpdate: true }));
    } else if (action.type.includes('Delete')) {
      this.store.dispatch(deleteRowsSuccess({ ...action.payload, isTriggeredFromUiUpdate: true }));
    }
  }
}

export const appEffects = [
  AppEffects,
  DbMigrationEffects,
  FileManagerEffects,
  UndoRedoEffects,
  UnitsEffects,
  DbDependentCommonDbEffects,
  CommonDbEffects,
  AuthEffects,
  FluidEffects,
  GravelEffects,
  SettingsEffects,
  WellEffects,
  CompletionEffects,
  PumpingEffects,
  MASPCalculatorEffects,
  SettlingCalculatorEffects,
  ResuspensionCalculatorEffects,
  FileSettingsEffects,
  SummaryEffects,
  ReportInfoEffects,
  ScenarioEffects,
  ReportingEffects,
  ReportingTabEffects,
  DataStorageEffects,
  CalculationEngineEffects,
  JsWorkerJobsEffects,
  CalculationEngineDbDependentEffects,
  AboutEffects,
  FrictionCalculatorEffects,
  InjectionTestCalculatorEffects,
  LeakoffCoefficientCalculatorEffects,
  PSDAnalysisEffects,
  ImportDataEffects,
  GaugeDataEffects,
  RangeEffects,
  ReferenceVariablesEffects,
  LicensingEffects,
  ElectronMainEffects,
  EquationEffects,
  ErrorTestingEffects,
  UiEffects,
  NoteEffects,
  TempProjectFileEffects,
];
