import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getAllFilesAndFoldersState, getRecentFilesState } from '../../../+store/file-manager/file-manager.selectors';
import { combineLatest, Subscription } from 'rxjs';
import { IFile } from '@dunefront/common/dto/file.dto';
import { Router } from '@angular/router';
import { FileUrlHelper } from '../file-url.helper';
import { ArrayHelpers } from '@dunefront/common/common/array-helpers';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { isElectron } from '@dunefront/common/common/electron/is-electron';

@Component({
  selector: 'app-file-filter',
  templateUrl: 'file-filter.component.html',
  styleUrls: ['./file-filter.component.scss'],
})
export class FileFilterComponent implements OnInit, OnDestroy {
  public clearable = true;
  public disabled = false;
  public items: ISelectItem<string>[] = [];
  public value = '';
  private subscription = new Subscription();

  constructor(
    private store: Store,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private modalService: ModalService,
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      combineLatest([this.store.select(getAllFilesAndFoldersState), this.store.select(getRecentFilesState)]).subscribe(
        ([allFilesAndFolders, recentFiles]) => {
          if (!allFilesAndFolders.rootFolders) {
            return;
          }
          const items: ISelectItem<string>[] = [];

          if (isElectron()) {
            // In Electron we don't have personal folder, so we will create artificial repository that will contain only recent files
            const folder: IFile = {
              Children: recentFiles.recent.recentFiles,
              Name: '',
              Folder: [],
              FileType: 'type-in-filename',
              Repository: 'electron',
            };
            this.addFolderToItems(folder, 'My Projects', items);
          } else {
            this.addFolderToItems(allFilesAndFolders.rootFolders?.personalRootFolder, 'My Projects', items);
            this.addFolderToItems(allFilesAndFolders.rootFolders?.trashRootFolder, 'Trash', items);
            this.addFolderToItems(allFilesAndFolders.rootFolders?.sharedRootFolder, 'Shared Projects', items);
          }
          this.addFolderToItems(allFilesAndFolders.rootFolders?.demoRootFolder, 'Demo Projects', items);
          this.items = items;

          this.cdRef.markForCheck();
        },
      ),
    );
  }

  public addFolderToItems(folder: IFile, repositoryDisplayName: string, items: ISelectItem<string>[]): void {
    if (folder.Repository !== 'electron') {
      const folderItem: ISelectItem<string> = {
        value: JSON.stringify(folder),
        text:
          '/' +
          repositoryDisplayName +
          '/' +
          folder.Folder?.join('/') +
          (folder.Folder?.length ? '/' : '') +
          folder.Name +
          (folder.Name !== '' ? '/' : ''),
      };
      items.push(folderItem);
    }
    if (!folder.Children) {
      return;
    }

    for (const file of [...folder.Children].sort((a, b) => ArrayHelpers.stringCompare(a.Name, b.Name))) {
      switch (file.FileType) {
        case 'ppf-file':
          {
            const fileItem: ISelectItem<string> = {
              value: JSON.stringify(file),
              text: '/' + repositoryDisplayName + '/' + file.Folder?.join('/') + (file.Folder?.length ? '/' : '') + file.Name,
            };
            items.push(fileItem);
          }
          break;
        case 'folder':
          this.addFolderToItems(file, repositoryDisplayName, items);
          break;

        case 'type-in-filename':
          items.push({
            value: JSON.stringify(file),
            text: file.Name,
          });
      }
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private navigateToFile(): void {
    const file: IFile = JSON.parse(this.value);
    this.value = '';
    FileUrlHelper.fileNavigate(file, this.router, this.modalService);
  }

  public onEnterKeyDown(): void {
    // this should be triggered when user navigates between projects by keyboard, and press enter
    this.navigateToFile();
  }

  public onChange(e: any): void {
    // this should trigger only when dropdown is opened, and user clicked on project
    if (this.value === null || e.originalEvent.type !== 'click') {
      return;
    }
    this.navigateToFile();
  }
}
