<div class="dialog-header">
  <h4>DB Migration</h4>
</div>
<div class="dialog-content" *ngIf="isMigrationRequired">
  File requires update.
  <br />
  <br />
  If the file is from an older version of the application, it will be upgraded to the latest format and can't be reverted. <br />

  <span *ngIf="migrationInfo.status === 'migrationRequiredWithBackup'"
    >A copy of the original file will be made in the same directory as a backup.</span
  >
</div>
<div class="dialog-footer" *ngIf="isMigrationRequired">
  <app-button
    [dialogRef]="ref"
    dataCy="migration-cancel"
    [isUiLockable]="false"
    (buttonClick)="onCancelMigrationClicked()"
    text="Cancel"
  >
  </app-button>
  <app-button
    [dialogRef]="ref"
    dataCy="migration-ok"
    [isUiLockable]="false"
    (buttonClick)="onStartMigrationClicked()"
    text="Start Migration"
  >
  </app-button>
</div>

<div class="dialog-content" *ngIf="migrationInfo.status === 'migrationInProgress'">
  File update in progress.<br />
  {{ migrationInfo.initialDbVersion ? 'Initial DB Version: ' + migrationInfo.initialDbVersion : '' }}<br />
  {{ migrationInfo.currentDbVersion ? 'Current DB Version: ' + migrationInfo.currentDbVersion : '' }}<br />
  <span *ngIf="migrationInfo.importDataFileCount">
    Import Data Files Complete: {{ migrationInfo.importDataFilesDone }} of {{ migrationInfo.importDataFileCount }}<br />
    <span *ngIf="migrationInfo.importDataRowCount">
      Import Data File Rows Complete: {{ migrationInfo.importDataRowsDone }} of {{ migrationInfo.importDataRowCount }}
      <br />
    </span>
  </span>
</div>

<div class="dialog-content" *ngIf="migrationInfo.status === 'creatingBackup'">
  <span>Creating file backup.</span>
</div>

<div class="dialog-content" *ngIf="migrationInfo.status === 'migrationInitialization'">
  <span>Initializing migration.</span>
</div>

<div
  class="dialog-footer"
  *ngIf="
    migrationInfo.status === 'migrationInProgress' ||
    migrationInfo.status === 'creatingBackup' ||
    migrationInfo.status === 'migrationInitialization'
  "
></div>

<div class="dialog-content" *ngIf="migrationInfo.status === 'migrationComplete'">Update complete.</div>
<div class="dialog-footer" *ngIf="migrationInfo.status === 'migrationComplete'">
  <app-button
    [dialogRef]="ref"
    dataCy="migration-close"
    [isUiLockable]="false"
    (buttonClick)="onCompleteMigrationClicked()"
    text="OK"
  >
  </app-button>
</div>

<div class="dialog-content" *ngIf="migrationInfo.status === 'migrationError'">Migration error occurred.</div>
