<div class="expanding-row" data-cy="expanding-row" *ngIf="files" appClickOutside
     (rightClickOutside)="resetContextMenu()">
  <cdk-virtual-scroll-viewport itemSize="30px" class="file-list" (scroll)="resetContextMenu()">
    <app-files-list-file
      *cdkVirtualFor="let file of files; let i = index; trackBy: trackByFn"
      [file]="file"
      [style.animation-delay]="i * .03 +'s'"
      (click)="openFile(file)"
      (contextmenu)="onContextMenu(file, $event)"
      (contextMenuClick)="onContextMenu($event.file, $event.event)"
      [attr.data-cy]="file.Name.toLowerCase().replace(removeSpaceRegex, '-')"
      [class.disableHover]="isAdmin && file.FileType !== 'folder'"
      [allFilesJobTypes] = allFilesJobTypes
      [repository]="repository"
      [isRecentMode]="isRecentMode"
      [showRowContextMenu]="showRowContextMenu"
    >

    </app-files-list-file>
  </cdk-virtual-scroll-viewport>
  <app-file-context-menu
    [(contextMenuRequired)]="contextMenuRequired"
    [isRecentMode]="isRecentMode"
    (openFile)="openFile($event)"
    data-cy="file-context-menu"
  ></app-file-context-menu>
</div>
