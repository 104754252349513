import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../modals/modal.service';
import { DbDependentComponent } from '../../../../db-connection/db-dependent.component';
import { ScenarioManagerComponent } from '../../../../modals/scenario-manager/scenario-manager.component';
import { RouterHelperService } from '../../../../../shared/services/router-helper.service';
import { navigateToScenarioAction } from '../../../../../+store/reporting/reporting.actions';
import { getCurrentFileHasAnyUIBlockingJobs } from '../../../../../+store/calculation-engine/calculation-engine.selectors';

@Component({
  selector: 'app-scenario',
  templateUrl: './scenario.component.html',
  styleUrls: ['./scenario.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioComponent extends DbDependentComponent implements OnDestroy {
  public hasAnyUIBlockingJobs$ = this.store.select(getCurrentFileHasAnyUIBlockingJobs);
  @Input() public isRibbonMenu = false;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private readonly modalService: ModalService,
    protected routerHelperService: RouterHelperService,
  ) {
    super(store, cdRef);
  }

  public async scenarioChange(scenarioId: number): Promise<void> {
    if (isNaN(scenarioId)) {
      return;
    }

    this.store.dispatch(navigateToScenarioAction({ scenarioId }));
  }

  public openScenarioManager(): void {
    this.modalService.open(ScenarioManagerComponent, {}, 'scenario-modal', 'md');
  }
}
