import { MenuItem } from 'primeng/api';
import { contextMenuEvent } from './file-context-menu.component';

export class ContextMenuButtonBuilder {
  constructor(private cb: (event: contextMenuEvent) => void) {}

  public download(props?: MenuItem): MenuItem {
    return Object.assign({ visible: true, command: () => this.cb('download'), icon: 'icon-download', label: 'Download' }, props);
  }

  public delete(props?: MenuItem): MenuItem {
    return Object.assign({ visible: true, command: () => this.cb('delete'), icon: 'icon-trashcan', label: 'Delete' }, props);
  }

  public move(props?: MenuItem): MenuItem {
    return Object.assign({ visible: true, command: () => this.cb('move'), icon: 'icon-move', label: 'Move' }, props);
  }

  public copy(props?: MenuItem): MenuItem {
    return Object.assign({ visible: true, command: () => this.cb('copy'), icon: 'icon-copy-file', label: 'Copy' }, props);
  }

  public rename(props?: MenuItem): MenuItem {
    return Object.assign({ visible: true, command: () => this.cb('rename'), icon: 'icon-pen', label: 'Rename' }, props);
  }

  public share(props?: MenuItem): MenuItem {
    return Object.assign({ visible: true, command: () => this.cb('share'), icon: 'icon-share-arrow', label: 'Share' }, props);
  }

  public open(props?: MenuItem): MenuItem {
    return Object.assign({ visible: true, command: () => this.cb('open'), icon: 'icon-folder', label: 'Open' }, props);
  }

  public goToLocation(props?: MenuItem): MenuItem {
    return Object.assign({ visible: true, command: () => this.cb('go-to-location'), icon: 'icon-move', label: 'Open file location' }, props);
  }

  public restoreBackup(props?: MenuItem): MenuItem {
    return Object.assign(
      { visible: true, command: () => this.cb('restore-backup'), icon: 'icon-time-loading-back', label: 'Restore backup' },
      props,
    );
  }

  public restoreFromTrash(props?: MenuItem): MenuItem {
    return Object.assign(
      { visible: true, command: () => this.cb('restore'), icon: 'icon-trash-bin-restore-back-up-arrow', label: 'Restore' },
      props,
    );
  }
}
