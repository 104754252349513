<app-generic-modal [title]="'Licensing'" [modalButtonsConfigs]="modalButtonsConfigs" [showHelpBtn]="true"
                   (helpClick)="isHelpOpen = $event">
  <ng-container
    *ngrxLet="{detachedLicenseExpirationWarning: licenseExpirationWarning$, currentProduct: currentProduct$, loginInfo:  loginInfo$ } as vm ">
    <div>
      <p data-cy="licensing-panel-status"><b>Status: </b> {{ vm.loginInfo?.loginStatusMessage ?? 'Open a file to connect to license' }}</p>
      <p data-cy="licensing-panel-product" *ngIf="vm.currentProduct" >Product: {{ vm.currentProduct.name }} (Id: {{ vm.currentProduct.id }}
        Detachable: {{ vm.currentProduct.isDetachable }})</p>
      <p data-cy="licensing-panel-exp" *ngIf="vm.loginInfo?.expirationMessage">{{ vm.loginInfo?.expirationMessage }}</p>
      <p data-cy="licensing-panel-parent-exp" *ngIf="vm.loginInfo?.parentLicenseExpirationMessage">{{ vm.loginInfo?.parentLicenseExpirationMessage }}</p>
    </div>

    <div class="buttons-panel">
      <div>
        <ng-container *ngIf="isElectron() && vm.loginInfo">
          <app-button
            (buttonClick)="btnDetachClick(vm.loginInfo.licenseId, vm.currentProduct)"
            [hidden]="vm.detachedLicenseExpirationWarning !== ''"
            [disabled]="!vm.currentProduct || !vm.currentProduct.isDetachable"
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            text="Detach"
            data-cy="licensing-panel-detach-btn"
          >
          </app-button>
          <app-button
            [isUiLockable]="false"
            [hidden]="!vm.detachedLicenseExpirationWarning"
            (buttonClick)="btnCancelDetachClick(vm.loginInfo.licenseId, vm.loginInfo.parentLicenseId)"
            [isDisabledWhenCalculationActive]="false"
            text="Cancel Detach"
            data-cy="licensing-panel-detach-cancel-detach-btn"
          >
          </app-button>
        </ng-container>

        <app-button
          *ngIf="electronService.isElectronApp"
          [isUiLockable]="false"
          (buttonClick)="btnGenerateLicensingDiagnostics()"
          [isDisabledWhenCalculationActive]="false"
          data-cy="licensing-panel-generate-diagnostics-btn"
          text="Generate Diagnostics"
        >
        </app-button>
        <app-button
          *ngIf="isTestVersion"
          dataCy="btn-about-licensing"
          [isUiLockable]="false"
          [isDisabledWhenCalculationActive]="false"
          (buttonClick)="btnLicensingClick()"
          text="Sessions"
        >
        </app-button>


        <ng-template #noDetaching>
          <div class="no-calculations-container" data-cy="no-detaching-container">
            Open a file to get detaching info
          </div>
        </ng-template>

      </div>
    </div>

    <div class="">
      <app-page-panel header="Features" [allowFullScreen]="false" class="no-padding">
        <ng-container *ngIf="features$ | async as features else noFeatures">
          <table *ngIf="features.length > 0 else noFeatures" class="table table-sm table-bordered">
            <thead>
            <tr>
              <th></th>
              <th>
                <div class="top-header">Feature</div>
              </th>
              <th>Status</th>
              <th style="width:60px;">Detached</th>
              <th>Expires</th>
              <th style="width:60px;">Primary</th>
            </tr>
            </thead>
            <tbody>

            <tr *ngFor="let feature of features; index as index;" [ngClass]="{alternate: index % 2 === 0,}">
              <td></td>
              <td>{{ feature.name }}</td>
              <td>{{ feature.loginInfo ? feature.loginInfo.loginStatusMessage : 'Not connected' }}</td>
              <td>{{ feature.loginInfo?.isDetached ? 'Yes' : 'No' }}</td>
              <td>
                {{ feature.loginInfo?.expirationMessage }}
                {{ feature.loginInfo?.parentLicenseExpirationMessage }}
              </td>
              <td>
                {{ feature.isMainFeature ? 'Yes' : 'No' }}
              </td>
            </tr>
            </tbody>
          </table>

        </ng-container>
        <ng-template #noFeatures>
          <div class="no-calculations-container" data-cy="no-features-container">
            No features available
          </div>
        </ng-template>
      </app-page-panel>
    </div>
  </ng-container>
</app-generic-modal>
<div class="modal-help" [class.open]="isHelpOpen">
  <div class="help-content">
    <ng-container *ngIf="'assets/help-files/top-bar/licensing.html' | helpUrl | async as url">
      <iframe [src]="url"></iframe>
    </ng-container>
  </div>
</div>
