import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { getPsdAnalysisModuleMenuData } from './calculations-page.menu';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { setAppModuleAction } from '../../+store/ui/ui.actions';

@Component({
  selector: 'app-calculations',
  templateUrl: './calculations-page.component.html',
  styleUrls: ['./calculations-page.component.scss'],
})
export class CalculationsPageComponent {
  public calculationMenu$ = this.store.select(getPsdAnalysisModuleMenuData);

  constructor(protected store: Store) {
    store.dispatch(setAppModuleAction({ appModuleType: ModuleType.Calculators_Generic }));
  }
}
