import { FileSettingsModuleState } from './file-settings.reducer';
import { DbConnectionResponse } from '@dunefront/common/modules/db-connection/db-connection.actions';
import { CrudResponse } from '@dunefront/common/modules/common.actions';

export class FileSettingsReducerHelper {
  public static fileSettingsModuleLoaded(
    state: FileSettingsModuleState,
    response: { payload: DbConnectionResponse; scenarioId: number },
  ): FileSettingsModuleState {
    const projectFileInfo = response.payload.dbConnectionResult?.projectFileInfo;
    if (projectFileInfo == null) {
      return state;
    }

    return { ...state, fileSettings: projectFileInfo.fileSettings, isLoaded: true };
  }

  public static updateFileSettingsSuccess(state: FileSettingsModuleState, action: CrudResponse): FileSettingsModuleState {
    if (!action.affectedRows.fileSettings) {
      return state;
    }
    return { ...state, fileSettings: action.affectedRows.fileSettings };
  }
}
