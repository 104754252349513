<div class="dialog-header">
  <h4>Connected Sessions</h4>
</div>
<div class="dialog-content">
  <div *ngIf="getLicenseId(currentLoginInfo$ | async) === null">Open a file to connect to license</div>
  <div class="buttons-panel">
    <app-button
      [isUiLockable]="false"
      [isDisabledWhenCalculationActive]="false"
      (buttonClick)="onGetSessionsClick()"
      [disabled]="getLicenseId(currentLoginInfo$ | async) === null"
      dataCy="btn-check-sessions"
      text="Get Sessions"
    >
    </app-button>
  </div>
  <div class="session-grid" *ngIf="licensesWithSessions$ | async as licensesWithSessions">
    <div *ngFor="let license of licensesWithSessions">
      <p>License: {{ license.licenseId }}</p>
      <table class="table table-sm table-bordered with-selection-col">
        <thead>
          <tr>
            <th></th>
            <th>Session Id</th>
            <th>Feature</th>
            <th>Login Time</th>
            <th>Timeout</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let session of license.sessions"
            class="mat-mdc-row cdk-row"
            [class.killing]="session.disableDisconnect === 1"
            [attr.data-cy]="'license_session_row_' + session.feature"
          >
            <th></th>
            <td class="row_sessionId">{{ session.sessionId }}</td>
            <td>{{ session.featureName }}</td>
            <td>{{ session.loginTime }}</td>
            <td>{{ session.remainingTime }}</td>
            <td>
              <app-button
                [dialogRef]="ref"
                [isUiLockable]="false"
                (buttonClick)="killSession(license.licenseId, session.sessionId)"
                [isDisabledWhenCalculationActive]="false"
                text="Kill"
              >
              </app-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="dialog-footer">
  <app-button
    [dialogRef]="ref"
    [isUiLockable]="false"
    (buttonClick)="ref.close()"
    [isDisabledWhenCalculationActive]="false"
    text="Close"
  >
  </app-button>
</div>
