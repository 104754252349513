import { WsAction } from '../../ws.action';
import {
  CalculationContext,
  CalculationEngineMessageType,
  ICalcEngineJobInfo,
  ICalculationEngineJobDataOptions,
  ICalculationEngineMessage,
  IJsWorkerJobInfo,
  RedisConnectionStatus,
} from './calculation-engine.interfaces';
import { ModuleType } from '../scenario/scenario.dto';
import { DeveloperSettingsDto } from '../settings/dto/settingsDto';

export const CalculationEngineModuleName = 'calculationEngine';

export enum CalculationEngineActionTypes {
  StartCalculations = '[CalculationEngine] InsertCalculations',
  ConnectionStatusChanged = '[CalculationEngine] ConnectionStatusChanged',
  CalculationUpdate = '[CalculationEngine] CalculationUpdate',
  QueueUpdate = '[CalculationEngine] QueueUpdate',
  ListUserJobs = '[CalculationEngine] ListUserJobs',
  RedisConnectionChanged = '[CalculationEngine] RedisConnectionChanged',
  CancelJobs = '[CalculationEngine] CancelJobs',
  CrashRunningJob = '[CalculationEngine] CrashRunningJob',
}

export class ConnectionStatusChangedAction extends WsAction {
  public override readonly type = CalculationEngineActionTypes.ConnectionStatusChanged;

  constructor(public override payload: { isConnected: boolean }) {
    super();
  }
}

// region StartCalculation

export class StartCalculationsAction extends WsAction {
  public override readonly type = CalculationEngineActionTypes.StartCalculations;

  constructor(
    public override payload: StartCalculationsActionPayload,
    public options: Required<ICalculationEngineJobDataOptions>,
  ) {
    super();
  }
}

export interface StartCalculationsActionPayload {
  calcDetails: CalculationContext[];
  developerSettings: DeveloperSettingsDto;
}

export interface StartCalculationActionResponse {
  jobId: string;
  scenarioId: number;
  rangeId: number;
  calculationType: ModuleType;
  fileHash: string;
}

// endregion

// region ListUserJobsAction
export class ListUserJobsAction extends WsAction {
  public override readonly type = CalculationEngineActionTypes.ListUserJobs;
}

export interface UserJobs {
  calcEngineJobs: {
    activeJobs: ICalcEngineJobInfo[];
    waitingJobs: ICalcEngineJobInfo[];
  };
  jsWorkerJobs: {
    activeJobs: IJsWorkerJobInfo[];
    waitingJobs: IJsWorkerJobInfo[];
  };

  status: RedisConnectionStatus;
}

export class CalcEngineQueueUpdateAction extends WsAction {
  public override readonly type = CalculationEngineActionTypes.QueueUpdate;

  constructor(public override payload: UserJobs) {
    super();
  }
}

// endregion

export class BaseCalculationEngineUpdateAction<T extends IBaseCalculationEngineUpdatePayload<any>> extends WsAction {
  constructor(public override payload: T) {
    super();
  }
}

export class CalculationUpdateAction extends BaseCalculationEngineUpdateAction<ICalculationEngineUpdatePayload> {
  public override readonly type = CalculationEngineActionTypes.CalculationUpdate;
}

export class RedisConnectionChangedAction extends WsAction {
  public override readonly type = CalculationEngineActionTypes.RedisConnectionChanged;

  constructor(public status: RedisConnectionStatus) {
    super();
  }
}

export class CancelJobsAction extends WsAction {
  public override readonly type = CalculationEngineActionTypes.CancelJobs;

  constructor(public jobIds: string[]) {
    super();
  }
}

export class CalculationEngineCrashRunningJob extends WsAction {
  public override readonly type = CalculationEngineActionTypes.CrashRunningJob;

  constructor(
    public jobId: string,
    public ignoreSentry: boolean,
  ) {
    super();
  }
}

export interface IBaseCalculationEngineUpdatePayload<T> {
  jobId: string;
  messageType: CalculationEngineMessageType;
  calcEngineMessage: T;
}

export interface ICalculationEngineUpdatePayload extends IBaseCalculationEngineUpdatePayload<ICalculationEngineMessage> {
  calcEngineMessage: ICalculationEngineMessage;
  options?: ICalculationEngineJobDataOptions;
}

export type CalculationEngineModuleActions =
  | StartCalculationsAction
  | ListUserJobsAction
  | CancelJobsAction
  | CalculationUpdateAction
  | RedisConnectionChangedAction
  | CalcEngineQueueUpdateAction
  | ConnectionStatusChangedAction;
