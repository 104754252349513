<div class="home-container" [class.electron]="isElectron">

  <div class="home-nav-wrapper">
    <div class="logo-wrapper">
      <img class="logo" [src]="appConfig.appIconPath" alt="App Logo">
    </div>

    <div class="home-nav">
      <div class="home-nav-links">
        <div
          *ngFor="let homeTab of homeTabs"
          [ngClass]="{ 'active': homeTab.uri === selectedTab }"
          [routerLinkActive]="'active'"
          [routerLink]="homeTab.uri"
          [routerLinkActiveOptions]="{exact: true}"
          class="home-nav-links-single"
          [attr.data-cy]="homeTab.label.toLowerCase().replace(' ', '-')"
          (click)="navigate(homeTab.uri)"
        >
          <span class="{{ homeTab.icon }} icon-light icon"></span>
          {{ homeTab.label }}
        </div>
      </div>
      <img src="assets/dunefront-logo-white.svg" alt="DuneFront">
    </div>

  </div>

  <div class="home-content-wrapper">
    <app-header></app-header>

    <div class="home-content">
      @if (isElectron()) {
        <app-sub-home-header [repository]="repository"></app-sub-home-header>
      } @else {
        <app-sub-home-header [repository]="repository" [folder]="folder"
                             (uploadTriggered)="uploadFile()"></app-sub-home-header>
      }
      <div class="home-page-content" *ngIf="isBackendConnected$ | async">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

@if (isElectron()) {
  <app-upload [accept]="getAcceptedFileExtension()" *ngIf="isElectron()"></app-upload>
} @else {
  <app-upload
    [repository]="repository"
    [folder]="folder"
    [fileListInFolder]="getFileListInFolder()"
    [accept]="getAcceptedFileExtension()"
    (uploadFinished)="uploadFinished($event)"
  >
  </app-upload>
}


