import { combineLatest, Subscription } from 'rxjs';
import { selectCurrentUnitSystem } from '../../+store/units/units.selectors';
import { Store } from '@ngrx/store';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { getCurrentScenario, getCurrentScenarioId } from '../../+store/scenario/scenario.selectors';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { LengthConverter } from '@dunefront/common/unit-converters/converters/length/length.converter';
import { PlaneAngleConverter } from '@dunefront/common/unit-converters/converters/plane-angle/plane-angle.converter';
import { LinearMassConverter } from '@dunefront/common/unit-converters/converters/linear-mass/linear-mass.converter';
import { getHelpUrlSuccessAction, HelpIds, PageType } from '../../+store/help/help.actions';
import { getCurrentHelpUrl } from '../../+store/help/help.selectors';
import { TemperatureConverter } from '@dunefront/common/unit-converters/converters/temperature/temperature.converter';
import { PressureConverter } from '@dunefront/common/unit-converters/converters/pressure/pressure.converter';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { getConnectedDbInfo } from '../../+store/backend-connection/backend-connection.selectors';
import { IDbInfo } from '@dunefront/common/modules/db-connection/db-connection.actions';
import { getCurrentAppModuleType } from '../../+store/ui/ui.selectors';
import { getIsAppStateLoaded } from '../../+store/app.selector';
import { distinctUntilChanged, filter, map, pairwise, startWith } from 'rxjs/operators';
import { RangeConstants, RangeDto } from '@dunefront/common/dto/range.dto';
import { observableToBehaviorSubject } from '@dunefront/common/common/state.helpers';
import { getCurrentRange, getCurrentRangeId } from '../../+store/range/range.selectors';
import { InitialScenarioId } from '../../+store/scenario/scenario.reducer';
import { getChartIds } from '../../+store/reporting/reporting.selectors';
import { SolidConcentrationConverter } from '@dunefront/common/unit-converters/converters/solid-concentration/solid-concentration.converter';
import { getDefaultMetricUnitSystem } from '@dunefront/common/common/constants';
import { IGetCurrentFeaturesResult } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { getCurrentFeatures } from '../../+store/licensing/licensing.selectors';
import { getAboutServer } from '../../+store/about/about.selectors';
import { AboutServerDto } from '@dunefront/common/dto/about-server.dto';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';

@Component({
  template: '',
})
export abstract class DbDependentComponent implements OnDestroy, OnInit {
  public currentUnitSystem: IUnitSystemDto = getDefaultMetricUnitSystem();
  public currentAppModuleType: ModuleType = ModuleType.None;
  public currentRange$ = observableToBehaviorSubject(this.store.select(getCurrentRange), undefined);
  public currentRangeId$ = observableToBehaviorSubject(this.store.select(getCurrentRangeId), RangeConstants.EmptyRangeId);
  public currentHelpUrl = '';
  public Length = LengthConverter;
  public PlaneAngleConverter = PlaneAngleConverter;
  public GravelConcentration = SolidConcentrationConverter;
  public LinearDensity = LinearMassConverter;
  public TemperatureConverter = TemperatureConverter;
  public PressureConverter = PressureConverter;
  public isStateLoaded$ = this.store.select(getIsAppStateLoaded);
  public chartIds$ = observableToBehaviorSubject(this.store.select(getChartIds), undefined);
  public currentLicenseFeatures: IGetCurrentFeaturesResult = { accessibleFeatures: [], mainFeature: undefined, addonFeatures: [] };
  public serverInfo: AboutServerDto | undefined;

  private isLoaded = false;

  private scenario$ = observableToBehaviorSubject(
    combineLatest([this.isStateLoaded$.pipe(filter((isLoaded) => isLoaded)), this.store.select(getCurrentScenario)]).pipe(
      map(([, scenario]) => scenario),
    ),
    undefined,
  );

  public currentScenarioId$ = observableToBehaviorSubject(
    this.store.select(getCurrentScenarioId).pipe(distinctUntilChanged()),
    InitialScenarioId,
  );

  public dbInfo: IDbInfo | undefined;

  public get isDevValidationDisabled(): boolean {
    return !!localStorage.getItem('dev-disable-validation');
  }

  public get currentScenarioId(): number {
    return this.currentScenarioId$.value as number;
  }

  public get currentRange(): RangeDto | undefined {
    return this.currentRange$.value;
  }

  public get currentRangeId(): number {
    return this.currentRangeId$.value;
  }

  public get isTestVersion(): boolean {
    return this.serverInfo?.isTestEnv ?? false;
  }

  protected subscription = new Subscription();

  protected constructor(
    protected store: Store,
    protected cdRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.store.select(selectCurrentUnitSystem).subscribe((currentUnitSystem) => {
        this.currentUnitSystem = currentUnitSystem;
        this.onUnitSystemChanged(currentUnitSystem);
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(this.store.select(getCurrentHelpUrl).subscribe((helpUrl) => (this.currentHelpUrl = helpUrl)));

    this.subscription.add(this.store.select(getCurrentAppModuleType).subscribe((appModuleType) => (this.currentAppModuleType = appModuleType)));
    this.subscription.add(this.store.select(getConnectedDbInfo).subscribe((dbInfo) => (this.dbInfo = dbInfo)));
    this.subscription.add(
      this.currentRange$.subscribe((range) => {
        this.onRangeChanged(range);
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getCurrentFeatures).subscribe((currentFeatures) => {
        this.currentLicenseFeatures = currentFeatures;
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.scenario$.pipe(startWith(undefined), pairwise()).subscribe(([prevScenario, currentScenario]) => {
        if (currentScenario) {
          if (prevScenario === undefined) {
            this.onScenarioLoaded(currentScenario);
          } else if (prevScenario.Id !== currentScenario.Id) {
            this.onCurrentScenarioChanged(currentScenario);
          }
        }
        if (!this.isLoaded && currentScenario) {
          this.onStateLoaded();
          this.isLoaded = true;
        }
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getAboutServer).subscribe((aboutServer) => {
        this.serverInfo = aboutServer.serverInfo;
        this.cdRef.markForCheck();
      }),
    );
  }

  protected onStateLoaded(): void {
    // this can be overridden
  }

  protected onUnitSystemChanged(currentUnitSystem: IUnitSystemDto): void {
    // this can be overridden
  }

  protected onCurrentScenarioChanged(currentScenario: Scenario): void {
    // this can be overridden
  }

  protected onScenarioLoaded(currentScenario: Scenario): void {
    // this can be overridden
  }

  protected onRangeChanged(range: RangeDto | undefined): void {
    // this can be overridden
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();

    // release objects held by observableToBehaviorSubject
    this.currentRange$ = null as any;
    this.currentRangeId$ = null as any;
    this.chartIds$ = null as any;
    this.scenario$ = null as any;
    this.currentScenarioId$ = null as any;
  }

  public onHelpChange(page: PageType, uri: HelpIds, overrideModuleType: ModuleType | undefined = undefined): void {
    const overrideFolder = overrideModuleType != null ? `__${ModuleType[overrideModuleType].toLowerCase()}/` : '';
    const helpUrl = `assets/help-files/${overrideFolder}${page}/${uri}.html`;
    if (this.currentHelpUrl !== helpUrl) {
      this.store.dispatch(getHelpUrlSuccessAction({ helpUrl }));
    }
  }
}
