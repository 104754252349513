<div (keydown)="onKeyPress($event)" tabindex="1" style="outline: none" [id]="elementId" [attr.data-cy]="dataCy"
     #gridOutline>
  <!--  headerHeight and footerHeight in cdk-virtual-scroll-viewport are kind of magic numbers. -->
  <!--  Our header is 50 and there is no footer but 20 and 30 numbers were found during tests as ones working properly.-->
  <cdk-virtual-scroll-viewport
    class="grid-viewport"
    data-cy="grid-viewport"
    style="width: 100%"
    [style.height.px]="height"
    [tvsItemSize]="rowHeight"
    [headerEnabled]="true"
    [headerHeight]="headerTotalHeight"
    
  >
    <table
      mat-table
      [dataSource]="virtualScrollDataSource"
      [trackBy]="trackByRowId"
      class="table table-sm table-bordered"
      [ngClass]="{ 'with-selection-col': hasSelectionColumn, 'no-head': hideThead }"
      (mousemove)="onMouseMove($event)"
      (mouseup)="stopScrollingTable()"
      (mouseleave)="stopScrollingTable()"
      appClickOutside
      (clickOutside)="onClickOutside()"
    >
      <ng-container
        [matColumnDef]="colConfig.colId.toString()"
        *ngFor="let colConfig of columns; let cellIndex = index; trackBy: trackByFn"
      >
        <th
          cdk-header-cell
          app-table-cell-header
          *matHeaderCellDef
          [class.hidden]="isCellHidden(0, cellIndex)"
          [attr.data-cy]="getCellDataCy('header', colConfig)"
          [colConfig]="colConfig"
          [headerTopRowHeight]="actualHeaderTopRowHeight"
          [headerText]="getHeaderText(cellIndex)"
          [currentUnitSystem]="currentUnitSystem"
          [showError]="getShowError(cellIndex)"
          [style.width.px]="colConfig.width"
          [style.height.px]="headerTotalHeight"
          [showHeaderUnits]="showHeaderUnits"
          (contextmenu)="onContextMenu(-1, cellIndex, $event)"
          (mousedown)="onMouseDown(-1, cellIndex, $event, true)"
          (mouseup)="onMouseUp(-1, cellIndex, $event, true)"
          (mouseover)="onMouseOver(-1, cellIndex, $event, true)"
        ></th>

        <td
          mat-cell
          *matCellDef="let row"
          class="cell"
          [style.width.px]="colConfig.width"
          (contextmenu)="onContextMenu(row.rowIndex, cellIndex, $event)"
          (mousedown)="onMouseDown(row.rowIndex, cellIndex, $event)"
          (mouseup)="onMouseUp(row.rowIndex, cellIndex, $event)"
          (dblclick)="onDoubleClick(row.rowIndex, cellIndex, $event)"
          (mouseover)="onMouseOver(row.rowIndex, cellIndex, $event)"
          [class.disabled]="isCellDisabled(row.rowIndex, cellIndex)"
          [class.error-cell]="row.error[$any(colConfig.colId)]"
          [class.warning-cell]="row.warning ? row.warning[$any(colConfig.colId)] : ''"
          [class.selection-col]="isSelectionCol(cellIndex)"
          [class.selected]="isCellSelected(row.rowIndex, cellIndex) && !isCellInEditMode(row.rowIndex, cellIndex)"
          [class.hidden]="isCellHidden(row.rowIndex, cellIndex)"
          [attr.data-cy]="getCellDataCy(row.rowIndex, colConfig)"
        >
          <app-table-cell
            [row]="row"
            [colConfig]="colConfig"
            [selected]="isCellSelected(row.rowIndex, cellIndex)"
            [isOnlyOneSelected]="selectedCells.size === 1"
            [isInEditMode]="isCellInEditMode(row.rowIndex, cellIndex)"
            (valueChanged)="onCellValueChanged($event, row.rowIndex, cellIndex)"
            (stopEditing)="onCellStoppedEditing($event, row.rowIndex, cellIndex)"
            [getLookupDataSourceFn]="gridConfig?.getLookupDataSource"
            [getLookupDataPlaceholderFn]="gridConfig?.getLookupDataPlaceholder"
            [gridElementId]="elementId"
            (changeFocusCell)="changeFocusedCell($event, true)"
            [align]="colConfig.align"
            [disabled]="isCellDisabled(row.rowIndex, cellIndex)"
            [readonly]="isCellReadOnly(row.rowIndex, cellIndex)"
            [dataCy]="getCellDataCy(row.rowIndex, colConfig)"
            [allowOptionalStringValue]="!!colConfig.allowOptionalStringValue"
            [castNullToNumber]="colConfig.castNullToNumber ?? true"
            [style.height.px]="rowHeight"
            [height]="rowHeight"
            [initialInputValue]="getCellInitialValue(row.rowIndex, cellIndex)"
            (keyPressed)="onKeyPress($event)"
          >
          </app-table-cell>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="allColumnIds; sticky: true" [attr.data-cy]="dataCy + '_header'"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: allColumnIds"
        [class.alternate]="row.rowIndex % 2 === 0 && !isGridExpandable"
        [attr.data-cy]="dataCy + '_row_' + row.rowIndex"
        [attr.data-row-id]="row.rowIndex"
      ></tr>

      <ng-container matColumnDef="groupHeader">
        <td
          mat-cell
          class="group-header"
          [attr.colspan]="visibleColumnsLength"
          *matCellDef="let group; let row"
          [class.level-2]="group.level === 2"
          [style.height.px]="rowHeight"
        >
          <span class="smaller-icon icon-top-chevron" (click)="groupHeaderClick(row)" *ngIf="group.expanded"></span>
          <span class="smaller-icon icon-bottom-chevron" (click)="groupHeaderClick(row)" *ngIf="!group.expanded"></span>
          <strong
          >{{ getGroupName(groupByColumns[group.level - 1], group.rowData[groupByColumns[group.level - 1]]) }}
            <span *ngIf="showGroupTotalCounts"> = ({{ group.totalCounts }})</span></strong
          >
        </td>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" class="alternate"></tr>
    </table>
  </cdk-virtual-scroll-viewport>

  <div class="button-row" *ngIf="showCrudControls">
    <app-button (buttonClick)="onInsertClicked()" text="Insert"></app-button>
    <app-button (buttonClick)="onDeleteClicked()" text="Delete"></app-button>
    <app-button (buttonClick)="scroll('down')" text="Scroll Down"></app-button>
    <app-button (buttonClick)="scroll('up')" text="Scroll Up"></app-button>
  </div>
  <p-contextMenu
    [model]="contextMenuItems"
    (onShow)="isContextMenuVisible = true"
    (onHide)="isContextMenuVisible = false"
    [appendTo]="'body'"
    [style]="{ 'top.px': contextMenuPosTop, 'left.px': contextMenuPosLeft, 'width.px': 235 }"
  ></p-contextMenu>
</div>
