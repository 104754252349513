<ng-container *ngIf="vm$ | async as vm">
  <app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="vm.modalButtonsConfigs">
    <div class="grid-form-container">
      <label>Select Chart:</label>
      <app-select
        [value]="vm.selectedProviderId"
        [items]="providersToSelectItems(vm.providers)"
                [isUiLockable]="false"
        [disabled]="vm.providers.length < 2"
        dataCy="copy-data-select-chart-dropdown"
        (primitiveValueChanged)="selectedProviderChanged($event, vm.providers)"
      ></app-select>
    </div>

    <div class="cols-1 grid-form-container">
      <label>Select Series:</label>
      <div class="cols-1 grid-form-container scrollable-container">
        <ng-container *ngFor="let dataset of vm.selectedChartDataSets; let index = index">
          <app-check-box
            [label]="dataset.displayName"
            [value]="dataset.isSelected"
            [isUiLockable]="false"
            [dataCy]="'copy-data-' + index + '-checkbox'"
            (primitiveValueChanged)="onColumnSelected(dataset, $event)"
          >
          </app-check-box>
        </ng-container>
      </div>
    </div>
  </app-generic-modal>
</ng-container>




