<ng-container *ngIf="isFluidsModuleLoaded$ | async">
  <app-fluid-header (click)="onHelpChange('fluids', 'project-fluids')"></app-fluid-header>

  <app-page-panel header="Fluids Properties">
    <app-fluid-properties *ngIf="currentFluid" [fluid]="currentFluid"></app-fluid-properties>
  </app-page-panel>

  <div class="buttons-panel" (click)="onHelpChange('fluids', 'project-fluids')">
    <div>
      <app-button
        dataCy="fluid-to-database"
        [isUiLockable]="false"
        (buttonClick)="saveToDatabase()"
        text="Save to Database"
      >
      </app-button>
    </div>
    <div>
      <app-button
        dataCy="new-fluid"
        (buttonClick)="newFluid()"
        [isDeleteResultsEnabled]="false"
        text="New Fluid"
      >

      </app-button>
      <app-button
        dataCy="copy-fluid"
        (buttonClick)="copyFluid()"
        [isDeleteResultsEnabled]="false"
        text="Copy Fluid"
      >

      </app-button>
      <app-button dataCy="delete-fluid" (buttonClick)="deleteFluid()" text="Delete Fluid"></app-button>
    </div>
  </div>
</ng-container>
