<div class="wrapper with-help" [class.help-open]="isHelpOpen" *ngIf="state">
  <div class="dialog-header">
    <h4>
      Import Data
      <ng-container *ngIf="state.fileName"> - {{ state.fileName }}</ng-container>
    </h4>

    <app-modal-help-button
      *ngIf="step !== ImportDataStep.SendData"
      [dialogHasScrollbar]="dialogHasScrollbar"
      (helpClick)="onHelpClick($event.isHelpOpen)"
    ></app-modal-help-button>
  </div>
  <div class="dialog-content">
    <app-file-loader
      *ngIf="step === ImportDataStep.SelectFile"
      [state]="state"
      (fileLoaded)="onFileLoaded($event)"
      (stateChange)="changeState($event)"
    ></app-file-loader>
    <app-file-preview
      data-cy="import-data-preview"
      *ngIf="step === ImportDataStep.Preview || step === ImportDataStep.DefineData"
      [parsedResult]="FileParser.parsedResult"
      [state]="state"
      [currentUnitSystem]="currentUnitSystem"
      (stateChange)="changeState($event)"
      (dateFormatsHelpClicked)="openDateFormatHelp()"
      [step]="step"
    ></app-file-preview>
    <app-send-data
      *ngIf="step === ImportDataStep.SendData"
      [parsedResult]="FileParser.parsedResult"
      [state]="state"
      (isUploadedChange)="onIsUploadedChanged($event)"
    ></app-send-data>
  </div>
  <div class="dialog-footer">
    <app-button
      *ngIf="!isSendDataStep"
      dataCy="cancel-data-import"
      [isUiLockable]="false"
      (buttonClick)="onCancelClick()"
      text="Cancel"
    >

    </app-button>
    <app-button
      *ngIf="isSendDataStep"
      [isUiLockable]="false"
      (buttonClick)="onImportAnotherFileClick()"
      dataCy="import-another-file-btn"
      [disabled]="!isFinishButtonEnabled"
      text="Import another file"
    >

    </app-button>
    <app-button
      *ngIf="!isSendDataStep"
      [isUiLockable]="false"
      (buttonClick)="onBackClick()"
      [disabled]="isBackButtonDisabled"
      text="< Back"
    >

    </app-button>
    <app-button
      *ngIf="!isSendDataStep"
      [isUiLockable]="false"
      (buttonClick)="onNextClick()"
      dataCy="import-next-btn"
      [disabled]="isNextButtonDisabled"
      text="Next >"
    >
    </app-button>
    <app-button
      *ngIf="!isSendDataStep"
      [isUiLockable]="false"
      (buttonClick)="onImportClick()"
      [disabled]="!isImportButtonEnabled || !haveIncludedColumns"
      text="Import"
    >

    </app-button>
    <app-button
      *ngIf="isSendDataStep"
      [isUiLockable]="false"
      (buttonClick)="onFinishClick()"
      class="btn btn-primary btn-sm"
      [disabled]="!isFinishButtonEnabled"
      text="Finish"
    >

    </app-button>
  </div>
</div>

<div class="modal-help" [class.open]="isHelpOpen && helpContent[step]" *ngIf="step !== ImportDataStep.SendData">
  <div class="help-content">
    <ng-container *ngIf="currentHelpContent">
      <ng-container *ngIf="currentHelpContent.length === 1">
        <iframe *ngIf="currentHelpContent[0].url | helpUrl | async as url" [src]="url"></iframe>
      </ng-container>

      <p-accordion
        *ngIf="currentHelpContent.length > 1"
        class="help-accordion"
        [ngClass]="'tabs-'+currentHelpContent.length"
        [styleClass]="'data-import-help-accordion'"
        [(activeIndex)]="helpAccordionActiveIndex"
      >
        <p-accordionTab header="{{ help.label }}" *ngFor="let help of currentHelpContent">
          <ng-container *ngIf="help.url | helpUrl | async as url">
            <iframe [src]="url"></iframe>
          </ng-container>
        </p-accordionTab>
      </p-accordion>

    </ng-container>
  </div>
</div>
