import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BackendConnectionModuleFeatureName, BackendConnectionModuleState, ConnectionStatus } from './backend-connection.reducer';
import { getCurrentScenarioId } from '../scenario/scenario.selectors';

export const getBackendConnectionState = createFeatureSelector<BackendConnectionModuleState>(BackendConnectionModuleFeatureName);
export const getIsBackendConnected = createSelector(
  getBackendConnectionState,
  (state) => state.backendConnectionStatus === ConnectionStatus.connected,
);
export const getIsDatabaseConnected = createSelector(
  getBackendConnectionState,
  (state) => state.dbConnectionStatus === ConnectionStatus.connected,
);
export const getDbInfo = createSelector(getBackendConnectionState, (state) => state.dbInfo);
export const getConnectedDbInfo = createSelector(getDbInfo, (dbInfo) =>
  dbInfo && dbInfo.dbVersionStatus !== 'connecting' ? dbInfo : undefined,
);
export const getFileHash = createSelector(getDbInfo, (dbInfo) => dbInfo?.fileHash);
export const getBackendActiveRequestsCount = createSelector(getBackendConnectionState, (state) => state.backendConnectionActiveRequests.length);

export const hasBackendActiveRequests = createSelector(getBackendActiveRequestsCount, (activeRequestsCount) => activeRequestsCount > 0);

export const getConnectedFile = createSelector(getConnectedDbInfo, (dbInfo) => dbInfo?.file);

export const getConnectionStatusWithScenarioId = createSelector(
  getBackendConnectionState,
  getCurrentScenarioId,
  (...[connectionState, currentScenarioId]): IConnectionStatusWithScenarioId => ({
    connectionState,
    currentScenarioId,
  }),
);

export interface IConnectionStatusWithScenarioId {
  connectionState: BackendConnectionModuleState;
  currentScenarioId: number;
}
