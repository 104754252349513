<div class="psd-selection-container" data-cy="psd-selection-container">
  <div>
    <app-check-box
      *ngFor="let psdData of psdChartSelectionData; let i = index"
      [label]="psdLabels[i]"
      [source]="psdData.psd"
      [key]="'IsSelected'"
      [dataCy]="'psd-selection-' + psdData.psd.Id"
      (valueChanged)="updatePSD($event, i)"
      [overrideErrorMessage]="psdData.psd.isValid ? '' : 'Correct data validation errors to plot PSD'"
    >
    </app-check-box>
  </div>
  <div class="buttons-panel grid-four-one">
    <div></div>
    <div>
      <app-button (buttonClick)="selectUnselectAll(false)" text="Unselect All"></app-button>
      <app-button (buttonClick)="selectUnselectAll(true)" text="Select All"></app-button>
    </div>
  </div>
</div>
