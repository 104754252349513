<div class="wrapper with-help" [class.help-open]="isHelpOpen">
  <div class="dialog-header">
    <h4>Create a new chart</h4>
    <app-modal-help-button
      [dialogHasScrollbar]="false"
      (helpClick)="onHelpClick($event.isHelpOpen)"
    ></app-modal-help-button>
  </div>
  <div class="dialog-content page-container" data-cy="create-chart-p-dialog-content">
    <app-page-panel header="Create Chart" class="no-padding" [allowFullScreen]="false">
      <div>
        <app-radio-group
          *ngIf="chartCreateItems"
          dataCy="create-chart-modal-chart-create-radio"
          [items]="chartCreateItems"
          [value]="chartCreateSelectedValue"
          [isUiLockable]="false"
          (primitiveValueChanged)="chartCreateValueChanged($event)"
          [cssOverride]="'grid-form-container'"
        >
        </app-radio-group>
      </div>
    </app-page-panel>

    <app-page-panel class="chart-type no-padding" header="Chart Type"
                    *ngIf="currentAppModuleType !== ModuleType.Data_Analysis && chartCreateSelectedValue === 'blank'"
                    [allowFullScreen]="false">
      <app-radio-group
        dataCy="create-chart-modal-chart-type-radio"
        [items]="chartTypeItems"
        [value]="chartTypeSelectedValue$.value"
        [isUiLockable]="false"
        [cssOverride]="'grid-form-container'"
        (primitiveValueChanged)="chartTypeValueChanged($event)"
      >
      </app-radio-group>
    </app-page-panel>

    <app-page-panel header="Select Templates" *ngIf="chartCreateSelectedValue !== 'blank'" [allowFullScreen]="false"
                    class="no-padding">
      <div class="filters">
        <label>Search: </label>
        <app-input
          dataCy="create-chart-modal-templates-search"
          [maxWidth]="185"
          [width]="195"
          [showSymbol]="false"
          [align]="'left'"
          [value]="filterByName"
          [isTextInput]="true"
          [isUiLockable]="false"
          [placeholder]="'Name'"
          [triggerOnKeyPress]="true"
          (primitiveValueChanged)="onNameFilterChanged($event)"
        ></app-input>
        <app-labels-selector
          (labelsUpdated)="onLabelsFilterChanged($event)"
          [componentWidth]="195"
          [rangeLabels]="filterByLabels"
          [showNoLabel]="true"
          [dataCy]="'create-chart-modal-range-labels-multiselect'"
          class="blue-bg"
        ></app-labels-selector>
        <app-button [disabled]="!filterByName && !filterByLabels" [isUiLockable]="false"
                    (click)="clearFilters()" text="Clear" class="clear-btn">

        </app-button>
      </div>
      <hr>
      <p-listbox [multiple]="true"
                 [checkbox]="true"
                 [metaKeySelection]="true"
                 [showToggleAll]="false"
                 [options]="templateItems"
                 [ngModel]="selectedChartTemplatesEncodedIds"
                 [group]="true"
                 [listStyle]="{ 'height': '348px', 'max-height': '348px' }"
                 [style]="{ width: '100%', border: 'none' }"
                 [attr.data-cy]="'create-chart-modal-template-select'"
                 (onChange)="onReportingChartSelection($event.value)">
        <ng-template let-group pTemplate="group">
          <div class="flex align-items-center">
            <span>{{ group.label }}</span>
          </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div class="item-wrapper">
            <span [attr.data-cy]="item.value">{{ item.label }}</span>
            <span class="time-vol-indicator">{{ item.icon }}</span>
          </div>
        </ng-template>
      </p-listbox>

      <hr>
      <div class="buttons-panel">

        <div>
          <app-button
            dataCy="create-chart-modal-chart-template-clear-all"
            [isUiLockable]="false"
            [disabled]="chartCreateSelectedValue !== 'template'"
            (buttonClick)="onClearAll()"
            text="Clear all"
          >

          </app-button>
          <app-button
            class="ml"
            dataCy="create-chart-modal-chart-template-select-all"
            [isUiLockable]="false"
            [disabled]="chartCreateSelectedValue !== 'template'"
            (buttonClick)="onSelectAll()"
            text="Select all"
          >

          </app-button>
        </div>
        <div>
          <app-button
            dataCy="create-chart-modal-chart-template-edit"
            [disabled]="isEditDisabled"
            [isUiLockable]="false"
            (buttonClick)="onEditClick()"
            text="Edit"
          >
          </app-button>
          <app-button
            dataCy="create-chart-modal-chart-template-delete"
            class="ml"
            [disabled]="isDeleteDisabled"
            [isUiLockable]="false"
            (buttonClick)="onDeleteClick()"
            text="Delete"
          >

          </app-button>
        </div>
      </div>

      <div class="data-source-info">
        <div *ngIf="areCurrentResultsPresentAndCompleted$ | async else importData">
          Chart(s) will be generated using calculation results
        </div>
        <ng-template #importData>
          There are no calculation results, chart(s) will be generated using imported data
        </ng-template>
      </div>

    </app-page-panel>
  </div>
  <div class="dialog-footer">
    <app-button
      [dialogRef]="ref"
      dataCy="create-chart-modal-cancel"
      [isUiLockable]="false"
      (buttonClick)="cancelClicked()"
      buttonType="cancel"
      text="Cancel"
    >
    </app-button>
    <app-button
      [dialogRef]="ref"
      [disabled]="chartTemplatesEnabled() && selectedChartTemplatesEncodedIds.length === 0"
      dataCy="create-chart-modal-ok"
      [isUiLockable]="false"
      (buttonClick)="okClicked()"
      buttonType="primary"
      text="OK"
    >
    </app-button>
  </div>
</div>

<div class="modal-help" [class.open]="isHelpOpen">
  <div class="help-content">
    <ng-container *ngIf="'assets/help-files/toolbars/charts-create.html' | helpUrl | async as url">
      <iframe [src]="url"></iframe>
    </ng-container>
  </div>
</div>
