<div class="wrapper with-help" [class.help-open]="isHelpOpen">
  <div class="dialog-header">
    <h4>Add new column</h4>
    <app-modal-help-button (helpClick)="onHelpClick($event.isHelpOpen)"></app-modal-help-button>
  </div>

  <div class="dialog-content" *ngIf="column$ | async as column">
    <app-page-panel header="Output column">
      <app-gauge-data-column-props [selectedColumn]="column" [isAddColumnMode]="true"></app-gauge-data-column-props>
    </app-page-panel>

    <app-page-panel header="Define variables">
      <div class="define-variables-wrapper">
        <app-variables-grid (selectedRowsChanged)="onSelectRowChanged($event)"></app-variables-grid>

        <div class="buttons-panel">
          <div>
            <app-button dataCy="btn-add-new-variable" (buttonClick)="onAddNewVariable()" text="Add new variable"></app-button>
            <app-button dataCy="btn-remove-selected-variable"
              [disabled]="selectedRow === null"
              (buttonClick)="onRemoveVariable()"
              text="Remove selected variable"
            >
            </app-button>
          </div>
        </div>

        <app-add-column-equation-input></app-add-column-equation-input>
      </div>
    </app-page-panel>

    <app-page-panel header="Preview">
      <app-equation-preview></app-equation-preview>
    </app-page-panel>
  </div>

  <div class="dialog-footer space-between">
    <app-button
      buttonType="cancel"
      [isUiLockable]="false"
      (buttonClick)="cancelClicked()"
      dataCy="btn-cancel-add-new-column"
      text="Cancel"
    >
    </app-button>
    <app-button
      dataCy="confirm-modal-confirm"
      [isUiLockable]="false"
      (buttonClick)="onConfirm()"
      text="Save"
    >

    </app-button>
  </div>
</div>

<div class="modal-help" [class.open]="isHelpOpen">
  <div class="help-content">
    <ng-container *ngIf="'assets/help-files/data/equations.html' | helpUrl | async as url">
      <iframe [src]="url"></iframe>
    </ng-container>
  </div>
</div>
