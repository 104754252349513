import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as uiActions from '../../+store/ui/ui.actions';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { Subscription } from 'rxjs';
import { getDataAnalysisModuleMenuData } from './data-analysis-page.menu';
import { AppTargetConfig } from '../../shared/services/app-target-config';
import { ThemeService } from '../../shared/services/theme.service';

@Component({
  selector: 'app-data-analysis-page',
  templateUrl: './data-analysis-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataAnalysisPageComponent implements OnInit, OnDestroy {
  public dataAnalysisMenu$ = this.store.select(getDataAnalysisModuleMenuData);
  public subscription = new Subscription();
  private isDataPro = false;

  constructor(
    private store: Store,
    private appConfig: AppTargetConfig,
    private themeService: ThemeService,
  ) {
    this.isDataPro = this.appConfig.isAppCode('data-pro');
    store.dispatch(uiActions.setAppModuleAction({ appModuleType: ModuleType.Data_Analysis }));
  }

  public ngOnInit(): void {
    if (this.isDataPro == false) {
      this.themeService.loadStyle('theme-data-pro', 'packpro-theme-datapro.css');
    }
  }

  public ngOnDestroy(): void {
    if (this.isDataPro == false) {
      this.themeService.removeStyle('theme-data-pro');
    }
  }
}
