<div class="grid-form-container no-padding main" *ngIf="stylesObj">
  <label
    [class.highlight]="isValueDiffThanDefault('SeriesLineThickness')">Series
    Line Thickness</label>
  <app-select
    [source]="stylesObj"
    [sourceDefaults]="defaultSeriesStyle"
    [key]="'SeriesLineThickness'"
    [items]="lineThicknessSelect"
    [isUiLockable]="false"
    [disableSorting]="true"
      [disabled]="disabled"
    [highlight]="isValueDiffThanDefault('SeriesLineThickness')"
    (valueChanged)="emitStyleValue($event)"
    dataCy="chart-series-style-series-line-thickness"
  ></app-select>
  <!-- /Thickness -->

  <ng-container *ngIf="globalStylesDataSource as globalStyles">
    <label class="align-top"> Default series colors </label>
    <div class="color-pickers default">
      <div class="col">
        <label [class.highlight]="isValueDiffThanDefault('PumpPressureColor')">Pump
          Pressure</label>
        <app-select
          [items]="Colors"
          [source]="globalStyles"
          [sourceDefaults]="defaultSeriesStyle"
          [key]="'PumpPressureColor'"
          [colorPicker]="true"
          [isUiLockable]="false"
          [disabled]="disabled"
          [highlight]="isValueDiffThanDefault('PumpPressureColor')"
          (valueChanged)="emitStyleValue($event)"
        ></app-select>
      </div>
      <div class="col">
        <label [class.highlight]="isValueDiffThanDefault('PumpRateColor')">Pump Rate</label>
        <app-select
          [items]="Colors"
          [source]="globalStyles"
          [sourceDefaults]="defaultSeriesStyle"
          [key]="'PumpRateColor'"
          [colorPicker]="true"
          [isUiLockable]="false"
          [disabled]="disabled"
          [highlight]="isValueDiffThanDefault('PumpRateColor')"
          (valueChanged)="emitStyleValue($event)"
        ></app-select>
      </div>
      <div class="col">
        <label [class.highlight]="isValueDiffThanDefault('ReturnRateColor')">Return
          Rate</label>
        <app-select
          [items]="Colors"
          [source]="globalStyles"
          [sourceDefaults]="defaultSeriesStyle"
          [key]="'ReturnRateColor'"
          [colorPicker]="true"
          [isUiLockable]="false"
          [disabled]="disabled"
          [highlight]="isValueDiffThanDefault('ReturnRateColor')"
          (valueChanged)="emitStyleValue($event)"
        ></app-select>
      </div>
      <div class="col">
        <label [class.highlight]="isValueDiffThanDefault('GravelConcColor')">Gravel
          Conc.</label>
        <app-select
          [items]="Colors"
          [source]="globalStyles"
          [sourceDefaults]="defaultSeriesStyle"
          [key]="'GravelConcColor'"
          [colorPicker]="true"
          [isUiLockable]="false"
          [disabled]="disabled"
          [highlight]="isValueDiffThanDefault('GravelConcColor')"
          (valueChanged)="emitStyleValue($event)"
        ></app-select>
      </div>
    </div>
  </ng-container>

  <!-- Colors -->
  <label class="align-top">Series {{ isTemplateMode ? 'Color' : 'Colors' }}</label>
<div *ngIf="!isTemplateMode" class="color-pickers">
  <div class="col" *ngFor="let color of seriesColors; let i = index; trackBy: trackByFn">
    <label
      [class.highlight]="!isTemplateMode && seriesColors[i] !== defaultSeriesColors[i]">Series {{ i + 1 }}</label>
    <app-select
      [source]="seriesColors"
      [sourceDefaults]="defaultSeriesColors"
      [key]="i"
      [items]="Colors"
      [colorPicker]="true"
      [isUiLockable]="false"
      [disabled]="disabled"
      [highlight]="!isTemplateMode && seriesColors[i] !== defaultSeriesColors[i]"
      (primitiveValueChanged)="updateColorAtIndex($event, i)"
    ></app-select>
  </div>
</div>


  <ng-container *ngIf="isTemplateMode">
    <app-select
      [items]="Colors"
      [colorPicker]="true"
      [isUiLockable]="false"
      [value]="seriesColour"
      [disabled]="disabled"
      (primitiveValueChanged)="emitSeriesColourValueChange($event)"
    ></app-select>
  </ng-container>
  <!-- /Colors -->

  <!-- Line style -->
  <label [class.highlight]="isValueDiffThanDefault('SeriesLineStyle')">Series
    Line Style</label>
  <app-select
    [source]="stylesObj"
    [sourceDefaults]="defaultSeriesStyle"
    [key]="'SeriesLineStyle'"
    [items]="seriesLineStyles"
    [value]="stylesObj.SeriesLineStyle"
    [isUiLockable]="false"
    [disabled]="disabled"
    [highlight]="isValueDiffThanDefault('SeriesLineStyle')"
    (valueChanged)="emitStyleValue($event)"
  ></app-select>

  <!-- /Line style -->

  <!-- Markers enabled -->
  <label
    [class.highlight]="isValueDiffThanDefault('SeriesPointMarkersEnabled')">Point
    Markers</label>
  <app-radio-group
    [source]="stylesObj"
    [sourceDefaults]="defaultSeriesStyle"
    [key]="'SeriesPointMarkersEnabled'"
    [items]="seriesPointMarkerItems"
    [disabled]="disabled || isMarkerSelectDisabled"
    id="IsOverrideLegend"
    [isUiLockable]="false"
    [highlight]="isValueDiffThanDefault('SeriesPointMarkersEnabled')"
    (valueChanged)="emitStyleValue($event)"
    class="grid-two-one point-markers-radio"
    dataCy="point-markers-radio"
    layout="horizontal"
  >
  </app-radio-group>
  <!--/Markers enabled -->

  <!-- Markers Shape -->
  <label
    [class.highlight]="!disabled && stylesObj.SeriesPointMarkersEnabled && isValueDiffThanDefault('SeriesPointMarkersShape')">Point
    Markers Shape</label>
  <app-select
    [source]="stylesObj"
    [sourceDefaults]="defaultSeriesStyle"
    [key]="'SeriesPointMarkersShape'"
    [items]="pointMarkerShapes"
    [disabled]="disabled || !stylesObj.SeriesPointMarkersEnabled"
    [value]="stylesObj.SeriesPointMarkersShape"
    [highlight]="!disabled && stylesObj.SeriesPointMarkersEnabled && isValueDiffThanDefault('SeriesPointMarkersShape')"
    [isUiLockable]="false"
    (valueChanged)="emitStyleValue($event)"
  ></app-select>
  <!-- /Markers Shape -->

  <!-- Markers Size -->
  <label
    [class.highlight]="!disabled && stylesObj.SeriesPointMarkersEnabled && isValueDiffThanDefault('SeriesPointMarkersSize')">Point
    Markers Size</label>
  <app-select
    [source]="stylesObj"
    [sourceDefaults]="defaultSeriesStyle"
    [key]="'SeriesPointMarkersSize'"
    [items]="pointMarkersSizeSelect"
    [disabled]="disabled || !stylesObj.SeriesPointMarkersEnabled"
    [isUiLockable]="false"
    [disableSorting]="true"
    [highlight]="!disabled && stylesObj.SeriesPointMarkersEnabled && isValueDiffThanDefault('SeriesPointMarkersSize')"
    (valueChanged)="emitStyleValue($event)"
    dataCy="chart-series-style-series-point-markers-size"
  ></app-select>
  <!-- /Markers Size -->
</div>
