<button *ngIf="!isAdvancedButtonOnly"
        type="button"
        #button
        class="btn {{ class }} {{ buttonType === 'cancel' ? 'btn-cancel' : '' }}"
        [class.toggled]="isToggled"
        [attr.data-cy]="dataCy"
        [disabled]="isDisabled"
        [class.advanced-top]="isAdvancedButton"
        [style.width.px]="buttonWidth ?? 'unset'"
        (click)="onClick($event)"
>
  <div class="img" *ngIf="icon"><img [src]="icon" /></div>
  <div class="icon-as-text" *ngIf="iconAsText">{{ iconAsText }}</div>
  <div class="text">
    <i class="icon-burger" *ngIf="isMenuButton"></i>
    <span class="text-icon {{ textIcon }}" style="{{ 'transform:' + iconTransformation }}" *ngIf="textIcon">
      <span class="{{ subIcon }}" *ngIf="subIcon"></span>
    </span>
    <span [innerHTML]="text" *ngIf="!isAdvancedButton"></span>
    <span class="error-row-icon" *ngIf="!isValid"></span>
  </div>
  <span *ngIf="showExclamationMark" class="error-row-icon"></span>

</button>
<button *ngIf="isAdvancedButton"
        type="button"
        [class.toggled]="isToggled"
        [class.is-advanced-btn-only]="isAdvancedButtonOnly"
        [disabled]="isDisabled"
        [attr.data-cy]="dataCy + '-dropdown'"
        class="btn {{ class }} advanced-bottom"
        (click)="menu.toggle($event)">
  <span class="bottom-text">{{ text }}<span class="icon-bottom-chevron"> </span></span>
</button>
<p-menu #menu appendTo="body"
        [style]="advancedButtonWidth ? {width: advancedButtonWidth} : {}"
        [model]="advancedButtonContextMenuItems" [popup]="true"></p-menu>
