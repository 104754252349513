<ng-container *ngIf="completionState.isLoaded">
  <app-page-panel class="grid-panel" header="Lower Completion" (click)="onPipeTypeHelpChange('gravel-pack-packer')"
                  [panelHelpMode]="PanelHelpMode.GRID">
    <app-lower-completion-grid
      #gridComponent
      [lowerCompletionData]="completionState.LowerCompletion"
      (selectedRowsChanged)="onSelectedRowsChanged($event)"
    >
    </app-lower-completion-grid>
  </app-page-panel>

  <app-page-panel class="options-panel" [header]="pipeTypeName" *ngIf="selectedScreen"
                  (click)="setSelectedRow(); onPipeTypeHelpChange()">
    <app-screen-component
      [screenPipeRow]="selectedScreen"
      [icdPortData]="selectedIcdPortData"
      (rowUpdated)="onLowerCompletionScreenPipePropertyChanged($any($event))"
      (icdPortDataRowUpdated)="onIcdPortDataPropertyChanged($event)"
      [diameterUnit]="currentUnitSystem.Diameter"
    ></app-screen-component>
  </app-page-panel>

  <div class="bottom-panel-button-lg">
    <app-page-panel
      header="Screen Centralizers"
      class="location-type-panel"
      *ngIf="completionState"
      (click)="onHelpChange('completion', 'screen-centralizer')"
    >
      <app-screen-centralizers [completionData]="completionState"
                               (completionPropertyChanged)="onCompletionPropertyChanged($event)">
      </app-screen-centralizers>
    </app-page-panel>

    <div class="buttons-panel">
      <div></div>
      <div>
        <app-button
          dataCy="pipe-data"
          [isUiLockable]="false"
          (buttonClick)="onShowPipeDataClick()"
          [disabled]="isPipeDataDisabled"
          text="Pipe Data"
        >

        </app-button>
        <app-button dataCy="lower-completion-import" (buttonClick)="onImport()" [isDeleteResultsEnabled]="false"
                    text="Import"></app-button>
        <app-button (buttonClick)="onInsert()" [isDeleteResultsEnabled]="false" text="Insert"></app-button>
        <app-button (buttonClick)="onDelete()" [isDeleteResultsEnabled]="false" text="Delete"></app-button>
      </div>
    </div>

  </div>
</ng-container>
