<div class="main-panel">
  <div class="left-button-menu">
    <div>
      <app-button
        dataCy="scenario-manager-create-btn"
        [disabled]="isReorderOn"
        [isDeleteResultsEnabled]="false"
        (buttonClick)="createScenario()"
        [buttonWidth]="100"
        text="Create"
      >
      </app-button>
      <app-button
        dataCy="scenario-manager-copy-btn"
        [disabled]="selectedScenarioIds.length !== 1 || isReorderOn"
        [isDeleteResultsEnabled]="false"
        (buttonClick)="copyScenario()"
        [buttonWidth]="100"

        text="Copy"
      >
      </app-button>
      <app-button
        dataCy="scenario-manager-rename-btn"
        [disabled]="selectedScenarioIds.length !== 1 || isReorderOn"
        [isDeleteResultsEnabled]="false"
        (buttonClick)="editScenario()"
        text="Rename"
        [buttonWidth]="100"
      >
      </app-button>
      <app-button
        dataCy="scenario-manager-delete-btn"
        [disabled]="selectedScenarioIds.length === 0 || isReorderOn"
        [isDeleteResultsEnabled]="false"
        (buttonClick)="deleteScenarios()"
        text="Delete"
        [buttonWidth]="100"
      >
      </app-button>
      <app-button
        dataCy="scenario-manager-reorder"
        (buttonClick)="toggleReorder()"
        [class.highlight]="isReorderOn"
        [isUiLockable]="false"
        [disabled]="scenarios.length <= 1"
        text="Reorder"
        [buttonWidth]="100"
      >
      </app-button>
      <app-button
        dataCy="scenario-manager-switch"
        (buttonClick)="switchScenario()"
        [isUiLockable]="false"
        [disabled]="selectedScenarioIds.length !== 1 || selectedScenarioIds[0] === currentScenarioId"
        text="Switch"
        [buttonWidth]="100"
      >
      </app-button>
    </div>
    <div *ngIf="isSimulateBased(currentAppModuleType)">
      <app-button
        dataCy="scenario-manager-simulate-btn"
        (buttonClick)="runSimulations()"
        [isUiLockable]="false"
        [disabled]="selectedScenarioIds.length === 0 || isReorderOn"
        text="Simulate"
        [buttonWidth]="100"
      >
      </app-button>
    </div>
  </div>
  <app-page-panel header="Select Scenario(s)" class="scenarios-selector-container" [allowFullScreen]="false">
    <app-scenario-manager-checkboxes
      dataCy="scenario-manager-checkboxes"
      [selectedIds]="selectedScenarioIds"
      [labelWidth]="360"
      (valueChanged)="updateSelectedIds($event)"
      [isReorderOn]="isReorderOn"
      (scenarioDropped)="onDrop($event)"
    >
    </app-scenario-manager-checkboxes>
  </app-page-panel>
</div>
