<ng-container *ngIf="contentVisible && (currentResultsPresent$ | async); else noResults">
  <ng-container *ngIf="chartIds$ | async as chartIds; else noResults">
    <div class="visualization-drawing" (click)="onHelpChange('results', 'animation-visualization')">
      <app-animation-packing-drawing [drawingSection]="selectedWellPartType"
                                     [layoutType]="selectedLayoutType" [chartId]="chartIds.wellVisualizationId"></app-animation-packing-drawing>
    </div>
    <div class="animation-footer" (click)="onHelpChange('results', 'animation-visualization')">
      <div class="slider">
        <app-chart-slider *ngIf="contentVisible"></app-chart-slider>
      </div>
      <div class="layout-selection padding-right">
        <app-select
          [items]="layoutTypes"
          [value]="selectedLayoutType"
          [isUiLockable]="false"
          (primitiveValueChanged)="onLayoutChange($event.value)"
        >
        </app-select>
      </div>
      <div class="layout-selection padding-right" *ngIf="isFluidProModule">
        <app-select
          [items]="visualizationTypes"
          [value]="selectedVisualizationType$ | async"
          [isUiLockable]="false"
          (primitiveValueChanged)="onVisualizationTypeChange($event.value)"
          [dataCy]="'select-visualization-type'"
        >
        </app-select>
      </div>
      <div class="layout-selection">
        <app-select
          [items]="wellPartTypes"
          [value]="selectedWellPartType"
          [isUiLockable]="false"
          [disabled]="isWellPartFixed"
          (primitiveValueChanged)="onWellPartChange($event.value)"
        >
        </app-select>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #noResults>
  <div class="centered-message" (click)="onHelpChange('results', 'animation-visualization')">
    No results available, please run a simulation.
  </div>
</ng-template>
