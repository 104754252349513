<div class="dialog-header">
  <h4>Import Temperature Profile</h4>
</div>

<div class="dialog-content">
  <ng-container *ngIf="scenarioRangeWithResults.length">
    <div class="import-temp-header-container">
      <span><b>Module</b></span>
      <span><b>Scenario Name</b></span>
      <span><b>Range Name</b></span>
      <span></span>
    </div>
    <div class="import-temp-grid-container" *ngFor="let scenarioRangeWithResult of scenarioRangeWithResults">
      <span>{{ EnumHelpers.getEnumName(ModuleType, scenarioRangeWithResult.moduleType) }}</span>
      <span>{{ scenarioRangeWithResult.scenarioName }}</span>
      <span>{{ scenarioRangeWithResult.rangeName }}</span>

      <app-check-box
        [id]="scenarioRangeWithResult.scenarioName + scenarioRangeWithResult.rangeName"
        [isUiLockable]="false"
        [value]="scenarioRangeWithResult === selectedScenarioRangeWithResults"
        (primitiveValueChanged)="selectValue(scenarioRangeWithResult, $event.value)"
        [dataCy]="'cb-import-temp-profile_' + scenarioRangeWithResult.scenarioName + scenarioRangeWithResult.rangeName"
      >
      </app-check-box>
    </div>
  </ng-container>
  <app-form-input
    class="initial-bhct-input"
    [source]="well.TemperatureProfile"
    label="Initial BHCT at Max Survey Depth"
    dataCy="txt-import-temp-profile-BHCT"
    key="BottomholeCirculating"
    [decimalPlaces]="2"
    [isUiLockable]="false"
    [unitType]="UnitType.Temperature"
    [autoFocus]="true"
    (valueChanged)="onValueChanged($event)"
  >
  </app-form-input>
</div>

<div class="dialog-footer">
  <app-button [isUiLockable]="false" class="align-button-left" (buttonClick)="clearSelection()" text="Clear All">
  </app-button>

  <app-button
    buttonType="cancel"
    [dialogRef]="ref"
    [isUiLockable]="false"
    (buttonClick)="closeModal()"
    text="Cancel"
  >
  </app-button>

  <app-button
    [isUiLockable]="false"
    [dialogRef]="ref"
    (buttonClick)="importClicked()"
    [disabled]="scenarioRangeWithResults.length === 0"
    dataCy="btn-import-temp-profile_import"
    text="Import"
  >
  </app-button>
</div>
