import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { getHelpUrlSuccessAction, HelpIds, PageType, ToolbarsHelpIds } from '../../+store/help/help.actions';
import { Subscription } from 'rxjs';
import { getAppModuleType, getCurrentMenuTab, getIsHelpPinned, getIsRibbonDisplayed, getIsSideNavPinned } from '../../+store/ui/ui.selectors';
import { ribbonMenuChangedAction, setIsRibbonDisplayedAction } from '../../+store/ui/ui.actions';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { GeneralMenuProps, getGeneralMenuProps } from '../../+store/menu-selectors/general/menu-general.selector';
import { undoRedoActionClick } from '../../+store/undo-redo/undo-redo.action';
import { isElectron } from '@dunefront/common/common/electron/is-electron';
import { isDataBased } from '@dunefront/common/modules/scenario/scenario.dto';

export interface Tab {
  id: ToolbarsHelpIds;
  name: string;
  icon: string;
}

export const ribbonMenuTabs: Tab[] = [
  {
    id: 'general',
    name: 'General',
    icon: 'icon-menu-general',
  },
  {
    id: 'analysis',
    name: 'Analysis',
    icon: 'icon-analysis',
  },
  {
    id: 'charts',
    name: 'Charts',
    icon: 'icon-chart',
  },
  {
    id: 'view',
    name: 'View',
    icon: 'icon-eye5',
  },
  {
    id: 'docs',
    name: 'Docs',
    icon: 'icon-folder',
  },
];

@Component({
  selector: 'app-ribbon-menu',
  templateUrl: './ribbon-menu.component.html',
  styleUrls: ['./ribbon-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RibbonMenuComponent implements OnDestroy {
  @ViewChild('ribbonContent', { static: true }) public ribbonContent?: ElementRef;
  public tabs = ribbonMenuTabs;
  public openTab: HelpIds = 'general';
  public isMenuDisplayed = true;
  public isPinned = true;
  public generalMenuProps!: GeneralMenuProps;
  public isElectron = isElectron();

  private subscription = new Subscription();

  public isNavPinned = false;
  public isHelpPinned = false;

  constructor(
    protected store: Store,
    private eRef: ElementRef,
    private cdRef: ChangeDetectorRef,
  ) {
    this.subscription.add(
      this.store.select(getAppModuleType).subscribe((appModuleType) => {
        if (isDataBased(appModuleType)) {
          // render all tabs
          this.tabs = ribbonMenuTabs;
        } else {
          // don't render analysis tab
          this.tabs = ribbonMenuTabs.filter((tab) => tab.id !== 'analysis');
          if (this.openTab === 'analysis') {
            this.changeTab('toolbars', 'general');
          }
        }
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      notEmpty(store.select(getGeneralMenuProps)).subscribe((generalMenuProps) => {
        this.generalMenuProps = generalMenuProps;
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(this.store.select(getIsRibbonDisplayed).subscribe((isRibbonDisplayed) => (this.isMenuDisplayed = isRibbonDisplayed)));

    this.subscription.add(
      this.store.select(getIsSideNavPinned).subscribe((isNavPinned) => {
        this.isNavPinned = isNavPinned;
      }),
    );
    this.subscription.add(
      this.store.select(getIsHelpPinned).subscribe((isHelpPinned) => {
        this.isHelpPinned = isHelpPinned;
      }),
    );
    this.subscription.add(this.store.select(getCurrentMenuTab).subscribe((currentMenuTab) => (this.openTab = currentMenuTab)));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
  public clickOut(event: { target: any }): void {
    if (!this.eRef.nativeElement.contains(event.target) && !this.isPinned && this.isMenuDisplayed) {
      this.store.dispatch(setIsRibbonDisplayedAction({ isRibbonDisplayed: false }));
    }
  }

  public changeTab(page: PageType, uri: HelpIds): void {
    this.changeHelp(page, uri);

    this.store.dispatch(ribbonMenuChangedAction({ chartTab: uri }));
  }

  public changeHelp(page: PageType, uri: HelpIds): void {
    this.store.dispatch(getHelpUrlSuccessAction({ helpUrl: `assets/help-files/${page}/${uri}.html` }));
  }

  public undoClick(): void {
    this.store.dispatch(undoRedoActionClick('undo'));
  }

  public redoClick(): void {
    this.store.dispatch(undoRedoActionClick('redo'));
  }
}
