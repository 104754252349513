export interface SummaryDto {
  ScenarioId: number;
  RangeId: number;
  IsReversePosition: boolean;
  IsShuntTubes: boolean;
  MaxUpperAnnulusFriction: number;
  MaxAnnularLineFriction: number;
  MaxBlankFriction: number;
  MaxChokeFriction: number;
  MaxGaugeCarrierFriction: number;
  MaxHeelPressure: number;
  MaxScreenFriction: number;
  MaxServiceToolDownFlowFriction: number;
  MaxServiceToolUpFlowFriction: number;
  MaxShuntFriction: number;
  MaxSurfacePressure: number;
  MaxToePressure: number;
  MaxTreatingFriction: number;
  MaxWashpipeFriction: number;
  MaxWorkstringFriction: number;
  FinalScreenPackPercentage: number;
  GravelPack: number;
  FinalOverallPackPercentage: number;
  FinalPerforationPackPercentage: number;
  BlankGravelMass: number;
  ScreenGravelMass: number;
  PerfGravelMass: number;
  FracNetPressure: number;
  FracFluidEfficiency: number;
  FracPackEfficiency: number;
  BlankPackLength: number;
  PerfLinearGravelMass: number;
  MaxPackerWorkstringPressure: number;
  MaxPackerAnnulusPressure: number;
  MaxLowerAnnulusFriction: number;
  MaxHeelECD: number;
  MaxToeECD: number;
  MaxPackerWorkstringECD: number;
  MaxPackerAnnulusECD: number;
  MaxPackHeightRatio: number;
  MaxPackHeight: number;
}

export const createEmptySummaryDto = (): SummaryDto => {
  return {
    ScenarioId: -1,
    RangeId: -1,
    IsReversePosition: false,
    IsShuntTubes: false,
    MaxUpperAnnulusFriction: 0,
    MaxAnnularLineFriction: 0,
    MaxBlankFriction: 0,
    MaxChokeFriction: 0,
    MaxGaugeCarrierFriction: 0,
    MaxHeelPressure: 0,
    MaxScreenFriction: 0,
    MaxServiceToolDownFlowFriction: 0,
    MaxServiceToolUpFlowFriction: 0,
    MaxShuntFriction: 0,
    MaxSurfacePressure: 0,
    MaxToePressure: 0,
    MaxTreatingFriction: 0,
    MaxWashpipeFriction: 0,
    MaxWorkstringFriction: 0,
    FinalScreenPackPercentage: 0,
    GravelPack: 0,
    FinalOverallPackPercentage: 0,
    FinalPerforationPackPercentage: 0,
    BlankGravelMass: 0,
    ScreenGravelMass: 0,
    PerfGravelMass: 0,
    FracNetPressure: 0,
    FracFluidEfficiency: 0,
    FracPackEfficiency: 0,
    BlankPackLength: 0,
    PerfLinearGravelMass: 0,
    MaxPackerWorkstringPressure: 0,
    MaxPackerAnnulusPressure: 0,
    MaxLowerAnnulusFriction: 0,
    MaxHeelECD: 0,
    MaxToeECD: 0,
    MaxPackerWorkstringECD: 0,
    MaxPackerAnnulusECD: 0,
    MaxPackHeightRatio: 0,
    MaxPackHeight: 0,
  };
};
