<ng-container *ngIf="selectedFluid">
  <label>Fluid Color</label>
  <app-select
    dataCy="fluid-color-selector"
    [source]="selectedFluid"
    [key]="'Color'"
    [items]="Colors"
    [colorPicker]="true"

    [isUiLockable]="false"
    (valueChanged)="changeFluidColor($event)"
  ></app-select>
</ng-container>
