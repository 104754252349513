<ng-container *ngIf="currentUnitSystem && wellState.isLoaded">
  <div class="grid-panel-container" [class.detailed-calc]="isDetailedCalc">
    <app-page-panel
      header="Survey Data"
      class="grid-panel survey-data-panel"
      (click)="onHelpChange('well', 'survey')"
      [panelHelpMode]="PanelHelpMode.GRID"
    >
      <app-survey-grid
        #gridComponent
        [surveyData]="wellState.SurveyData"
        [temperatureProfile]="wellState.TemperatureProfile"
      ></app-survey-grid>
    </app-page-panel>

    <app-page-panel
      header="Survey Preview"
      class="no-padding survey-preview-panel"
      (click)="onHelpChange('well', 'survey')"
      [panelHelpMode]="PanelHelpMode.CHART"
    >
      <app-survey-preview-chart [state]="wellState" [longLengthUnit]="currentUnitSystem.Long_Length"> </app-survey-preview-chart>
    </app-page-panel>
  </div>
  <div class="general-data-props-panel" [class.detailed-calc]="isDetailedCalc">
    <app-page-panel
      header="Treatment and Location Type"
      class="location-type-panel"
      (click)="onHelpChange('well', 'location-type')"
    >
      <app-location-type
        [state]="wellState"
        (wellPropertyChanged)="onWellPropertyChanged($event)"
        (temperatureProfileChanged)="onTemperatureProfileChanged($event)"
      >
      </app-location-type>
    </app-page-panel>

    <app-page-panel
      header="Temperature Profile"
      class="temperature-profile-panel"
      (click)="onHelpChange('well', 'temperature-profile')"
    >
      <app-temperature-profile
        [temperatureProfile]="wellState.TemperatureProfile"
        [locationType]="wellState.LocationType"
        (temperatureProfileChanged)="onTemperatureProfileChanged($event)"
        class="container-full"
      >
      </app-temperature-profile>
    </app-page-panel>
  </div>
</ng-container>
