<app-page-panel header="Chart Series" [allowFullScreen]="false" (click)="onHelpChange('settings', 'chart-series')">
  <div style="flex-direction: column">
    <app-radio-group
      [items]="[
        { value: false, text: 'Default' },
        { value: true, text: 'Custom' }
      ]"
      [source]="globalOptions"
      [key]="'IsOverrideSeries'"
      id="IsOverrideSeries"
      dataCy="is-override-series-radio"
      [isUiLockable]="false"
      (valueChanged)="onValueChange($event)"
      class="grid-two-one"
      layout="horizontal"
    >
    </app-radio-group>
    <app-chart-series-style
      class="block m-top-1"
      [disabled]="!globalOptions.IsOverrideSeries"
      [seriesStyle]="globalOptions"
      [defaultSeriesStyle]="defaultGlobalOptions"
      (valueChanged)="onStyleValueChange($event)"
    ></app-chart-series-style>
  </div>
</app-page-panel>
