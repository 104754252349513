<div class="grid-form-container no-padding" *ngIf="state">
  <label>{{ isSaveAsTemplateMode ? 'Used Template' : 'Select Template' }}</label>
  <app-select
    [items]="templateItems"
    [value]="selectedTemplateId"
       (primitiveValueChanged)="onTemplateChanged($event)"
    [disabled]="isSaveAsTemplateMode || !state.fileName"
    [overrideErrorMessage]="state.templateError"
    dataCy="template-manager-select"
  ></app-select>

  <div class="buttons-panel">
    <div>
      <ng-container *ngIf="isSaveAsTemplateMode">
        <app-button
          dataCy="import-data-template-save"
          [disabled]="disabled"
          (buttonClick)="onSaveAsTemplateClick()"
          [text]="selectedTemplate ? 'Save as new' : 'Save'"
        >
        </app-button>
      </ng-container>
      <ng-container *ngIf="!isSaveAsTemplateMode">
        <app-button text="Rename" [disabled]="!isEditDeleteEnabled" (buttonClick)="onRenameClick()"></app-button>
        <app-button
          dataCy="import-data-template-delete"
          [disabled]="!isEditDeleteEnabled"
          (buttonClick)="onDeleteClick()"
          text="Delete"
        >
        </app-button>
      </ng-container>
    </div>
  </div>
</div>
