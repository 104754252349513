<div *ngIf="fluid">
  <div (click)="onHelpChange('fluids', 'fluid-properties')">
    <div class="grid-form-container no-padding fluid-prop-top-grid">
      <app-form-input
        [source]="fluid"
        [key]="'Name'"
        appUnwrap
        label="Fluid Name"
        dataCy="fluid-name"
        [isTextInput]="true"
        [unitType]="UnitType.None"
        (valueChanged)="emitValue($event)"
      >
      </app-form-input>
      <label>Fluid Type</label>
      <app-select [source]="fluid" [key]="'Type'" [items]="fluidTypes" dataCy="fluid-type"
                  (valueChanged)="emitValue($event)">
      </app-select>

      <app-form-input
        [source]="fluid"
        [key]="'CleanFluidDensity'"
        class="grid-two-one"
        appUnwrap
        label="Density"
        dataCy="fluid-density"
        [decimalPlaces]="3"
        [unitType]="UnitType.Liquid_Density"
        (valueChanged)="emitValue($event)"
      >
      </app-form-input>
      <app-form-input
        [source]="fluid"
        [key]="'DragReductionFactor'"
        class="grid-two-one"
        appUnwrap
        label="Drag Reduction"
        dataCy="fluid-drag-reduction"
        [unitType]="UnitType.None"
        [unitLabel]="'%'"
        (valueChanged)="emitValue($event)"
      >
      </app-form-input>
    </div>
  </div>

  <div class="grid-form-container no-padding fluid-prop-middle-grid">
    <label (click)="onHelpChange('fluids', 'gravel-settling')">Gravel Settling</label>
    <app-radio-group
      [source]="fluid"
      [key]="'IsGravelSettling'"
      [items]="isGravelSettlingRadio"
      dataCy="fluid-is-gravel-settling"
      id="is_gravel_settling"
      (click)="onHelpChange('fluids', 'gravel-settling')"
      (valueChanged)="emitValue($event)"
    >
    </app-radio-group>

    <ng-container *ngIf="isFluidPro">
      <label (click)="onHelpChange('fluids', 'fluid-properties')">Yield Stress</label>
      <app-radio-group
        [source]="fluid"
        [key]="'IsYieldStress'"
        [items]="isYieldStressRadio"
        dataCy="fluid-is-yield-stress"
        id="is_yield-stress"
        (click)="onHelpChange('fluids', 'fluid-properties')"
        (valueChanged)="emitValue($event)"
      >
      </app-radio-group>
    </ng-container>
  </div>

  <div class="grid-form-container no-padding fluid-prop-bottom-grid">
    <label (click)="onHelpChange('fluids', 'fluid-rheology')">Rheology</label>
    <app-radio-group
      [source]="fluid"
      [key]="'RheologyType'"
      [items]="rheologyRadio"
      dataCy="fluid-rheology-type"
      id="rheology"
      (click)="onHelpChange('fluids', 'fluid-rheology')"
      (valueChanged)="emitValue($event)"
    >
    </app-radio-group>

    <div *ngIf="fluidAdvancedOptionsAvailable$ | async" class="btn-wrapper">
      <app-button
        dataCy="open-advanced-fluid-options"
        [isUiLockable]="false"
        tooltipPosition="top"
        positionStyle="absolute"
        tooltipStyleClass="error-tooltip"
        [tooltipDisabled]="fluidAdvOptionsValid"
        [pTooltip]="'Click the button to correct advanced properties errors'"
        (buttonClick)="openAdvancedFluidProperties(); onHelpChange('fluids', 'fluid-properties')"
        text="Advanced Properties"
        [showExclamationMark]="!fluidAdvOptionsValid"
      >
      </app-button>
    </div>
  </div>
</div>
<app-page-panel class="grid-panel" header="Rheologies" (click)="onHelpChange('fluids', 'fluid-rheology')"
                [panelHelpMode]="PanelHelpMode.GRID">
  <app-fluid-rheologies-grid
    [fluid]="fluid"
    *ngIf="fluid"
    #gridComponent
    [rheologiesData]="rheologies$ | async"
  ></app-fluid-rheologies-grid>
</app-page-panel>

<div class="buttons-panel">
  <div>
    <app-button
      dataCy="fluid-rheologies-import"
      [disabled]="fluid.RheologyType === RheologyType.Rheometer"
      (buttonClick)="onImport()"
      text="Import Rheology"
    >

    </app-button>
    <app-button
      dataCy="delete-rheology"
      [disabled]="fluid.RheologyType === RheologyType.Rheometer"
      [isDeleteResultsEnabled]="false"
      (buttonClick)="onDelete()"
      text="Delete Rheology"
    >

    </app-button>
  </div>
  <div class="props-panel">
    <app-form-input
      *ngIf="settings$ | async as settings"
      label="Shear Rate"
      dataCy="project-fluids-shear-rate"
      class="shear-rate-input"
      [source]="settings"
      [key]="'ShearRate'"
      [decimalPlaces]="2"
      [unitType]="UnitType.None"
      [isUiLockable]="false"
      unitLabel="1/s"
      (valueChanged)="changeShearRate($event)"
    >
    </app-form-input>
  </div>
</div>
