<app-select
  *ngIf="selectData$ | async as selectData"
  [items]="selectData"
  [value]="value"
  [class]="class"
  [isUiLockable]="false"
  [dataCy]="dataCy"
  [disableSorting]="true"
  (primitiveValueChanged)="valueChanged.emit($event.value)"

>
</app-select>
