<div class="notification" [attr.data-cy]="'notification-'+notification.id">
  <h3>{{ notification.title }}</h3>
  <p class="m-0">
    {{ notification.description }}
  </p>

  <div style="display: flex; justify-content: space-between; padding-top: 1rem">
          <span>
            <ng-container *ngIf="showExtraButtons">
            <app-button
              [isUiLockable]="false"
              buttonType="cancel"
              (click)="buttonClicked.emit(NotificationStatus.DISMISSED)"
              text="Dismiss"
            ></app-button>
            <app-button
              [isUiLockable]="false"
              class="no-bg"
              style="margin-left: .5rem"
              (click)="buttonClicked.emit(NotificationStatus.REMIND_LATER)"
              text="Remind me later">
            </app-button>
              </ng-container>
          </span>
    <app-button [isUiLockable]="false"
                (click)="buttonClicked.emit(NotificationStatus.OPENED)"
                text="Open"
    >
    </app-button>
  </div>
</div>
