import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { ReportFileType, ReportInfoDto, ReportInfoFields } from '@dunefront/common/dto/report-info.dto';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { getKeys } from '@dunefront/common/common/helpers';
import { isSimulateBased, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { getIsRangesSelectionComponentVisible, getReportInfo, getReportInfoFields } from '../../../../+store/report-info/report-info.selectors';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import {
  generateReportAction,
  loadReportInfoModuleAction,
  reportInfoSelectAllClearAllAction,
  ToggleInfoFieldsActionPayload,
  updateReportInfoAction,
  updateReportInfoFieldsAction,
} from '../../../../+store/report-info/report-info.actions';
import { getIsTrendAnalysisRunning } from '../../../../+store/calculation-engine/calculation-engine.selectors';
import {
  getCanRenderDepthChart,
  getIsReportPanelVisible,
  getSortedReportingTabsForCurrentRange,
} from '../../../../+store/reporting/reporting.selectors';
import { changeProp, DataSourceValue, ObjectChangeProp, PrimitiveChangeValue } from '@dunefront/common/common/common-state.interfaces';
import { WsActionPropsFactory } from '@dunefront/common/common/ws-action/ws-action-props.factory';
import { firstValueFrom, Observable, of, timeout } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';
import { filterNil } from '@dunefront/common/common/state.helpers';
import { ReportingTabDto } from '@dunefront/common/dto/reporting-tab.dto';
import { checkDepthDataAction, clearResultsChartsData, setSelectedReportingChartTabId } from '../../../../+store/reporting/reporting.actions';
import { ReportingChartComponent } from '../chart/reporting-chart.component';
import { SurveyPreviewChartRendererComponent } from '../../../simulate-evaluate-page/well/general-data/survey-preview-chart-renderer/survey-preview-chart-renderer.component';
import { CaliperPreviewChartRendererComponent } from '../../../simulate-evaluate-page/well/caliper-data/caliper-preview-chart-renderer/caliper-preview-chart-renderer.component';
import { SchematicComponent } from '../../../simulate-evaluate-page/completion/schematic/schematic.component';
import { AnimationPackingDrawingComponent } from '../../../simulate-evaluate-page/results/animation/animation-packing-drawing/animation-packing-drawing.component';
import { CaliperValidation } from '@dunefront/common/modules/well/model/caliper/caliper.validation';
import { getWellModuleState } from '../../../../+store/well/well.selectors';
import { AnimationDepthBasedChartComponent } from '../../../simulate-evaluate-page/results/animation/animation-depth-based-chart/animation-depth-based-chart.component';
import { Base64Image, Base64Media, ImageSize } from '../../../../common-modules/chart/image-provider.helpers';
import {
  IReportingTabImage,
  ReportScenarioImages,
} from '../../../../shared/helpers/document-generator/generators/doc/sections/chart-report-generator.helper';
import { AnimationTimeBasedChartComponent } from '../../../simulate-evaluate-page/results/animation/animation-time-based-chart/animation-time-based-chart.component';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { getAreCurrentResultsPresent } from '../../../../+store/calculation-engine/calculation-engine-results.selectors';
import { PsdChartPanelRendererComponent } from '../../../psd-analysis-page/psd-input/psd-chart/psd-chart-panel-renderer/psd-chart-panel-renderer.component';
import { getPsdDict, getPsdReadingsDict } from '../../../../+store/psd-analysis/psd-analysis.selectors';
import { ConsolidatedPsdChartRendererComponent } from '../../../psd-analysis-page/psd-analysis/psd-comparison/consolidated-psd-chart-renderer/consolidated-psd-chart-renderer.component';
import { getCurrentAppModuleType } from '../../../../+store/ui/ui.selectors';
import { TrendAnalysisChartComponent } from '../../../trend-analysis-page/trend-analysis/trend-analysis-chart/trend-analysis-chart.component';
import { getCurrentScenario } from '../../../../+store/scenario/scenario.selectors';
import { getConnectedFile } from '../../../../+store/backend-connection/backend-connection.selectors';
import { FileDisplayNamePipe } from '../../../../shared/pipes/file/file-display-name.pipe';
import { AnimationLayout, DrawingLayoutType } from '@dunefront/common/modules/settings/dto/settingsDto';
import {
  getEffectiveSimulationAnimationWellLayout,
  getEffectiveSimulationAnimationWellPart,
  getEffectiveWellVisualizationWellPart,
} from '../../../simulate-evaluate-page/results/animation.selectors';
import { getWellVisualizationWellLayout } from '../../../../+store/settings/validated-settings.selectors';
import { loadReferenceVariablesAction } from '../../../../+store/reference-variables/reference-variables.actions';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { VideoRecorderService } from '../../../../common-modules/chart/video-recorder.service';
import { changeCurrentRangeIdAction } from '../../../../+store/range/range.actions';
import { getCurrentRange } from '../../../../+store/range/range.selectors';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';
import { ReportingRangeSelectionComponent } from '../reporting-range-selection/reporting-range-selection.component';
import { isDataAnalysis, isEvaluate, isPsdAnalysis, isTrendAnalysis } from '../../../../+store/menu-selectors/menu-selectors.helpers';
import {
  BaseContentProvider,
  DrawableContentProviderComponent,
  DrawableRegistryService,
} from '../../../../shared/services/drawable-registry.service';
import { pptImageAspectRatio } from '../../../../shared/helpers/document-generator/generators/ppt/style-constants';

const defaultImageSizeDoc: ImageSize = { width: 800, height: 600 };
const defaultImageSizePpt: ImageSize = { width: 1200, height: 1200 / pptImageAspectRatio };
const wellDrawingHorizontalLayoutImageRatio = 0.3;
const wellDrawingVerticalLayoutImageRatio = 1;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportComponent extends DbDependentComponent {
  @ViewChild('imageRenderingContainer', { read: ViewContainerRef }) public imageRenderingContainer!: ViewContainerRef;
  @ViewChild(ReportingRangeSelectionComponent, { read: ViewContainerRef }) public chartsSelectorComponent!: ViewContainerRef;

  public reportInfo!: ReportInfoDto;
  public reportInfoFields!: ReportInfoFields;
  public isReportPanelVisible$ = this.store.select(getIsReportPanelVisible);
  public isTrendAnalysisRunning$ = this.store.select(getIsTrendAnalysisRunning);
  public isRangeSelectionComponentVisible$ = this.store.select(getIsRangesSelectionComponentVisible);

  public UnitType = UnitSystem;
  public date: Date = new Date();

  public readonly appFormInputWidth = 225;
  public readonly appFormInputMinWidth = 175;
  public readonly chartName = 'generate-report-chart';

  public readonly reportTypeItems: IRadioItem<ReportFileType>[] = [
    { value: 'docx', text: 'Word' },
    { value: 'pptx', text: 'PowerPoint' },
  ];

  public imageRenderingContainerVisible = false;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    private drawableRegistryService: DrawableRegistryService,
    private fileDisplayNamePipe: FileDisplayNamePipe,
    private readonly recorderService: VideoRecorderService,
  ) {
    super(store, cdRef);
    store.dispatch(uiActions.setReportingTabAction({ tabType: 'report' }));
    this.store.dispatch(loadReferenceVariablesAction({ scenarioId: this.currentScenarioId }));

    this.subscription.add(
      this.store.select(getReportInfo).subscribe((reportInfo) => {
        this.reportInfo = reportInfo;
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getReportInfoFields).subscribe((reportInfoFields) => {
        this.reportInfoFields = reportInfoFields;
        this.date = reportInfoFields.Date === 0 ? new Date() : new Date(reportInfoFields.Date);
      }),
    );

    super.onHelpChange('results', 'report');
  }

  public updateReportInfoFields(props: ObjectChangeProp<ReportInfoFields>): void {
    if (props.key === 'Date') {
      const newDate = props.value == null || props.value === 0 ? new Date().getTime() : (props.value as number);
      this.store.dispatch(updateReportInfoFieldsAction({ changes: [{ value: newDate, changedKey: props.key }] }));
      return;
    }

    this.store.dispatch(
      updateReportInfoFieldsAction({
        changes: [
          {
            value: props.value as any,
            changedKey: props.key,
          },
        ],
      }),
    ); // Todo as any
  }

  public get isWellDataDisabled(): boolean {
    return !this.reportInfoFields.IsWell;
  }

  public get isPpt(): boolean {
    return this.reportInfoFields.ReportType === 'pptx';
  }

  public get isEvaluationAnimationVisible(): boolean {
    return this.currentAppModuleType === ModuleType.Evaluate;
  }

  public get isSimulationAnimationVisible(): boolean {
    return isSimulateBased(this.currentAppModuleType) || this.currentAppModuleType === ModuleType.Evaluate;
  }

  public get isImportedDataInfoVisible(): boolean {
    return isEvaluate(this.currentAppModuleType) || isTrendAnalysis(this.currentAppModuleType) || isDataAnalysis(this.currentAppModuleType);
  }

  public get isTrendAnalysisInputsVisible(): boolean {
    return isTrendAnalysis(this.currentAppModuleType);
  }

  public get isInputDataVisible(): boolean {
    return isEvaluate(this.currentAppModuleType) || isSimulateBased(this.currentAppModuleType);
  }

  public get isSimulationSummaryVisible(): boolean {
    return isEvaluate(this.currentAppModuleType) || isSimulateBased(this.currentAppModuleType);
  }

  public get isPsdDataVisible(): boolean {
    return isPsdAnalysis(this.currentAppModuleType);
  }

  public get isChartsVisible(): boolean {
    return this.isInputDataVisible || this.isImportedDataInfoVisible;
  }

  public updateReportDateFieldField(event: PrimitiveChangeValue<Date>): void {
    const newTime = event.value.getTime();
    if (newTime !== this.date.getTime()) {
      this.updateReportInfoFields({
        key: 'Date',
        value: newTime,
        shouldResetResults: event.shouldResetResults,
      });
    }
  }

  public async toggleReportInfoCheckboxes(value: boolean): Promise<void> {
    if (this.currentAppModuleType == null) {
      this.currentAppModuleType = await firstValueFrom(this.store.select(getCurrentAppModuleType));
    }

    const actionProps: ToggleInfoFieldsActionPayload = {
      selectAll: value,
      isInputDataVisible: this.isInputDataVisible,
      isImportedDataInfoVisible: this.isImportedDataInfoVisible,
      moduleType: this.currentAppModuleType,
      isPsdDataVisible: this.isPsdDataVisible,
      isTrendAnalysisVisible: this.isTrendAnalysisInputsVisible,
      isSimulationAnimationVisible: this.isSimulationAnimationVisible,
      isEvaluationAnimationVisible: this.isEvaluationAnimationVisible,
      isChartsVisible: this.isChartsVisible,
      isSimulationSummaryVisible: this.isSimulationSummaryVisible,
    };

    this.store.dispatch(reportInfoSelectAllClearAllAction(actionProps));
  }

  public async generateReport(): Promise<void> {
    const isRangeSelectorDisplayed = await firstValueFrom(this.isRangeSelectionComponentVisible$);
    const keys = getKeys(this.reportInfoFields);

    const omitKeys: Array<keyof ReportInfoFields> = ['Date', 'ReportType'];
    if (!isRangeSelectorDisplayed) {
      omitKeys.push('RangeIds', 'ReportingTabIds');
    }
    const hasAnyOptionsSelected = keys.some((key) => {
      return !omitKeys.includes(key) && this.reportInfoFields[key];
    });

    if (!hasAnyOptionsSelected) {
      this.modalService.showAlert('Please select at least one option to generate the report.', 'Warning').then();
      return;
    }

    if (isRangeSelectorDisplayed) {
      const isAnyRangeSelected = this.reportInfoFields.RangeIds && this.reportInfoFields.RangeIds.length > 0;

      if (!isAnyRangeSelected) {
        this.modalService.showAlert('Please select at least one item in Range Selection Panel', 'Warning').then();
        return;
      }
    }

    const scenario = await firstValueFrom(this.store.select(getCurrentScenario));
    const file = await firstValueFrom(this.store.select(getConnectedFile));
    const inputValue = `${this.fileDisplayNamePipe.transform(file)} - ${scenario?.Name ?? 'Default'}`;
    this.cdRef.detectChanges();

    const reportName = await this.modalService.showPrompt(
      '',
      'Create New Report',
      'Enter new report name',
      'sm',
      undefined,
      undefined,
      true,
      undefined,
      undefined,
      inputValue,
      'generate-report-prompt',
      false,
    );

    if (reportName == null || typeof reportName !== 'string') {
      return;
    }

    const activityIndicator = this.modalService.showActivityOverlay('Generating images');

    const images = await this.generateImages(this.reportInfoFields.RangeIds ?? [], this.reportInfoFields.ReportingTabIds ?? []);

    activityIndicator.close();

    this.store.dispatch(
      generateReportAction({
        reportInfo: this.reportInfo,
        reportInfoFields: this.reportInfoFields,
        reportName: reportName,
        images,
      }),
    );
  }

  public emitValue(props: ObjectChangeProp<ReportInfoDto>): void {
    this.reportInfo = changeProp(this.reportInfo, props);
    this.store.dispatch(updateReportInfoAction(WsActionPropsFactory.updateDto(this.reportInfo, props)));
  }

  public override onScenarioLoaded(currentScenario: Scenario): void {
    if (currentScenario.Id !== -1) {
      this.store.dispatch(loadReportInfoModuleAction());
    }
  }

  public override onCurrentScenarioChanged(currentScenario: Scenario): void {
    if (currentScenario.Id !== -1) {
      this.store.dispatch(loadReportInfoModuleAction());
    }
  }

  public async renderReportingCharts(reportingTabs: ReportingTabDto[]): Promise<IReportingTabImage[]> {
    const result: IReportingTabImage[] = [];

    // The ReportingChartComponent includes a panel header and slider. It's challenging to dynamically hide these elements.
    // We apply height to the entire ReportingChartComponent.
    // To achieve the desired size for the chart canvas, we need to add some offsets to compensate.
    const mdTvdHeightOffset = 99;
    const timeVolumeHeightOffset = 67;
    const { width, height } = this.defaultImageSize;

    for (const reportingTab of reportingTabs) {
      const heightOffset = reportingTab.IsChartTimeVolume ? timeVolumeHeightOffset : mdTvdHeightOffset;

      const reportingChartComponentSize: ImageSize = {
        width,
        height: height + heightOffset,
      };

      this.store.dispatch(setSelectedReportingChartTabId({ tabId: reportingTab.Id }));

      const image = await this.renderImage(ReportingChartComponent, reportingChartComponentSize);

      result.push({ reportingTab, image });
    }

    return result;
  }

  private get defaultImageSize(): ImageSize {
    return this.isPpt ? defaultImageSizePpt : defaultImageSizeDoc;
  }

  public async prepareContentProviderComponent<T extends DrawableContentProviderComponent>(
    componentType: Type<T>,
    size: ImageSize,
    setInputs?: (component: T) => void,
  ): Promise<string> {
    // make rendering container visible (available)
    this.imageRenderingContainerVisible = true;
    this.cdRef.detectChanges();

    // set element size
    const elem = this.imageRenderingContainer.element as ElementRef<HTMLElement>;
    const parentElement = elem.nativeElement.parentElement;
    if (parentElement != null) {
      parentElement.style.width = `${size.width}px`;
      parentElement.style.height = `${size.height}px`;
    }

    // create component
    const providerId = `DrawableContentProviderComponent-${componentType.name}-${uuidV4()}`;
    const componentRef = this.imageRenderingContainer.createComponent<T>(componentType);
    componentRef.instance.drawableProviderId = providerId;

    // set component inputs
    setInputs?.(componentRef.instance);
    componentRef.changeDetectorRef.detectChanges();

    return providerId;
  }

  public async waitForProvider<T extends BaseContentProvider>(providerId: string, providers$: Observable<T[]>): Promise<T | undefined> {
    // stream of all providers with given id
    const allProviders$ = this.drawableRegistryService.allProviders$.pipe(
      map((providers) => providers.find((p) => p.id === providerId)),
      filterNil(),
      timeout(5000),
      catchError(() => of(undefined)),
    );

    // wait for generic provider to get registered
    const genericProvider = await firstValueFrom(allProviders$);
    if (genericProvider == null) {
      return undefined;
    }

    // try to find provider on list of 'specialised' providers
    // provider registered on global list of all providers might not be present on list of image / video providers if is not able to
    // provide content (has no data loaded)
    const providers = await firstValueFrom(providers$);
    return providers.find((p) => p.id === providerId);
  }

  public cleanupRenderingContainer(): void {
    // hide (remove) rendering container
    this.imageRenderingContainerVisible = false;
    this.cdRef.detectChanges();
  }

  public async renderImage<T extends DrawableContentProviderComponent>(
    componentType: Type<T>,
    size: ImageSize,
    setInputs?: (component: T) => void,
  ): Promise<Base64Image | undefined> {
    const providerId = await this.prepareContentProviderComponent<T>(componentType, size, setInputs);

    const provider = await this.waitForProvider(providerId, this.drawableRegistryService.imageProviders$);
    if (provider == null) {
      return undefined;
    }

    const image = await provider.getBase64Image();

    // hide (remove) rendering container
    this.cleanupRenderingContainer();

    return image ?? undefined;
  }

  public async recordVideo<T extends DrawableContentProviderComponent>(
    componentType: Type<T>,
    size: ImageSize,
    setInputs?: (component: T) => void,
  ): Promise<Base64Media | undefined> {
    const providerId = await this.prepareContentProviderComponent<T>(componentType, size, setInputs);

    const provider = await this.waitForProvider(providerId, this.drawableRegistryService.videoProviders$);
    if (provider == null) {
      return undefined;
    }

    const { id, getDisplayName, getCanvasForRecording, getBase64Image } = provider;
    const video = await this.recorderService.recordBase64Video({
      id,
      getDisplayName,
      getCanvasForRecording,
      getBase64Image,
    });

    // hide (remove) rendering container
    this.cleanupRenderingContainer();

    return video;
  }

  public async captureMedia<T extends DrawableContentProviderComponent>(
    componentType: Type<T>,
    size: ImageSize,
    video: boolean,
    setInputs?: (component: T) => void,
  ): Promise<Base64Image | Base64Media | undefined> {
    if (video) {
      return this.recordVideo<T>(componentType, size, setInputs);
    } else {
      return this.renderImage<T>(componentType, size, setInputs);
    }
  }

  private async generateImages(selectedRanges: number[] = [], selectedReportingTabs: number[] = []): Promise<ReportScenarioImages> {
    this.store.dispatch(clearResultsChartsData());

    const images: ReportScenarioImages = {};

    if (this.reportInfoFields.IsWell) {
      images.surveyChart = await this.renderImage(SurveyPreviewChartRendererComponent, this.defaultImageSize);

      const wellModuleState = await firstValueFrom(this.store.select(getWellModuleState));
      if (CaliperValidation.IsCaliperScreenEnabled(wellModuleState)) {
        images.caliperChart = await this.renderImage(CaliperPreviewChartRendererComponent, this.defaultImageSize);
      }
    }

    if (this.reportInfoFields.IsCompletion) {
      images.schematic = await this.renderImage(SchematicComponent, { width: 1100, height: 700 });
    }

    const range = await firstValueFrom(this.store.select(getCurrentRange));

    for (const rangeId of selectedRanges) {
      this.store.dispatch(changeCurrentRangeIdAction({ rangeId }));
      if (images.rangeImages == null) {
        images.rangeImages = {};
      }
      images.rangeImages[rangeId] = {};

      if (rangeId != RangeConstants.EntireRangeId) {
        if (this.reportInfoFields.IsTrendAnalysisInputs) {
          images.rangeImages[rangeId].trendAnalysisInputs = await this.renderImage(
            TrendAnalysisChartComponent,
            this.defaultImageSize,
            (component) => (component.noHeader = true),
          );
        }
      }

      const areCurrentResultsPresent = await firstValueFrom(this.store.select(getAreCurrentResultsPresent));
      if (areCurrentResultsPresent) {
        if (this.reportInfoFields.IsWellVisualization) {
          const wellPart = await firstValueFrom(this.store.select(getEffectiveWellVisualizationWellPart));
          const layout = await firstValueFrom(this.store.select(getWellVisualizationWellLayout));
          const video = this.isPpt;

          images.rangeImages[rangeId].wellVisualization = await this.captureMedia(
            AnimationPackingDrawingComponent,
            this.defaultImageSize,
            video,
            (component) => {
              component.drawingSection = wellPart;
              component.layoutType = layout;
            },
          );
        }

        if (this.reportInfoFields.IsSimulationAnimation) {
          const wellPart = await firstValueFrom(this.store.select(getEffectiveSimulationAnimationWellPart));
          const layout = await firstValueFrom(this.store.select(getEffectiveSimulationAnimationWellLayout));
          const drawingLayoutType = layout === AnimationLayout.Horizontal ? DrawingLayoutType.Horizontal : DrawingLayoutType.Vertical;

          const { width } = this.defaultImageSize;
          const verticalSize = { width, height: width * wellDrawingVerticalLayoutImageRatio };
          const horizontalSize = { width, height: width * wellDrawingHorizontalLayoutImageRatio };
          const imageSize = layout === AnimationLayout.Horizontal ? horizontalSize : verticalSize;
          const renderVideo = this.isPpt;

          this.store.dispatch(
            checkDepthDataAction({
              depthDataKeys: [
                {
                  moduleType: this.currentAppModuleType,
                  scenarioId: this.currentScenarioId,
                  rangeId: this.currentRangeId,
                },
              ],
            }),
          );

          const isFluidPro = this.currentAppModuleType === ModuleType.Simulate_Disp;
          images.rangeImages[rangeId].simulationAnimationPressureChart = await this.renderImage(
            AnimationTimeBasedChartComponent,
            this.defaultImageSize,
            (component) => {
              component.chartDataSourceType = isFluidPro
                ? ChartDataSourceType.ChartSourceFluidProPressureAndECD
                : ChartDataSourceType.ChartSourceResultsTimeBased;
            },
          );

          images.rangeImages[rangeId].simulationAnimationWell = await this.captureMedia(
            AnimationPackingDrawingComponent,
            imageSize,
            renderVideo,
            (component) => {
              component.layoutType = drawingLayoutType;
              component.drawingSection = wellPart;
            },
          );

          const addPerfPackingChart = await firstValueFrom(this.store.select(getCanRenderDepthChart));
          if (addPerfPackingChart) {
            images.rangeImages[rangeId].simulationAnimationPerfPackingChart = await this.captureMedia(
              AnimationDepthBasedChartComponent,
              this.defaultImageSize,
              renderVideo,
              (component) => {
                component.layoutType = drawingLayoutType;
              },
            );
          }
        }

        if (this.reportInfoFields.IsEvaluationAnimation) {
          images.rangeImages[rangeId].evaluationAnimationFrictionChart = await this.renderImage(
            AnimationTimeBasedChartComponent,
            this.defaultImageSize,
            (component) => {
              component.chartDataSourceType = ChartDataSourceType.ChartSourceEvaluateFriction;
            },
          );

          images.rangeImages[rangeId].evaluationAnimationPressureChart = await this.renderImage(
            AnimationTimeBasedChartComponent,
            this.defaultImageSize,
            (component) => {
              component.chartDataSourceType = ChartDataSourceType.ChartSourceEvaluatePressure;
            },
          );
        }
      }

      if (this.reportInfoFields.IsCharts) {
        const reportingTabs = await firstValueFrom(this.store.select(getSortedReportingTabsForCurrentRange));
        const reportingTabsToRender = reportingTabs.filter((rt) => selectedReportingTabs.includes(rt.Id));
        if (reportingTabsToRender?.length > 0) {
          images.rangeImages[rangeId].reportingTabs = await this.renderReportingCharts(reportingTabsToRender);
        }
      }
    }
    this.store.dispatch(changeCurrentRangeIdAction({ rangeId: range?.Id ?? RangeConstants.EmptyRangeId }));

    if (this.reportInfoFields.IsPsdInput) {
      const psdDict = await firstValueFrom(this.store.select(getPsdDict));
      const psdReadingsDict = await firstValueFrom(this.store.select(getPsdReadingsDict));

      const resultDict: { [id: string]: Base64Image | undefined } = {};
      for (const key of Object.keys(psdDict)) {
        const currPSD = psdDict[key];
        const currReadingsPSD = psdReadingsDict[key];
        if (currPSD?.IsSelected && currReadingsPSD) {
          resultDict[key] = await this.renderImage(PsdChartPanelRendererComponent, this.defaultImageSize, (component) => {
            component.selectedPSD = currPSD;
            component.selectedPSDReadings = currReadingsPSD;
          });
        }
      }
      images.psdInputChart = resultDict;
    }

    if (this.reportInfoFields.IsPsdAnalysis) {
      images.psdAnalysisChart = await this.renderImage(ConsolidatedPsdChartRendererComponent, this.defaultImageSize);
    }

    return images;
  }

  public onReportTypeChanged(event: PrimitiveChangeValue<ReportFileType>): void {
    const changes: { value: DataSourceValue<ReportInfoFields>; changedKey: keyof ReportInfoFields }[] = [];
    if (event.value === 'pptx') {
      changes.push({ value: false, changedKey: 'IsWellCaliperData' });
      changes.push({ value: false, changedKey: 'IsWellSurveyData' });
      changes.push({ value: false, changedKey: 'IsSettings' });
    }
    changes.push({ value: event.value, changedKey: 'ReportType' });

    this.store.dispatch(updateReportInfoFieldsAction({ changes }));
  }
}
