import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WellModuleFeatureName } from './well.reducer';
import { WellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { PipeType } from '@dunefront/common/dto/pipe.dto';
import { WellModuleCalculations } from '@dunefront/common/modules/well/well-module.calculations';
import { getRowsForCalculations } from '@dunefront/common/common/common-grid.interfaces';
import { SurveyCalculations } from '@dunefront/common/modules/well/dto/survey/survey.dto';
import { WellCalculations } from '@dunefront/common/modules/well/model/well.calculations';

export type WellOrientation = 'vertical' | 'horizontal';

export const getWellRawModuleState = createFeatureSelector<WellModuleState>(WellModuleFeatureName);

export const getWellModuleState = createSelector(getWellRawModuleState, (state) => WellModuleCalculations.applyCalculations(state));

export const getIsWellStateLoaded = createSelector(getWellModuleState, (state) => state.isLoaded);

export const getHasOpenHole = createSelector(
  getWellModuleState,
  (state) => !!state.CasingData.rows.find((row) => row.rowData.PipeType === PipeType.Open_Hole),
);

export const getHasPerforatedCasing = createSelector(getWellModuleState, (state) =>
  state.CasingData.rows.some((row) => row.rowData.PipeType === PipeType.Perforated_Casing),
);

export const getWellTreatmentType = createSelector(getWellModuleState, (state) => state.TreatmentType);
export const getSurveyDataForCalculation = createSelector(getWellModuleState, (state) => getRowsForCalculations(state.SurveyData.rows));
export const getIsOpenHole = createSelector(getWellModuleState, (state) => WellCalculations.IsOpenHole(state));
export const getMaxSurveyMD = createSelector(getSurveyDataForCalculation, (survey) => SurveyCalculations.MaxSurveyMD(survey));
export const getZonesDataForCalculations = createSelector(getWellModuleState, (state) => getRowsForCalculations(state.ZoneData.rows));
export const getCasingDataForCalculation = createSelector(getWellModuleState, (state) => getRowsForCalculations(state.CasingData.rows));
export const getCaliperDataForCalculation = createSelector(getWellModuleState, (state) => getRowsForCalculations(state.CaliperData.rows));
